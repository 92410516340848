/* eslint-disable max-len */
import React from 'react';

export default function HabitWaterIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.76 16.237c0-.884.386-2.148.961-3.543-1.047-1.86-1.962-3.298-1.962-3.298s-5 7.845-5 10.605a5 5 0 0 0 5 5 4.993 4.993 0 0 0 4.542-2.922 6.596 6.596 0 0 1-3.542-5.842z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M19.352 4.788c-1.44 2.343-3.823 6.505-4.842 9.347-.302.845-.486 1.574-.486 2.102a5.326 5.326 0 0 0 2.657 4.603 5.298 5.298 0 0 0 2.671.724 5.333 5.333 0 0 0 5.328-5.327c0-2.305-3.46-8.41-5.328-11.45z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
