/* eslint-disable max-len */
import React from 'react';

export default function HabitStarIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.503 12.488a.2.2 0 0 0-.117-.362h-7.447l-2.373-7.521a.2.2 0 0 0-.381 0l-2.372 7.52H5.365a.2.2 0 0 0-.117.363l6.378 4.637-2.883 7.494c-.071.186.143.351.304.234l6.329-4.603 6.328 4.603c.161.117.376-.048.304-.234l-2.882-7.494 6.377-4.637z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
