import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { colors } from '~/Config/Colors';
import { getColors } from '~/Redux/Modules/Colors';
import { useMountEffect } from '~/util';

import AppFeatureView, { CUSTOM_COLORS } from '../AppFeatureView';
import ColorButtonGrid from './ColorButtonGrid';

import './ColorPicker.scss';

const ColorPicker = ({
  selectedColor,
  name,
  onChange,
  parentToggle,
  showPreview,
  roundedPreview,
  isColorPickerInEventModal,
}) => {
  const dispatch = useDispatch();

  const customColors = useSelector(({ Colors }) => Colors.colors);

  const [open, setOpen] = useState(false);

  useMountEffect(() => {
    dispatch(getColors());
  });

  const handleColorSelect = useCallback(color => onChange?.({ [name]: color }), [name, onChange]);

  const toggle = useCallback(() => {
    setOpen(open => !open);
  }, [setOpen]);

  // Render Methods
  const renderPreview = () => {
    return (
      <>
        <FontAwesome
          className="preview"
          name="square"
          size="2x"
          onClick={toggle}
          style={
            roundedPreview
              ? { color: selectedColor }
              : {
                  color: 'transparent',
                  background: selectedColor,
                  lineHeight: '2.4rem',
                }
          }
        />
        <FontAwesome onClick={toggle} name="caret-down" size="lg" />
      </>
    );
  };

  return (
    <div className="color-picker-wrapper">
      {showPreview && renderPreview()}
      {(parentToggle || open) && (
        <div
          id={cn({ 'event-modal-color-picker': isColorPickerInEventModal })}
          className="color-picker"
        >
          <ColorButtonGrid
            selectedColor={selectedColor}
            colors={colors}
            onSelect={handleColorSelect}
            isAdjustable
          />

          <AppFeatureView featureName={CUSTOM_COLORS}>
            <section className="my-colors">
              <h2 className="heading">My Colors</h2>

              <ColorButtonGrid
                selectedColor={selectedColor}
                colors={customColors}
                onSelect={handleColorSelect}
                isGrowable
                isEditable
              />
            </section>
          </AppFeatureView>
        </div>
      )}
    </div>
  );
};

ColorPicker.propTypes = {
  selectedColor: propTypes.string,
  name: propTypes.string,
  onChange: propTypes.func,
  parentToggle: propTypes.bool,
  showPreview: propTypes.bool,
  roundedPreview: propTypes.bool,
  isColorPickerInEventModal: propTypes.bool,
};

ColorPicker.defaultProps = {
  name: 'color',
  parentToggle: false,
  showPreview: false,
  roundedPreview: false,
};

export default ColorPicker;
