import React from 'react';
import { useManageSubscription } from '~/Components/ManageSubscription';

const ManageSubscriptionSection = props => {
  const manageSubscription = useManageSubscription();

  return (
    <section className="payments__manage">
      <a onClick={() => manageSubscription()}>MANAGE PLAN</a>
    </section>
  );
};

export default ManageSubscriptionSection;
