export const ADD_NOTIFICATION = 'artful-client/notification/ADD_NOTIFICATION';
export const NOTIFICATION_ADDED = 'artful-client/notification/NOTIFICATION_ADDED';
export const CLEAR_NOTIFICATIONS = 'artful-client/notification/CLEAR_NOTIFICATIONS';
export const NOTIFICATION_REMOVED = 'artful-client/notification/NOTIFICATION_REMOVED';

const initialState = {
  displayed: [], // array of uids of rendered elements, used if we need to clear all notifications
  queued: [],
  clear: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return { ...state, queued: [...state.queued, action.notification], clear: false };
    case NOTIFICATION_ADDED: {
      const queued = state.queued.filter(notification => {
        return notification.title !== action.title;
      });
      return { ...state, displayed: [...state.displayed, action.uid], queued };
    }
    case CLEAR_NOTIFICATIONS:
      return { ...state, clear: true };
    case NOTIFICATION_REMOVED: {
      const displayed = state.displayed.filter(uid => uid !== action.uid);
      return { ...state, displayed };
    }
    default:
      return state;
  }
}

export const warning = (title, message, duration = 10, action) => dispatch => {
  dispatch(addNotification(title, message, 'warning', duration, action));
};

export const error = (title, message, duration = 15, action) => dispatch => {
  dispatch(addNotification(title, message, 'error', duration, action));
};

export const success = (title, message, duration = 4, action) => dispatch => {
  dispatch(addNotification(title, message, 'success', duration, action));
};

/**
 * Dismiss all active notification
 * @param  {[type]} ) [description]
 * @return {[type]}   [description]
 */
export const clearNotifications = () => dispatch => {
  dispatch({ type: CLEAR_NOTIFICATIONS });
};

/**
 * Fire after a single notification is dismissed programatically to remove it from the stack
 * @param  {[type]} title) [description]
 * @return {[type]}        [description]
 */
export const notificationRemoved = uid => dispatch => {
  dispatch({ type: NOTIFICATION_REMOVED, uid });
};

/**
 * Dispatches an action to add notification to the notifications stack
 * @param  {[type]} title   [description]
 * @param  {[type]} message [description]
 * @param  {[type]} level)  [description]
 * @return {[type]}         [description]
 */
const addNotification = (title, message, level, autoDismiss = 0, action = false) => dispatch => {
  let notification = {
    title,
    message,
    level,
    autoDismiss, // user must click this notification away
    position: 'tr', // display notification in top right of screen
    onAdd: notification => {
      // Remove notification from the notifications array so that we don't re-render it
      dispatch({ type: NOTIFICATION_ADDED, title: title, uid: notification.uid });
    },
  };

  if (action) notification.action = action;

  dispatch({
    type: ADD_NOTIFICATION,
    notification,
  });
};
