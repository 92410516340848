import React from 'react';
import propTypes from 'prop-types';
import { guid } from '~/util';

const Checkbox = ({
  name,
  label,
  value,
  isDisabled,
  className,
  onChange,
  formatted,
  checklistStyle = 'normal',
}) => {
  const updateField = e => {
    return onChange(formatted ? { [e.target.name]: e.target.checked } : e);
  };
  const nameToUse = name || guid();
  return (
    <>
      <input
        key="a"
        id={nameToUse}
        className={`${className}`}
        disabled={isDisabled}
        name={nameToUse}
        onChange={updateField}
        type="checkbox"
        checked={value}
      />
      <label
        key="b"
        htmlFor={nameToUse}
        className={value ? 'checked' : ''}
        onClick={e =>
          console.log('checkbox label click', e.defaultPrevented, e.isDefaultPrevented(), e.target)
        }
      >
        <div className={checklistStyle}>{label}</div>
      </label>
    </>
  );
};

Checkbox.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  value: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  isDisabled: propTypes.bool,
  onChange: propTypes.func,
  artful: propTypes.bool,
  isRegistrationScreen: propTypes.bool,
};

Checkbox.defaultProps = {
  isDisabled: false,
  formatted: true,
  value: false,
  className: '',
};

export default Checkbox;
