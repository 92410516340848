import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import history from '~/Providers/History';

import AdminPage from '~/Components/Layout/AdminPage/AdminPage';
import StickerPackForm from '~/Components/Pages/Admin/AdminStickerPacks/StickerPackForm';
import Button from '~/Components/Global/Form/Button';

import './AdminStickerPacks.scss';

class AdminStickerPacks extends Component {
  constructor() {
    super();
    this.columns = [
      { header: 'Name', accessor: sp => sp.name },
      { header: 'Product ID', accessor: sp => sp.product.id },
      { header: 'Icons', accessor: sp => this.generatePackPreview(sp) },
      {
        header: 'Price',
        accessor: s => (s.product?.price ? `$${s.product.price}` : ''),
      },
      { header: 'Code', accessor: sp => sp.product && sp.product.unlock_code },
      { header: 'Fundraiser', accessor: s => (s.product?.is_fundraiser ? 'Y' : '') },
      {
        header: 'Actions',
        accessor: sp => {
          return (
            <Link className="actions center-button" to={`${this.props.match.path}/${sp.id}`}>
              {this.generateDeleteButton(sp)}
            </Link>
          );
        },
      },
    ];
  }

  generatePackPreview = stickerPack => {
    return stickerPack.stickers.map((s, index) => {
      return <img className="table-sticker" key={index} src={s.url} alt="" />;
    });
  };

  deleteStickerPack = stickerPack => {
    const { deleteStickerPack } = this.props;
    if (window.confirm('Are you sure you want to delete this sticker pack?')) {
      deleteStickerPack(stickerPack);
    }
  };

  generateDeleteButton = stickerPack => {
    const { actionId, isDeleting } = this.props;
    return [
      <Button
        text="Delete"
        actionText="Deleting"
        onClick={() => this.deleteStickerPack(stickerPack.id)}
        isFetching={isDeleting && stickerPack.id === actionId}
      />,
      <Button
        key="b"
        text="Edit"
        onClick={() => history.push(`/admin/sticker-packs/${stickerPack.id}`)}
      />,
    ];
  };

  componentDidMount = () => {
    this.props.getStickers();
  };

  render() {
    const {
      stickers,
      stickerPacks,
      isFetching,
      isAdding,
      getStickerPacks,
      actionStickerPack,
      updateForm,
      addStickerPack,
      getStickers,
    } = this.props;
    return (
      <div className="admin-sticker-packs">
        <AdminPage
          header="Sticker Packs"
          dataObject={stickerPacks}
          isFetching={isFetching}
          columns={this.columns}
          getData={getStickerPacks}
          dataName="Sticker Pack"
        >
          <StickerPackForm
            isUpdating={isAdding}
            onSubmit={() =>
              addStickerPack(actionStickerPack).then(({ data: { id } }) =>
                history.push(`/admin/sticker-packs/${id}`)
              )
            }
            data={actionStickerPack}
            onChange={updateForm}
            getNewRecords={getStickers}
            buttonText="Add Sticker Pack"
            actionText="Adding Sticker Pack"
          />
        </AdminPage>
      </div>
    );
  }
}

AdminStickerPacks.propTypes = {
  stickers: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  isFetching: propTypes.bool,
  isDeleting: propTypes.bool,
  isAdding: propTypes.bool,
  match: propTypes.object,
  addStickerPack: propTypes.func,
  updateForm: propTypes.func,
  getStickers: propTypes.func,
  getStickerPacks: propTypes.func,
  deleteStickerPack: propTypes.func,
  stickerPacks: propTypes.array,
  actionId: propTypes.oneOfType([propTypes.number, propTypes.bool]),
  actionStickerPack: propTypes.oneOfType([propTypes.number, propTypes.bool]),
};

export default AdminStickerPacks;
