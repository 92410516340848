/* eslint-disable max-len */
import React from 'react';

export default function HabitPeopleIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.952 10.268a2.267 2.267 0 1 1-4.534.001 2.267 2.267 0 0 1 4.534-.001zM25.584 10.268a2.268 2.268 0 1 1-4.536 0 2.268 2.268 0 0 1 4.536 0zM23.316 14.047c-.68 0-1.286.227-1.89.529l-6.425 3.78-6.425-3.78c-.606-.302-1.211-.529-1.892-.529a3.743 3.743 0 0 0-3.78 3.78v6.049h7.559v-4.688l3.78 2.268c.152.075.227.152.379.152.151 0 .226.075.378.075s.227 0 .379-.075c.151 0 .226-.075.378-.152l3.78-2.268-.001 4.686h7.559v-6.048a3.742 3.742 0 0 0-3.78-3.779z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
