export const CONFIRM_COLOR_REMOVAL_MODAL = 'CONFIRM_COLOR_REMOVAL_MODAL';
export const CONFIRM_DISCONNECT_CALENDAR_ACCOUNT_MODAL =
  'CONFIRM_DISCONNECT_CALENDAR_ACCOUNT_MODAL';
export const CONFIRM_STICKER_REMOVAL_MODAL = 'CONFIRM_STICKER_REMOVAL_MODAL';
export const EVENT_CREATE_MODAL = 'EVENT_CREATE_MODAL';
export const HABIT_CREATE_EDIT_MODAL = 'HABIT_CREATE_EDIT_MODAL';
export const HABIT_DELETE_STOP_TRACKING_MODAL = 'HABIT_DELETE_STOP_TRACKING_MODAL';
export const MANAGE_SUBSCRIPTION_MODAL = 'MANAGE_SUBSCRIPTION_MODAL';
export const SWAP_TO_STRIPE_MODAL = 'SWAP_TO_STRIPE_MODAL';
export const MY_ACCOUNT_MODAL = 'MY_ACCOUNT_MODAL';
export const PAYMENT_MODAL = 'PAYMENT_MODAL';
export const REPORT_PROBLEM_MODAL = 'REPORT_PROBLEM_MODAL';
export const REVALIDATE_PASSWORD_MODAL = 'REVALIDATE_PASSWORD_MODAL';
export const UPLOAD_STICKERS_MODAL = 'UPLOAD_STICKERS_MODAL';
export const STICKER_EDIT_TAGS_MODAL = 'STICKER_EDIT_TAGS_MODAL';
export const CALENDAR_DISPLAY_ERROR_MODAL = 'CALENDAR_DISPLAY_ERROR_MODAL';
export const EVENT_DISPLAY_WARNING_MODAL = 'EVENT_DISPLAY_WARNING_MODAL';
