/* eslint-disable max-len */
import React from 'react';

export default function HabitCalendarIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.766 8.085h5.553v-1.85h-5.553V5h-1.83v4.32h1.83V8.084z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M20.064 6.234V5h-1.83v4.32h1.83V8.084h3.213v3.021H6.724v-3.02H9V6.233H4.893V25h20.213V6.234h-5.042zm3.212 16.936H6.724V12.915h16.531V23.17h.021z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M8.617 14.766h3.276v2.362H8.617v-2.362zM13.362 14.766h3.276v2.362h-3.276v-2.362zM18.107 14.766h3.276v2.362h-3.276v-2.362zM8.617 18.958h3.276v2.362H8.617v-2.362zM13.362 18.958h3.276v2.362h-3.276v-2.362zM18.107 18.958h3.276v2.362h-3.276v-2.362z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
