/* eslint-disable max-len */
import React from 'react';

export default function HabitAlarmClockIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#vijm60r2ma)">
        <path
          className="fill"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.83 5.938V6.992h5.393V5.938H11.83zm10.393 1.055c.736 0 1.443.278 1.964.773.52.495.813 1.166.813 1.866 0 .7-.293 1.371-.813 1.866l-3.929-3.732a2.857 2.857 0 0 1 1.965-.773zM5 9.633c0-.7.292-1.372.813-1.867a2.856 2.856 0 0 1 1.964-.773c.737 0 1.443.278 1.965.773l-3.929 3.732A2.573 2.573 0 0 1 5 9.632zm16.285 12.831c1.667-1.583 2.604-3.731 2.604-5.97v-.002a8.12 8.12 0 0 0-1.192-4.221 8.679 8.679 0 0 0-3.253-3.091A9.247 9.247 0 0 0 15 8.049c-2.358 0-4.618.89-6.285 2.473-1.667 1.584-2.604 3.731-2.604 5.971s.937 4.388 2.604 5.971c1.667 1.584 3.927 2.474 6.285 2.474s4.619-.89 6.285-2.474zm-7.284-5.527v-6h1.504v4.583H19v1.418l-5-.001z"
          fill={props.fill ? props.fill : '#000'}
        />
      </g>
      <defs>
        <clipPath id="vijm60r2ma">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
