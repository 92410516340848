import { connect } from 'react-redux';

import AdminSkins from '~/Components/Pages/Admin/AdminSkins/AdminSkins';
import {
  getSkins,
  updateForm,
  addSkin,
  deleteSkin,
  updateFormImage,
} from '~/Redux/Modules/Admin/Skins';

const mapStateToProps = ({ Skins }) => {
  return {
    skins: Skins.data,
    isFetching: Skins.isFetching,
    isAdding: Skins.isAdding,
    isUpdating: Skins.isUpdating,
    isDeleting: Skins.isDeleting,
    actionSkin: Skins.actionSkin,
    actionId: Skins.actionId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSkins: (page, search) => dispatch(getSkins(page, search)),
    addSkin: data => dispatch(addSkin(data)),
    updateForm: update => dispatch(updateForm(update)),
    deleteSkin: id => dispatch(deleteSkin(id)),
    updateFormImage: (image, name) => dispatch(updateFormImage(image, name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSkins);
