import { combineReducers } from 'redux';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  CLEAR_LISTS,
  SET_CURRENT,
  UNSET_CURRENT,
  START_UPDATING,
  FINISH_UPDATING,
  SET_QUEUE,
  SET_LAST_UPDATE,
  TOGGLE_RECOVER,
} from './constants';

const listsInitialState = {
  isFetching: false,
  isSaving: false,
  fetched: false,
  data: [],
  current: false,
  error: '',
  inRecoveryMode: false,
};

const listsReducer = (state = listsInitialState, action) => {
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true, fetched: false };
    case FETCH_SUCCESS:
      return { ...state, isFetching: false, fetched: true, data: action.data };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        error: action.error,
      };

    case FETCH_ONE:
      return { ...state, isFetching: true, fetched: false };
    case FETCH_ONE_SUCCESS: {
      const { id } = action;
      return {
        ...state,
        isFetching: false,
        fetched: true,
        data: [...state.data.filter(list => list.id !== id), action.data],
        current: state.current.id === id ? action.data : state.current,
      };
    }
    case FETCH_ONE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case UPDATE:
      return {
        ...state,
        isFetching: true,
        isSaving: true,
        data: state.data.map(ul => (ul.id === action.data.id ? { ...ul, ...action.data } : ul)),
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        data: state.data.map(ul => (ul.id === action.data.id ? action.data : ul)),
        current: state.current && state.current.id === action.data.id ? action.data : state.current,
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        error: action.error,
      };

    case DELETE:
      return { ...state, isFetching: true };
    case DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.filter(ul => ul.id !== action.data.id),
      };
    case DELETE_FAILURE:
      return { ...state, isFetching: false, error: action.error };

    case STORE:
      return { ...state, isFetching: true, isSaving: true };
    case STORE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        data: [...state.data, action.data],
      };
    case STORE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        error: action.error,
      };

    case SET_CURRENT:
      return { ...state, current: action.data };

    case UNSET_CURRENT:
      return { ...state, current: false };

    case TOGGLE_RECOVER:
      return { ...state, inRecoveryMode: action.payload };

    case CLEAR_LISTS:
      return listsInitialState;
    default:
      return state;
  }
};

const updatesInitialState = {
  queue: [],
  lastUpdates: {},
  isUpdating: false,
};

const updatesReducer = (state = updatesInitialState, action) => {
  switch (action.type) {
    case START_UPDATING:
      return { ...state, isUpdating: true };
    case FINISH_UPDATING:
      return { ...state, isUpdating: false };
    case SET_QUEUE:
      return { ...state, queue: action.queue };

    case SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdates: {
          ...state.lastUpdates,
          [action.original.id]: { updated_at: action.data.updated_at },
        },
      };
  }
  return state;
};

export default combineReducers({
  updates: updatesReducer,
  lists: listsReducer,
});
