import { useState } from 'react';
import { ConsentApi } from '~/Providers/Api';
import { useMountEffect } from '~/util';

export const useConsentSettings = () => {
  const [settings, setSettings] = useState(null);

  useMountEffect(() => {
    ConsentApi.settings().then(settings => {
      setSettings(settings);
    });
  });

  return settings;
};

export default useConsentSettings;
