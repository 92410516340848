/* eslint-disable max-len */
import React from 'react';

export default function HabitCloudIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.5 15.25c-.467 0-.933.074-1.4.299.156-.3.233-.675.233-1.05 0-1.65-1.4-3-3.11-3-.7 0-1.244.225-1.79.525C18.656 9.099 15.933 7 12.667 7c-3.89 0-7 3-7 6.75 0 .299 0 .6.077.825-.31-.075-.544-.075-.855-.075C2.712 14.5 1 16.15 1 18.25 1 22 1 22 4.89 22H25.5c3.5 0 3.5 0 3.5-3.375 0-1.875-1.556-3.376-3.5-3.376z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
