import reducer from './reducers';

export { SET_RANGE_TYPE, INCREMENT, DECREMENT } from './constants';

export {
  setToday,
  setDayType,
  setWeekType,
  setMonthType,
  setHabitMonth,
  setLocale,
  onDayChange,
  onWeekChange,
  onMonthChange,
  onHabitChange,
  increment,
  decrement,
  getChanges,
  updateMonthPath,
  updateWeekPath,
  updateDayPath,
  updateHabitPath,
  heartbeat,
} from './actions';

export default reducer;
