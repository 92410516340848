import config from '~/Config';
import { APPLE } from '~/Config/Drivers';

import Http from '~/Services/Http';
import Storage from '~/Providers/Storage';
import { findUserTimeZone } from '~/Context/Event';
import { DATE_FORMAT } from '~/Redux/Constants';

// API provider is just a configured instance of a HTTP client
// TODO: Swap this out for a more robust subdomain parser
const api = new Http({ host: config.api.baseUrl });

api.getHeaders = () => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Artful-Client': `Web Version ${VERSION}+${BUILD_NUMBER} (${COMMIT}) / timezone: ${findUserTimeZone()} / userAgent: ${
      window.navigator.userAgent
    }`,
  };

  const accessToken = Storage.get('access_token');
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

  return headers;
};

export default api;

export const AuthApi = {
  createToken: creds => api.post('/oauth/token', creds),
  requestPasswordReset: data => api.post('/api/password/email', data),
  resetPassword: data => api.post('/api/password/reset', data),
  status: () => api.get('api/login/status'),
};

export const featureFlagApi = {
  fetch: () => api.get('api/feature-flags'),
};

export const appFeatureApi = {
  fetch: () => api.get('api/app-features'),
};

export const UserApi = {
  fetch: () => api.get('api/user'),
  updatePaymentMethod: data => api.put('api/user/update-payment-method', data),
  setGAClientId: data => api.put('api/user/set-client-id', data),
  updateTrackingStatus: tracking_status =>
    api.post('api/user/tracking-status', { tracking_status }),
  setDeviceId: device_id => api.put('api/user/device-id', { device_id }),
  deleteAccount: password => api.delete(`api/user/?password_confirmation=${password}`),
  historicalPlans: () => api.get('api/user/historical-plans'),
};

export const AccountApi = {
  get: () => api.get('api/user', { billing: 1 }),
  register: data => api.post('/api/register', { platform: 'web', ...data }),
  update: data => api.put('/api/user', data),
  updatePassword: data => api.put('/api/user/password', data),
  checkCode: code => api.get(`/api/checkRegisterCode?referral_code=${code}`),
};

export const ConsentApi = {
  settings: () => api.get(`${location.origin}/api/consent/settings`),
};

export const stripeApi = {
  subscribe: data => api.post('api/user/subscribe', data),
  subscribeWithPaymentMethod: data => api.post('api/user/subscribeWithPaymentMethod', data),
  startTrial: () => api.post('api/user/start-trial'),
  cancel: () => api.delete('api/user/subscribe'),
  change: data => api.put('api/user/subscribe', data),
};

export const setupIntentApi = {
  findOrCreate: () => api.get('api/user/setup-intent'),
  findById: id => api.get(`api/user/setup-intent/${id}`),
};

export const productApi = {
  purchase: (id, data) => api.post(`api/product/purchase/${id}`, data),
};

export const googleApi = {
  createToken: creds => api.post('api/login/google', creds),
  register: data => api.post('/api/register/google', { platform: 'web', ...data }),
  googleCallback: data => api.post('/api/google/callback', data),
  updateAccount: data => api.put('/api/calendar-accounts/google', data),
};

export const outlookApi = {
  outlookCallback: data => api.post('/api/outlook/callback', data),
  updateAccount: data => api.put('/api/calendar-accounts/outlook', data),
};

export const appleApi = {
  createToken: creds => api.post('api/login/apple', creds),
  register: data => api.post('/api/register/apple', { platform: 'web', ...data }),
  addAccount: data => api.post(`/api/register-account/${APPLE}`, data),
  updateAccount: data => api.put('/api/calendar-accounts/apple', data),
};

export const calendarAccountsApi = {
  fetchAll: () => api.get('/api/calendar-accounts'),
  sync: () => api.put('/api/calendar-accounts/sync'),
  activate: calendar => api.put(`api/calendars/activate/${calendar}`),
  deactivate: calendar => api.put(`api/calendars/deactivate/${calendar}`),
  hide: calendar => api.put(`api/calendars/hide/${calendar}`),
  unhide: calendar => api.put(`api/calendars/unhide/${calendar}`),
  color: (calendar, color) => api.put(`api/calendars/color/${calendar}`, color),
  deleteAccount: account => api.delete(`api/calendar-accounts/${account.id}`),
  updateAlarms: calendar => api.put(`api/calendars/alarms/${calendar.id}`, calendar),
  linkAccount: (driver, data) => api.post(`api/calendar-accounts/${driver}`, data),
  updateAccount: (account, data) => api.put(`api/calendar-accounts/${account.id}`, data),
  toggleUseCalendarTimezones: ({ id, use_calendar_timezones }) =>
    api.put(`api/calendar-accounts/${id}/use-calendar-timezones`, { use_calendar_timezones }),
  changeDisplayView: calendar => api.put(`api/calendars/displayView/${calendar.id}`, calendar),
};

export const contactsApi = {
  fetchAll: () => api.get('/api/contacts'),
};

export const couponApi = {
  check: coupon => api.get(`/api/coupon/check?coupon=${coupon}`),
};

export const errorApi = {
  noticeError: (error, info) => api.post('/api/frontend/error', { error, info }),
};

export const drawingsApi = {
  get: data => api.get('/api/drawings', data),
  store: data => api.post('/api/drawings', data),
};

export const eventsApi = {
  get: data => api.get('api/events', data),
  store: (data, calendar) => api.post(`api/events/${calendar}`, data),
  sync: data => api.put('api/events/sync', data),
  syncToken: () => api.get('api/events/syncToken'),
  update: data => api.put(`api/events/${data.id}`, data),
  updateLocal: data => api.put(`api/events/${data.id}/local`, data),
  delete: ({ id, confirm, startForRecurrence }) =>
    api.delete(`api/events/${id}?confirm=${confirm}&start=${startForRecurrence}`),
};

export const stickerPacksApi = {
  fetch: () => api.get('api/sticker-packs?with_my_stickers=true'),
};

export const skinsApi = {
  fetch: () => api.get('api/skins?hidden=1'),
};

export const droppedStickersApi = {
  fetch: data => api.get('api/stickers/dropped', data),
  drop: data => api.post('api/stickers/dropped', data),
  move: data => api.put(`api/stickers/dropped/${data.id}`, data),
  delete: id => api.delete(`api/stickers/dropped/${id}`),
  recent: () => api.get('api/stickers/recent'),
  frequent: () => api.get('api/stickers/frequent'),
  search: tag => api.get(`api/stickers/search?search=${tag}`),
};

export const userColorsApi = {
  fetch: () => api.get('api/colors'),
  store: hex => api.post('api/colors', { color: hex }),
  delete: id => api.delete(`api/colors/${id}`),
};

export const userStickersApi = {
  store: (data, onProgress) => api.upload('api/stickers/custom', data, onProgress),
  update: data => api.put(`api/stickers/custom/${data.id}`, data),
  delete: ({ id }) => api.delete(`api/stickers/custom/${id}`),
};

export const userListsApi = {
  fetch: () => api.get('api/user-lists'),
  fetchDeleted: () => api.get(`api/user-lists?deleted=true`),
  recoverDeleted: id => api.post(`/api/user-lists/${id}/restore`),
  fetchOne: id => api.get(`api/user-lists/${id}`),
  store: data => api.post('api/user-lists', data),
  update: data => api.put(`api/user-lists/${data.id}`, data),
  delete: data => api.delete(`api/user-lists/${data.id}`),
};

export const listGroupsApi = {
  fetch: () => api.get('api/list-groups'),
  fetchOne: id => api.get(`api/list-groups/${id}`),
  store: data => api.post('api/list-groups', data),
  update: data => api.put(`api/list-groups/${data.id}`, data),
  delete: data => api.delete(`api/list-groups/${data.id}`),
};

export const quoteApi = {
  fetch: data => api.get('api/quote', data),
};

export const checklistApi = {
  fetchAll: data => api.get('api/checklists', data),
  complete: id => api.put(`api/checklists/complete/${id}`),
  incomplete: id => api.put(`api/checklists/incomplete/${id}`),
  changeOrder: data => api.put('api/checklists/change-order', data),
  changeList: data => api.put('api/checklists/change-list', data),
  store: data => api.post('api/checklists', data),
  delete: id => api.delete(`api/checklists/${id}`),
  update: data => api.put(`api/checklists/${data.id}`, data),
  snooze: (item, date, type) => api.put(`api/checklists/snooze`, { item, date, type }),
  unsnooze: (item, date, type) => api.put(`api/checklists/unsnooze`, { item, date, type }),
  fetchOne: data => api.get(`api/checklists/type/${data.type}/date/${data.date}`),
  createOrUpdate: data => api.put(`api/checklists/type/${data.type}/date/${data.date}`, data),
};

export const taskApi = {
  fetchAll: ({ type, start, end }) =>
    api.get(`api/tasks/type/${type}`, {
      start: start.format(DATE_FORMAT),
      end: end.format(DATE_FORMAT),
    }),
  fetchOne: ({ id }) => api.get(`api/tasks/${id}`),
  store: data => api.post('api/tasks', data),
  update: data => api.put(`api/tasks/${data.id}`, data),
  delete: ({ id, recurrence_id, start, confirm }) =>
    api.delete(`api/tasks/${id}?recurrence_id=${recurrence_id || start}&confirm=${confirm}`),
  restore: data => api.post(`api/tasks/${data.id}/restore`, data),
};

export const habitApi = {
  fetchAll: ({ start, end }) =>
    api.get('api/habits', {
      start: start.format(DATE_FORMAT),
      end: end.format(DATE_FORMAT),
    }),
  fetchOne: ({ id }) => api.get(`api/habits/${id}`),
  store: data => api.post('api/habits', data),
  update: data => api.put(`api/habits/${data.id}`, data),
  delete: ({ id }) => api.delete(`api/habits/${id}`),
  restore: data => api.post(`api/habits/${data.id}/restore`, data),
};

export const habitTrackedApi = {
  fetchAll: ({ start, end }) =>
    api.get('api/habits/tracked', {
      start: start.format(DATE_FORMAT),
      end: end.format(DATE_FORMAT),
    }),
  fetchOne: ({ habit_id }) => api.get(`api/habits/${habit_id}/tracked`),
  store: data => api.post(`api/habits/${data.habit_id}/tracked/${data.track_date}`),
  delete: ({ habit_id, track_date }) => api.delete(`api/habits/${habit_id}/tracked/${track_date}`),
};

export const waterApi = {
  store: date => api.post('api/water', date),
  fetchByDate: date => api.get('api/water', date),
  drink: id => api.put(`api/water/drink/${id}`),
  pour: id => api.put(`api/water/pour/${id}`),
};

export const timeZoneApi = {
  fetchAll: () => api.get('api/time-zones'),
};

export const taxApi = {
  show: (zip, amount) => api.get(`api/tax?zip=${zip}&amount=${amount}`),
};

export const userOptionsApi = {
  store: data => api.post('api/user-options', data),
  fetch: data => api.get('api/user-options', data),
  update: (id, data) => api.put(`api/user-options/${id}`, data),
  updateTimezone: (id, data) => api.put(`api/user-options/timezone/${id}`, data),
  updateFont: (id, fontId) => api.put(`api/user-options/font/${id}/${fontId}`),
  updateSkin: (id, skinId) => api.put(`api/user-options/skin/${id}/${skinId}`),
};

export const fontApi = {
  fetch: () => api.get('api/fonts?version=1'),
};

export const mealPrepApi = {
  fetch: data => api.get('api/meal-preps', data),
  fetchOne: data => api.get(`api/meal-preps/date/${data.date}`),
  store: data => api.post('api/meal-preps', data),
  update: (id, data) => api.put(`api/meal-preps/${id}`, data),
  createOrUpdate: data => api.put(`api/meal-preps/date/${data.date}`, data),
};

export const unlockCodeApi = {
  consume: data => api.post('/api/unlock/consume', data),
};

export const searchApi = {
  search: search => api.get(`/api/search?search=${search}&pageSize=25`),
};

/**
 * ADMIN ROUTES
 */

export const AdminUserApi = {
  fetch: (page, search, isSubscribed) => {
    const queryParams = [''];
    if (search && search !== '') {
      queryParams.push(`search=${search}`);
    }
    if (isSubscribed !== null) {
      queryParams.push(`is_subscribed=${isSubscribed}`);
    }
    const endpoint = `api/admin/users/page/${page}${
      queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    }`;
    return api.get(endpoint);
  },
  fetchOne: id => api.get(`api/admin/users/${id}`),
  update: (id, data) => api.put(`api/admin/users/${id}`, data),
  delete: (id, password) => api.delete(`api/admin/users/${id}?password_confirmation=${password}`),
};

export const AdminStickersApi = {
  fetch: (page, search) => {
    let endpoint = `api/admin/stickers/page/${page}?pageSize=1000`;
    if (search && search !== '') endpoint += `&search=${search}`;
    return api.get(endpoint);
  },
  store: data => api.upload('api/admin/stickers', data),
  delete: id => api.delete(`api/admin/stickers/${id}`),
};

export const AdminSkinsApi = {
  fetchAll: (page, search) => {
    let endpoint = `api/admin/skins/page/${page}`;
    if (search && search !== '') endpoint += `?search=${search}`;
    return api.get(endpoint);
  },

  fetch: id => api.get(`api/admin/skins/${id}`),
  update: data => api.upload(`api/admin/skins/${data.id}`, { ...data, _method: 'PUT' }),
  store: data => api.upload('api/admin/skins', data),
  delete: id => api.delete(`api/admin/skins/${id}`),
};

export const AdminStickerPacksApi = {
  fetchAll: (page, search) => {
    let endpoint = `api/admin/sticker-packs/page/${page}`;
    if (search && search !== '') endpoint += `?search=${search}`;
    return api.get(endpoint);
  },
  fetch: id => api.get(`api/admin/sticker-packs/${id}`),
  store: data => api.post('api/admin/sticker-packs', data),
  delete: id => api.delete(`api/admin/sticker-packs/${id}`),
  update: data => api.put(`api/admin/sticker-packs/${data.id}/with-stickers`, data),
};
