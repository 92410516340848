import React from 'react';
import propTypes from 'prop-types';

import Select from '~/Components/Global/Form/Select';

const CalendarSelect = ({ calendars, value, onChange, name, label }) => {
  const handleOnChange = e => {
    onChange({ [e.target.name]: e.target.value });
  };
  return (
    <Select
      className="minimal-input"
      label={label}
      inlineLabel
      name={name}
      onChange={handleOnChange}
      value={value}
      data={calendars}
      dataValueKey="id"
      dataLabelKey="display_name"
      allowPlaceholder={false}
    />
  );
};

CalendarSelect.propTypes = {
  onChange: propTypes.func,
  calendars: propTypes.array,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  label: propTypes.string,
  name: propTypes.string,
};

CalendarSelect.defaultProps = {
  onChange: () => {},
};

export default CalendarSelect;
