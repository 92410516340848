import { transform, isEqual } from 'lodash';
import moment from '~/Providers/Moment';

import { eventsApi } from '~/Providers/Api';
import mParticle, { CreateEventEvent } from '~/Providers/mParticle';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';

import { closeModal, openModal } from '~/Redux/Modules/Modal';
import { EVENT_CREATE_MODAL } from '~/Config/Modals';
import Store from '~/Redux/Store';
import History from '~/Providers/History';
import { DATE_FORMAT, SYNC_INTERVAL } from '~/Redux/Constants';
import { ALL_EVENTS } from '~/Components/Pages/CreateEvent/EventConfirmation';
import { toggleCalendarStatus } from '~/Redux/Modules/CalendarAccounts';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  SYNC,
  SYNC_SUCCESS,
  SYNC_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  SET_EDIT_EVENT,
  SET_DRAG_EVENT,
  CLEAR_DRAG_EVENT,
  UPDATE_EVENTS_STATE_COLOR,
  UPDATE_INPUT,
  UPDATE_TIMEZONE,
  FILTER_NON_ACTIVE_EVENTS,
  CLEAR,
} from './constants';

export const getEvents = (start, end, skipSync) => async dispatch => {
  const stamp = moment().unix();
  dispatch({ type: FETCH, stamp });
  if (!skipSync) dispatch(syncEvents(start, end, stamp));
  try {
    const response = await eventsApi.get({ start: start, end: end });
    dispatch({ type: FETCH_SUCCESS, data: response.data, stamp });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
    throw err;
  }
};

export const syncEvents = (start, end, stamp, force) => async dispatch => {
  const {
    Events: { currentSync, currentSyncStamp },
  } = Store.getState();
  const startDate = start.format(DATE_FORMAT);
  if (currentSync === startDate && !force && currentSyncStamp + SYNC_INTERVAL > stamp) return; // allow new syncs after heartbeat_interval seconds
  dispatch({ type: SYNC, stamp, start: startDate });
  try {
    const response = await eventsApi.sync({
      start: start,
      end: end,
      preferredTimezone: moment.tz.guess(),
    });
    dispatch({ type: SYNC_SUCCESS, data: response.data, stamp });
    if (!(response.errors instanceof Array)) {
      console.log(
        'syncEvents - errors',
        response.errors,
        response.errors.message,
        Object.keys(response.errors.detail)
      );
      let errorMessages = [];
      Object.keys(response.errors.detail).forEach(item => {
        const messages = response.errors.detail[item];
        if (
          messages?.toLowerCase &&
          (messages.toLowerCase().indexOf('invalid credentials') >= 0 ||
            messages.toLowerCase().indexOf('refresh token') >= 0 ||
            messages.toLowerCase().indexOf('refresh_token') >= 0 ||
            messages.toLowerCase().indexOf('access token') >= 0 ||
            messages.toLowerCase().indexOf('invalid_grant') >= 0 ||
            messages.toLowerCase().indexOf('401 unauthorized') >= 0)
        ) {
          if (item == 'APPLE') {
            errorMessages.push(
              `${item}: Invalid credentials, possibly due to a recent change in your iCloud password. Please visit the Manage Calendars screen to provide a new App-Specific Password.`
            );
          } else {
            errorMessages.push(
              `${item}: Invalid credentials. Please visit the Manage Calendars screen to refresh your account credentials.`
            );
          }
        } else {
          errorMessages.push(`${item}: ${messages}`);
        }
      });
      dispatch(
        error(response.errors.message, errorMessages.join('\n\n'), 60, {
          label: 'Manage Calendars',
          callback: () => {
            console.log('to manage calendars');
            History.push('/add-accounts');
          },
        })
      );
    }

    return response;
  } catch (err) {
    dispatch({ type: SYNC_FAILURE, error: err });
  }
};

export const filterNonActiveEvents = calendarId => dispatch => {
  dispatch({ type: FILTER_NON_ACTIVE_EVENTS, calendarId });
};

export const updateEventsStateColor = (calendar, newColor, oldColor) => dispatch => {
  dispatch({ type: UPDATE_EVENTS_STATE_COLOR, calendar, newColor, oldColor });
};

export const setCurrentEvent = (event, shouldOpenModal = true) => dispatch => {
  dispatch({ type: SET_EDIT_EVENT, event });
  if (shouldOpenModal) {
    console.log('opening event modal with ' + JSON.stringify(event));
    dispatch(openModal(EVENT_CREATE_MODAL));
  }
};

export const createEvent = (event, currentView, currentViewStart) => async dispatch => {
  dispatch({ type: STORE });
  try {
    const response = await eventsApi.store(event, event.calendar_id);
    mParticle.logEvent(new CreateEventEvent());
    if (!event.calendar.active) {
      await dispatch(toggleCalendarStatus(true, event.calendar_id, currentViewStart, false));
    }
    dispatch({ type: STORE_SUCCESS, data: response.data });
    dispatch(closeModal(EVENT_CREATE_MODAL));
    dispatch(success('Event Created!'));
    dispatch(
      getEvents(
        currentViewStart
          .clone()
          .startOf('month')
          .subtract(1, 'month')
          .startOf('week'),
        currentViewStart
          .clone()
          .startOf('month')
          .add(2, 'months')
          .startOf('week')
          .add(1, 'week'),
        true
      )
    );
    return response;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
    dispatch(error('Event was not created', parseErrors(err)));
  }
};
const difference = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = value;
    }
  });
};

const eventsApiUpdate = (() => {
  let lastUpdate = new Promise(resolve => resolve({}));

  const makeRequest = (event, diff, response, stamp) => {
    const {
      Events: { data, localOnly },
    } = Store.getState();
    console.log('Events', 'localOnly', localOnly, 'confirm', event.confirm);
    if (localOnly && (!event.confirm || event.confirm === ALL_EVENTS)) {
      console.log('Events', 'perform local-only update');
      return eventsApi.updateLocal(event);
    }
    let original = data.filter(ev => `${ev.id}` === `${event.id}`)[0];
    if (
      response &&
      response.data &&
      response.data.event &&
      `${response.data.event.id}` === `${event.id}`
    )
      original = response.data.event;
    console.log('makeRequest', stamp, original.etag, diff);
    return eventsApi.update({ ...original, ...diff, etag: original.etag });
  };

  const retry = async (callback, maxAttempts) => {
    if (!maxAttempts) return await callback();
    try {
      return await callback();
    } catch (err) {
      if (err.status == 412) throw err;
      console.error('Remaining retries: ', maxAttempts, err);
      return retry(callback, maxAttempts - 1);
    }
  };

  return (event, diff, stamp) => {
    lastUpdate = lastUpdate.then(
      response => retry(() => makeRequest(event, diff, response, stamp), 3),
      () => retry(() => makeRequest(event, diff, {}, stamp), 3)
    );
    return lastUpdate;
  };
})();

export const updateEvent = (event, currentView, currentViewStart) => async dispatch => {
  const stamp = moment().unix();
  const {
    Events: { data },
  } = Store.getState();
  const original = data.filter(ev => `${ev.id}` === `${event.id}`)[0];
  const diff = difference(event, original);
  dispatch({
    type: UPDATE,
    event: { ...event, color: event.color || event.calendarColor },
    stamp,
    diff,
  });
  try {
    const response = await eventsApiUpdate(event, diff, stamp);
    dispatch({ type: UPDATE_SUCCESS, data: response.data, stamp });
    dispatch(closeModal(EVENT_CREATE_MODAL));
    dispatch(success('Event Updated!'));
    dispatch(
      getEvents(
        currentViewStart
          .clone()
          .startOf('month')
          .subtract(1, 'month')
          .startOf('week'),
        currentViewStart
          .clone()
          .startOf('month')
          .add(2, 'months')
          .startOf('week')
          .add(1, 'week'),
        true
      )
    );
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_FAILURE, error: err, stamp });
    const {
      Modal: { modalsOpen },
    } = Store.getState();
    dispatch(error('Event was not updated', parseErrors(err, modalsOpen.length > 0)));
    dispatch(
      syncEvents(
        currentViewStart
          .clone()
          .startOf('month')
          .subtract(1, 'month')
          .startOf('week'),
        currentViewStart
          .clone()
          .startOf('month')
          .add(2, 'months')
          .startOf('week')
          .add(1, 'week'),
        moment.unix(),
        true
      )
    );
  }
};

export const deleteEvent = (event, currentView, currentViewStart) => async dispatch => {
  dispatch({ type: DELETE });
  try {
    console.log('delete event ' + JSON.stringify(event));
    if (window.Rollbar) console.log(window.Rollbar.error('Deleting event'));
    const response = await eventsApi.delete(event);
    dispatch({
      type: DELETE_SUCCESS,
      data: response.data,
      start: event.startForRecurrence,
    });
    dispatch(closeModal(EVENT_CREATE_MODAL));
    dispatch(success('Event deleted!'));
    dispatch(
      getEvents(
        currentViewStart
          .clone()
          .startOf('month')
          .subtract(1, 'month')
          .startOf('week'),
        currentViewStart
          .clone()
          .startOf('month')
          .add(2, 'months')
          .startOf('week')
          .add(1, 'week'),
        true
      )
    );
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, error: err });
    dispatch(error('Event was not deleted', parseErrors(err)));
  }
};

export const openPresetModal = update => dispatch => {
  dispatch({ type: UPDATE_INPUT, update: { ...update, forceUpdate: true } });
  dispatch(openModal(EVENT_CREATE_MODAL));
};

export const setDragEvent = () => dispatch => {
  dispatch({ type: SET_DRAG_EVENT });
};

export const clearDragEvent = () => dispatch => {
  dispatch({ type: CLEAR_DRAG_EVENT });
};

export const updateField = update => dispatch => {
  dispatch({ type: UPDATE_INPUT, update });
};

export const updateTimezone = update => dispatch => {
  dispatch({ type: UPDATE_TIMEZONE, update });
};

export const clearEvent = () => dispatch => {
  dispatch({ type: CLEAR });
};
