import uniqBy from 'lodash.uniqby';
import { MOBILE_IMAGE } from '~/Redux/Modules/Admin/Skins';

export const sortList = (list, key = 'order', desc = false) => {
  if (typeof list !== 'undefined') {
    return list.sort((a, b) => {
      if (desc) {
        if (a[key] > b[key]) return -1;
        if (a[key] < b[key]) return 1;
        return 0;
      } else {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      }
    });
  }
  return list;
};

export const updateDateState = (state, date, mapAction) => {
  return {
    ...state.data,
    [date]: (state.data[date] || []).map(currentItem => {
      return mapAction(currentItem);
    }),
  };
};

export const filterDateState = (state, date, filterAction) => {
  return {
    [date]: (state.data[date] || []).filter(currentItem => {
      return filterAction(currentItem);
    }),
  };
};

export const insertDateState = (state, date, data) => {
  if (!state.data[date]) {
    return { [date]: [data] };
  }
  return { [date]: [...state.data[date], data] };
};

export const changeStateDate = (state, data, oldDate, newDate, filterAction) => {
  if (oldDate !== newDate) {
    return {
      ...state.data,
      ...filterDateState(state, oldDate, filterAction),
      ...insertDateState(state, newDate, { ...data, date: newDate }),
    };
  }
  return updateDateState(state, oldDate, s => (s.id === data.id ? data : s));
};

export const uniqueMergeState = (state, data) => {
  let mergedEvents = { ...state.data }; //cloning state
  for (let currentDate in data) {
    mergedEvents[currentDate] =
      typeof mergedEvents[currentDate] === 'undefined'
        ? data[currentDate]
        : uniqBy([...mergedEvents[currentDate], ...data[currentDate]], a => a.id);
  }
  return mergedEvents;
};

// Get an array that represents [day no.] => day of month (moment)
export const generateWeek = startTime => {
  const day = startTime.clone().startOf('week');
  const week = Array(7).fill(null);
  week.forEach((item, index) => {
    week[index] = day.clone().add(index, 'days');
  });
  return week;
};

export const insertState = (state, data) => {
  if (!state.data || state.data.length) {
    return [data];
  }
  return [...state.data, data];
};

export const filterState = (state, filterAction) => {
  return state.data.filter(currentItem => {
    return filterAction(currentItem);
  });
};

export const updateState = (state, date, mapAction) => {
  return state.data.map(currentItem => {
    return mapAction(currentItem);
  });
};

export const convertToCloudfront = path => {
  if (!path) {
    return false;
  }

  return path
    .replace('https://artful-agenda-staging.s3.amazonaws.com', 'https://staging.artfulagenda.com')
    .replace('https://artful-agenda-production.s3.amazonaws.com', 'https://app.artfulagenda.com');
};

export const findImagePath = (images, name, mobile = false) => {
  if (!images) return false;
  let image = images.find(image => image.name === name);
  if (mobile) image = images.find(image => image.name === MOBILE_IMAGE) || image;
  return image ? convertToCloudfront(image.public_path) : false;
};

export const findImagePreviews = (images, name, mobile = false) => {
  if (!images) return false;
  let previewImages = {
    '1x': convertToCloudfront(
      images.find(image => image.name.indexOf(`${name}_preview@1x`) !== -1)?.public_path
    ),
    '2x': convertToCloudfront(
      images.find(image => image.name.indexOf(`${name}_preview@2x`) !== -1)?.public_path
    ),
    '3x': convertToCloudfront(
      images.find(image => image.name.indexOf(`${name}_preview@3x`) !== -1)?.public_path
    ),
  };
  if (mobile) {
    previewImages = {
      '1x':
        convertToCloudfront(
          images.find(image => image.name.indexOf(`${MOBILE_IMAGE}_preview@1x`) !== -1)?.public_path
        ) || previewImages['1x'],
      '2x':
        convertToCloudfront(
          images.find(image => image.name.indexOf(`${MOBILE_IMAGE}_preview@2x`) !== -1)?.public_path
        ) || previewImages['2x'],
      '3x':
        convertToCloudfront(
          images.find(image => image.name.indexOf(`${MOBILE_IMAGE}_preview@3x`) !== -1)?.public_path
        ) || previewImages['3x'],
    };
  }
  return previewImages;
};

export const browser = () => {
  if (window.navigator.userAgent.indexOf('Edge') !== -1) return 'Edge';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) return 'Chrome';
  if (window.navigator.userAgent.indexOf('Firefox') !== -1) return 'Firefox';
  if (window.navigator.userAgent.indexOf('Safari') !== -1) return 'Safari';
  return false;
};

export const dragAndDropSupported = () => {
  if (window.navigator.userAgent.indexOf('iPad') !== -1) return false;
  if (window.navigator.userAgent.indexOf('iPhone') !== -1) return false;
  return 'draggable' in document.getElementById('artful-client');
};
