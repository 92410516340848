/* eslint-disable max-len */
import React from 'react';

export default function HabitShotIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.371 5.1 24.9 2.63a247.984 247.984 0 0 0-1.275-1.279l-1.688 1.575s.511.523 1.313 1.35l-2.883 2.892-1.244-1.244a39.657 39.657 0 0 0-1.237-1.198l-1.573 1.688s.333.335 1.162 1.16l.411.411-8.661 8.663L7.875 18s.825.834 1.266 1.311l-6.516 7.24C2.3 26.873 1.8 28.2 1.8 28.2s1.327-.502 1.65-.826l7.239-6.516c.477.441 1.348 1.191 1.348 1.191s.488-.45 1.316-1.278l8.66-8.658.412.411c.852.829 1.313 1.2 1.313 1.2l1.575-1.611s-.436-.417-1.232-1.235l-1.239-1.237 2.886-2.892A52.28 52.28 0 0 0 27 7.984l1.575-1.571s-.402-.491-1.204-1.313zM16.646 15 15 13.354l4.121-4.129 1.654 1.654L16.646 15z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
