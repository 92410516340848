import { habitTrackedApi } from '~/Providers/Api';
import moment from '~/Providers/Moment';
import mParticle, { CreateHabitTrackedEvent } from '~/Providers/mParticle';
import { parseErrors } from '~/Redux/Helpers';
import { guid } from '~/util';
import { error } from '../Notifications';
import { fetchAllHabits } from '../Habits';
import {
  FETCH_ALL,
  FETCH_ALL_FAILURE,
  FETCH_ALL_SUCCESS,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from './constants';

export const fetchAllHabitsTracked = (start, end) => async dispatch => {
  const stamp = moment().unix();
  dispatch({ type: FETCH_ALL, start, end });
  try {
    let response = await habitTrackedApi.fetchAll({ start, end });
    dispatch({ type: FETCH_ALL_SUCCESS, data: response.data, start, end, stamp });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_ALL_FAILURE, error: err, start, end, stamp });
    throw err;
  }
};

export const createHabitTracked = (data, start = null, end = null) => async dispatch => {
  const id = data.tempId || `temp-${guid()}`;
  try {
    dispatch({ type: CREATE, data: { ...data, id } });
    let response = await habitTrackedApi.store(data);

    mParticle.logEvent(new CreateHabitTrackedEvent());

    dispatch({ type: CREATE_SUCCESS, data: response.data, id });
    if (start !== null && end !== null) {
      dispatch(fetchAllHabits(start, end));
    }
    return response;
  } catch (err) {
    dispatch({ type: CREATE_FAILURE, data, id });
    dispatch(error('Error creating habit', parseErrors(err)));
  }
};

export const deleteHabitTracked = (data, start = null, end = null) => async dispatch => {
  try {
    dispatch({ type: DELETE, data });
    let response = await habitTrackedApi.delete(data);
    dispatch({ type: DELETE_SUCCESS, data });
    if (start !== null && end !== null) {
      dispatch(fetchAllHabits(start, end));
    }
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, data });
    dispatch(error('Error deleting habit', parseErrors(err)));
  }
};
