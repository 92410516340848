import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '~/Containers/Global/Modal/Modal';
import { closeModal, setModalData } from '~/Redux/Modules/Modal';
import { updateSticker } from '~/Redux/Modules/Products';
import { STICKER_EDIT_TAGS_MODAL } from '~/Config/Modals';

import TextInput from '../Form/TextInput';

import './StickerEditTagsModal.scss';
import Spinner from '../Loading/Spinner';

function StickerEditTagsModal() {
  const sticker = useSelector(state => state.Modal.modalData?.sticker);
  const stickerTags = useSelector(state => state.Modal.modalData?.sticker?.tags);
  const [tags, setTags] = useState('');
  const dispatch = useDispatch();

  const updateTags = input => {
    const newValue = input[sticker.id] || '';
    setTags(newValue);
  };

  const handleSubmit = () => {
    if (!tags) {
      dispatch(updateSticker({ id: sticker.id, tags: '' }));
    }

    dispatch(updateSticker({ id: sticker.id, tags }));

    dispatch(closeModal(STICKER_EDIT_TAGS_MODAL));
    dispatch(setModalData(null));
  };

  useEffect(() => {
    if (stickerTags) {
      setTags(stickerTags);
    } else {
      setTags('');
    }
  }, [sticker]);

  if (sticker) {
    return (
      <Modal
        id={STICKER_EDIT_TAGS_MODAL}
        overlayClassName="image-upload-modal"
        showCloseButton={false}
      >
        <section id="edit-tags-dialog">
          <h1 className="heading">Your Stickers</h1>

          <p>
            Add tags to your stickers, so you can easily search them later <i>(optional)</i>
          </p>

          <section id="sticker-tag-form-list">
            <article key={sticker.id} className="sticker-tag-form">
              <div className="image-container">
                <img src={sticker.url} />
              </div>

              <span className="sticker-name">{sticker.name}</span>

              <TextInput
                className="tag-input"
                name={sticker.id}
                value={tags}
                onChange={e => updateTags(e)}
              />
            </article>
          </section>

          <div className="action-buttons">
            <div
              id="done-button"
              className="action-button"
              tabIndex="0"
              role="button"
              onClick={() => handleSubmit()}
            >
              Done
            </div>
          </div>
        </section>
      </Modal>
    );
  } else {
    return (
      <Modal
        id={STICKER_EDIT_TAGS_MODAL}
        overlayClassName="sticker-edit-tags-modal"
        showCloseButton={false}
      >
        <Spinner />
      </Modal>
    );
  }
}

export default StickerEditTagsModal;
