import { connect } from 'react-redux';

import { openPresetModal } from '~/Redux/Modules/Events';
import { setToday, setDayType, setWeekType, setMonthType } from '~/Redux/Modules/DateRange';

import ViewHeader from '~/Components/Pages/Calendar/ViewHeader/ViewHeader';
import { humanDateFormats } from '~/Redux/Modules/UserOptions';

const mapStateToProps = ({ DateRange, UserOptions }) => {
  return {
    currentView: DateRange.currentType,
    start: DateRange.start.clone(),
    humanDateFormats: humanDateFormats({ UserOptions }),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToday: currentView => dispatch(setToday(currentView)),
    openPresetModal: update => dispatch(openPresetModal(update)),
    setDayType: day => dispatch(setDayType(day.clone().startOf('day'))),
    setWeekType: day => dispatch(setWeekType(day.clone().startOf('week'))),
    setMonthType: day => dispatch(setMonthType(day.clone().startOf('month'))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewHeader);
