/**
 * Generates an action set for the common async use case, i.e. a 'about to send' action, a 'success' action,
 * and a 'failed' action. The generated actions can be used in conjuction with the `asyncActionCreator` below to easily
 * scaffold simple API requests
 *
 * @param {string} baseAction The root 'this is about to be sent' action constant. Should be of form
 *                            `${applicationName}/${moduleName}/${constantName}`, e.g.
 *                            'artful-client/users/GET'
 */
export const asyncActionSet = baseAction => {
  return {
    send: baseAction,
    success: `${baseAction}_SUCCESS`,
    failed: `${baseAction}_FAILED`,
  };
};

/**
 * Generates an action creator for common async use cases. If callback is successful, dispatches a success action with the response
 * set to 'data', otherwise sends an 'error' action with the error received
 *
 * TODO: Add usage notes here - the currying below is probably confusing
 *
 * TODO: I would like to be able to check the current state and return cached data if it is available; may need to modify this function for that use case
 *
 * @param {object} actionSet Should have `send`, `success`, and `failed` data members. Can be generated with `asyncActionSet`
 * @param {function} callback Executes the API request or other asynchronous action to get data/update state for this action.
 */
export const asyncActionCreator = (actionSet, callback, successCallback, errorCallback) => (
  ...args
) => async dispatch => {
  dispatch({ type: actionSet.send });
  try {
    const data = await callback(...args);

    dispatch({ type: actionSet.success, data });

    if (successCallback) successCallback(data, dispatch);

    return data;
  } catch (error) {
    dispatch({ type: actionSet.failed, error });

    if (errorCallback) errorCallback(error, dispatch);

    throw error;
  }
};
