import moment from '~/Providers/Moment';

import {
  FETCH_ALL,
  FETCH_ALL_SUCCESS,
  FETCH_ALL_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  SET_QUEUE,
  START_UPDATING,
  FINISH_UPDATING,
  SET_LAST_UPDATE,
} from './constants';

const initialState = {
  isFetching: false,
  data: {},
  actionDate: false,
  isUpdating: false,
  queue: [],
  lastUpdates: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL:
      return { ...state, isFetching: true };
    case FETCH_ALL_SUCCESS:
      return { ...state, isFetching: false, data: action.data };
    case FETCH_ALL_FAILURE:
      return { ...state, isFetching: false };

    case FETCH_ONE:
      return { ...state, isFetching: true, actionDate: action.date };
    case FETCH_ONE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.date]: {
            ...action.data,
            fetchedAt: moment.unix(),
          },
        },
        isFetching: false,
        actionDate: false,
      };
    case FETCH_ONE_FAILURE:
      return { ...state, isFetching: false, actionDate: false };

    case SET_QUEUE:
      return { ...state, queue: action.queue };

    case START_UPDATING:
      return { ...state, isUpdating: true };

    case FINISH_UPDATING:
      return { ...state, isUpdating: false };

    case SET_LAST_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.date]: {
            ...action.data,
          },
        },
        lastUpdates: { ...state.lastUpdates, [action.data.date]: action.data },
      };

    default:
      return state;
  }
}
