import React, { useState, useRef, useEffect, useMemo } from 'react';
import SearchIcon from '~/Components/Global/Assets/Svgs/Search';
import TextInput from '~/Components/Global/Form/TextInput';
import { searchApi } from '~/Providers/Api';
import moment from '~/Providers/Moment';
import SearchResult from './SearchResult';

import './Search.scss';

const minSearchLength = 2;

const useSearch = term => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const interval = useRef();
  const request = useRef();
  useEffect(() => {
    console.log(`search for ${term}`, interval.current);
    if (interval.current) clearTimeout(interval.current);
    if (term.length < minSearchLength) {
      request.current = null;
      setResults([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    interval.current = setTimeout(() => {
      const stamp = moment().unix();
      request.current = stamp;
      searchApi.search(term).then(results => {
        if (request.current !== stamp) {
          console.log(`discard results for ${term}`, stamp, request.current);
          return;
        }
        console.log(`search results for ${term}`, results.data);
        setResults(results.data);
        setLoading(false);
      });
    }, 300);
  }, [term]);
  return [results, loading];
};

const Search = () => {
  const [isOpen, setOpen] = useState(false);
  const [autoFocus, setAutofocus] = useState(true);
  const [currentSearch, setSearch] = useState('');
  const [results, loading] = useSearch(currentSearch);
  const containerRef = useRef(null);

  const handleClickOutside = useMemo(
    () => event => {
      if (isOpen && containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
      }
    },
    [isOpen, setOpen, containerRef]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen, setOpen, containerRef]);

  useEffect(() => {
    const handleNativeSearch = event => {
      setSearch(event.text);
      setAutofocus(event.text.length === 0);
      setOpen(event.text.length > 0);
    };
    window.addEventListener('native.search', handleNativeSearch);
    return () => {
      window.removeEventListener('native.search', handleNativeSearch);
    };
  }, [setSearch, setAutofocus]);

  const clear = () => {
    setSearch('');
    setOpen(false);
  };

  if (!isOpen) {
    return (
      <a className="search-wrapper" onClick={() => setOpen(true)}>
        <SearchIcon />
      </a>
    );
  }
  return (
    <div className="search-text-wrapper" ref={containerRef}>
      <TextInput
        className="minimal-input search-input"
        placeholder="Search"
        value={currentSearch}
        onChange={({ search }) => {
          setSearch(search);
        }}
        onKeyDown={({ key }) => key === 'Escape' && clear()}
        autoFocus={autoFocus}
        name="search"
      />
      <a className="clear-btn material-icons" onClick={clear}>
        close
      </a>
      {!results.length && !loading && currentSearch.length > 0 && (
        <div className="search-results">
          {currentSearch.length >= minSearchLength ? (
            <div className="no-results">No results found</div>
          ) : (
            <div className="no-results">Enter at least 2 characters to search</div>
          )}
        </div>
      )}
      {!!results.length && !loading && (
        <div className="search-results">
          {results.map((result, i) => (
            <SearchResult {...result} key={i} clear={clear} />
          ))}
        </div>
      )}
    </div>
  );
};

Search.propTypes = {};

export default Search;
