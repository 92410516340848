export const MONTH = 'month';
export const WEEK = 'week';
export const DAY = 'day';
export const HABIT = 'habit';

export const periodForDateType = {
  [MONTH]: MONTH,
  [WEEK]: WEEK,
  [DAY]: DAY,
  [HABIT]: MONTH,
};
