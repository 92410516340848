import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Storage from '~/Providers/Storage';

/**
 * Used instead of a basic `Route` to guard access to Pages should not be accessed when a user is Authenticated
 */
const SubscribedRoute = ({
  component: RenderComponent,
  hasFetched,
  authHasFetched,
  authIsFetching,
  isAuthenticated,
  isFetching,
  isSubscribed,
  redirect,
  sendGAClientId,
  sentGAClientId,
  sendDeviceId,
  sentDeviceId,
  user,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!authIsFetching && authHasFetched && !isAuthenticated) return <Redirect to="/login" />;
        if (isFetching || !hasFetched) return null;
        if (!isSubscribed) {
          var redirectToUse = redirect;
          if (user.id && user.trial_ends_at == null) {
            redirectToUse = '/trial-with-card';
          }
          if (Storage.get('referralCode')) {
            redirectToUse = `${redirectToUse}?referral_code=${Storage.get('referralCode')}`;
            Storage.remove('referralCode');
          }
          return <Redirect to={redirectToUse} />;
        }
        if (!sentGAClientId) sendGAClientId();
        if (!sentDeviceId) sendDeviceId();
        return <RenderComponent {...props} />;
      }}
    />
  );
};

SubscribedRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.node, propTypes.func]),
  isSubscribed: propTypes.bool,
  isFetching: propTypes.bool,
  hasFetched: propTypes.bool,
  redirect: propTypes.string,
  sendGAClientId: propTypes.func,
};

SubscribedRoute.defaultProps = {
  isAuthenticated: false,
  hasFetched: false,
  isFetching: false,
  redirect: '/subscription',
};

export default SubscribedRoute;
