import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from '~/Providers/Moment';
import { DATE_FORMAT } from '~/Redux/Constants';

class ReferralPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popups: JSON.parse(
        localStorage.getItem('referral_popups') || JSON.stringify([moment().format(DATE_FORMAT)])
      ),
    };
    localStorage.setItem('referral_popups', JSON.stringify(this.state.popups));
  }

  shouldRender() {
    const { popups } = this.state;
    const { user } = this.props;
    if (!user || !user.isSubscribed) return false;
    if (popups.length >= 4) return false;
    if (
      popups.length == 3 &&
      moment().isBefore(moment(popups[2], DATE_FORMAT).add(5, 'days'), 'day')
    )
      return false;
    if (
      popups.length == 2 &&
      moment().isBefore(moment(popups[1], DATE_FORMAT).add(2, 'days'), 'day')
    )
      return false;
    if (
      popups.length == 1 &&
      moment().isBefore(moment(popups[0], DATE_FORMAT).add(1, 'days'), 'day')
    )
      return false;
    return true;
  }

  handleClick = () => {
    const { popups } = this.state;
    const newPopups = [...popups, moment().format(DATE_FORMAT)];
    localStorage.setItem('referral_popups', JSON.stringify(newPopups));
    this.setState({ popups: newPopups });
  };

  render() {
    if (!this.shouldRender()) return null;

    return (
      <div className="referral-popup">
        <div className="referral-popup__dismiss" onClick={this.handleClick}>
          x
        </div>
        <div className="referral-popup__header">Our Referral Program is Live!</div>
        <div className="referral-popup__body">
          <p>
            Tell your friends to use referral code{' '}
            <span className="referral-popup__body__code">{this.props.user.referral_code}</span>.
            They will unlock an exclusive cover, and you will receive account credit after their
            first payment. Check the My Account area for details.
          </p>
        </div>
      </div>
    );
  }
}

ReferralPopup.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ User }) => {
  return {
    user: User.data,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralPopup);
