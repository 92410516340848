import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import { getUser } from '~/Redux/Modules/User';

import Modal from '~/Containers/Global/Modal/Modal';

import { HABIT_CREATE_EDIT_MODAL } from '~/Config/Modals';
import './HabitModalCreateEdit.scss';
import NewHabitForm from './NewHabitForm';

function HabitModalCreateEdit({ modalId = HABIT_CREATE_EDIT_MODAL, user, fetchUser, habit }) {
  useEffect(() => {
    fetchUser();
  }, []);

  if (!user) return null;

  return (
    <Modal id={modalId} overlayClassName="habit-create-edit-modal" showCloseButton>
      <NewHabitForm user={user} habit={habit} />
    </Modal>
  );
}
const mapStateToProps = ({ User, Modal }) => ({
  user: User.data,
  habit: Modal.modalData?.habit,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(getUser()),
});

HabitModalCreateEdit.propTypes = {
  modalId: propTypes.string,
  user: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  fetchUser: propTypes.func.isRequired,
  habit: propTypes.oneOfType([propTypes.bool, propTypes.object]),
};

HabitModalCreateEdit.defaultProps = {
  modalId: HABIT_CREATE_EDIT_MODAL,
};

export default connect(mapStateToProps, mapDispatchToProps)(HabitModalCreateEdit);
