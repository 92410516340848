/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';

export default function FilledWater(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      style={{ enableBackground: 'new 0 0 300 300' }}
      xmlSpace="preserve"
      {...props}
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `\n\t.st0{display:none;}\n\t.st1{display:inline;}\n\t.st2{fill:none;stroke:${
            props.stroke ? props.stroke : '#999999'
          };stroke-width:25;stroke-miterlimit:10;}\n\t.st3{fill-rule:evenodd;clip-rule:evenodd;fill:${
            props.fill ? props.fill : '#999999'
          };}\n\t.st4{fill:${props.fill ? props.fill : '#999999'};}\n\t.st5{fill:${
            props.fill ? props.fill : '#231F20'
          };}\n\t.st6{fill:${props.fill ? props.fill : '#555555'};}\n\t.st7{fill:${
            props.fill ? props.fill : '#DDDDDD'
          };}\n\t.st8{fill:${props.fill ? props.fill : '#4D4D4D'};}\n\t.st9{fill:none;stroke:${
            props.stroke ? props.stroke : '#999999'
          };stroke-width:15;stroke-miterlimit:10;}\n`,
        }}
      />
      <g id="Letter_Shapes" className="st0">
        <g className="st1">
          <path
            className="st2"
            d="M-99.5,266.4c0,40.5,31.6,62.5,75.5,62.5s73.5-33.6,73.5-77.5c0,0.5,0-36.5,0-36.5s-28-10-69.5-10
          			C-63.9,204.9-99.5,229.9-99.5,266.4z"
          />
          <path className="st2" d="M-90.5,178c0-35.9,34.1-55,70-55s70,19.1,70,55v160.9" />
        </g>
      </g>
      <g id="Mobile_Logo" />
      <g id="Today" />
      <g id="Plate__x26__Silverware" />
      <g id="Fork_Knife_Spoon" />
      <g id="Fork__x26__Knife" />
      <g id="Closed_Apple" />
      <g id="Open_Apple" />
      <g id="Filled_Water">
        <g>
          <g>
            <g>
              <path
                className="st6"
                style={props.fill ? { fill: props.fill } : {}}
                d="M64.5,72.7C68.8,92,87.8,293.8,87.8,293.8c0.2,2.3,2.2,4,4.5,4h115.4c2.3,0,4.2-1.7,4.5-4
              					c0,0,21.1-211.4,23.3-221.1c-11.8,9.7-21.5,9.7-31.1,0c-9.7,9.7-19.4,9.7-29,0c-9.7,9.7-19.4,9.7-29,0c-9.7,9.7-19.4,9.7-29,0
              					c-9.7,9.7-19.4,9.7-29,0C78.5,82.4,78.5,82.4,64.5,72.7z"
              />
            </g>
            <path
              className="st7"
              style={props.fill ? { fill: props.fill } : {}}
              d="M232.6,9.7l-29.5,280.6H96.9L67.4,9.7H232.6 M238.3,0H61.7c-2.7,0-4.8,2.3-4.5,5l30.6,291
            				c0.2,2.3,2.2,4,4.5,4h115.4c2.3,0,4.2-1.7,4.5-4L242.8,5C243.1,2.3,241,0,238.3,0L238.3,0z"
            />
          </g>
        </g>
      </g>
      <g id="Empty_Water" />
      <g id="Background_Color" />
      <g id="Font_Color" />
      <g id="Account" />
      <g id="Calendar_Styling" />
      <g id="Calendar_Settings" />
      <g id="Left_Justified" />
      <g id="Justified" />
      <g id="Right_Align" />
      <g id="Center_Align" />
      <g id="Left_Align" />
      <g id="Bulleted_List" />
      <g id="Numbered_List" />
      <g id="Increase_Size" />
      <g id="Decrease_Size" />
      <g id="Strikethrough" />
      <g id="Underline" />
      <g id="Italic" />
      <g id="Bold" />
      <g id="Trash" />
      <g id="New" />
      <g id="Edit" />
      <g id="Check_Closed" />
      <g id="Check_Open" />
      <g id="Heading" />
    </svg>
  );
}
/* eslint-enable no-mixed-spaces-and-tabs */
