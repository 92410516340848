/* eslint-disable max-len */
import React from 'react';

export default function HabitBathroomScrubIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.901 24.667c-.068.213-.282.908-.282.908H5.827l.606-3.442H3.748l-.847 2.534zM6.529 25.575h3.208l.365-3.442H7.135l-.606 3.442zM10.432 25.575h3.215l.124-3.442h-2.974l-.365 3.442zM14.46 22.133l-.125 3.442h3.222l-.117-3.442h-2.98zM18.128 22.133l.117 3.442h3.215l-.358-3.442h-2.974zM21.797 22.133l.358 3.442h3.208l-.599-3.442h-2.966zM28.95 24.542l-.805-2.41h-2.678l.599 3.443h3.152s-.213-.93-.268-1.033zM3.555 21.445h25.471v-4.819a3.445 3.445 0 0 0-3.442-3.442V6.3H4.932v4.13h16.521v2.754H6.31a3.445 3.445 0 0 0-3.442 3.442v4.819h.688z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
