/* eslint-disable max-len */
import React from 'react';

export default function HabitMusicIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#kte3m9d5na)">
        <path
          d="M10.5 9.531v8.775a2.68 2.68 0 0 0-1.35-.337c-1.71 0-3.15 1.35-3.15 2.952 0 1.604 1.44 2.954 3.15 2.954s3.15-1.35 3.15-2.954v-8.015l9.9-1.687v6.16c-.45-.169-.9-.338-1.35-.338-1.71 0-3.15 1.35-3.15 2.953 0 1.603 1.44 2.952 3.15 2.952S24 21.596 24 19.994V7L10.5 9.531z"
          fill={props.fill ? props.fill : '#000'}
          className="fill"
        />
      </g>
      <defs>
        <clipPath id="kte3m9d5na">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
