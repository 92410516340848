import { connect } from 'react-redux';

import AdminLayout from '~/Components/Layout/AdminLayout/AdminLayout';
import { clearMenus } from '~/Redux/Modules/Menus';

const mapStateToProps = ({ Menus }) => {
  return {
    routes: Menus.routes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearMenu: () => dispatch(clearMenus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
