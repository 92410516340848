export const FETCH_ALL = 'artful-client/Habit/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'artful-client/Habit/FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'artful-client/Habit/FETCH_ALL_FAILURE';

export const FETCH_ONE = 'artful-client/Habit/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'artful-client/Habit/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'artful-client/Habit/FETCH_ONE_FAILURE';

export const CREATE = 'artful-client/Habit/CREATE';
export const CREATE_SUCCESS = 'artful-client/Habit/CREATE_SUCCESS';
export const CREATE_FAILURE = 'artful-client/Habit/CREATE_FAILURE';

export const UPDATE = 'artful-client/Habit/UPDATE';
export const UPDATE_SUCCESS = 'artful-client/Habit/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'artful-client/Habit/UPDATE_FAILURE';

export const DELETE = 'artful-client/Habit/DELETE';
export const DELETE_SUCCESS = 'artful-client/Habit/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/Habit/DELETE_FAILURE';

export const RESTORE = 'artful-client/Habit/RESTORE';
export const RESTORE_SUCCESS = 'artful-client/Habit/RESTORE_SUCCESS';
export const RESTORE_FAILURE = 'artful-client/Habit/RESTORE_FAILURE';
