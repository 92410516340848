export const SHOW_CONFIRM = 'artful-client/EventConfirmation/SHOW_CONFIRM';
export const HIDE_CONFIRM = 'artful-client/EventConfirmation/HIDE_CONFIRM';

const initialState = {
  heading: null,
  onConfirm: false,
  onCancel: false,
  model: 'Event',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_CONFIRM: {
      const { heading, onConfirm, onCancel, model } = action;
      return { ...state, heading, onConfirm, onCancel, model: model || initialState.model };
    }
    case HIDE_CONFIRM:
      return initialState;
    default:
      return state;
  }
}

export const showConfirm = (heading, onConfirm, onCancel) => dispatch => {
  dispatch({ type: SHOW_CONFIRM, heading, onConfirm, onCancel });
};

export const showConfirmWithSettings = settings => dispatch => {
  dispatch({ ...settings, type: SHOW_CONFIRM });
};

export const hideConfirm = () => dispatch => {
  dispatch({ type: HIDE_CONFIRM });
};
