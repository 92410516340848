import { useDispatch } from 'react-redux';
import { openModal, setModalData } from '~/Redux/Modules/Modal';
import { MANAGE_SUBSCRIPTION_MODAL } from '~/Config/Modals';

const useManageSubscription = () => {
  const dispatch = useDispatch();

  const manageSubscription = opts => {
    const deferred = {};
    const confirmPromise = new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    return new Promise((resolve, reject) => {
      dispatch(openModal(MANAGE_SUBSCRIPTION_MODAL));
      dispatch(setModalData({ confirmPromise: deferred, ...opts }));
      confirmPromise
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  return manageSubscription;
};

export default useManageSubscription;
