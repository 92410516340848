/* eslint-disable max-len */
import React from 'react';

export default function HabitFootstepsIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.118 10.984c1.195 1.312-.598 3.118-1.537 4.348l-1.793-1.23 1.281-2.543c.598-.984 1.623-1.067 2.05-.575h-.001zm-6.832 9.352c1.196 1.313-.598 3.118-1.536 4.348l-1.794-1.23 1.366-2.543c.513-.984 1.537-1.067 1.964-.575zm.257-6.89c-1.623-.575-2.818 1.64-3.757 2.87l1.878 1.231 1.964-2.05c.854-.82.598-1.805-.086-2.052zm-3.928 3.199-.342.41c-.427.575-.341 1.312.171 1.64.512.328 1.281.164 1.708-.41l.341-.41-1.878-1.231zm1.964 8.285-.341.492c-.428.575-1.196.739-1.623.41-.512-.327-.598-1.066-.17-1.64L9.7 23.7l1.878 1.231zm8.795-20.837c-1.707-.574-2.817 1.64-3.757 2.871l1.878 1.231 1.964-2.05c.854-.82.598-1.805-.085-2.052zm-3.927 3.2-.342.41c-.427.574-.341 1.312.17 1.64.513.328 1.282.164 1.708-.41l.257-.41-1.793-1.231zm1.963 8.285-.341.492c-.427.575-1.195.739-1.707.41-.513-.327-.513-1.066-.171-1.64l.341-.492 1.878 1.231z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
