/**
 * This function accepts an array of functions that need to be dispatched from component did mount
 * The functions should be declared in map dispatch to props then passed in
 * @param apiFetches
 */

import Store from '~/Redux/Store';

export const fetchAll = (apiFetches = null) => {
  if (!apiFetches) {
    return;
  }
  if (Array.isArray(apiFetches)) {
    apiFetches.map(apiFetch => {
      Store.dispatch(apiFetch());
    });
  }
};
