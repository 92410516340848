/* eslint-disable max-len */
import React from 'react';

export default function HabitAlarmClockIcon2(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.842 14.982c0-6.392-5.315-11.59-11.842-11.59S3.158 8.597 3.158 14.983c0 4.12 2.21 7.74 5.525 9.797l-2.039 2.555c-.199.252-.344.454-.344.454l.9.787s.241-.362.358-.509l2.168-2.712a11.954 11.954 0 0 0 5.28 1.218c1.898 0 3.691-.44 5.28-1.218l2.167 2.712c.117.147.384.51.384.51l.9-.788s-.17-.196-.37-.454l-2.045-2.549c3.317-2.056 5.52-5.683 5.52-9.803zm-7.095.956h-5.45V9.31h1.172v5.449h4.278v1.178zM3.258 6.357c0-1.916 1.6-3.474 3.568-3.474V1.71c-2.613 0-4.74 2.087-4.74 4.647h1.172zM23.174 1.71l.001.54-.001.633c1.969 0 3.568 1.558 3.568 3.474h1.172c0-2.56-2.127-4.646-4.74-4.646z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
