import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';

import { updateSticker } from '~/Redux/Modules/Products';

import TextInput from '../../Form/TextInput';

import './AddTagsDialog.scss';

function AddTagsDialog({ uploads, handleCloseModal }) {
  const [tags, setTags] = useState({});
  const dispatch = useDispatch();

  const updateTags = input => {
    setTags({ ...tags, ...input });
  };

  const handleSubmit = () => {
    Object.entries(tags).forEach(([id, tags]) => {
      if (!tags) {
        return;
      }

      dispatch(updateSticker({ id, tags }));
    });

    handleCloseModal();
  };

  return (
    <section id="edit-tags-dialog">
      <h1 className="heading">Your Stickers</h1>

      <p>
        Add tags to your stickers, so you can easily search them later <i>(optional)</i>
      </p>

      <section id="sticker-tag-form-list">
        {uploads.map(({ sticker }) => {
          return (
            <article key={sticker.id} className="sticker-tag-form">
              <div className="image-container">
                <img src={sticker.url} />
              </div>

              <span className="sticker-name">{sticker.name}</span>

              <TextInput
                className="tag-input"
                name={sticker.id}
                value={tags[sticker.id.toString()]}
                onChange={e => updateTags(e)}
              />
            </article>
          );
        })}
      </section>

      <div className="action-buttons">
        <div
          id="done-button"
          className="action-button"
          tabIndex="0"
          role="button"
          onClick={() => handleSubmit()}
        >
          Done
        </div>
      </div>
    </section>
  );
}

AddTagsDialog.propTypes = {
  uploads: propTypes.array,
  handleCloseModal: propTypes.func,
};

export default AddTagsDialog;
