import React from 'react';
import propTypes from 'prop-types';

const Panel = props => (
  <div className={props.fullWidthContainer ? 'container-fluid' : 'container'}>
    <div className="row">
      <div className={props.small ? props.columns : props.fullWidthColumns}>
        <div className="panel panel-default">
          {props.heading && <div className="panel-heading">{props.heading}</div>}
          <div className="panel-body">{props.children}</div>
        </div>
      </div>
    </div>
  </div>
);

Panel.defaultProps = {
  columns: 'col-sm-8 col-md-offset-2',
  fullWidthColumns: 'col-12',
  fullWidthContainer: false,
  small: false,
};

Panel.propTypes = {
  children: propTypes.node,
  columns: propTypes.string,
  fullWidthColumns: propTypes.string,
  fullWidthContainer: propTypes.bool,
  small: propTypes.bool,
  heading: propTypes.string,
};
export default Panel;
