import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import FormToggle from '~/Components/Pages/Account/UserSettings/FormToggle';
import UpdateAccount from '~/Components/Pages/Account/UserSettings/Profile/UpdateAccount';
import UpdatePassword from '~/Components/Pages/Account/UserSettings/Profile/UpdatePassword';

import { fetchAll } from '~/Services/Container';
import {
  handleGet,
  handleUpdate,
  handleUpdateFieldInput,
  handleUpdatePassword,
  handleUpdatePasswordFieldInput,
} from '~/Redux/Modules/Account';

const Profile = ({
  fetchAll,
  fieldsUpdate,
  fieldsUpdatePassword,
  handleUpdateAccount,
  handleUpdatePassword,
  updateField,
  updatePasswordField,
}) => [
  <FormToggle key="A">
    <UpdateAccount
      fetchAll={fetchAll}
      fieldsUpdate={fieldsUpdate}
      handleUpdateAccount={handleUpdateAccount}
      updateField={updateField}
    />
  </FormToggle>,
  <FormToggle key="B">
    <UpdatePassword
      fetchAll={fetchAll}
      fieldsUpdatePassword={fieldsUpdatePassword}
      handleUpdatePassword={handleUpdatePassword}
      updateField={updatePasswordField}
    />
  </FormToggle>,
];

Profile.propTypes = {
  fetchAll: propTypes.func,
  handleUpdateAccount: propTypes.func,
  updateField: propTypes.func,
  handleUpdatePassword: propTypes.func,
  updatePasswordField: propTypes.func,
  fieldsUpdate: propTypes.object,
  fieldsUpdatePassword: propTypes.object,
};

const mapStateToProps = ({ Account }) => {
  return {
    fieldsUpdate: Account.fieldsUpdate,
    fieldsUpdatePassword: Account.fieldsUpdatePassword,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAll: () => fetchAll(dispatch(handleGet())), //pass dispatches
    handleUpdateAccount: data => {
      return dispatch(handleUpdate(data));
    },
    updateField: data => {
      return dispatch(handleUpdateFieldInput(data));
    },
    handleUpdatePassword: data => {
      return dispatch(handleUpdatePassword(data));
    },
    updatePasswordField: data => {
      return dispatch(handleUpdatePasswordFieldInput(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
