import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';

import Edit from '../Assets/Svgs/Edit';

function EditColorsButton({ onChange, isCircle }) {
  return (
    <div
      className={cn('button', { circle: isCircle })}
      role="button"
      tabIndex={0}
      onClick={onChange}
    >
      <Edit className="button-icon" />
    </div>
  );
}

EditColorsButton.propTypes = {
  onChange: propTypes.func.isRequired,
  isCircle: propTypes.bool,
};

EditColorsButton.defaultProps = {
  isCircle: false,
};

export default EditColorsButton;
