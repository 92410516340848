import React, { Component } from 'react';
import propTypes from 'prop-types';

import Button from '~/Components/Global/Form/Button';
import SkinForm from '~/Components/Pages/Admin/AdminSkins/SkinForm';

import './AdminSkins.scss';

class AdminEditSkins extends Component {
  componentDidMount = () => {
    const { match, getSkin } = this.props;
    getSkin(match.params.skinId);
  };

  componentWillUnmount = () => {
    this.props.clearSkin();
  };

  render() {
    const { isUpdating, updateSkin, actionSkin, updateForm, updateFormImage, addSkin } = this.props;
    return (
      <div className="admin-skins-edit">
        <SkinForm
          isUpdating={isUpdating}
          onSubmit={() => addSkin(actionSkin)}
          data={actionSkin}
          onChange={updateForm}
          onImageChange={updateFormImage}
        />
        <Button
          text="Update"
          actionText="Updating"
          onClick={() => updateSkin(actionSkin)}
          isFetching={isUpdating}
        />
      </div>
    );
  }
}

AdminEditSkins.propTypes = {
  actionSkin: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  isUpdating: propTypes.bool,
  match: propTypes.object,
  updateForm: propTypes.func,
  getSkin: propTypes.func,
  clearSkin: propTypes.func,
  updateSkin: propTypes.func,
  updateFormImage: propTypes.func,
  addSkin: propTypes.func,
};

export default AdminEditSkins;
