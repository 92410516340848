import {
  SET_DRAWING_DATA,
  CLEAR_DRAWING,
  RESET_DRAWINGS,
  FETCH_DRAWINGS_FAILURE,
  FETCH_DRAWINGS_SUCCESS,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  FETCH_DRAWINGS,
} from './constants';

const initialDrawing = {
  date: '',
  calendar_view: '',
  position: {
    version: 1,
    positionTop: 0.0,
    positionBottom: 0.0,
    positionLeft: 0.0,
    positionRight: 0.0,
    centerX: 0.0,
    centerY: 0.0,
    scale: 1.0,
    rotation: 0.0,
  },
  data: {
    points: [],
  },
};

const initialState = {
  isFetching: false,
  drawings: [],
  currentDrawing: initialDrawing,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DRAWING_DATA: {
      return {
        ...initialState,
        drawings: action.data.drawings,
        currentDrawing: {
          data: { points: [...action.data.data.points] },
          position: { ...action.data.position },
          calendar_view: action.data.calendar_view,
          date: action.data.date,
        },
      };
    }
    case CLEAR_DRAWING: {
      return {
        ...initialState,
        currentDrawing: initialDrawing,
      };
    }
    case RESET_DRAWINGS: {
      return {
        ...initialState,
        drawings: [],
      };
    }
    case FETCH_DRAWINGS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_DRAWINGS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        drawings: action.data,
      };
    }
    case FETCH_DRAWINGS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case STORE:
      return {
        ...state,
        isFetching: true,
      };
    case STORE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        drawings: [...state.drawings, action.data],
      };
    case STORE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: state.error,
      };
    default: {
      return state;
    }
  }
}
