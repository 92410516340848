import { mealPrepApi } from '~/Providers/Api';
import mParticle, { MealPrepUpdateEvent } from '~/Providers/mParticle';
import Store from '~/Redux/Store';
import { error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import {
  FETCH_ALL,
  FETCH_ALL_SUCCESS,
  FETCH_ALL_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  SET_QUEUE,
  START_UPDATING,
  FINISH_UPDATING,
  SET_LAST_UPDATE,
} from './constants';

export const getAllMealPreps = (start, end) => async dispatch => {
  dispatch({ type: FETCH_ALL });
  try {
    let response = await mealPrepApi.fetch({ start, end });
    dispatch({ type: FETCH_ALL_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_ALL_FAILURE, error: err });
  }
};

const mealPrepUpdate = (data, dispatch) => {
  const {
    MealPreps: { lastUpdates },
  } = Store.getState();
  let toUpdate = data;

  if (
    lastUpdates[data.date] &&
    (!data.updated_at || lastUpdates[data.date].updated_at > data.updated_at)
  ) {
    console.log('mealPrepUpdate - safety valve');
    toUpdate = {
      ...data,
      updated_at: lastUpdates[data.date].updated_at,
    };
  }

  dispatch({ type: START_UPDATING });
  mealPrepApi
    .createOrUpdate(toUpdate)
    .then(result => {
      if (result && result.data) {
        dispatch({ type: SET_LAST_UPDATE, data: result.data });
      }

      mParticle.logEvent(new MealPrepUpdateEvent());

      const {
        MealPreps: { queue },
      } = Store.getState();

      if (!queue.length) {
        dispatch({ type: FINISH_UPDATING });
        return;
      }

      let [nextUpdate, ...rest] = queue;
      dispatch({ type: SET_QUEUE, queue: rest });
      if (result && result.data && nextUpdate.id === result.data.id) {
        nextUpdate = { ...nextUpdate, updated_at: result.data.updated_at };
      }
      mealPrepUpdate(nextUpdate, dispatch);
    })
    .catch(err => {
      dispatch(
        error('Meal Prep was not updated', err.status === 422 ? err.message : parseErrors(err))
      );
      dispatch({ type: FETCH_ONE, date: data.date });
      mealPrepApi
        .fetchOne(data)
        .then(result => {
          dispatch({ type: FETCH_ONE_SUCCESS, data: result.data });
        })
        .catch(err => {
          dispatch({ type: FETCH_ONE_FAILURE });
          dispatch(error('Unable to fetch updated meal prep', parseErrors(err)));
        })
        .finally(() => {
          const {
            MealPreps: { queue },
          } = Store.getState();

          let updatedQueue = queue.filter(item => item.id !== data.id);
          if (updatedQueue.length) {
            let [nextUpdate, ...rest] = updatedQueue;
            dispatch({ type: SET_QUEUE, queue: rest });
            mealPrepUpdate(nextUpdate, dispatch);
          } else {
            dispatch({ type: SET_QUEUE, queue: [] });
            dispatch({ type: FINISH_UPDATING });
          }
        });
    });
};

export const createOrUpdateMealPrep = data => async dispatch => {
  const {
    MealPreps: { isUpdating, queue },
  } = Store.getState();

  if (isUpdating) {
    // enqueue an update
    dispatch({
      type: SET_QUEUE,
      queue: [...queue.filter(update => update.date !== data.date), data],
    });
  } else {
    // call api
    mealPrepUpdate(data, dispatch);
  }
};
