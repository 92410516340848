import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import Modal from '~/Containers/Global/Modal/Modal';
import { EVENT_DISPLAY_WARNING_MODAL } from '~/Config/Modals';
import { closeModal } from '~/Redux/Modules/Modal';
import Button from '~/Components/Global/Form/Button';

import './ChangeDisplayWarning.scss';

const ChangeDisplayWarning = ({
  modalId = EVENT_DISPLAY_WARNING_MODAL,
  onSave,
  displayOptions,
}) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal(modalId));
  };

  const renderActionButtons = () => {
    return (
      <div>
        <div className="col-xs-6 align-right">
          <Button
            type="button"
            text="Cancel"
            className="default false"
            onClick={() => handleCloseModal()}
            actionText="Cancel"
          />
        </div>
        <div className="col-xs-6 align-left">
          <Button
            type="button"
            text="OK"
            className="default ok false"
            onClick={() => {
              onSave(displayOptions);
              handleCloseModal();
            }}
            actionText="OK"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      overlayClassName="event-display-warning-modal"
      id={modalId}
      ariaHideApp={false}
      onClose={() => {}}
    >
      <>
        <h1>Warning:</h1>
        <p className="notes">
          You are excluding this event from ALL views. It will continue to be searchable but will
          not display on your calendar.
        </p>
        <div className="actions">{renderActionButtons()}</div>
      </>
    </Modal>
  );
};

const mapStateToProps = ({ Modal }) => ({
  onSave: Modal.modalData?.onSave,
  displayOptions: Modal.modalData?.displayOptions,
});

ChangeDisplayWarning.propTypes = {
  modalId: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  displayOptions: PropTypes.object.isRequired,
};

ChangeDisplayWarning.defaultProps = {
  modalId: EVENT_DISPLAY_WARNING_MODAL,
};

export default connect(mapStateToProps)(ChangeDisplayWarning);
