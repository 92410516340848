/* eslint-disable max-len */
import React from 'react';

export default function HabitPlayIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#o01ula7x6a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 16A9.98 9.98 0 0 1 16 6a9.98 9.98 0 0 1 10 10 9.98 9.98 0 0 1-10 10A9.98 9.98 0 0 1 6 16zm7.001 4.526 8.25-4.763L13.001 11v9.526z"
          fill={props.fill ? props.fill : '#000'}
          className="fill"
        />
      </g>
      <defs>
        <clipPath id="o01ula7x6a">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
