/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';

export default function ClosedApple(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      style={{ enableBackground: 'new 0 0 300 300' }}
      xmlSpace="preserve"
      {...props}
    >
      <g id="Closed_Apple">
        <g>
          <path className="st4" d="M243.8,0c0,0-93.8,0-93.8,75C150,75,243.8,75,243.8,0z" />
          <g>
            <path
              className="st4"
              d="M206.9,81.6c-15.9,0-34.7,3.7-56.9,12.1c-22.2-8.5-41-12.1-56.9-12.1c-54.6,0-74.3,43.6-74.3,87.1
            				C18.8,225,85.2,300,112.5,300c36,0,34.5-18.8,37.5-18.8s1.5,18.8,37.5,18.8c27.4,0,93.8-75,93.8-131.2
            				C281.2,125.2,261.6,81.6,206.9,81.6z M107.1,125.1c-17.8,0-30.7,5.8-39.4,17.9c-1.5,2-3.8,3.1-6.1,3.1c-1.5,0-3-0.5-4.4-1.4
            				c-3.4-2.4-4.1-7.1-1.7-10.5c11.5-16,28.8-24.1,51.6-24.1c4.1,0,7.5,3.4,7.5,7.5S111.2,125.1,107.1,125.1z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
/* eslint-enable no-mixed-spaces-and-tabs */
