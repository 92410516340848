import React, { Component } from 'react';
import propTypes from 'prop-types';

import ApplicationNavigation from '~/Containers/Layout/ApplicationNavigation/ApplicationNavigation';
import Notifications from '~/Containers/Global/Notifications/Notifications';
import GlobalModals from '~/Components/Layout/ApplicationLayout/GlobalModals';
import MobileTray from '~/Components/Global/MobileTray/MobileTray';
import history from '~/Providers/History';
import EventConfirmation from '~/Components/Pages/CreateEvent/EventConfirmation';
import ReferralPopup from '~/Components/Global/ReferralPopup';
import TrackingPopup from '~/Components/Global/TrackingPopup';
import MobilePopup from '~/Components/Global/MobilePopup';
import TrialPopup from '~/Components/Global/TrialPopup';
import SnoozePopup from '~/Components/Global/ChecklistsDisplay/SnoozePopup';
import { RepeatRulePopup } from '~/Components/Pages/Calendar/RepeatRule';
import { UndoOverlay } from '~/Components/Global/Undo';
import { browser, dragAndDropSupported } from 'Context/Global';

import './ApplicationLayout.scss';

/**
 * Wraps the entire application
 * @param {boolean} authenticated Whether or not the current user is authenticated
 */
class ApplicationLayout extends Component {
  componentDidMount = () => {
    if (this.props.probeAuthStatus()) {
      this.props.getAuthStatus();
    }
    window.addEventListener('flutter.userOptionsUpdated', this.handleUserOptionsUpdated);
  };

  dataFetch = () => {
    this.props.fetchAll();
    this.props.getUser();
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.currentPath !== nextProps.location.pathname ||
      nextProps.currentHash !== nextProps.location.hash
    ) {
      nextProps.updatePath(this.props.location);
    }
    if (nextProps.isAuthenticated && this.props.isAuthenticated !== nextProps.isAuthenticated) {
      this.dataFetch();
    }
    this.props.fetchFeatureFlags();
    this.props.fetchAppFeatures();
  };

  componentWillUnmount = () => {
    window.removeEventListener('flutter.userOptionsUpdated', this.handleUserOptionsUpdated);
  };

  handleUserOptionsUpdated = e => {
    console.log('handleUserOptionsUpdated', e);
    this.props.fetchUserOptions(e.detail);
  };

  render() {
    const { isFetching, hasFetched, isAuthenticated, isSubscribed, hasAccounts } = this.props;
    const isRegistration =
      history.location.pathname.startsWith('/register') ||
      history.location.pathname.startsWith('/subscription') ||
      history.location.pathname.startsWith('/add-accounts') ||
      history.location.pathname.startsWith('/login') ||
      history.location.pathname.startsWith('/forgot-password') ||
      history.location.pathname.startsWith('/reset-password') ||
      history.location.pathname.startsWith('/start-trial') ||
      history.location.pathname.startsWith('/trial-') ||
      history.location.pathname.startsWith('/swap-to-stripe');
    const browserName = browser();
    const dndSupport = dragAndDropSupported();
    const className = `${browserName ? browserName.toLowerCase() : ''} ${
      dndSupport ? '' : 'no-dnd-available'
    }`;
    return (
      <>
        <Notifications key="a" />
        <GlobalModals key="b" />
        {!isFetching && hasFetched && (
          <ApplicationNavigation
            isAuthenticated={isAuthenticated}
            user={this.props.user}
            className={className}
            key="d"
          />
        )}
        <div
          className={`application-background ${browserName ? browserName.toLowerCase() : ''} ${
            isRegistration ? 'registration' : ''
          } ${isFetching || !hasFetched ? 'app-loading' : ''}`}
          key="e"
        >
          {this.props.children}
          {isAuthenticated && isSubscribed && hasAccounts && <MobileTray />}
        </div>
        <EventConfirmation key="f" />
        <ReferralPopup key="g" />
        <MobilePopup key="h" />
        <TrialPopup key="i" />
        <SnoozePopup key="j" />
        <UndoOverlay key="k" />
        <RepeatRulePopup key="l" />
        <TrackingPopup key="m" />
      </>
    );
  }
}

ApplicationLayout.propTypes = {
  children: propTypes.node,
  isAuthenticated: propTypes.bool,
  user: propTypes.object,
  fetchAll: propTypes.func,
  stackingIndex: propTypes.oneOfType([propTypes.number, propTypes.string]),
  probeAuthStatus: propTypes.func,
  getUser: propTypes.func,
  getAuthStatus: propTypes.func,
  updatePath: propTypes.func,
  location: propTypes.object,
  hasFetched: propTypes.bool,
  isFetching: propTypes.bool,
  currentHash: propTypes.string,
  currentPath: propTypes.string,
  isSubscribed: propTypes.bool,
  hasAccounts: propTypes.bool,
  fetchFeatureFlags: propTypes.func.isRequired,
  fetchAppFeatures: propTypes.func.isRequired,
  fetchUserOptions: propTypes.func.isRequired,
};

export default ApplicationLayout;
