export const FETCH_ALL = 'artful-client/MealPrep/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'artful-client/MealPrep/FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'artful-client/MealPrep/FETCH_ALL_FAILURE';
export const FETCH_ONE = 'artful-client/MealPrep/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'artful-client/MealPrep/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'artful-client/MealPrep/FETCH_ONE_FAILURE';

export const SET_QUEUE = 'artful-client/MealPrep/SET_QUEUE';
export const START_UPDATING = 'artful-client/MealPrep/START_UPDATING';
export const FINISH_UPDATING = 'artful-client/MealPrep/FINISH_UPDATING';
export const SET_LAST_UPDATE = 'artful-client/MealPrep/SET_LAST_UPDATE';
