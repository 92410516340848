export const SHOW_SNOOZE = 'artful-client/SnoozePopup/SHOW_SNOOZE';
export const HIDE_SNOOZE = 'artful-client/SnoozePopup/HIDE_SNOOZE';

const initialState = {
  show: false,
  currentDate: false,
  onSnooze: false,
  onCancel: false,
  positionRect: null,
  changeType: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNOOZE: {
      const { onSnooze, onCancel, currentDate, positionRect, changeType } = action;
      return { ...state, show: true, currentDate, onSnooze, onCancel, positionRect, changeType };
    }
    case HIDE_SNOOZE:
      return initialState;
    default:
      return state;
  }
}

export const showSnooze = (
  currentDate,
  positionRect,
  onSnooze,
  onCancel,
  changeType
) => dispatch => {
  dispatch({ type: SHOW_SNOOZE, currentDate, positionRect, onSnooze, onCancel, changeType });
};

export const hideSnooze = () => dispatch => {
  dispatch({ type: HIDE_SNOOZE });
};
