import * as Auth from './Auth';
import * as CalendarAccounts from './CalendarAccounts';

const initialState = {};

const sendToFlutter = payload => {
  const message = JSON.stringify(payload);
  window?.webkit?.messageHandlers?.iosListener?.postMessage(message);
  window?.chrome?.webview?.postMessage(message);
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Auth.SET_ACCESS_TOKEN:
    case Auth.LOGOUT:
    case CalendarAccounts.SYNC_SUCCESS:
    case CalendarAccounts.SYNC_FAILURE:
      sendToFlutter({ type: action.type });
      break;
  }
  return state;
}
