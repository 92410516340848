import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StoreIcon from './StoreIcon.js';
import './StoreLink.scss';

const StoreLink = () => {
  return (
    <Link
      className="store-link"
      to={{ pathname: 'https://store.artfulagenda.com/' }}
      target="_blank"
      role="button"
    >
      <StoreIcon />
    </Link>
  );
};

export default StoreLink;
