import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import range from 'lodash.range';

import DatePicker from '~/Components/Global/DatePicker/DatePicker';

import './OpenDatePicker.scss';

const OpenDatePicker = ({ selected, onMonthChange, ...props }) => {
  const highlightDates = useMemo(() => {
    const weekStart = selected.clone().startOf('week');
    return range(0, 7).map(index => weekStart.clone().add(index, 'days'));
  }, [selected]);

  return (
    <span className="open-date-picker">
      <DatePicker
        inline
        showTimeSelect={false}
        highlightDates={highlightDates}
        value={selected}
        {...props}
        onMonthChange={onMonthChange}
      />
    </span>
  );
};

OpenDatePicker.propTypes = {
  selected: propTypes.object,
  onChange: propTypes.func,
  onMonthChange: propTypes.func,
};

export default OpenDatePicker;
