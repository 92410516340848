/* eslint-disable max-len */
import React from 'react';

export default function HabitMagnifyingGlassIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#2w2hrlbrna)" className="fill">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m29.587 26.55-3.037 3.038s-.342-.293-.84-.788l-4.935-4.94a1.277 1.277 0 0 1 0-1.8l1.284-1.285a1.277 1.277 0 0 1 1.8 0L28.8 25.71c.495.498.787.84.787.84zm-7.916-6.678-1.854-1.854a10.986 10.986 0 0 1-1.8 1.8l1.854 1.854c.068-.1.145-.195.229-.282l1.29-1.29a2.11 2.11 0 0 1 .281-.229zM11.067 21.3a10.233 10.233 0 1 1 0-20.466 10.233 10.233 0 0 1 0 20.466zm6.033-4.2a8.519 8.519 0 0 0-1.772-13.396A8.518 8.518 0 1 0 17.1 17.1zm1.554-6.027A7.582 7.582 0 1 1 16.428 5.7a7.562 7.562 0 0 1 2.22 5.367l.006.006zM7.407 14.73c-.174-.172-.398-.36-.544-.555 0 0-.45.338-.69.573.174.228.362.445.565.648 0 0 .67-.534.67-.658v-.008zm-.669-7.992a6.127 6.127 0 0 0-1.45 6.35c.337-.15.9-.3.9-.3a5.194 5.194 0 0 1-.17-2.888c.216-.95.828-1.902 1.52-2.587-.338-.225-.8-.575-.8-.575z"
          fill={props.fill ? props.fill : '#000'}
          className="fill"
        />
      </g>
      <defs>
        <clipPath id="2w2hrlbrna">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
