import React from 'react';
import PropTypes from 'prop-types';

export const eventFontSizeHelper = fontName => {
  switch (fontName) {
    case 'Swanky and Moo Moo':
      return '1.5em';
    case 'Liu Jian Mao Cao':
      return '1.3em';
    case 'Bellota':
    case 'Give You Glory':
    case 'Shadows Into Light':
    case 'Crafty Girls':
      return '1.2em';
    case 'Handlee':
    case 'Architects Daughter':
      return window.innerWidth <= 1280 ? '1.2em' : '1.3em';
    case 'Raleway':
    case 'Poppins':
      return window.innerWidth <= 1280 ? '1em' : '1.2em';
    default:
      return '1em';
  }
};

export const fontSizeHelper = fontName => {
  switch (fontName) {
    case 'Give You Glory':
    case 'Shadows Into Light':
    case 'Crafty Girls':
    case 'Handlee':
    case 'Architects Daughter':
    case 'Bellota':
      return '1.6rem';
    case 'Raleway':
    case 'Poppins':
      return window.innerWidth <= 1280 ? '1.4rem' : '1.6rem';
    case 'Swanky and Moo Moo':
    case 'Liu Jian Mao Cao':
      return '1.8rem';
    default:
      return '1.3rem';
  }
};

const FontWrapper = ({ font, children }) => {
  const fontFamily = font ? font.css : 'Raleway';
  const extraClass =
    fontFamily === 'Give You Glory' || fontFamily === 'Crafty Girls' || fontFamily === 'Handlee'
      ? 'extra-padding'
      : '';
  return (
    <div
      className={`font-wrapper ${extraClass} font-${fontFamily.replace(/\s/g, '-')}`}
      style={{ fontFamily: fontFamily, fontSize: fontSizeHelper(font?.css) }}
    >
      {children}
    </div>
  );
};

FontWrapper.propTypes = {
  font: PropTypes.object,
  children: PropTypes.node,
};

export default FontWrapper;
