/* eslint-disable max-len */
import React from 'react';

export default function HabitPrayingHandsIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.197 11.288h-.028a.83.83 0 0 0-1.043.8v4.511a.213.213 0 0 1-.425 0v-4.52a1.277 1.277 0 0 1 1.264-1.263h.104l.64-5.27a.487.487 0 0 0-.412-.547h-.08c-.2 0-.38.123-.455.31l-2.001 5.117a15.09 15.09 0 0 0-1.039 5.17l-.055 2.79a1.706 1.706 0 0 1-.356 1.01L8.23 22.098a.558.558 0 0 0 0 .677 6.503 6.503 0 0 0 2.701 2.179.561.561 0 0 0 .609-.111l2.017-1.932a4.017 4.017 0 0 0 1.228-2.883v-7.95a.831.831 0 0 0-.587-.791z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M15 11.382c.128-.192.308-.346.518-.445L15 6.701l-.52 4.23c.213.1.392.256.52.45z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M19.695 19.395a1.718 1.718 0 0 1-.356-1.009l-.064-2.789c-.039-1.77-.39-3.52-1.036-5.171L16.237 5.32a.493.493 0 0 0-.534-.321.487.487 0 0 0-.406.54l.638 5.275h.102a1.276 1.276 0 0 1 1.264 1.264v4.51a.212.212 0 1 1-.425 0v-4.509a.83.83 0 0 0-1.043-.8h-.03a.828.828 0 0 0-.583.793v7.957a4.019 4.019 0 0 0 1.228 2.883l2.014 1.932a.567.567 0 0 0 .611.11 6.498 6.498 0 0 0 2.698-2.178.558.558 0 0 0 0-.677l-2.076-2.704z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
