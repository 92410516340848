/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';

export default function CalendarSettings(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4d4d4d;}' }} />
      </defs>
      <title>Calendar Settings</title>
      <g id="Calendar_Settings" data-name="Calendar Settings">
        <g id="Calendar_Icon" data-name="Calendar Icon">
          <path
            className="cls-1"
            d="M270,20H195V0H185V20H115V0H105V20H30A20,20,0,0,0,10,40V280a20,20,0,0,0,20,20H270a20,20,0,0,0,20-20V40A20,20,0,0,0,270,20Zm10,260a10,10,0,0,1-10,10H30a10,10,0,0,1-10-10V40A10,10,0,0,1,30,30h75V50h10V30h70V50h10V30h75a10,10,0,0,1,10,10Z"
          />
          <path className="cls-1" d="M140,100H130v40h40V100H140Zm20,30H140V110h20Z" />
          <path className="cls-1" d="M70,100H60v40h40V100H70Zm20,30H70V110H90Z" />
          <path className="cls-1" d="M210,100H200v40h40V100H210Zm20,30H210V110h20Z" />
          <path className="cls-1" d="M140,170H130v40h40V170H140Zm20,30H140V180h20Z" />
          <path className="cls-1" d="M70,170H60v40h40V170H70Zm20,30H70V180H90Z" />
          <path className="cls-1" d="M210,170H200v40h40V170H210Zm20,30H210V180h20Z" />
        </g>
      </g>
    </svg>
  );
}
