/* eslint-disable max-len */
import React from 'react';

export default function HabitCartIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25 18.75H10.686a.715.715 0 0 1 0-.625c.188-.563.812-1 1.875-1.25l11.376-1.25C24.562 15.561 25 15 25 14.373V8.749c0-1.249 0-1.25-1.25-1.25H8.374L8.125 6c0-.999 0-.999-1.25-.999H5C3.75 5 3.75 5 3.75 6.25S3.75 7.5 5 7.5h.876l2.312 11.564v.062c.25 1.25 1.187 2.062 2.25 2.062h14.561c.688 0 1.25-.563 1.25-1.251 0-.687-.562-1.187-1.25-1.187zm-12.5-4.375V10H15v4.063l-2.5.312zm3.75-4.376h2.5v3.688l-2.5.25V9.999zm6.25 3.251-2.5.25V10h2.5v3.25zM11.25 10v4.625c-.5.187-.938.375-1.313.624l-1-5.25h2.313zM9.375 24.375a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0zM24.375 24.375a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
