/* eslint-disable max-len */
import React from 'react';

export default function HabitPencilIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.49 20.865a.2.2 0 0 0-.334.087l-1.057 3.702a.2.2 0 0 0 .247.247l3.702-1.057a.2.2 0 0 0 .086-.334L6.49 20.865zM25.499 7.352a.2.2 0 0 1 0 .282l-1.794 1.794a.2.2 0 0 1-.283 0l-2.81-2.81a.2.2 0 0 1 0-.283l1.793-1.794a.2.2 0 0 1 .283 0L25.5 7.35zM7.342 19.843a.2.2 0 0 1 0-.283L19.433 7.47a.2.2 0 0 1 .283 0l2.81 2.81a.2.2 0 0 1 0 .283l-12.09 12.09a.2.2 0 0 1-.283 0l-2.81-2.81z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
