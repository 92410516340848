import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import TextInput from '~/Components/Global/Form/TextInput';
import DatePicker from '~/Components/Global/DatePicker/DatePicker';
import { DATE_TIME_FORMAT } from '~/Redux/Constants';
import useRevalidate from './useRevalidate';

const AdminEditableField = ({
  name,
  value,
  onChange,
  className,
  label,
  revalidate,
  hidden,
  type = 'text',
}) => {
  const [newValue, setNewValue] = useState(value);
  const [isEditing, setEditing] = useState(false);
  const revalidateFn = useRevalidate();

  useEffect(() => {
    if (isEditing) {
      setNewValue(value);
    }
  }, [isEditing, value]);

  const revalidateIfNeeded = () => {
    if (!revalidate) {
      return Promise.resolve('');
    }
    return revalidateFn();
  };

  if (isEditing) {
    switch (type) {
      case 'datetime':
        return (
          <span className={className}>
            <DatePicker
              className="minimal-input inline-input"
              name={name}
              value={value}
              onChange={date => {
                return revalidateIfNeeded()
                  .then(password => {
                    onChange({ value: date.format(DATE_TIME_FORMAT), password });
                  })
                  .finally(() => setEditing(false));
              }}
              showTimeSelect
            />
          </span>
        );
      default:
        return (
          <span className={className}>
            <TextInput
              label={label}
              labelClassName="sr-only"
              name={name}
              type={type}
              value={newValue}
              onChange={changes => setNewValue(changes[name])}
              onKeyDown={e => {
                if (e.key !== 'Enter') return;
                return revalidateIfNeeded()
                  .then(password => {
                    onChange({ value: newValue, password });
                  })
                  .finally(() => setEditing(false));
              }}
            />
          </span>
        );
    }
  }

  return (
    <span
      className={`editable-value ${className}`}
      role="button"
      tabIndex="0"
      onClick={() => setEditing(true)}
    >
      {hidden ? label : value}
      <FontAwesome name="pencil" />
    </span>
  );
};

AdminEditableField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  revalidate: PropTypes.bool,
  hidden: PropTypes.bool,
  type: PropTypes.string,
};

export default AdminEditableField;
