/* eslint-disable max-len */
import React from 'react';

export default function HabitLeafIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.895 7.138c-5.339-.55-8.302 2.133-9.29 3.251a6.467 6.467 0 0 0-1.584 2.53 6.226 6.226 0 0 0 .036 4.102c2.573-3.217 6.883-3.818 8.082-3.925-1.399.679-7.554 3.985-8.931 10.229-.015.051-.033.1-.046.153-.033.13-.065.259-.094.393-.016.075-.034.15-.041.229-.033.146-.08.524.18.623l.88.263s.464.124.454-.376c.01-1.668.466-3.538 1.627-4.665a7.013 7.013 0 0 0 8.244.273C22.403 16.65 25 5 25 5c-2.231 2.818-8.106 2.138-8.106 2.138zm-2.458 5.947v-.01c.013 0 .023-.003.023-.003a.16.16 0 0 1-.023.013z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
