import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';

import './HexInput.scss';

function formatValue(value) {
  if (!value) {
    return '';
  }

  return value.replace(/^#/, ''); // Remove leading "#" if present
}

const isValidHex = value => /^#?[0-9a-fA-F]{6}$/.test(value);

const HexInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(formatValue(value));

  useEffect(() => {
    setInputValue(formatValue(value));
  }, [value]);

  // Listener Callbacks
  const handleOnChange = ({ target: { value } }) => {
    value = value.replace(/[^0-9a-fA-F]/g, '').substring(0, 6); // Enforces hexadecimal valid values

    if (isValidHex(value) && value != inputValue) {
      setInputValue(value);
      onChange(value);
    } else {
      setInputValue(value);
    }
  };

  return (
    <div className="hex-input-wrapper">
      <label htmlFor="hex-input">#</label>
      <input className="hex-input" name="hex-input" value={inputValue} onChange={handleOnChange} />
    </div>
  );
};

HexInput.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func,
};

export default HexInput;
