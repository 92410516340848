import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { couponApi, taxApi } from '~/Providers/Api';
import { getPricing } from '~/Config/Stripe';

class TotalWithTax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingTax: false,
      taxError: false,
      taxPercentage: 0,
      taxAmount: 0,
      totalAmount: 0,
      fetchingCoupon: false,
      couponError: false,
      coupon: null,
    };
  }

  componentDidMount = () => {
    this.setState({ totalAmount: this.props.amount });
    if (this.props.user) {
      this.setUserBasedTax(this.props);
      return;
    }

    if (this.props.zip && this.props.zip.length === 5) {
      this.handleZip(this.props.zip, this.props.amount);
    }
  };

  setUserBasedTax = props => {
    const taxRate = props.user ? props.user.tax_pct : 0;
    const taxAmount = Math.round(taxRate * props.amount) / 100;
    this.setState({
      taxPercentage: taxRate,
      taxAmount: taxAmount,
      totalAmount: props.amount + taxAmount,
    });
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.coupon !== this.props.coupon) {
      if (nextProps.coupon) {
        this.handleCoupon(nextProps.coupon);
      } else {
        this.setState({
          coupon: null,
          fetchingCoupon: false,
          couponError: false,
        });
      }
    }

    if (nextProps.user) {
      this.setUserBasedTax(nextProps);
      return;
    }

    if (this.props.zip !== nextProps.zip || this.props.amount !== nextProps.amount) {
      if (nextProps.zip && nextProps.zip.length === 5) {
        this.handleZip(nextProps.zip, nextProps.amount);
      } else {
        this.setState({
          taxPercentage: 0,
          taxAmount: 0,
          totalAmount: nextProps.amount,
        });
      }
    }
  };

  handleCoupon = debounce(coupon => {
    this.setState({ fetchingCoupon: true, couponError: false, coupon: null });
    couponApi.check(coupon).then(
      result => {
        console.log('coupon', result.data);
        if (result.data.valid) {
          this.setState({
            fetchingCoupon: false,
            couponError: false,
            coupon: result.data,
          });
        } else {
          this.setState({
            fetchingCoupon: false,
            couponError: 'Coupon is no longer valid',
            coupon: null,
          });
        }
        this.props.onCouponChanged?.(result.data.valid);
      },
      error => {
        console.log('coupon error', error);
        this.setState({
          fetchingCoupon: false,
          couponError: error.message,
          coupon: null,
        });
        this.props.onCouponChanged?.(false);
      }
    );
  }, 600);

  handleZip = (zip, amount) => {
    this.setState({
      fetchingTax: true,
      taxError: false,
      taxPercentage: 0,
      taxAmount: 0,
      totalAmount: amount,
    });
    taxApi.show(zip, amount).then(
      result => {
        console.log('handle zip', result);
        this.setState({
          fetchingTax: false,
          taxPercentage: result.data.rate * 100,
          taxAmount: result.data.amount_to_collect,
          totalAmount: result.data.amount_to_collect + amount,
        });
      },
      error => {
        console.log('handle zip error', error);
        this.setState({
          fetchingTax: false,
          taxError: true,
          taxPercentage: 0,
          taxAmount: 0,
          totalAmount: amount,
        });
      }
    );
  };

  render() {
    let { coupon, couponError, fetchingCoupon } = this.state;
    const { plan } = this.props;

    if (['AUBREY'].filter(id => id === coupon?.id).length && plan != getPricing().yearly.plan) {
      coupon = null;
      couponError = 'That promo code is only valid for annual subscriptions';
    }
    return (
      <div className="total-with-tax">
        {fetchingCoupon && (
          <div className="total-with-tax__coupon">
            <div className="total-with-tax__coupon__label">Promo</div>
            <div className="total-with-tax__coupon__value">Loading...</div>
          </div>
        )}
        {coupon?.percent_off && (
          <div className="total-with-tax__coupon">
            <div className="total-with-tax__coupon__label">
              {`${coupon.percent_off === 100 ? 'FREE' : `${coupon.percent_off}% OFF`} ${
                coupon.duration === 'repeating' ? `FOR ${coupon.duration_in_months} MONTHS` : 'ONCE'
              }`}
              <div className="total-with-tax__coupon__value">
                <TotalWithTax
                  amount={(this.props.amount * (100 - coupon.percent_off)) / 100}
                  zip={this.props.zip}
                />
              </div>
            </div>
          </div>
        )}
        {coupon?.amount_off && (
          <div className="total-with-tax__coupon">
            <div className="total-with-tax__coupon__label">
              {`\$${(coupon.amount_off / 100).toFixed(2)} OFF ${
                coupon.duration === 'repeating' ? `FOR ${coupon.duration_in_months} MONTHS` : 'ONCE'
              }`}
              <div className="total-with-tax__coupon__value">
                <TotalWithTax
                  amount={this.props.amount - coupon.amount_off / 100}
                  zip={this.props.zip}
                />
              </div>
            </div>
          </div>
        )}
        {couponError && <div className="total-with-tax__coupon-error">{couponError}</div>}
        {coupon && <h2>After Code Expires:</h2>}
        <div className="total-with-tax__tax">
          <div className="total-with-tax__tax__label">Sales Tax ({this.state.taxPercentage}%)</div>
          <div className="total-with-tax__tax__value">
            {this.state.fetchingTax ? 'Loading...' : `$${this.state.taxAmount.toFixed(2)}`}
          </div>
        </div>
        <div className="total-with-tax__total">
          <div className="total-with-tax__total__label">Total</div>
          <div className="total-with-tax__total__value">
            <span className="total-with-tax__total__value__currency">USD$</span>
            <span className="total-with-tax__total__value__amount">
              {this.state.totalAmount.toFixed(2)}
            </span>
          </div>
        </div>
        {this.state.taxError && (
          <div className="total-with-tax__error">
            Sorry, an error occurred. Please check your postal code and try again.
          </div>
        )}
      </div>
    );
  }
}

TotalWithTax.propTypes = {
  amount: PropTypes.number,
  user: PropTypes.object,
  zip: PropTypes.string,
  coupon: PropTypes.string,
  plan: PropTypes.string,
  onCouponChanged: PropTypes.func,
};

export default TotalWithTax;
