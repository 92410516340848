import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

/**
 * Used instead of a basic `Route` to guard access to Pages should not be accessed when a user is Authenticated
 */
const UnAuthRoute = ({
  component: RenderComponent,
  hasFetched,
  isFetching,
  isAuthenticated,
  redirect,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isFetching || !hasFetched) return null;
      if (isAuthenticated) return <Redirect to={redirect} />;
      return <RenderComponent {...props} />;
    }}
  />
);

UnAuthRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.node, propTypes.func]),
  isAuthenticated: propTypes.bool,
  isFetching: propTypes.bool,
  hasFetched: propTypes.bool,
  redirect: propTypes.string,
};

UnAuthRoute.defaultProps = {
  isAuthenticated: false,
  hasFetched: false,
  isFetching: false,
  redirect: '/month',
};

export default UnAuthRoute;
