const SET_SRC = 'artful-client/SkinWrapper/SET_SRC';
const SET_LOADED = 'artful-client/SkinWrapper/SET_LOADED';

const initialState = {
  src: false,
  loaded: false,
  imageWidth: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SRC:
      return { ...state, src: action.src, loaded: false, imageWidth: false };
    case SET_LOADED:
      return { ...state, loaded: action.loaded, imageWidth: action.imageWidth };
    default:
      return state;
  }
}

export const setSrc = src => dispatch => {
  dispatch({ type: SET_SRC, src });
};

export const setLoaded = (loaded, imageWidth) => dispatch => {
  dispatch({ type: SET_LOADED, loaded, imageWidth });
};
