import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Leanplum from 'leanplum-sdk';
import config from '~/Config';
import Button from '~/Components/Global/Form/Button';
import history from '~/Providers/History';
import moment from '~/Providers/Moment';

import Card from '~/Components/Layout/Card/Card';
import Form from '~/Components/Global/Form/Form';
import TextInput from '~/Components/Global/Form/TextInput';
import ArtfulCheckbox from '~/Components/Global/Form/ArtfulCheckbox';
import { useMountEffect } from '~/util';
import { AccountApi, errorApi } from '~/Providers/Api';

import ConsentRequiredView, {
  CONSENT_TYPE_EMAIL,
} from '~/Components/Global/ConsentRequiredView/ConsentRequiredView';
import mParticle, {
  ScreenViewEvent,
  SocialRegistrationPresentedEvent,
} from '~/Providers/mParticle';
import Store from '~/Redux/Store';
import { error } from '~/Redux/Modules/Notifications';

import './Register.scss';
import GoogleSignInWrapper from '~/Components/Global/GoogleSignInWrapper/GoogleSignInWrapper';
import { useConsentSettings } from '~/Components/Global/ConsentRequiredView';
import AppleSignInButton from '~/Components/Global/AppleSignInButton/AppleSignInButton';
import Storage from '~/Providers/Storage';

const ordinalNumbers = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
];

const useRegisterCode = referralCode => {
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const interval = useRef();
  const request = useRef();

  useEffect(() => {
    if (interval.current) clearTimeout(interval.current);
    interval.current = setTimeout(() => {
      const stamp = moment().unix();
      request.current = stamp;
      setError(null);
      AccountApi.checkCode(referralCode)
        .then(results => {
          if (request.current !== stamp) {
            console.log(`discard results for ${referralCode}`, stamp, request.current);
            return;
          }
          console.log(`results for ${referralCode}`, results.data);
          setResults(results.data);
          setError(null);
        })
        .catch(error => {
          if (request.current !== stamp) {
            console.log(`discard error for ${referralCode}`, stamp, request.current);
            return;
          }
          console.log(`error for ${referralCode}`, error);
          setResults(null);
          setError(error);
        });
    }, 300);
  }, [referralCode]);

  return { results, error };
};

const Register = ({
  handleRegistration,
  updateField,
  fieldsRegister,
  isFetching,
  handleGoogleRegistration,
  handleAppleRegistration,
}) => {
  console.log('Leanplum', Leanplum.getVariables(), Leanplum.getVariable('registerWithSocial'));

  const [showSocialButtons, setShowSocialButtons] = useState(
    Leanplum.getVariable('registerWithSocial')
  );

  useMountEffect(() => {
    Leanplum.addVariablesChangedHandler(function() {
      // don't hide social buttons once shown
      const newValue = Leanplum.getVariable('registerWithSocial') || showSocialButtons;
      setShowSocialButtons(newValue);
      mParticle.logEvent(new SocialRegistrationPresentedEvent(newValue));
    });

    Leanplum.forceContentUpdate();
  });

  const handleRegister = () => {
    handleRegistration(fieldsRegister);
  };

  useMountEffect(() => {
    if (config.gaProperty) {
      ReactGA.ga(tracker => {
        console.log(`tracker: ${tracker.get('clientId')}`);
        updateField({ gaClientId: tracker.get('clientId') });
      });
    }

    mParticle.logEvent(new ScreenViewEvent('Registration'));
  });

  const consentSettings = useConsentSettings();
  useEffect(() => {
    if (consentSettings) {
      updateField({
        [CONSENT_TYPE_EMAIL]: consentSettings[CONSENT_TYPE_EMAIL],
        consent_country: consentSettings.country,
      });
    }
  }, [consentSettings, updateField]);

  const referralCode = fieldsRegister?.referral_code;

  const { results: registerCodeSettings, error: registerCodeError } = useRegisterCode(referralCode);

  const renderTrialInfo = () => {
    if (
      fieldsRegister.referral_code.toLowerCase() === 'sparkle19' ||
      fieldsRegister.referral_code.toLowerCase() === 'artfulmedia'
    )
      return 'Try Artful Agenda FREE for three months!';

    if (registerCodeSettings && registerCodeSettings.trial_without_card) {
      let amount = registerCodeSettings.trial_without_card?.amount;
      if (amount < ordinalNumbers.length) amount = ordinalNumbers[amount];
      return `Try Artful Agenda FREE for ${amount} ${registerCodeSettings.trial_without_card?.units}!`;
    }
    return 'Try Artful Agenda FREE for two weeks!';
  };

  const renderStartButton = () => {
    let buttonText = 'START MY TRIAL';
    if (
      fieldsRegister.referral_code.toLowerCase() === 'sparkle19' ||
      fieldsRegister.referral_code.toLowerCase() === 'artfulmedia'
    )
      buttonText = 'START PLANNING';

    if (registerCodeSettings?.trial_without_card) buttonText = 'START PLANNING';
    return (
      <Button
        type="submit"
        text={buttonText}
        actionText="SIGNING UP"
        isFetching={isFetching}
        disabled={isFetching}
        className="btn btn-default btn-artful"
      />
    );
  };

  const renderRegisterCodeError = () => {
    if (registerCodeError?.status === 422) {
      return <div className="text-error center">That registration code is no longer valid</div>;
    } else if (registerCodeError?.status === 404) {
      return <div className="text-error center">That registration code was not found</div>;
    } else if (registerCodeError) {
      return (
        <div className="text-error center">An error occurred checking the registration code</div>
      );
    }
    return null;
  };

  const handleGoogleRegisterSuccess = response => {
    console.info('handleGoogleSuccess', response.code);
    let marketingOptOut = fieldsRegister.marketing_opt_out || fieldsRegister.marketing_opt_out_top;
    handleGoogleRegistration({
      code: response.code,
      marketing_opt_out: marketingOptOut,
      consent_country: consentSettings?.country,
    });
  };

  const handleGoogleRegisterFailed = err => {
    console.error('handleGoogleFailed', JSON.stringify(err.error));
    errorApi.noticeError(err, 'handleGoogleRegisterFailed');
    Store.dispatch(error('Sorry, an error occurred', err.error));
  };

  const handleAppleSuccess = response => {
    console.info('handleAppleSuccess', response);
    if (response.user) {
      console.log('found apple user', response.user);
      Storage.set('appleIdUser', JSON.stringify(response.user));
    }
    const user = response.user || JSON.parse(Storage.get('appleIdUser')) || {};
    let marketingOptOut = fieldsRegister.marketing_opt_out || fieldsRegister.marketing_opt_out_top;
    handleAppleRegistration({
      code: response.authorization.code,
      marketing_opt_out: marketingOptOut,
      consent_country: consentSettings?.country,
      name: user?.name?.firstName,
      last_name: user?.name?.lastName,
    });
  };

  const handleAppleFailed = err => {
    console.error('handleAppleFailed', JSON.stringify(err.error));
    errorApi.noticeError(err, 'handleAppleFailed');
    Store.dispatch(error('Sorry, an error occurred', err.error));
  };

  const inAppBrowser = () => {
    return (
      window.navigator.userAgent.indexOf('FBAN') !== -1 ||
      window.navigator.userAgent.indexOf('FBAV') !== -1 ||
      window.navigator.userAgent.indexOf('Bytedance') !== -1
    );
  };

  return (
    <Card
      footerAction={() => <Link to="/login">Click here to login</Link>}
      footerText="Already have an account?"
      noLogo
    >
      {showSocialButtons && (
        <>
          <h2 className="center">{renderTrialInfo()}</h2>
          <br />
          {!inAppBrowser() && (
            <GoogleSignInWrapper
              actionText="Signing Up"
              isUpdating={isFetching}
              buttonText="Continue with Google"
              onSuccess={credentialResponse => {
                handleGoogleRegisterSuccess(credentialResponse);
                console.log(credentialResponse);
              }}
              onError={err => {
                handleGoogleRegisterFailed(err);
                console.log('Account Registration Failed', err);
              }}
            />
          )}
          <AppleSignInButton
            buttonText="Continue with Apple"
            actionText="Signing Up"
            isFetching={isFetching}
            onSuccess={handleAppleSuccess}
            onError={handleAppleFailed}
          />
          <ConsentRequiredView consentType={CONSENT_TYPE_EMAIL}>
            <div className="top-checkbox">
              <ArtfulCheckbox
                checklistStyle="small"
                label="Do not email me with updates, tips, or offers from Artful Agenda"
                value={fieldsRegister.marketing_opt_out_top}
                onChange={updateField}
                name="marketing_opt_out_top"
              />
            </div>
          </ConsentRequiredView>
          <div className="divider-wrapper">
            <div className="divider" />
            <p className="divider-text">or</p>
            <div className="divider" />
          </div>
        </>
      )}
      <Form handleSubmit={handleRegister}>
        <TextInput
          className="minimal-input mobile-font-fix"
          onChange={updateField}
          placeholder="First name"
          name="name"
          showLabel={false}
          value={fieldsRegister.name}
        />
        <TextInput
          className="minimal-input mobile-font-fix"
          onChange={updateField}
          placeholder="Last name"
          name="last_name"
          showLabel={false}
          value={fieldsRegister.last_name}
        />
        <TextInput
          className="minimal-input mobile-font-fix"
          onChange={updateField}
          placeholder="Email address"
          name="email"
          showLabel={false}
          type="email"
          value={fieldsRegister.email}
        />
        <TextInput
          className="minimal-input mobile-font-fix"
          onChange={updateField}
          placeholder="Password"
          name="password"
          showLabel={false}
          type="password"
          value={fieldsRegister.password}
        />
        <TextInput
          className="minimal-input mobile-font-fix"
          onChange={updateField}
          placeholder="Confirm password"
          name="password_confirmation"
          showLabel={false}
          type="password"
          value={fieldsRegister.password_confirmation}
        />
        <TextInput
          className="minimal-input mobile-font-fix"
          onChange={updateField}
          placeholder="Referral code (optional)"
          name="referral_code"
          showLabel={false}
          value={fieldsRegister.referral_code}
        />
        {renderRegisterCodeError()}
        <ConsentRequiredView consentType={CONSENT_TYPE_EMAIL}>
          <div className="bottom-checkbox">
            <ArtfulCheckbox
              checklistStyle="small"
              label="Do not email me with updates, tips, or offers from Artful Agenda"
              value={fieldsRegister.marketing_opt_out}
              onChange={updateField}
              name="marketing_opt_out"
            />
          </div>
        </ConsentRequiredView>
        {!showSocialButtons && <h2 className="center">{renderTrialInfo()}</h2>}
        <div className="center">{renderStartButton()}</div>
      </Form>
      <hr />
      <div className="card-caption login-link-wrapper">
        <small className="login-link-text">Already have an account?</small>
        <button onClick={() => history.push('/login')} className="login-link">
          <small>LOGIN</small>
        </button>
      </div>
      <div className="disclosure">
        {`By signing up, you agree to Artful Agenda's`}{' '}
        <a href="http://artfulagenda.com/terms" className="terms">
          Terms of Service
        </a>
      </div>
    </Card>
  );
};

Register.propTypes = {
  handleRegistration: propTypes.func,
  handleAppleRegistration: propTypes.func,
  handleGoogleRegistration: propTypes.func,
  fieldsRegister: propTypes.object,
  updateField: propTypes.func,
  isFetching: propTypes.bool,
  checklistStyle: propTypes.bool,
};

Register.defaultProps = {};

export default Register;
