import { useDispatch } from 'react-redux';
import { openModal, setModalData } from '~/Redux/Modules/Modal';
import { REVALIDATE_PASSWORD_MODAL } from '~/Config/Modals';

const useRevalidate = () => {
  const dispatch = useDispatch();

  const revalidate = opts => {
    const deferred = {};
    const revalidatePromise = new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    return new Promise((resolve, reject) => {
      dispatch(openModal(REVALIDATE_PASSWORD_MODAL));
      dispatch(setModalData({ revalidatePromise: deferred, ...opts }));
      revalidatePromise
        .then(password => {
          resolve(password);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  return revalidate;
};

export default useRevalidate;
