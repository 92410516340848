import React, { Component } from 'react';
import propTypes from 'prop-types';

import Table from '~/Components/Global/Table/Table';
import LoadingMessage from '~/Components/Global/Loading/LoadingMessage';

import './AdminPage.scss';

class AdminPage extends Component {
  constructor() {
    super();
    this.state = {
      showCreateForm: false,
    };
  }

  componentDidMount = () => {
    this.getData();
  };

  getData = (page = 1, search = '') => {
    this.props.getData(page, search);
  };

  generateHeader = () => {
    const { children, dataName, header } = this.props;
    return [
      <h3 key="a" className="text-center margin-bottom">
        {header}
      </h3>,
      <button
        key="b"
        className="btn btn-primary"
        onClick={() => this.setState({ showCreateForm: !this.state.showCreateForm })}
      >
        {this.state.showCreateForm ? 'Cancel' : `Add ${dataName}`}
      </button>,
      this.state.showCreateForm && (
        <div key="c" className="create-form">
          {children}
        </div>
      ),
    ];
  };

  generateNoData = () => {
    return [
      this.generateHeader(),
      <h4
        key="a"
        className="margin-top center"
      >{`No ${this.props.dataName}s click add above.`}</h4>,
    ];
  };

  render() {
    const { loadingMessage, isFetching } = this.props;
    const { data, current_page, per_page, total } = this.props.dataObject;
    if (!data.length && isFetching) return <LoadingMessage messages={loadingMessage} />;
    if (!data.length) return this.generateNoData();
    return (
      <div>
        {this.generateHeader()}
        <Table
          data={data}
          columns={this.props.columns}
          curPage={current_page}
          perPage={per_page}
          total={total}
          getNewRecords={this.getData}
          showSearch
        />
      </div>
    );
  }
}

AdminPage.propTypes = {
  getData: propTypes.func,
  children: propTypes.node,
  header: propTypes.string,
  dataObject: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  isFetching: propTypes.bool,
  current_page: propTypes.number,
  per_page: propTypes.number,
  total: propTypes.number,
  dataName: propTypes.string,
  loadingMessage: propTypes.string,
  columns: propTypes.array,
};

AdminPage.defaultProps = {
  loadingMessage: 'Loading Data',
  dataName: 'data',
  header: 'Data',
};

export default AdminPage;
