/* eslint-disable max-len */
import React from 'react';

export default function HabitLotionIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.483 1.575H7.518l.21 1.038h.864l1.887 18.412h9.038l1.892-18.412.902-.014.172-1.024zM15 14.927a2.135 2.135 0 0 1-2.136-2.132c0-.99 1.41-3.37 1.69-3.836.094-.157.446-.643.446-.643s.348.488.441.643c.286.466 1.695 2.846 1.695 3.836A2.135 2.135 0 0 1 15 14.927zM10.535 25.545c0 .929.654 1.706 1.524 1.903v-2.555c0-.856.7-1.556 1.556-1.556h2.763c.855 0 1.555.7 1.555 1.556v2.555a1.96 1.96 0 0 0 1.53-1.903v-3.484l-8.928-.02v3.504z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M16.38 24.373h-2.764a.52.52 0 0 0-.519.52V27.5h3.8v-2.608a.519.519 0 0 0-.518-.518z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
