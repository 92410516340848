import { userColorsApi } from '~/Providers/Api';
import { parseErrors } from '~/Redux/Helpers';

import { error, success } from '../Notifications';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from './constants';

export const getColors = () => async dispatch => {
  dispatch({ type: FETCH });

  try {
    const response = await userColorsApi.fetch();
    dispatch({ type: FETCH_SUCCESS, colors: response.data });

    return response.data;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
    throw err;
  }
};

export const addColor = hex => async dispatch => {
  dispatch({ type: STORE });

  try {
    const response = await userColorsApi.store(hex);
    dispatch({ type: STORE_SUCCESS, color: response.data });
    dispatch(success('COLOR ADDED!'));

    return response.data;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
    dispatch(error('COLOR NOT ADDED.', parseErrors(err)));
    throw err;
  }
};

export const deleteColor = color => async dispatch => {
  dispatch({ type: DELETE });

  try {
    const response = await userColorsApi.delete(color);
    dispatch({ type: DELETE_SUCCESS, color: response.data });
    dispatch(success('COLOR DELETED!'));

    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, error: err });
    dispatch(error('COLOR NOT DELETED.', parseErrors(err)));
    throw err;
  }
};
