import { AdminSkinsApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';

const FETCH_ALL = 'artful-client/Admin/Skins/FETCH_ALL';
const FETCH_ALL_SUCCESS = 'artful-client/Admin/Skins/FETCH_ALL_SUCCESS';
const FETCH_ALL_FAILURE = 'artful-client/Admin/Skins/FETCH_ALL_FAILURE';

const FETCH = 'artful-client/Admin/Skins/FETCH';
const FETCH_SUCCESS = 'artful-client/Admin/Skins/FETCH_SUCCESS';
const FETCH_FAILURE = 'artful-client/Admin/Skins/FETCH_FAILURE';

const STORE = 'artful-client/Admin/Skins/STORE';
const STORE_SUCCESS = 'artful-client/Admin/Skins/STORE_SUCCESS';
const STORE_FAILURE = 'artful-client/Admin/Skins/STORE_FAILURE';

const UPDATE = 'artful-client/Admin/Skins/UPDATE';
const UPDATE_SUCCESS = 'artful-client/Admin/Skins/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'artful-client/Admin/Skins/UPDATE_FAILURE';

const DELETE = 'artful-client/Admin/Skins/DELETE';
const DELETE_SUCCESS = 'artful-client/Admin/Skins/DELETE_SUCCESS';
const DELETE_FAILURE = 'artful-client/Admin/Skins/DELETE_FAILURE';

const UPDATE_FORM = 'artful-client/Admin/Skins/UPDATE_FORM';

const UPDATE_FORM_IMAGE = 'artful-client/Admin/Skins/UPDATE_FORM_IMAGE';

const CLEAR_SKIN = 'artful-client/Admin/Skins/CLEAR_SKIN';

const CLEAR = 'artful-client/Admin/Skins/CLEAR';

export const PORTRAIT_IMAGE = 'displayImage';
export const LANDSCAPE_IMAGE = 'backgroundImage';
export const MOBILE_IMAGE = 'mobileImage';

const initialState = {
  isFetching: false,
  isAdding: false,
  isDeleting: false,
  hasFetched: false,
  isUpdating: false,
  data: {
    current_page: 1,
    per_page: 20,
    total: 20,
    data: [],
  },
  error: '',
  actionSkin: {
    name: '',
    price: 0.0,
    status: true,
    images: [{ name: PORTRAIT_IMAGE }, { name: LANDSCAPE_IMAGE }],
  },
  actionId: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL:
      return { ...state, isFetching: true, hasFetched: false };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        data: action.data,
      };
    case FETCH_ALL_FAILURE:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        error: action.error,
      };

    case FETCH:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        actionSkin: {
          ...action.data,
          price: action.data.product && action.data.product.price,
          sku: action.data.product && action.data.product.sku,
        },
      };
    case FETCH_FAILURE:
      return { ...state, isFetching: false, error: action.error };

    case STORE:
      return { ...state, isAdding: true };
    case STORE_SUCCESS:
      return {
        ...state,
        isAdding: false,
        actionSkin: initialState.actionSkin,
        data: { ...state.data, data: [...state.data.data, action.data] },
      };
    case STORE_FAILURE:
      return { ...state, isAdding: false, error: action.error };

    case DELETE:
      return { ...state, isDeleting: true, actionId: action.actionId };
    case DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        actionId: false,
        data: {
          ...state.data,
          data: state.data.data.filter(s => s.id !== action.data.id),
        },
      };
    case DELETE_FAILURE:
      return { ...state, isDeleting: false, error: action.error };

    case UPDATE:
      return { ...state, isUpdating: true, actionId: action.actionId };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        actionId: false,
        data: {
          ...state.data,
          data: state.data.data.map(s => (s.id === action.data.id ? action.data : s)),
        },
      };
    case UPDATE_FAILURE:
      return { ...state, isUpdating: false, error: action.error };

    case UPDATE_FORM:
      return {
        ...state,
        actionSkin: { ...state.actionSkin, ...action.update },
      };

    case UPDATE_FORM_IMAGE:
      return {
        ...state,
        actionSkin: {
          ...state.actionSkin,
          images: [
            ...state.actionSkin.images.filter(si => si.name !== action.name),
            { ...action.image, name: action.name },
          ],
        },
      };

    case CLEAR_SKIN:
      return { ...state, actionSkin: initialState.actionSkin };

    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

export const getSkins = (page = 1, search = '') => async dispatch => {
  dispatch({ type: FETCH_ALL });
  try {
    let response = await AdminSkinsApi.fetchAll(page, search);
    dispatch({ type: FETCH_ALL_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_ALL_FAILURE, error: err });
  }
};

export const getSkin = skinId => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await AdminSkinsApi.fetch(skinId);
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const deleteSkin = id => async dispatch => {
  dispatch({ type: DELETE, actionId: id });
  try {
    let response = await AdminSkinsApi.delete(id);
    dispatch({ type: DELETE_SUCCESS, data: response.data });
    dispatch(success('Skin Deleted!'));
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, error: err });
  }
};

export const addSkin = data => async dispatch => {
  dispatch({ type: STORE });
  const displayImage = data.images.find(image => image.name === PORTRAIT_IMAGE);
  const backgroundImage = data.images.find(image => image.name === LANDSCAPE_IMAGE);
  const mobileImage = data.images.find(image => image.name === MOBILE_IMAGE);

  try {
    let response = await AdminSkinsApi.store({
      ...data,
      displayImage: displayImage ? displayImage.displayImage : false,
      backgroundImage: backgroundImage ? backgroundImage.backgroundImage : false,
      mobileImage: mobileImage ? mobileImage.mobileImage : false,
    });
    dispatch({ type: STORE_SUCCESS, data: response.data });
    dispatch(success('Skin Added!'));
    return response;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
    dispatch(error('Skin not added', parseErrors(err)));
  }
};

export const updateSkin = data => async dispatch => {
  dispatch({ type: UPDATE });
  const displayImage = data.images.find(image => image.name === PORTRAIT_IMAGE);
  const backgroundImage = data.images.find(image => image.name === LANDSCAPE_IMAGE);
  const mobileImage = data.images.find(image => image.name === MOBILE_IMAGE);

  try {
    let response = await AdminSkinsApi.update({
      ...data,
      displayImage: displayImage && displayImage.displayImage ? displayImage.displayImage : '',
      backgroundImage:
        backgroundImage && backgroundImage.backgroundImage ? backgroundImage.backgroundImage : '',
      mobileImage: mobileImage && mobileImage.mobileImage ? mobileImage.mobileImage : '',
    });
    dispatch({ type: UPDATE_SUCCESS, data: response.data });
    dispatch(success('Skin Updated!'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_FAILURE, error: err });
    dispatch(error('Skin not updated', parseErrors(err)));
  }
};

export const updateForm = update => dispatch => {
  dispatch({ type: UPDATE_FORM, update });
};

export const updateFormImage = (image, name) => dispatch => {
  dispatch({ type: UPDATE_FORM_IMAGE, image, name });
};

export const clear = () => dispatch => {
  dispatch({ type: CLEAR });
};

export const clearSkin = () => dispatch => {
  dispatch({ type: CLEAR_SKIN });
};
