import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import NavItem from '~/Components/Layout/ApplicationNavigation/NavItem';
import DropDown from '~/Components/Layout/ApplicationNavigation/DropDown';
import CalendarStyleSvg from '~/Components/Global/Assets/Svgs/CalendarStyle';
import Spinner from '~/Components/Global/Loading/Spinner';
import { eventFontSizeHelper } from '~/Components/Global/FontWrapper/FontWrapper';

import './Styles.scss';
import FeatureFlagView from '~/Components/Global/FeatureFlagView';
import { useMountEffect } from '~/util';

const Styles = ({
  fonts,
  userOptions,
  updateFont,
  updateUserOptions,
  isFetching,
  fontNameForEvents,
}) => {
  const dropdownRef = useRef();
  useEffect(() => {
    const handleOpenStyleDropdown = () => {
      console.log('handleOpenStyleDropdown', dropdownRef);
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(dropdownRef.current)
        ?.querySelector('.dropdown-toggle')
        ?.click?.();
    };

    window.addEventListener('native.openStyleDropdown', handleOpenStyleDropdown);
    return () => {
      window.removeEventListener('native.openStyleDropdown', handleOpenStyleDropdown);
    };
  }, [dropdownRef]);

  return (
    <DropDown className="style-chooser" auth svg={CalendarStyleSvg} ref={dropdownRef}>
      <DropDown
        subMenu
        className="style-chooser-inner"
        auth
        label="CHOOSE HANDWRITING"
        ref={dropdownRef}
      >
        {fonts.map((font, index) => {
          return (
            <NavItem key={index} auth>
              <span onClick={() => updateFont(userOptions.id, font)}>
                <span
                  style={{ fontFamily: font.css, fontSize: eventFontSizeHelper(font.css) }}
                  className="menu-label"
                >
                  {font.css}
                </span>
                {userOptions && userOptions.font_id === font.id && (
                  <FontAwesome className="fa-check" name="check" />
                )}
              </span>
            </NavItem>
          );
        })}
        <hr />
        <NavItem
          key="use-in-events"
          className="use-in-events"
          onClick={() =>
            updateUserOptions({
              ...userOptions,
              use_font_for_events: !userOptions.use_font_for_events,
            })
          }
        >
          {isFetching ? (
            <Spinner />
          ) : (
            <FontAwesome
              className="use-font-in-events"
              name={`check-circle ${userOptions.use_font_for_events ? 'complete' : 'incomplete'}`}
            />
          )}
          <span>Use In Events</span>
        </NavItem>
      </DropDown>
      <NavItem label="CHOOSE COVER" path="/choose-cover-list" auth />
    </DropDown>
  );
};

Styles.propTypes = {
  fonts: propTypes.array,
  userOptions: propTypes.object,
  updateFont: propTypes.func,
  isFetching: propTypes.bool,
  updateUserOptions: propTypes.func,
  fontNameForEvents: propTypes.string,
};

export default Styles;
