import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import config from '~/Config';
import { getPricing, planMatchesIap } from '~/Config/Stripe';
import { subscribe, updateField } from '~/Redux/Modules/Subscription';
import Button from '~/Components/Global/Form/Button';
import StripePermittedView from '~/Components/Global/StripePermittedView';
import Spinner from '~/Components/Global/Loading/Spinner';
import { parseErrors } from '~/Redux/Helpers';
import { guid, useMountEffect } from '~/util';
import { UserApi } from '~/Providers/Api';
import moment from '~/Providers/Moment';
import { DATE_FORMAT } from '~/Redux/Constants';
import useSetupIntent from '~/Components/Global/Stripe/useSetupIntent';
import Storage from '~/Providers/Storage';
import SetupIntentForm from '~/Components/Pages/Subscription/SetupIntentForm';

import './ManageSubscription.scss';

const stripePromise = loadStripe(config.stripeKey, config.stripeOptions);

const SwapToStripe = () => {
  const dispatch = useDispatch();
  const account = useSelector(({ Account }) => Account.data);
  const pricingPlans = useCallback(getPricing(), []);
  const [selectedPlan, setSelectedPlan] = useState(pricingPlans.yearlyPlus);
  const {
    isFetching,
    data: { coupon },
  } = useSelector(({ Subscription }) => Subscription);
  const [errorMessage, setErrorMessage] = useState('');
  const [historicalPlans, setHistoricalPlans] = useState([]);
  const { setupIntent, error: setupIntentError, setSetupIntentId } = useSetupIntent();
  const [postalCode, setPostalCode] = useState('');
  const [eventId, setEventId] = useState(Storage.get('subscriptionEventId'));
  console.log('SwapToStripe', account, pricingPlans, selectedPlan);
  const platformNames = {
    ios: 'Apple',
    android: 'Google Play',
  };

  useMountEffect(() => {
    if (!eventId) {
      const newId = guid();
      Storage.set('subscriptionEventId', newId);
      setEventId(newId);
    }
  });

  useMountEffect(() => {
    console.log('SwapToStripe', 'useMountEffect');
    UserApi.historicalPlans()
      .then(({ data }) => {
        console.log('SwapToStripe', 'historical plans', data);
        const plans = data
          .map(plan => {
            return Object.keys(pricingPlans).find(
              key => pricingPlans[key].plan === plan || planMatchesIap(pricingPlans[key], plan)
            );
          })
          .filter(plan => plan && ['yearly', 'yearlyPlus'].indexOf(plan) === -1);
        console.log('SwapToStripe', 'historical plans', plans);
        setHistoricalPlans(plans);
      })
      .catch(err => {
        console.log('SwapToStripe', 'Error fetching historical plans', err);
      });
  });

  const activeSubscriptionSku = account?.iapSubscription?.sku;

  useEffect(() => {
    const activePlan = Object.values(pricingPlans).find(plan =>
      planMatchesIap(plan, activeSubscriptionSku)
    );
    setSelectedPlan(activePlan);
  }, [activeSubscriptionSku, pricingPlans, setSelectedPlan]);

  const handleFieldChanged = useCallback(
    change => {
      console.log('SwapToStripe', 'handleFieldChanged', change);
      if (change.postal_code) {
        setPostalCode(change.postal_code);
      }
      dispatch(updateField(change));
    },
    [dispatch, setPostalCode]
  );

  const handleSave = () => {
    setErrorMessage('');
    console.log('SwapToStripe', 'handleSave', setupIntent, selectedPlan);
    dispatch(
      subscribe({
        paymentMethod: setupIntent.payment_method,
        coupon,
        plan: selectedPlan.plan,
        price: selectedPlan.price,
        userId: account.id,
        eventId,
        postalCode,
        iap_subscription_id: account?.iapSubscription?.id,
      })
    ).catch(err => {
      setErrorMessage(parseErrors(err));
    });
  };

  const renderErrorMessage = () => {
    return (
      <>
        <div className="error-message">{errorMessage}</div>
      </>
    );
  };

  const renderSaveButton = () => (
    <Button
      type="button"
      text="SAVE"
      className="pill"
      onClick={() => handleSave()}
      isFetching={isFetching}
      actionText="SAVING"
    />
  );

  return (
    <div className="swap-to-stripe">
      <StripePermittedView>
        <h1>Access Your Grandfathered Plan</h1>
        <p className="text-center">
          You are currently subscribed through {platformNames[account?.iapSubscription?.platform]}.{' '}
          <b>Be sure to cancel your current subscription.</b>
        </p>
        <p className="text-center">
          You can swap to a web subscription of any of the plans listed below.
        </p>
        <p className="text-center">
          Your subscription will be billed when the current{' '}
          {platformNames[account?.iapSubscription?.platform]} subscription ends on{' '}
          <b>
            {moment(account?.iapSubscription?.ends_at)
              .local()
              .format(DATE_FORMAT)}
          </b>
          .
        </p>
        {[...historicalPlans, 'yearly', 'yearlyPlus'].map(planKey => {
          const plan = pricingPlans[planKey];
          return (
            <div
              key={planKey}
              className={cn('plan', { 'plan--selected': plan === selectedPlan })}
              role="button"
              tabIndex="0"
              onClick={() => setSelectedPlan(plan)}
            >
              <h2>{plan.displayName}</h2>
              <div className="plan__billing">
                ${plan.price}/{plan.billingCycle}
              </div>
            </div>
          );
        })}
        {setupIntent && selectedPlan && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: setupIntent.clientSecret,
              appearance: { variables: { fontSizeBase: '14px' } },
              loader: 'always',
            }}
            key={setupIntent.clientSecret}
          >
            <SetupIntentForm
              isFetching={isFetching}
              user={account}
              intent={setupIntent}
              setSetupIntentId={setSetupIntentId}
              amount={selectedPlan.price}
              plan={selectedPlan.plan}
              showCoupon
              coupon={coupon}
              updateField={handleFieldChanged}
              postalCode={postalCode}
            />
          </Elements>
        )}
        {setupIntentError && (
          <div className="text-danger">
            An error occurred while setting up payments. Please try again later.
          </div>
        )}
        {!setupIntent && !setupIntentError && <Spinner />}
        <input id="artfulUserId" type="hidden" name="userId" value={account.id} />
        <input id="artfulEventId" type="hidden" name="eventId" value={eventId} />

        {errorMessage && renderErrorMessage()}

        <div className="actions">{renderSaveButton()}</div>
      </StripePermittedView>
    </div>
  );
};

SwapToStripe.propTypes = {};

SwapToStripe.defaultProps = {};

export default SwapToStripe;
