export const colors = [
  '#000000',
  '#eb328b',
  '#f15bb6',
  '#e86499',
  '#b54071',
  '#af4352',
  '#db827e',
  '#d2565c',
  '#d43b39',
  '#e37d4e',
  '#df8e2a',
  '#d6b657',
  '#809f50',
  '#479a65',
  '#467353',
  '#2e6d76',
  '#5999a1',
  '#5f9cc3',
  '#4e7dc9',
  '#5971a1',
  '#758cc8',
  '#7b4b90',
  '#a26ea6',
  '#9f5883',
  '#c784aa',
];
