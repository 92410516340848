import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { UPLOAD_STICKERS_MODAL } from '~/Config/Modals';
import Modal from '~/Containers/Global/Modal/Modal';
import { closeModal } from '~/Redux/Modules/Modal';

import AddTagsDialog from './AddTagsDialog';
import StickerUploadDialog from './StickerUploadDialog';

import './UploadStickersModal.scss';

function UploadStickersModal() {
  const dispatch = useDispatch();

  const [uploads, setUploads] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const IN_PROGRESS = 'IN_PROGRESS';
  const COMPLETE = 'COMPLETE';

  function successfulUploads() {
    return uploads.filter(upload => upload.sticker != null);
  }

  const uploadsComplete = () => {
    const unsuccessful = uploads.filter(
      upload => upload['status'] === 'COMPLETE' && upload['sticker'] === null
    );
    if (uploads.length === 0 || unsuccessful.length === uploads.length) {
      return false;
    }

    for (const upload of uploads) {
      if (upload['status'] !== COMPLETE) {
        return false;
      }
    }

    return true;
  };

  const handleStickerDrop = files => {
    const newUploads = files.map(file => {
      return {
        file: file,
        sticker: null,
        status: IN_PROGRESS,
      };
    });

    setUploads(currentUploads => [...currentUploads, ...newUploads]);
  };

  const handleStickerRemoval = indexToRemove => {
    const updatedUploads = uploads.filter((_, index) => index !== indexToRemove);
    setUploads(updatedUploads);
  };

  const handleCloseModal = () => {
    dispatch(closeModal(UPLOAD_STICKERS_MODAL));
    resetModal();
  };

  const handleUploadComplete = data => {
    setUploads(currentUploads => {
      return currentUploads.map(upload => {
        if (upload !== data) {
          return upload;
        }

        return data;
      });
    });
  };

  const resetModal = () => {
    setUploads([]);
    setIsEditing(false);
  };

  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Modal id={UPLOAD_STICKERS_MODAL} overlayClassName="image-upload-modal" showCloseButton={false}>
      {isEditing ? (
        <AddTagsDialog uploads={successfulUploads()} handleCloseModal={handleCloseModal} />
      ) : (
        <StickerUploadDialog
          uploads={uploads}
          handleStickerDrop={handleStickerDrop}
          handleUploadComplete={handleUploadComplete}
          handleStickerRemoval={handleStickerRemoval}
          handleCloseModal={handleCloseModal}
          handleNext={toggleIsEditing}
          uploadsComplete={uploadsComplete}
        />
      )}
    </Modal>
  );
}

export default UploadStickersModal;
