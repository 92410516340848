/* eslint-disable max-len */
import React from 'react';

export default function HabitBroomIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#5fhrguoffa)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M14.501 22.264c-.23.137-.887.489-1.725.611a8.996 8.996 0 0 0 2.2.728l3.078-6.516-4.276-4.041C11.182 14.41 8.22 14.689 6 14.426c.175.784.467 1.591.853 2.387 1.038.058 1.955-.45 2.137-.557a.325.325 0 0 1 .43.09.281.281 0 0 1-.095.402c-.269.16-1.124.615-2.17.65.28.509.598 1.008.95 1.49 1.067.086 2.02-.44 2.207-.551a.325.325 0 0 1 .43.09.281.281 0 0 1-.096.403c-.262.155-1.079.592-2.087.647.363.446.756.873 1.175 1.272 1.12.145 2.146-.423 2.34-.539a.325.325 0 0 1 .43.09.281.281 0 0 1-.095.403 4.843 4.843 0 0 1-2.008.642c.426.353.874.677 1.34.966a.316.316 0 0 1 .084-.001c1.12.146 2.148-.423 2.342-.539a.325.325 0 0 1 .43.09.281.281 0 0 1-.096.403zM18.48 13.891l-1.774-1.676-.734-.694c-.516.467-1.07.87-1.645 1.215l3.997 3.777.904-1.915-.748-.707zM23.723 7.261A.961.961 0 0 0 23.057 7a.962.962 0 0 0-.666.261l-5.025 4.748.578.545.758.717 5.021-4.744a.862.862 0 0 0 0-1.266z" />
      </g>
      <defs>
        <clipPath id="5fhrguoffa">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
