import React, { Component } from 'react';
import propTypes from 'prop-types';

import moment from '~/Providers/Moment';

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from '~/Redux/Constants';
import OpenDatePicker from '~/Components/Global/DatePicker/OpenDatePicker';
import JumpToDayPopup from '~/Components/Pages/Calendar/DayView/JumpToDayPopup';

import { DAY, WEEK, MONTH, HABIT } from '~/Config/DateRangeTypes';

import './ViewHeader.scss';

export default class ViewHeader extends Component {
  renderHeader = () => {
    const {
      start,
      currentView,
      setDayType,
      setWeekType,
      setMonthType,
      showOnDayView,
      humanDateFormats,
    } = this.props;
    switch (currentView) {
      case DAY:
        return (
          <div className="day-header">
            {!showOnDayView && (
              <div className="current-day-text">
                <span className="day-of-week">{start.format('dddd')}</span>{' '}
                <span className="date">{start.format(humanDateFormats['MMMM DD, Y'])}</span>
                <JumpToDayPopup selected={start} onJump={setDayType} />
              </div>
            )}
            {showOnDayView && (
              <OpenDatePicker onChange={setDayType} onMonthChange={setDayType} selected={start} />
            )}
            {this.renderButtons()}
          </div>
        );
      case WEEK:
        return (
          <div className="week-header">
            <OpenDatePicker onChange={setWeekType} onMonthChange={setWeekType} selected={start} />
            {this.renderButtons()}
            <div className="clear-fix" />
          </div>
        );
      case MONTH:
        return (
          <div className="month-header">
            <OpenDatePicker onMonthChange={setMonthType} selected={start} />
            {this.renderButtons()}
            <div className="clear-fix" />
          </div>
        );
      case HABIT:
        return (
          <div className="month-header">
            <OpenDatePicker onMonthChange={setMonthType} selected={start} />
            {this.renderButtons()}
            <div className="clear-fix" />
          </div>
        );
    }
  };

  handleEventStartTime = () => {
    const { openPresetModal, start } = this.props;
    const now = moment();
    const remainder = 30 - (now.minute() % 30);
    now.add(remainder, 'minutes');
    const newStart = moment(
      `${start.format(DATE_FORMAT)} ${now.format(TIME_FORMAT)}`,
      DATE_TIME_FORMAT
    );
    openPresetModal({
      start: newStart.format(DATE_TIME_FORMAT),
      end: newStart
        .clone()
        .add(1, 'hour')
        .format(DATE_TIME_FORMAT),
    });
  };

  renderButtons = () => {
    const { setToday, currentView } = this.props;
    return (
      <div className="header-buttons">
        <button className="btn pill small" onClick={() => setToday(currentView)}>
          TODAY
        </button>
        <button className="btn pill small large-screen" onClick={() => this.handleEventStartTime()}>
          ADD EVENT
        </button>
        <button className="btn pill small small-screen" onClick={() => this.handleEventStartTime()}>
          ADD
        </button>
      </div>
    );
  };

  render() {
    return <div className="view-header">{this.renderHeader()}</div>;
  }
}

ViewHeader.propTypes = {
  start: propTypes.object,
  currentView: propTypes.string,
  openPresetModal: propTypes.func,
  setToday: propTypes.func,
  setWeekType: propTypes.func,
  setMonthType: propTypes.func,
  setDayType: propTypes.func,
  showOnDayView: propTypes.bool,
  humanDateFormats: propTypes.object.isRequired,
};
