/* eslint-disable max-len */
import React from 'react';

export default function HabitNoIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 6.937a8.06 8.06 0 0 1 8.076 8.072A8.06 8.06 0 0 1 15 23.082a8.059 8.059 0 0 1-8.073-8.073A8.058 8.058 0 0 1 15 6.937zm0-1.916c-5.506 0-9.99 4.484-9.99 9.988C5.01 20.516 9.495 25 15 25c5.507 0 9.991-4.484 9.991-9.99 0-5.505-4.485-9.989-9.99-9.989z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M20.415 8.621a.949.949 0 0 0-.658.29L8.89 19.755a.958.958 0 0 0-.017 1.372c.38.381 1 .375 1.372-.016L21.11 10.268c.627-.61.18-1.672-.695-1.647z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
