import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ handleSubmit, className, children, ...rest }) => {
  const submitForm = event => {
    event.preventDefault();
    handleSubmit(event);
  };

  return (
    <form action="" className={className} onSubmit={submitForm} {...rest}>
      {children}
    </form>
  );
};

Form.defaultProps = {};

Form.propTypes = {
  handleSubmit: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Form;
