import { createStore, applyMiddleware, compose } from 'redux';
import { enableBatching, batchDispatchMiddleware } from 'redux-batched-actions';
import thunk from 'redux-thunk';

import RootReducer from '~/Redux/RootReducer';

// Allow for Redux devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Single application store
const Store = createStore(
  enableBatching(RootReducer),
  composeEnhancers(applyMiddleware(thunk, batchDispatchMiddleware))
);

export default Store;
