/* eslint-disable max-len */
import React from 'react';

export default function HabitHealthyFoodIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.96 17.674c-.2 1.445-2.024 4.714-3.403 5.715-1.512 1.09-2.157-.044-3.869-.044-.756 0-1.2.755-1.979.644-.822-.134-1.846-.934-2.402-1.58-1.023-1.178-2.135-3.358-2.29-4.848-.334-3.002 1.6-7.672 7.116-6.426 5.381-1.111 7.227 3.536 6.827 6.539z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M15.377 10.29c-.089-1.245-.134-2.513-.422-3.736-.045-.178-.134-.467-.334-.512-.178-.044-.69.156-.711.334 0 .134.178.267.266.356.112.134.2.29.29.468.266.6.422 1.245.511 1.912.089.623.2 1.312.156 1.935v.067c0 .111.134.044.178 0 .067-.044.088-.156.088-.223a2.11 2.11 0 0 0-.022-.533v-.068z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M15.332 10.156s.468-4.091 4.67-4.047c-.022 0 1.046 4.159-4.67 4.047z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
