import React from 'react';
import proptypes from 'prop-types';

import MyAccountSection from '~/Components/Pages/Account/MyAccountSection';
import Profile from '~/Components/Pages/Account/UserSettings/Profile/Profile';

const UserSettings = () => {
  return (
    <MyAccountSection header={'User Settings'}>
      <Profile />
    </MyAccountSection>
  );
};

UserSettings.propTypes = {
  userOptions: proptypes.object,
};

export default UserSettings;
