import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '~/Components/Global/Form/Select';
import TextInput from '~/Components/Global/Form/TextInput';
import Checkbox from '~/Components/Global/Form/Checkbox';
import DatePicker from '~/Components/Global/DatePicker/DatePicker';
import moment from '~/Providers/Moment';
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT, DEFAULT_TIME_ZONE } from '~/Redux/Constants';

export const parseRepeatRule = (rule, tz, allDay) => {
  if (!rule) return {};
  let parsed = rule.split(';').reduce((obj, piece) => {
    let parts = piece.split('=');
    obj[parts[0]] = parts[1];
    return obj;
  }, {});
  if (parsed.FREQ === 'WEEKLY') parsed.BYDAY = parsed.BYDAY || '';
  if (parsed.FREQ === 'WEEKLY' && parsed.BYDAY) {
    let parts = parsed.BYDAY.split(',');
    parsed.BYDAY = {};
    parts.forEach(day => (parsed.BYDAY[day] = true));
  }
  if (parsed.UNTIL) {
    if (allDay) {
      parsed.UNTIL = moment(parsed.UNTIL, 'YYYYMMDD');
    } else {
      parsed.UNTIL = moment
        .tz(parsed.UNTIL, 'YYYYMMDDTHHmmssZ', tz)
        .subtract(1, 'day')
        .add(1, 'minute');
    }
  }
  return parsed;
};

export const formatRepeatRule = (repeatObj, allDay) => {
  if (!repeatObj.FREQ) return '';
  let parts = [];
  if (repeatObj.FREQ) parts = [...parts, `FREQ=${repeatObj.FREQ}`];
  if (repeatObj.INTERVAL) parts = [...parts, `INTERVAL=${repeatObj.INTERVAL}`];
  if (repeatObj.BYDAY && repeatObj.BYDAY != 'false' && repeatObj.FREQ === 'WEEKLY')
    parts = [
      ...parts,
      `BYDAY=${Object.keys(repeatObj.BYDAY)
        .filter(key => repeatObj.BYDAY[key])
        .join(',')}`,
    ];
  if (repeatObj.BYDAY && repeatObj.BYDAY != 'false' && repeatObj.FREQ !== 'WEEKLY')
    parts = [...parts, `BYDAY=${repeatObj.BYDAY}`];
  if (repeatObj.COUNT) parts = [...parts, `COUNT=${repeatObj.COUNT}`];
  if (repeatObj.UNTIL) {
    if (allDay) {
      parts = [...parts, `UNTIL=${repeatObj.UNTIL.format('YYYYMMDD')}`];
    } else {
      parts = [
        ...parts,
        `UNTIL=${repeatObj.UNTIL.clone()
          .add(1, 'day')
          .subtract(1, 'minute')
          .tz('UTC')
          .format('YYYYMMDDTHHmm')}00Z`,
      ];
    }
  }
  return parts.join(';');
};

const generateMonthlyOnValues = (freq, start) => {
  if (freq !== 'MONTHLY') return;
  start = moment(start, DATE_TIME_FORMAT);
  let day = start.format('D');
  let dayCode = start.format('dd').toUpperCase();
  let dayName = start.format('dddd');
  let dayNth = parseInt((day - 1) / 7);
  const translations = ['first', 'second', 'third', 'fourth', 'last'];
  return [
    { value: 'false', label: `day ${day}` },
    {
      value: `${dayNth === 4 ? -1 : dayNth + 1}${dayCode}`,
      label: `the ${translations[dayNth]} ${dayName}`,
    },
  ];
};

export const RepeatRule = ({
  repeatable,
  onChange,
  singleColumn,
  weekOnly,
  monthOnly,
  allDay,
  allowNever,
}) => {
  const [repeatObj, setRepeatObj] = useState({});
  const [monthlyOnValues, setMonthlyOnValues] = useState([]);
  const [ends, setEnds] = useState(null);
  const frequencies = [
    ...(allowNever ? [{ value: false, label: 'Does not repeat' }] : []),
    ...(weekOnly || monthOnly ? [] : [{ value: 'DAILY', label: 'Daily' }]),
    ...(monthOnly ? [] : [{ value: 'WEEKLY', label: 'Weekly' }]),
    { value: 'MONTHLY', label: 'Monthly' },
    ...(weekOnly ? [] : [{ value: 'YEARLY', label: 'Yearly' }]),
  ];

  const handleEndsChange = value => {
    let newRepeatObj = { ...repeatObj };
    if (value === 'count' || !value) delete newRepeatObj.UNTIL;
    if (value === 'until' || !value) delete newRepeatObj.COUNT;
    switch (value) {
      case 'count':
        newRepeatObj.COUNT = newRepeatObj.COUNT || 1;
        break;
      case 'until':
        newRepeatObj.UNTIL =
          newRepeatObj.UNTIL || moment(repeatable.start, allDay ? DATE_FORMAT : DATE_TIME_FORMAT);
        break;
    }
    setRepeatObj(newRepeatObj);
    setEnds(value);
    onChange(formatRepeatRule(newRepeatObj, allDay));
  };

  const handleRepeatChange = (input, key, subkey) => {
    let value;
    let newRepeatObj = { ...repeatObj };
    if (!subkey) {
      value = input.target.value;
      if (value === 'false') value = false;
      newRepeatObj[key] = value;
    } else {
      if (!newRepeatObj[key]) newRepeatObj[key] = {};
      value = !newRepeatObj[key][subkey];
      newRepeatObj[key][subkey] = value;
    }
    if (key === 'FREQ') {
      // setIntervals(generateIntervals(value));
      const newMonthlyOnValues = generateMonthlyOnValues(value, repeatable.start);
      setMonthlyOnValues(newMonthlyOnValues);
      newRepeatObj.BYDAY =
        value === 'WEEKLY'
          ? {
              [moment(repeatable.start, DATE_TIME_FORMAT)
                .format('dd')
                .toUpperCase()]: true,
            }
          : null;
      if (value === 'MONTHLY' && weekOnly) {
        newRepeatObj.BYDAY = newMonthlyOnValues[1].value;
      }
    }
    setRepeatObj(newRepeatObj);
    onChange(formatRepeatRule(newRepeatObj, allDay));
  };

  useEffect(() => {
    const repeatRule = parseRepeatRule(
      repeatable.repeat_rule,
      repeatable.timezone?.name || moment.tz.guess() || DEFAULT_TIME_ZONE,
      allDay
    );
    setRepeatObj(repeatRule);
    setEnds(repeatRule.COUNT ? 'count' : repeatRule.UNTIL ? 'until' : null);
    if (repeatRule.FREQ === 'MONTHLY') {
      const newMonthlyOnValues = generateMonthlyOnValues(repeatRule.FREQ, repeatable.start);
      setMonthlyOnValues(newMonthlyOnValues);
    }
  }, [repeatable, generateMonthlyOnValues, setMonthlyOnValues]);

  const weeklyOn = () => {
    if (repeatObj.FREQ !== 'WEEKLY' || weekOnly) return;
    return (
      <div className="weekly-byday">
        <label className="inline-label">on</label>
        <Checkbox
          type="checkbox"
          label="S"
          name="SU"
          value={repeatObj.BYDAY.SU}
          onChange={value => handleRepeatChange(value, 'BYDAY', 'SU')}
        />
        <Checkbox
          type="checkbox"
          label="M"
          name="MO"
          value={repeatObj.BYDAY.MO}
          onChange={value => handleRepeatChange(value, 'BYDAY', 'MO')}
        />
        <Checkbox
          type="checkbox"
          label="T"
          name="TU"
          value={repeatObj.BYDAY.TU}
          onChange={value => handleRepeatChange(value, 'BYDAY', 'TU')}
        />
        <Checkbox
          type="checkbox"
          label="W"
          name="WE"
          value={repeatObj.BYDAY.WE}
          onChange={value => handleRepeatChange(value, 'BYDAY', 'WE')}
        />
        <Checkbox
          type="checkbox"
          label="T"
          name="TH"
          value={repeatObj.BYDAY.TH}
          onChange={value => handleRepeatChange(value, 'BYDAY', 'TH')}
        />
        <Checkbox
          type="checkbox"
          label="F"
          name="FR"
          value={repeatObj.BYDAY.FR}
          onChange={value => handleRepeatChange(value, 'BYDAY', 'FR')}
        />
        <Checkbox
          type="checkbox"
          label="S"
          name="SA"
          value={repeatObj.BYDAY.SA}
          onChange={value => handleRepeatChange(value, 'BYDAY', 'SA')}
        />
      </div>
    );
  };

  const monthlyOn = () => {
    if (repeatObj.FREQ !== 'MONTHLY' || monthOnly || weekOnly) return;
    return (
      <Select
        name="monthlyOn"
        label="on"
        inlineLabel
        onChange={value => handleRepeatChange(value, 'BYDAY')}
        className="minimal-input monthly-byday"
        allowPlaceholder={false}
        data={monthlyOnValues}
        value={repeatObj.BYDAY}
      />
    );
  };

  return (
    <div className="row margin-top">
      <div className={`col-sm-${singleColumn ? '12' : '6'} repeat-left-values`}>
        <Select
          name="freq"
          label="repeats"
          inlineLabel
          onChange={value => handleRepeatChange(value, 'FREQ')}
          className="minimal-input repeat-freq"
          allowPlaceholder={false}
          data={frequencies}
          value={repeatObj.FREQ}
        />
        {repeatObj.FREQ && (
          <div className="repeat-interval-wrapper">
            <TextInput
              className="minimal-input"
              value={repeatObj.INTERVAL}
              name="interval"
              label="every"
              placeholder="1"
              onChange={({ interval }) => {
                console.log('onChange', interval);
                if (interval && interval <= 0) return;
                if (interval && parseInt(interval).toString() == 'NaN')
                  return handleRepeatChange(
                    {
                      target: {
                        value: '',
                      },
                    },
                    'INTERVAL'
                  );
                handleRepeatChange({ target: { value: interval } }, 'INTERVAL');
              }}
            />
            <span className="repeat-interval-after-label">
              {repeatObj.FREQ == 'DAILY' && 'days'}
              {repeatObj.FREQ == 'WEEKLY' && 'weeks'}
              {repeatObj.FREQ == 'MONTHLY' && 'months'}
              {repeatObj.FREQ == 'YEARLY' && 'years'}
            </span>
          </div>
        )}
        {weeklyOn()}
        {monthlyOn()}
      </div>
      {repeatObj.FREQ && (
        <div className={`col-sm-${singleColumn ? '12' : '6'} flex-row repeat-right-values`}>
          <div className="flex-1" style={{ minWidth: '3.0rem' }}>
            <label>ends</label>
          </div>
          <div className="flex-5">
            <div className="flex-row half-margin-bottom">
              <Checkbox
                type="checkbox"
                label="Never"
                name="ends-never"
                value={!ends}
                onChange={() => handleEndsChange(false)}
              />
            </div>
            <div className="flex-row half-margin-bottom">
              <Checkbox
                type="checkbox"
                label="After"
                name="ends-after"
                value={ends === 'count'}
                onChange={() => handleEndsChange('count')}
              />
              <TextInput
                className="minimal-input"
                value={repeatObj.COUNT}
                name="count"
                onChange={({ count }) => handleRepeatChange({ target: { value: count } }, 'COUNT')}
                enabled={ends === 'count'}
              />
              <span className="label" style={{ padding: 0 }}>
                Times
              </span>
            </div>
            <div className="flex-row half-margin-bottom">
              <Checkbox
                type="checkbox"
                label="On"
                name="ends-on"
                value={ends === 'until'}
                onChange={() => handleEndsChange('until')}
              />
              <DatePicker
                className="minimal-input inline-input"
                name="until"
                value={repeatObj.UNTIL}
                disabled={ends !== 'until'}
                onChange={value => {
                  let formattedValue = moment(
                    `${value.format(DATE_FORMAT)} ${moment(
                      repeatable.start,
                      DATE_TIME_FORMAT
                    ).format(TIME_FORMAT)}`,
                    DATE_TIME_FORMAT
                  );
                  console.log(`changed until to ${formattedValue.format(DATE_TIME_FORMAT)}`);
                  handleRepeatChange({ target: { value: formattedValue } }, 'UNTIL');
                }}
                showTimeSelect={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

RepeatRule.propTypes = {
  repeatable: PropTypes.shape({ repeat_rule: PropTypes.string.isRequired }).isRequired,
  onChange: PropTypes.func.isRequired,
  singleColumn: PropTypes.bool,
  weekOnly: PropTypes.bool,
  monthOnly: PropTypes.bool,
  allDay: PropTypes.bool,
  allowNever: PropTypes.bool,
};

export default RepeatRule;
