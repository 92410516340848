import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  // optional configuration object here
});

history.listen(({ location }) => {
  history.previousLocation = location;
});

export default history;
