/* eslint-disable max-len */
import React from 'react';

export default function HabitPillIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#q65mvrl6ya)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M25.723 17.502c-2.902.843-6.71 1.307-10.723 1.307-4.014 0-7.821-.465-10.723-1.306-1.568-.454-2.84-1.014-3.784-1.662a7.628 7.628 0 0 1-.495-.372v2.46c0 .86.501 1.641 1.49 2.321.79.541 1.892 1.02 3.282 1.423 2.744.799 6.377 1.237 10.23 1.237 3.854 0 7.486-.438 10.231-1.236 1.387-.403 2.49-.88 3.279-1.423.99-.68 1.49-1.461 1.49-2.321v-2.461c-.152.125-.317.25-.495.372-.943.647-2.216 1.206-3.781 1.661h-.001z" />
        <path d="M1.49 14.383c.79.542 1.892 1.02 3.282 1.423 2.535.736 5.828 1.167 9.352 1.23V7.09c-3.524.058-6.817.492-9.352 1.23-1.388.403-2.49.879-3.279 1.423C.502 10.42 0 11.202 0 12.062c0 .86.502 1.642 1.49 2.321zM28.51 14.383c.988-.68 1.49-1.46 1.49-2.321 0-.86-.502-1.64-1.49-2.32-.79-.543-1.892-1.021-3.28-1.422-2.535-.736-5.828-1.167-9.351-1.23v9.944c3.524-.06 6.817-.492 9.351-1.23 1.388-.401 2.49-.88 3.28-1.42z" />
      </g>
      <defs>
        <clipPath id="q65mvrl6ya">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
