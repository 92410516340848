/* eslint-disable max-len */
import React from 'react';

export default function HabitGratitudeIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m25.205 16.32-1.77-4.594a10 10 0 0 0-3.207-4.31l-1.44-1.115s-.68-.373-1.376.265l-.028.007-2.579 2.166-2.578-2.166-.028-.007c-.695-.638-1.377-.265-1.377-.265L9.383 7.417a10 10 0 0 0-3.205 4.308l-1.77 4.596-1.504 7.123 4.942 1.182.357-4.906.099-2.31 3.07 1.022 3.114 1.46s.588-.947 0-1.398c-.823-.627-2.83-2.317-4.23-4.117 0 0-.625-.59-1.18-1.9-.443-1.046-.346-2.093.673-2.977 1.346-1.17 3.716-.945 4.88 1.145.062.111.121.23.176.355.056-.125.115-.243.177-.355 1.164-2.09 3.532-2.315 4.88-1.145 1.019.884 1.128 1.936.673 2.977-.16.365-.368.737-.61 1.106-.175.268-.369.535-.574.8-1.44 1.85-3.495 3.506-4.249 4.12-.621.504-.007 1.387-.007 1.387l3.145-1.458 3.07-1.023.099 2.31.357 4.906 4.943-1.18-1.504-7.124z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
