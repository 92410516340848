import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import history from '~/Providers/History';

import { findImagePath } from '~/Context/Global';
import { LANDSCAPE_IMAGE, PORTRAIT_IMAGE } from '~/Redux/Modules/Admin/Skins';

import AdminPage from '~/Components/Layout/AdminPage/AdminPage';
import SkinForm from '~/Components/Pages/Admin/AdminSkins/SkinForm';
import Button from '~/Components/Global/Form/Button';

import './AdminSkins.scss';

class AdminSkins extends Component {
  constructor() {
    super();
    this.columns = [
      { header: 'Name', accessor: s => s.name },
      { header: 'Product ID', accessor: s => s.product.id },
      {
        header: 'Landscape',
        accessor: s => (
          <img className="skin-preview" src={findImagePath(s.images, LANDSCAPE_IMAGE)} />
        ),
      },
      {
        header: 'Portrait',
        accessor: s => (
          <img className="skin-preview" src={findImagePath(s.images, PORTRAIT_IMAGE)} />
        ),
      },
      {
        header: 'Price',
        accessor: s => (s.product?.price ? `$${s.product.price}` : ''),
      },
      { header: 'Code', accessor: s => s.product && s.product.unlock_code },
      { header: 'Fundraiser', accessor: s => (s.product?.is_fundraiser ? 'Y' : '') },
      {
        header: 'Actions',
        accessor: s => {
          return (
            <Link className="actions center-button" to={`${this.props.match.path}/${s.id}`}>
              {this.generateDeleteButton(s)}
            </Link>
          );
        },
      },
    ];
  }
  generateDeleteButton = skin => {
    const { actionId, deleteSkin, isDeleting } = this.props;
    return [
      <Button
        key="a"
        text="Delete"
        actionText="Deleting"
        className="margin-right"
        onClick={() => deleteSkin(skin.id)}
        isFetching={isDeleting && skin.id === actionId}
      />,
      <Button key="b" text="Edit" onClick={() => history.push(`/admin/skins/${skin.id}`)} />,
    ];
  };

  render() {
    const {
      skins,
      isFetching,
      isAdding,
      getSkins,
      actionSkin,
      updateForm,
      addSkin,
      updateFormImage,
    } = this.props;
    return (
      <div className="admin-skins">
        <AdminPage
          header="Skins"
          dataObject={skins}
          isFetching={isFetching}
          columns={this.columns}
          getData={getSkins}
          dataName="Skin"
        >
          <div className="margin-top margin-bottom">
            <SkinForm
              isAdding={isAdding}
              data={actionSkin}
              onChange={updateForm}
              onImageChange={updateFormImage}
            />
            <Button
              text="Add Skin"
              actionText="Adding Skin"
              onClick={() => addSkin(actionSkin)}
              isFetching={isAdding}
            />
          </div>
        </AdminPage>
      </div>
    );
  }
}

AdminSkins.propTypes = {
  skins: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  actionSkin: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  isFetching: propTypes.bool,
  isAdding: propTypes.bool,
  isDeleting: propTypes.bool,
  match: propTypes.object,
  addSkin: propTypes.func,
  updateForm: propTypes.func,
  getSkins: propTypes.func,
  deleteSkin: propTypes.func,
  updateFormImage: propTypes.func,
  actionId: propTypes.oneOfType([propTypes.number, propTypes.bool]),
};

export default AdminSkins;
