import React from 'react';
import propTypes from 'prop-types';

import { Link } from 'react-router-dom';
import TopNav from '~/Components/Layout/ApplicationNavigation/TopNav/TopNav';
import Panel from '~/Components/Global/Panel/Panel';

import './SideBar.scss';

const SideBarMenu = ({ routes, menuId, path, children }) => (
  <div className="sidebar-wrapper">
    <div className="sidebar-mobile">
      <TopNav routes={routes} menuId={menuId} alignment="left" />
    </div>
    <nav className="sidebar">
      <ul>
        {routes.map((route, index) => {
          return (
            <Link key={index} to={route.path}>
              <li key={index} className={path === route.path ? 'item active' : 'item'}>
                {route.label}
              </li>
            </Link>
          );
        })}
      </ul>
    </nav>
    <div className="page-content">
      <Panel fullWidthContainer header="Admin">
        {children}
      </Panel>
    </div>
  </div>
);

SideBarMenu.defaultProps = {
  routes: [],
  menuId: 'default-menu',
};

SideBarMenu.propTypes = {
  children: propTypes.node,
  routes: propTypes.array,
  menuId: propTypes.string,
  path: propTypes.string,
};

export default SideBarMenu;
