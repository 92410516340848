import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import ApplicationLayout from '~/Components/Layout/ApplicationLayout/ApplicationLayout';

import { fetchAll } from '~/Services/Container';
import { getUser } from '~/Redux/Modules/User';
import { probeAuthStatus, getAuthStatus } from '~/Redux/Modules/Auth';
import { getCalendarAccounts } from '~/Redux/Modules/CalendarAccounts';
import { getFonts } from '~/Redux/Modules/Fonts';
import { getUserOptions } from '~/Redux/Modules/UserOptions';
import { updatePath } from '~/Redux/Modules/Location';
import { fetchFeatureFlags } from '~/Redux/Modules/FeatureFlags';
import { fetchAppFeatures } from '~/Redux/Modules/AppFeatures';

const mapStateToProps = ({ Auth, Location, User, CalendarAccounts }) => {
  return {
    isAuthenticated: Auth.isAuthenticated,
    isSubscribed: User.data.isSubscribed,
    isFetching: Auth.isFetching,
    hasFetched: Auth.hasFetched,
    currentPath: Location.data.path,
    currentHash: Location.data.hash,
    hasAccounts: CalendarAccounts.isFetched && CalendarAccounts.data.length > 0,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => dispatch(getUser()),
    probeAuthStatus: () => dispatch(probeAuthStatus()),
    getAuthStatus: () => dispatch(getAuthStatus()),
    fetchAll: () => fetchAll([getCalendarAccounts, getUserOptions, getFonts]),
    fetchFeatureFlags: () => dispatch(fetchFeatureFlags()),
    fetchAppFeatures: () => dispatch(fetchAppFeatures()),
    updatePath: location => dispatch(updatePath(location)),
    fetchUserOptions: updates => dispatch(getUserOptions(updates)),
  };
};

// TODO fix update blocking using alternative to withRoute (see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationLayout));
