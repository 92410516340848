/* eslint-disable max-len */
import React from 'react';

export default function HabitPlugIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#zznxotr5ma)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M14.345 13.252v-3.014c0-.053-.046-.096-.103-.096h-6.14c-.056 0-.102.042-.102.095v3.014c0 .053.046.097.102.097h6.14c.057 0 .103-.043.103-.096zM12.134 16.972v-.67c0-.052-.046-.096-.103-.096h-1.719c-.056 0-.102.043-.102.097v.67c0 .052.046.096.102.096h1.72c.056 0 .102-.044.102-.097zM13.37 15.537l.72-1.439a.091.091 0 0 0-.007-.092.105.105 0 0 0-.087-.045H8.35a.104.104 0 0 0-.087.045.09.09 0 0 0-.006.092l.72 1.44a.102.102 0 0 0 .092.054h4.21a.1.1 0 0 0 .092-.055zM8.989 9.528h.86c.056 0 .101-.043.101-.097V6.5c0-.275-.239-.499-.532-.499-.293 0-.532.224-.532.499v2.933a.1.1 0 0 0 .103.096zM12.496 9.528h.86c.056 0 .102-.043.102-.097V6.5c0-.275-.24-.499-.533-.499-.293 0-.532.224-.532.499v2.933c0 .053.046.096.103.096zM21.424 13.935c-2.194 0-3.98 1.673-3.98 3.73v2.603c0 1.476-1.28 2.676-2.855 2.676-1.574 0-2.854-1.2-2.854-2.676v-2.49c0-.053-.046-.096-.102-.096h-.92c-.058 0-.103.043-.103.096v2.49c0 2.057 1.785 3.732 3.98 3.732 2.194 0 3.98-1.675 3.98-3.732v-2.603c0-1.475 1.28-2.676 2.854-2.676.31 0 .563-.237.563-.527 0-.291-.253-.527-.563-.527z" />
      </g>
      <defs>
        <clipPath id="zznxotr5ma">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
