export const FETCH = 'artful-client/ListGroups/FETCH';
export const FETCH_SUCCESS = 'artful-client/ListGroups/FETCH_SUCCESS';
export const FETCH_FAILURE = 'artful-client/ListGroups/FETCH_FAILURE';

export const FETCH_ONE = 'artful-client/ListGroups/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'artful-client/ListGroups/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'artful-client/ListGroups/FETCH_ONE_FAILURE';

export const UPDATE = 'artful-client/ListGroups/UPDATE';
export const UPDATE_SUCCESS = 'artful-client/ListGroups/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'artful-client/ListGroups/UPDATE_FAILURE';

export const DELETE = 'artful-client/ListGroups/DELETE';
export const DELETE_SUCCESS = 'artful-client/ListGroups/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/ListGroups/DELETE_FAILURE';

export const STORE = 'artful-client/ListGroups/STORE';
export const STORE_SUCCESS = 'artful-client/ListGroups/STORE_SUCCESS';
export const STORE_FAILURE = 'artful-client/ListGroups/STORE_FAILURE';

export const SET_CURRENT = 'artful-client/ListGroups/SET_CURRENT';
export const UNSET_CURRENT = 'artful-client/ListGroups/SET_CURRENT';

export const SET_QUEUE = 'artful-client/ListGroups/SET_QUEUE';
export const START_UPDATING = 'artful-client/ListGroups/START_UPDATING';
export const FINISH_UPDATING = 'artful-client/ListGroups/FINISH_UPDATING';
export const SET_LAST_UPDATE = 'artful-client/ListGroups/SET_LAST_UPDATE';
