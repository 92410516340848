/* eslint-disable max-len */
import React from 'react';

export default function HabitSunIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9599 4.9248L16.5026 8.71023C16.5629 8.85821 16.454 9.02014 16.2942 9.02014H13.6255C13.4657 9.02014 13.3569 8.85821 13.4172 8.71023L14.9599 4.9248ZM14.9599 25L13.4172 21.2146C13.3569 21.0666 13.4658 20.9047 13.6256 20.9047H16.2943C16.4541 20.9047 16.563 21.0666 16.5027 21.2146L14.9599 25ZM20.8461 23.0933L17.3766 20.9317C17.241 20.8472 17.2342 20.6522 17.3637 20.5585L19.5254 18.9936C19.6548 18.8999 19.838 18.9672 19.8759 19.1224L20.8461 23.0933ZM9.07385 6.83189L12.5433 8.99349C12.6789 9.07799 12.6857 9.273 12.5562 9.36671L10.3945 10.9317C10.2651 11.0254 10.0819 10.958 10.044 10.8028L9.07385 6.83189ZM24.5097 18.0534L20.4331 18.3553C20.2738 18.3671 20.1532 18.2137 20.2024 18.0616L21.0243 15.5226C21.0735 15.3706 21.2611 15.3169 21.3833 15.4198L24.5097 18.0534ZM5.41024 11.871L9.48678 11.569C9.64615 11.5572 9.76668 11.7107 9.71747 11.8627L8.89558 14.4017C8.84637 14.5537 8.65878 14.6074 8.53656 14.5045L5.41024 11.871ZM24.514 11.8844L21.384 14.5136C21.2617 14.6164 21.0742 14.5624 21.0252 14.4103L20.2068 11.8702C20.1578 11.7181 20.2785 11.5648 20.4379 11.5768L24.514 11.8844ZM5.40588 18.0404L8.53584 15.4112C8.6582 15.3084 8.84572 15.3623 8.89472 15.5144L9.71309 18.0546C9.7621 18.2067 9.64136 18.36 9.482 18.3479L5.40588 18.0404ZM20.8842 6.85959L19.8954 10.8259C19.8567 10.9809 19.6733 11.0474 19.5443 10.9531L17.39 9.37799C17.261 9.28367 17.2687 9.0887 17.4047 9.00483L20.8842 6.85959ZM9.03562 23.0652L10.0245 19.0989C10.0631 18.9438 10.2466 18.8774 10.3756 18.9717L12.5299 20.5468C12.6589 20.6411 12.6512 20.8361 12.5152 20.9199L9.03562 23.0652ZM14.8794 19.8609C17.5847 19.8609 19.7778 17.6678 19.7778 14.9625C19.7778 12.2573 17.5847 10.0642 14.8794 10.0642C12.1741 10.0642 9.98107 12.2573 9.98107 14.9625C9.98107 17.6678 12.1741 19.8609 14.8794 19.8609Z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
