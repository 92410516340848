import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

/**
 * Used instead of a basic `Route` to guard access to Pages should not be accessed when a user is Authenticated
 */
const Redirector = ({ hasFetched, isFetching, isAuthenticated, redirect, ...rest }) => (
  <Route
    {...rest}
    render={() => {
      if (isFetching || !hasFetched) return <div />;
      const search = new URLSearchParams(window.location.search);
      console.log('redirector', search.has('code'), search.get('code'));
      if (search.has('code'))
        return (
          <Redirect
            to={`/add-accounts/outlook/${search.get('code')}?state=${search.get('state')}`}
          />
        );
      if (isAuthenticated) return <Redirect to={redirect} />;
      return <Redirect to="/register" />;
    }}
  />
);

Redirector.propTypes = {
  component: propTypes.oneOfType([propTypes.node, propTypes.func]),
  isAuthenticated: propTypes.bool,
  isFetching: propTypes.bool,
  hasFetched: propTypes.bool,
  redirect: propTypes.string,
};

Redirector.defaultProps = {
  isAuthenticated: false,
  hasFetched: false,
  isFetching: false,
  redirect: '/month',
};

const mapStateToProps = ({ Auth }) => {
  return {
    user: Auth.user,
    isAuthenticated: Auth.isAuthenticated,
    isFetching: Auth.isFetching,
    hasFetched: Auth.hasFetched,
  };
};

export default connect(mapStateToProps)(Redirector);
