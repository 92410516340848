import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

const TextInput = ({
  id,
  name,
  label,
  placeholder,
  value,
  type,
  isDisabled,
  className,
  onChange,
  onBlur,
  labelInline,
  onKeyDown,
  formGroup,
  autoFocus,
  autoComplete,
  labelClassName,
  required,
}) => {
  const updateField = e => onChange({ [e.target.name]: e.target.value });

  const inputElement = (
    <input
      id={id}
      className={
        labelInline ? `inline-input form-control ${className}` : `form-control ${className}`
      }
      disabled={isDisabled}
      name={name}
      onChange={updateField}
      onBlur={onBlur}
      placeholder={placeholder}
      type={type}
      value={value ? value : ''}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      required={required}
    />
  );
  if (!formGroup) return inputElement;
  return (
    <div className="form-group flex-row">
      <label
        className={cn(label ? (labelInline ? 'inline-label' : '') : 'sr-only', labelClassName)}
        htmlFor={name}
      >
        {label}
      </label>
      {inputElement}
    </div>
  );
};

TextInput.propTypes = {
  id: propTypes.string,
  type: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]),
  isDisabled: propTypes.bool,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onKeyDown: propTypes.func,
  placeholder: propTypes.string,
  className: propTypes.string,
  labelInline: propTypes.bool,
  formGroup: propTypes.bool,
  autoFocus: propTypes.bool,
  autoComplete: propTypes.string,
  labelClassName: propTypes.string,
  required: propTypes.bool,
};

TextInput.defaultProps = {
  type: 'text',
  isDisabled: false,
  labelInline: false,
  formGroup: true,
};

export default TextInput;
