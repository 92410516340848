import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import MyAccountSection from '~/Components/Pages/Account/MyAccountSection';
import { UserApi } from '~/Providers/Api';
import { handleLogout } from '~/Redux/Modules/Auth';
import useRevalidate from '~/Components/Pages/Admin/Components/useRevalidate';
import { error, success } from '~/Redux/Modules/Notifications';
import history from '~/Providers/History';
import { closeModal } from '~/Redux/Modules/Modal';
import { MY_ACCOUNT_MODAL } from '~/Config/Modals';

const Support = ({ openReport }) => {
  const revalidateFn = useRevalidate();
  const dispatch = useDispatch();

  const handleDeleteAccount = async () => {
    const password = await revalidateFn({
      body: <p className="text-danger">Are you sure you want to delete your account?</p>,
    });
    UserApi.deleteAccount(password)
      .then(() => {
        dispatch(success('Account deleted'));
        dispatch(handleLogout());
        dispatch(closeModal(MY_ACCOUNT_MODAL));

        history.push('/login');
      })
      .catch(err => {
        dispatch(error('Unable to delete account', err.message));
      });
  };

  return (
    <MyAccountSection header={'Support'}>
      <a target="_blank" className="faq" href="http://www.artfulagenda.com/faq/">
        Frequently Asked Questions
      </a>
      <a target="_blank" className="report" onClick={openReport}>
        Report a Problem
      </a>
      <a target="_blank" className="terms" href="http://www.artfulagenda.com/terms-of-service/">
        Terms of Service
      </a>
      <a target="_blank" className="privacy" href="http://www.artfulagenda.com/privacy-policy/">
        Privacy Policy
      </a>
      <a target="_blank" className="delete-account" onClick={handleDeleteAccount}>
        Delete Account
      </a>
    </MyAccountSection>
  );
};

Support.propTypes = {
  openReport: PropTypes.func,
};

export default Support;
