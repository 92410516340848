import React from 'react';
import propTypes from 'prop-types';

import Form from '~/Components/Global/Form/Form';
import HorizontalTextInput from '~/Components/Global/Form/HorizontalTextInput';

const UpdatePassword = ({
  handleUpdatePassword,
  updateField,
  fieldsUpdatePassword,
  isEditing,
  toggleEditing,
}) => (
  <Form
    handleSubmit={() => {
      toggleEditing(null, isEditing);
      return handleUpdatePassword({ fieldsUpdatePassword });
    }}
    className={'form-horizontal'}
  >
    <div className="form-title">
      <div className="my-account-header" style={{ display: 'inline-block', fontSize: '1.25em' }}>
        Password
      </div>
      <div style={{ display: 'inline-block' }}>
        {isEditing ? (
          <button>Save</button>
        ) : (
          <button onClick={e => toggleEditing(e, isEditing)} type="button">
            Edit
          </button>
        )}
      </div>
    </div>
    <HorizontalTextInput
      handleChange={updateField}
      isDisabled={!isEditing}
      label="Current password"
      name="current_password"
      type="password"
      value={fieldsUpdatePassword.current_password}
    />
    {isEditing && (
      <HorizontalTextInput
        handleChange={updateField}
        label="New password"
        name="new_password"
        type="password"
        value={fieldsUpdatePassword.new_password}
      />
    )}
    {isEditing && (
      <HorizontalTextInput
        handleChange={updateField}
        label="Confirm new password"
        name="new_password_confirmation"
        type="password"
        value={fieldsUpdatePassword.new_password_confirmation}
      />
    )}
  </Form>
);

UpdatePassword.propTypes = {
  handleUpdatePassword: propTypes.func,
  updateField: propTypes.func,
  fieldsUpdatePassword: propTypes.object,
  isEditing: propTypes.bool,
  toggleEditing: propTypes.func,
};

UpdatePassword.defaultProps = {};

export default UpdatePassword;
