import React from 'react';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import ColorPicker from '~/Components/Global/ColorPicker/ColorPicker';

const artfulMagenta = '#ab3a72';

const CalendarColor = ({ actionCalendar, calendar, open, onChange, toggleColorPicker }) => [
  <FontAwesome
    key="a"
    onClick={() => toggleColorPicker(calendar.id)}
    className="color-arrow"
    name="caret-right"
  />,
  <ColorPicker
    key="b"
    selectedColor={calendar.color ?? artfulMagenta}
    parentToggle={open && actionCalendar === calendar.id}
    name="color"
    onChange={onChange}
    roundedPreview
  />,
];

CalendarColor.propTypes = {
  toggleColorPicker: propTypes.func,
  onChange: propTypes.func,
  open: propTypes.bool,
  actionCalendar: propTypes.number,
  calendar: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  path: propTypes.string,
};

export default CalendarColor;
