import React from 'react';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

const Spinner = ({ large }) => {
  return <FontAwesome className="fa-spin" name="spinner" size={large ? '2x' : 'lg'} />;
};

Spinner.propTypes = {
  large: propTypes.bool,
};

export default Spinner;
