import moment from '~/Providers/Moment';
import { DATE_TIME_FORMAT, DEFAULT_TIME_ZONE } from '~/Redux/Constants';
import config from '~/Config';

const BETA_MONTHLY = {
  plan: 'MONTHLY',
  price: 3,
  displayPrice: '3',
  displayUnits: '/MONTH',
  subtext: 'Normally $5/month',
  billingCycle: 'monthly',
  displayName: 'Beta (Grandfathered)',
};
const BETA_YEARLY = {
  plan: 'YEARLY',
  price: 30,
  displayPrice: '30',
  displayUnits: '/YEAR',
  subtext: 'Normally $50/year',
  billingCycle: 'yearly',
  displayName: 'Beta (Grandfathered)',
};
const EA_MONTHLY = {
  plan: 'EA_MONTHLY',
  price: 3.99,
  displayPrice: '3.99',
  displayUnits: '/MONTH',
  subtext: 'billed monthly',
  billingCycle: 'monthly',
  iapSku: 'ea_monthly',
  displayName: 'Early Access (Grandfathered)',
};
const EA_YEARLY = {
  plan: 'EA_YEARLY',
  price: 35,
  displayPrice: '2.92',
  displayUnits: '/MONTH',
  subtext: '$35 billed annually',
  starburst: 'SAVE $12.88!',
  billingCycle: 'yearly',
  iapSku: 'ea_yearly',
  displayName: 'Early Access (Grandfathered)',
};
const YEARLY = {
  plan: config.pricingPlans.yearlyWithTrial,
  price: 34.99,
  displayPrice: '2.92',
  displayUnits: '/MONTH',
  subtext: '$34.99 billed annually',
  billingCycle: 'yearly',
  iapSku: 'yearly_with_trial',
  displayName: 'Artful Agenda (Grandfathered)',
};
const AB_TEST_A = {
  plan: config.pricingPlans.yearlyWithTrialA,
  price: 39.99,
  displayPrice: '3.33',
  displayUnits: '/MONTH',
  subtext: '$39.99 billed annually',
  billingCycle: 'yearly',
  iapSku: 'yearly_w_trial_a',
  displayName: 'Artful Agenda (Grandfathered)',
};
const AB_TEST_B = {
  plan: config.pricingPlans.yearlyWithTrialB,
  price: 44.99,
  displayPrice: '3.75',
  displayUnits: '/MONTH',
  subtext: '$44.99 billed annually',
  billingCycle: 'yearly',
  iapSku: 'yearly_w_trial_b',
  displayName: 'Artful Agenda',
};
const YEARLY_PLUS = {
  plan: config.pricingPlans.yearlyPlus,
  price: 49.99,
  displayPrice: '4.17',
  displayUnits: '/MONTH',
  subtext: '$49.99 billed annually',
  billingCycle: 'yearly',
  iapSku: 'yearly_plus_w_trial',
  displayName: 'Artful Plus',
};

export const getPricing = pricingGroup => {
  switch (pricingGroup) {
    case 'abTestThirtyFive':
      return {
        yearly: YEARLY,
        yearlyPlus: YEARLY_PLUS,
      };
    case 'abTestForty':
      return {
        yearly: AB_TEST_A,
        yearlyPlus: YEARLY_PLUS,
      };
    case 'abTestFortyFive':
      return {
        yearly: AB_TEST_B,
        yearlyPlus: YEARLY_PLUS,
      };
  }

  return {
    yearly: AB_TEST_B,
    yearlyPlus: YEARLY_PLUS,

    // legacy plans
    betaMonthly: BETA_MONTHLY,
    betaYearly: BETA_YEARLY,
    eaMonthly: EA_MONTHLY,
    eaYearly: EA_YEARLY,
    legacyYearly: YEARLY,
    yearlyABTestA: AB_TEST_A,
  };
};

export const getLockInText = () => {
  return '';
};

export const planMatchesIap = (plan, iapSku) => {
  return (
    plan?.iapSku &&
    iapSku &&
    [
      plan.iapSku,
      `${plan.iapSku}.staging`,
      `${plan.iapSku}_w_trial`,
      `${plan.iapSku}_w_trial.staging`,
    ].includes(iapSku)
  );
};
