import React from 'react';
import { useSelector } from 'react-redux';

import './UndoOverlay.scss';
import UndoItem from './UndoItem';

const UndoOverlay = () => {
  const { current } = useSelector(({ Undo }) => Undo);
  return <div className="undo-overlay">{!!current && <UndoItem undo={current} />}</div>;
};

export default UndoOverlay;
