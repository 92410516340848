import { habitApi } from '~/Providers/Api';
import moment from '~/Providers/Moment';
import mParticle, { CreateHabitEvent } from '~/Providers/mParticle';
import { parseErrors } from '~/Redux/Helpers';
import { guid } from '~/util';
import { error } from '../Notifications';
import {
  FETCH_ALL,
  FETCH_ALL_FAILURE,
  FETCH_ALL_SUCCESS,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  RESTORE,
  RESTORE_SUCCESS,
  RESTORE_FAILURE,
} from './constants';

export const fetchAllHabits = (start, end) => async dispatch => {
  const stamp = moment().unix();
  dispatch({ type: FETCH_ALL, start, end, stamp });
  try {
    let response = await habitApi.fetchAll({ start, end });
    dispatch({ type: FETCH_ALL_SUCCESS, data: response.data, start, end, stamp });
    console.log(`Fetched habits: ${response}`);
    return response;
  } catch (err) {
    console.log(`Error fetching habits: ${err}`);
    dispatch({ type: FETCH_ALL_FAILURE, error: err, start, end, stamp });
    throw err;
  }
};

export const createHabit = data => async dispatch => {
  const id = data.tempId || `temp-${guid()}`;
  try {
    dispatch({ type: CREATE, data: { ...data, id } });
    let response = await habitApi.store(data);

    mParticle.logEvent(new CreateHabitEvent());

    dispatch({ type: CREATE_SUCCESS, data: response.data, id });
    return response;
  } catch (err) {
    dispatch({ type: CREATE_FAILURE, data, id });
    dispatch(error('Error creating habit', parseErrors(err)));
  }
};

export const updateHabit = data => async dispatch => {
  dispatch({ type: UPDATE, data });
  try {
    let response = await habitApi.update(data);
    dispatch({ type: UPDATE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_FAILURE, error: err });
  }
};

export const deleteHabit = data => async dispatch => {
  try {
    dispatch({ type: DELETE, data });
    let response = await habitApi.delete(data);
    dispatch({ type: DELETE_SUCCESS, data });
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, data });
    dispatch(error('Error deleting habit', parseErrors(err)));
  }
};

export const restoreHabit = data => async dispatch => {
  try {
    dispatch({ type: RESTORE, data });
    let response = await habitApi.restore(data);
    dispatch({ type: RESTORE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: RESTORE_FAILURE, data });
    dispatch(error('Error restoring habit', parseErrors(err)));
  }
};
