import React, { Component } from 'react';
import propTypes from 'prop-types';

import Modal from '~/Containers/Global/Modal/Modal';
import { MY_ACCOUNT_MODAL } from '~/Config/Modals';

import UserSettings from '~/Containers/Pages/Account/UserSettings/UserSettings';
import PaymentsAndBilling from '~/Components/Pages/Account/PaymentsAndBilling/PaymentsAndBilling';
import Support from '~/Components/Pages/Account/Support/Support';
import CancellationReport from '~/Components/Pages/Account/CancellationReport';
import CriticReport from '~/Components/Pages/Account/CriticReport';
import Referrals from '~/Components/Pages/Account/Referrals';
import StripePermittedView from '~/Components/Global/StripePermittedView';
import ConsentRequiredView, { CONSENT_TYPE_COOKIES } from '~/Components/Global/ConsentRequiredView';

import UnlockCode from './UnlockCode';
import TrackingStatus from './TrackingStatus';
import './MyAccount.scss';

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportOpen: false,
      referralsOpen: false,
      cancellationOpen: false,
    };
  }

  renderReport = () => {
    if (!this.state.reportOpen) return;
    return <CriticReport key="c" closeReport={() => this.setState({ reportOpen: false })} />;
  };

  renderReferrals = () => {
    if (!this.state.referralsOpen) return;
    return <Referrals key="d" closeReferrals={() => this.setState({ referralsOpen: false })} />;
  };

  renderCancellation = () => {
    if (!this.state.cancellationOpen) return;
    return (
      <CancellationReport
        key="d"
        closeReport={() => false}
        close={() => this.setState({ cancellationOpen: false })}
        openReport={() => this.setState({ reportOpen: true, cancellationOpen: false })}
      />
    );
  };

  renderMain = () => {
    if (this.state.reportOpen || this.state.referralsOpen || this.state.cancellationOpen) return;
    return (
      <>
        <div className="row" key="a">
          <div className="col-sm-6" key="aa">
            <UserSettings />
          </div>
          <div className="col-sm-6" key="ab">
            <PaymentsAndBilling
              openReferrals={() => this.setState({ referralsOpen: true })}
              openCancellation={() => this.setState({ cancellationOpen: true })}
            />
          </div>
        </div>
        <ConsentRequiredView
          consentType={CONSENT_TYPE_COOKIES}
          disabledView={
            <>
              <div className="row" key="b" style={{ paddingTop: 25 }}>
                <div className="col-sm-6">
                  <Referrals key="d" />
                </div>
                <div className="col-sm-6">
                  <Support openReport={() => this.setState({ reportOpen: true })} />
                  <StripePermittedView>
                    <div className="padding-top">
                      <UnlockCode />
                    </div>
                  </StripePermittedView>
                </div>
              </div>
            </>
          }
        >
          <div className="row" key="b" style={{ paddingTop: 25 }}>
            <div className="col-sm-6">
              <TrackingStatus />
            </div>
            <div className="col-sm-6">
              <Support openReport={() => this.setState({ reportOpen: true })} />
            </div>
          </div>
          <div className="row" key="b" style={{ paddingTop: 25 }}>
            <div className="col-sm-6">
              <Referrals key="d" />
            </div>
            <div className="col-sm-6">
              <StripePermittedView>
                <UnlockCode />
              </StripePermittedView>
            </div>
          </div>
        </ConsentRequiredView>
      </>
    );
  };

  render() {
    const { modalId } = this.props;
    return (
      <Modal overlayClassName="my-account-modal" id={modalId} displayOverlay ariaHideApp={false}>
        <div className="row" key="d">
          <div className="col-xs-12">
            <span className="my-account-header" style={{ fontSize: '2em' }}>
              My Account
            </span>
          </div>
        </div>
        {this.renderMain()}
        {this.renderReport()}
        {this.renderReferrals()}
        {this.renderCancellation()}
      </Modal>
    );
  }
}

MyAccount.propTypes = {
  modalId: propTypes.string,
};

MyAccount.defaultProps = {
  modalId: MY_ACCOUNT_MODAL,
};

export default MyAccount;
