import { connect } from 'react-redux';

import AdminStickerPacks from '~/Components/Pages/Admin/AdminStickerPacks/AdminStickerPacks';
import {
  getStickerPacks,
  updateForm,
  addStickerPack,
  deleteStickerPack,
} from '~/Redux/Modules/Admin/StickerPacks';
import { getStickers } from '~/Redux/Modules/Admin/Stickers';

const mapStateToProps = ({ StickerPacks, Stickers }) => {
  return {
    stickers: Stickers.data,
    stickerPacks: StickerPacks.data,
    isFetching: StickerPacks.isFetching,
    isAdding: StickerPacks.isAdding,
    isDeleting: StickerPacks.isDeleting,
    actionStickerPack: StickerPacks.actionStickerPack,
    actionId: StickerPacks.actionId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStickerPacks: (page, search) => dispatch(getStickerPacks(page, search)),
    getStickers: page => dispatch(getStickers(page)),
    addStickerPack: data => dispatch(addStickerPack(data)),
    updateForm: update => dispatch(updateForm(update)),
    deleteStickerPack: id => dispatch(deleteStickerPack(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStickerPacks);
