import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '~/Components/Global/Form/Button';
import { hideSnooze } from '~/Redux/Modules/SnoozePopup';
import OpenDatePicker from '~/Components/Global/DatePicker/OpenDatePicker';

import './JumpToDayPopup.scss';

class JumpToDayPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    console.log('handleOpen');
    this.setState({ open: !this.state.open });
  };

  handleCancel = () => {
    console.log('handleCancel');
    this.setState({ open: false });
  };

  handleJump = jumpDate => {
    const { onJump } = this.props;
    this.setState({ open: false });
    console.log('jump to', jumpDate);
    if (onJump) onJump(jumpDate);
  };

  renderCalendar = () => {
    const { selected } = this.props;
    const { open } = this.state;
    if (!open) return null;
    return (
      <div key="b" className="jump-popup" onClick={e => e.stopPropagation()}>
        <div className="jump-popup__body">
          <OpenDatePicker onChange={this.handleJump} selected={selected} />
        </div>

        <div className="jump-popup__buttons">
          <Button text="CANCEL" className="btn btn-default" onClick={this.handleCancel} />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="jump-popup-wrapper">
        <span key="a" className="open-jump-popup fa fa-chevron-down" onClick={this.handleOpen} />
        {this.renderCalendar()}
      </div>
    );
  }
}

JumpToDayPopup.propTypes = {
  onJump: PropTypes.func,
  selected: PropTypes.any,
  hideSnooze: PropTypes.func,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    hideSnooze: () => dispatch(hideSnooze()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JumpToDayPopup);
