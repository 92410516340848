import React, { Component } from 'react';
import propTypes from 'prop-types';

class FormToggle extends Component {
  constructor() {
    super();
    this.state = {
      isEditing: false,
    };
    this.toggleEditing = this.toggleEditing.bind(this);
  }

  toggleEditing(event, isEditing) {
    if (!isEditing) {
      event.preventDefault();
    }
    this.setState({ isEditing: !isEditing });
  }

  childWithProp = child =>
    React.cloneElement(child, {
      isEditing: this.state.isEditing,
      toggleEditing: this.toggleEditing,
    });

  render() {
    return [React.Children.map(this.props.children, this.childWithProp)];
  }
}

FormToggle.propTypes = {
  children: propTypes.node,
};

export default FormToggle;
