import React from 'react';
import propTypes from 'prop-types';

import { PORTRAIT_IMAGE, LANDSCAPE_IMAGE, MOBILE_IMAGE } from '~/Redux/Modules/Admin/Skins';

import TextInput from '~/Components/Global/Form/TextInput';
import Checkbox from '~/Components/Global/Form/Checkbox';
import DropZone from '~/Components/Global/Form/DropZone';

const SkinForm = ({ data, url, onChange, onImageChange, isFetching }) => (
  <div className="margin-top">
    <div className="flex-row">
      <div className="flex-1">
        <TextInput name="name" placeholder="Skin Name" value={data.name} onChange={onChange} />
        <TextInput name="price" placeholder="Price" value={data.price} onChange={onChange} />
        <TextInput name="sku" label="Mobile Product Id" value={data.sku} onChange={onChange} />
        <div>
          <Checkbox label="Active Status?" name="status" value={data.status} onChange={onChange} />
        </div>
        <div>
          <Checkbox
            label="Fundraiser?"
            name="is_fundraiser"
            value={data.is_fundraiser}
            onChange={onChange}
          />
        </div>
        <TextInput
          name="unlock_code"
          placeholder="Unlock Code"
          value={data.unlock_code}
          onChange={onChange}
        />
      </div>
    </div>
    <div className="flex-row">
      <div className="flex-1 center">
        <h4 className="center margin-bottom">Portrait</h4>
        <DropZone
          onDrop={image => onImageChange(image, PORTRAIT_IMAGE)}
          dropFile={data.images.find(image => image.name === PORTRAIT_IMAGE)}
          dropImagePath={url}
          dropText="Drop or Click to upload image"
          name="displayImage"
          isUploading={isFetching}
        />
      </div>
      <div className="flex-1 center">
        <h4 className="center margin-bottom">Landscape</h4>
        <DropZone
          onDrop={image => onImageChange(image, LANDSCAPE_IMAGE)}
          dropFile={data.images.find(image => image.name === LANDSCAPE_IMAGE)}
          dropImagePath={url}
          dropText="Drop or Click to upload image"
          name="backgroundImage"
          isUploading={isFetching}
        />
      </div>
      <div className="flex-1 center">
        <h4 className="center margin-bottom">Mobile</h4>
        <DropZone
          onDrop={image => onImageChange(image, MOBILE_IMAGE)}
          dropFile={data.images.find(image => image.name === MOBILE_IMAGE)}
          dropImagePath={url}
          dropText="Drop or Click to upload image"
          name="mobileImage"
          isUploading={isFetching}
        />
      </div>
    </div>
  </div>
);

SkinForm.defaultProps = {
  url: '',
};

SkinForm.propTypes = {
  data: propTypes.object,
  url: propTypes.string,
  onChange: propTypes.func,
  onImageChange: propTypes.func,
  isFetching: propTypes.bool,
};

export default SkinForm;
