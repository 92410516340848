import mParticle, { DowngradeEvent, StartTrialEvent, UpgradeEvent } from '~/Providers/mParticle';
import { stripeApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import { setUser } from '~/Redux/Modules/User';
import { setAccount } from '~/Redux/Modules/Account';

import history from '~/Providers/History';

import { getPricing } from '~/Config/Stripe';
import Storage from '~/Providers/Storage';

export const SUBSCRIBE = 'artful-client/Subscription/SUBSCRIBE';
export const SUBSCRIBE_SUCCESS = 'artful-client/Subscription/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'artful-client/Subscription/SUBSCRIBE_FAILURE';

export const CHANGE_SUBSCRIPTION = 'artful-client/Subscription/CHANGE_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_SUCCESS = 'artful-client/Subscription/CHANGE_SUBSCRIPTION_SUCCESS';
export const CHANGE_SUBSCRIPTION_FAILURE = 'artful-client/Subscription/CHANGE_SUBSCRIPTION_FAILURE';

export const UNSUBSCRIBE = 'artful-client/Subscription/UNSUBSCRIBE';
export const UNSUBSCRIBE_SUCCESS = 'artful-client/Subscription/UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'artful-client/Subscription/UNSUBSCRIBE_FAILURE';

export const START_TRIAL = 'artful-client/Subscription/START_TRIAL';
export const START_TRIAL_SUCCESS = 'artful-client/Subscription/START_TRIAL_SUCCESS';
export const START_TRIAL_FAILURE = 'artful-client/Subscription/START_TRIAL_FAILURE';

export const UPDATE_FIELD = 'artful-client/Subscription/UPDATE_FIELD';

const initialState = {
  isFetching: false,
  isStartingTrial: false,
  data: {
    coupon: '',
    name: '',
    plan: getPricing().yearly,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIBE:
    case CHANGE_SUBSCRIPTION:
      return { ...state, isFetching: true };
    case CHANGE_SUBSCRIPTION_SUCCESS:
    case SUBSCRIBE_SUCCESS:
      return { ...state, isFetching: false };
    case CHANGE_SUBSCRIPTION_FAILURE:
    case SUBSCRIBE_FAILURE:
      return { ...state, isFetching: false };
    case START_TRIAL:
      return { ...state, isStartingTrial: true };
    case START_TRIAL_SUCCESS:
      return { ...state, isStartingTrial: false };
    case START_TRIAL_FAILURE:
      return { ...state, isStartingTrial: false };
    case UPDATE_FIELD:
      return { ...state, data: { ...state.data, ...action.update } };
    case UNSUBSCRIBE:
      return { ...state, isFetching: true };
    case UNSUBSCRIBE_SUCCESS:
    case UNSUBSCRIBE_FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

export const subscribe = data => async dispatch => {
  dispatch({ type: SUBSCRIBE });
  try {
    let response = await stripeApi.subscribeWithPaymentMethod({
      ...data,
      adid: Storage.get('adid'),
      tracking_status: Storage.get('tracking_status'),
    });
    mParticle.logEvent(new StartTrialEvent(data.plan, data.pricingGroup));
    dispatch({ type: SUBSCRIBE_SUCCESS });
    dispatch(success(`Subscribed to Plan Successfully!`));
    dispatch(setUser(response.data));
    let subscription = response.data.subscriptions.sort((a, b) =>
      b.created_at.localeCompare(a.created_at)
    )[0];
    if (data.coupon) {
      history.push(`/add-accounts?coupon=${data.coupon}&sas_tracking=${subscription.id}`);
    } else {
      history.push(`/add-accounts?sas_tracking=${subscription.id}`);
    }
    return response;
  } catch (err) {
    dispatch({ type: SUBSCRIBE_FAILURE, error: err });
    dispatch(error('Subscribing Error', parseErrors(err)));
  }
};

export const changeSubscription = data => async dispatch => {
  dispatch({ type: CHANGE_SUBSCRIPTION });
  try {
    let response = await stripeApi.change(data);
    dispatch({ type: CHANGE_SUBSCRIPTION_SUCCESS });
    dispatch(success('Subscription Changed'));
    dispatch(setUser(response.data));
    dispatch(setAccount(response.data));
    if (data.isUpgrade) {
      mParticle.logEvent(new UpgradeEvent(data.plan));
    } else {
      mParticle.logEvent(new DowngradeEvent(data.plan));
    }
    return response;
  } catch (err) {
    dispatch({ type: CHANGE_SUBSCRIPTION_FAILURE, error: err });
    dispatch(error('Error Changing Subscription', parseErrors(err)));
    throw err;
  }
};

export const unsubscribe = () => async dispatch => {
  dispatch({ type: UNSUBSCRIBE });
  try {
    let response = await stripeApi.cancel();
    dispatch({ type: UNSUBSCRIBE_SUCCESS });
    dispatch(success('Subscription Cancelled'));
    dispatch(setUser(response.data));
    dispatch(setAccount(response.data));
    if (window.fbq) {
      console.log('Facebook track cancellation');
      window.fbq('trackCustom', 'Cancel');
    }
    return response;
  } catch (err) {
    dispatch({ type: UNSUBSCRIBE_FAILURE, error: err });
    dispatch(error('Error Cancelling Subscription', parseErrors(err)));
  }
};

export const updateField = update => dispatch => dispatch({ type: UPDATE_FIELD, update });

export const startTrial = () => async dispatch => {
  dispatch({ type: START_TRIAL });
  try {
    let response = await stripeApi.startTrial();
    dispatch({ type: START_TRIAL_SUCCESS });
    dispatch(setUser(response.data));
    history.push('/add-accounts');
    if (window.fbq) {
      console.log('Facebook track lead');
      window.fbq('track', 'Lead');
    }
    return response;
  } catch (err) {
    dispatch({ type: START_TRIAL_FAILURE, error: err });
    dispatch(error('Error Starting Trial', parseErrors(err)));
  }
};
