/* eslint-disable max-len */
import React from 'react';

export default function HabitChatIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.5 7.5h-15c-1.875 0-1.875 0-1.875 1.875v8.75C5.625 20 5.625 20 7.5 20h10l4.374 4.375V20h.625c1.875 0 1.875 0 1.875-1.875v-8.75c0-1.875 0-1.875-1.874-1.875zm-2.813 4.375c.876 0 1.563.688 1.563 1.562 0 .875-.688 1.562-1.563 1.562a1.545 1.545 0 0 1-1.562-1.561c0-.876.687-1.563 1.563-1.563zM9.688 15a1.547 1.547 0 0 1-1.561-1.562c0-.876.688-1.562 1.562-1.562.875 0 1.562.688 1.562 1.562 0 .874-.688 1.562-1.562 1.562zm5 0a1.547 1.547 0 0 1-1.562-1.562c0-.876.688-1.562 1.562-1.562.876 0 1.562.688 1.562 1.562A1.546 1.546 0 0 1 14.687 15z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
