import React from 'react';
import propTypes from 'prop-types';
import ArtfulCheckbox from '~/Components/Global/Form/ArtfulCheckbox';

import './ToggleTaskRollover.scss';

const ToggleTaskRollover = props => {
  return (
    <div className="toggle-task-rollover">
      <ArtfulCheckbox
        label="Rollover Unchecked Task"
        value={props.value}
        onChange={() => props.handleAutoRolloverChange(props.value)}
        name="TaskRollover"
      />
    </div>
  );
};

export default ToggleTaskRollover;

ToggleTaskRollover.propTypes = {
  value: propTypes.bool,
  handleAutoRolloverChange: propTypes.func,
};
