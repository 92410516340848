import { connect } from 'react-redux';

import { addRoute } from '~/Redux/Modules/Menus';

import AuthRouteComponent from '~/Components/Global/Routes/AuthRoute';

const mapStateToProps = ({ Auth, User, Menus }) => {
  return {
    user: User,
    isAuthenticated: Auth.isAuthenticated,
    isFetching: Auth.isFetching,
    hasFetched: Auth.hasFetched,
    currentRoutes: Menus.routes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addRoute: update => dispatch(addRoute(update)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRouteComponent);
