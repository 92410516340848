import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { featureEnabled } from '~/Redux/Modules/FeatureFlags';

export const FeatureFlagView = ({ featureName, children, disabledView }) => {
  const isFeatureEnabled = useSelector(state => featureEnabled(state, featureName));

  if (!isFeatureEnabled) {
    return disabledView || null;
  }

  return children;
};

FeatureFlagView.propTypes = {
  featureName: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabledView: PropTypes.node,
};

export default FeatureFlagView;
