/* eslint-disable react/no-danger */
/* eslint-disable max-len */

import React from 'react';

export default function Account(props) {
  return (
    <svg id="Account" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4d4d4d;}' }} />
      </defs>
      <title>Account Menu</title>
      <path
        id="Profile_Icon"
        data-name="Profile Icon"
        className="cls-1"
        d="M174.23,297.87C245.52,286.26,300,224.57,300,150A150,150,0,0,0,0,150c0,74.57,54.48,136.26,125.77,147.87,1.85.31,3.7.6,5.56.85s3.72.46,5.6.62c4.31.39,8.66.66,13.07.66s8.76-.27,13.07-.66c1.88-.16,3.74-.39,5.6-.62S172.38,298.18,174.23,297.87ZM10.71,150C10.71,73.2,73.2,10.71,150,10.71S289.29,73.2,289.29,150a139.21,139.21,0,0,1-64.38,117.24c-8.55-22.24-24-33-43.16-43.37a11,11,0,0,1-6.05-9.62v-6.16a71.42,71.42,0,0,0,21.48-18.56h18.34a12.55,12.55,0,0,0,11.83-16.65c-4.83-14.62-7.77-34.26-7.77-53,0-38.25-31.18-71.17-69.64-71.17S80.29,81.64,80.29,119.89c0,18.36-2.09,38.32-6.76,52.84-2.65,8.27,3.11,16.78,11.8,16.79H102.7a71.29,71.29,0,0,0,21.6,18.63v6.1a11,11,0,0,1-6,9.62C99.09,234.21,83.64,245,75.09,267.24A139.21,139.21,0,0,1,10.71,150ZM166.45,288.22a127,127,0,0,1-32.9,0c-.65-.07-1.29-.21-1.94-.29a132.66,132.66,0,0,1-46.93-15.85c6.75-18.62,18.81-28.07,38.65-38.78A21.78,21.78,0,0,0,135,214.25V201.78l-5.59-3a60.66,60.66,0,0,1-18.33-15.87l-3.21-4.06H85.33a1.48,1.48,0,0,1-1.32-.65,2.35,2.35,0,0,1-.28-2.14C88.36,161.62,91,141.17,91,119.89c0-32.77,27-60.45,58.93-60.45s58.93,27.68,58.93,60.45c0,19.83,3,40.37,8.3,56.35a2,2,0,0,1-.25,1.88,1.62,1.62,0,0,1-1.4.7H192l-3.22,4.06a60.57,60.57,0,0,1-18.22,15.81L165,201.74v12.51a21.79,21.79,0,0,0,11.67,19.05c19.85,10.71,31.91,20.16,38.66,38.78a132.66,132.66,0,0,1-46.93,15.85C167.74,288,167.1,288.15,166.45,288.22Z"
      />
    </svg>
  );
}
