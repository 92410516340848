import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { hasRoles } from 'Context/User';

/**
 * Used instead of a basic `Route` to guard access to Pages that require Authentication or certain roles
 * See the partner container component at Containers/Global/routes/AuthRoute for the redux state passed in to this component
 *
 * See the react router v4 docs on protecting a route for Auth https://reacttraining.com/react-router/web/example/Auth-workflow
 *
 * @param {*} param0
 */
class AuthRoute extends Component {
  handleUpdateMenu = () => {
    const { label, path, user, roles, isAuthenticated } = this.props;
    if (
      !label ||
      user.isFetching ||
      !user.hasFetched ||
      !isAuthenticated ||
      !hasRoles(user.data, roles)
    )
      return false;
    this.props.addRoute({ path: path, label: label });
  };

  componentDidMount = () => {
    this.handleUpdateMenu();
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.currentRoutes.find(r => r.path === nextProps.path)) return false;
    this.handleUpdateMenu();
  };

  render() {
    const {
      component: RenderComponent,
      isAuthenticated,
      isFetching,
      hasFetched,
      roles,
      user,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          if (isFetching || !hasFetched || user.isFetching || !user.hasFetched) return null;
          if (!isAuthenticated || !hasRoles(user.data, roles)) return <Redirect to="/login" />;
          return <RenderComponent {...props} />;
        }}
      />
    );
  }
}

AuthRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.node, propTypes.func]),
  roles: propTypes.oneOfType([propTypes.array, propTypes.string, propTypes.bool]),
  isAuthenticated: propTypes.bool,
  user: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  label: propTypes.string,
  path: propTypes.string,
  addRoute: propTypes.func,
  currentRoutes: propTypes.oneOfType([propTypes.array, propTypes.bool]),
  isFetching: propTypes.bool,
  hasFetched: propTypes.bool,
};

AuthRoute.defaultProps = {
  roles: false,
  isAuthenticated: false,
  user: false,
  hasFetched: false,
  isFetching: false,
};

export default AuthRoute;
