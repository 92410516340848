import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { AppContainer } from 'react-hot-loader';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import Leanplum from 'leanplum-sdk';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from '~/Config';
import history from '~/Providers/History';
import moment from '~/Providers/Moment';
import mParticle from '~/Providers/mParticle';

import AuthRoute from '~/Containers/Global/Routes/AuthRoute';
import UnAuthRoute from '~/Containers/Global/Routes/UnAuthRoute';
import Redirector from '~/Components/Global/Routes/Redirector';
import SubscribedRoute from '~/Containers/Global/Routes/SubscribedRoute';
import AdminLayout from '~/Containers/Layout/AdminLayout/AdminLayout';

import { ADMIN } from '~/Config/Roles';

import Store from '~/Redux/Store';
import Register from '~/Containers/Pages/Register/Register';

import { errorApi } from '~/Providers/Api';
import { error } from '~/Redux/Modules/Notifications';
import ApplicationLayout from './Containers/Layout/ApplicationLayout/ApplicationLayout';

import 'Styles/Main.scss'; // Include our own Global styles
import 'styles.js'; // Include third party scripts
import 'favicons/favicons.js';
import Storage from './Providers/Storage';

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

const Loader = () => <div />;

const AddAccount = Loadable({
  loader: () => import('./Components/Pages/AddAccount/AddAccount'),
  loading: Loader,
});
const ChooseCover = Loadable({
  loader: () => import('./Containers/Pages/ChooseCover/ChooseCover'),
  loading: Loader,
});
const Login = Loadable({
  loader: () => import('./Containers/Pages/Login/Login'),
  loading: Loader,
});
const Logout = Loadable({
  loader: () => import('./Containers/Pages/Logout/Logout'),
  loading: Loader,
});
const Lists = Loadable({
  loader: () => import('./Components/Pages/Lists/Lists'),
  loading: Loader,
});
const Habits = Loadable({
  loader: () => import('./Components/Pages/Habits/Habits'),
  loading: Loader,
});
const NotFound = Loadable({
  loader: () => import('./Components/Pages/NotFound/NotFound'),
  loading: Loader,
});
const MonthView = Loadable({
  loader: () => import('./Containers/Pages/Calendar/MonthView/MonthView'),
  loading: Loader,
});
const WeekView = Loadable({
  loader: () => import('./Components/Pages/Calendar/WeekView/WeekView'),
  loading: Loader,
});
const DayView = Loadable({
  loader: () => import('./Containers/Pages/Calendar/DayView/DayView'),
  loading: Loader,
});
const RequestPasswordReset = Loadable({
  loader: () => import('./Components/Pages/ForgotPassword/RequestPasswordReset'),
  loading: Loader,
});
const ResetPassword = Loadable({
  loader: () => import('./Components/Pages/ForgotPassword/ResetPassword'),
  loading: Loader,
});
const StartTrial = Loadable({
  loader: () => import('./Components/Pages/StartTrial/StartTrial'),
  loading: Loader,
});
const SubscriptionPage = Loadable({
  loader: () => import('./Components/Pages/Subscription/SubscriptionPage'),
  loading: Loader,
});
const SkinSelect = Loadable({
  loader: () => import('./Containers/Pages/SkinSelect/SkinSelect'),
  loading: Loader,
});
const MyAccountRouter = Loadable({
  loader: () => import('./Containers/Pages/Account/MyAccountRouter'),
  loading: Loader,
});
const CalendarSettings = Loadable({
  loader: () => import('./Components/Pages/CalendarSettings/CalendarSettings'),
  loading: Loader,
});
const ChangePaymentSource = Loadable({
  loader: () => import('./Components/Pages/ChangePaymentSource/ChangePaymentSource'),
  loading: Loader,
});
const UpgradeToPlus = Loadable({
  loader: () => import('./Components/Pages/UpgradeToPlus/UpgradeToPlus'),
  loading: Loader,
});
const CalendarSettingsRoute = Loadable({
  loader: () => import('./Components/Pages/CalendarSettings/CalendarSettingsRoute'),
  loading: Loader,
});
const ReportAProblemRoute = Loadable({
  loader: () => import('./Components/Pages/Account/ReportAProblemRoute'),
  loading: Loader,
});
const SwapToStripePage = Loadable({
  loader: () => import('./Components/Pages/Subscription/SwapToStripePage'),
  loading: Loader,
});

var appDiv = document.getElementById('artful-client');

window.disableStripe = localStorage.getItem('disableStripe');

if (config.leanPlum.appId) {
  if (config.leanPlum.devMode) {
    Leanplum.setAppIdForDevelopmentMode(config.leanPlum.appId, config.leanPlum.devKey);
  } else {
    Leanplum.setAppIdForProductionMode(config.leanPlum.appId, config.leanPlum.prodKey);
  }
  Leanplum.start(success => {
    console.log('Leanplum started', success, Leanplum.getVariables());
  });
}
Leanplum.setVariables({
  registerWithSocial: false,
  pricingGroupForTest2024: 'yearly',
});

Leanplum.enableRichInAppMessages(true);

const openUrl = e => {
  if (e.url.startsWith('com.artfulagenda')) {
    e.preventDefault();
    const url = e.url.replace(/com.artfulagenda.*?:\/\//g, '');
    console.log('Leanplum', 'openUrl', e.url, url);
    history.push(url);
  }
};

Leanplum.on('openUrl', openUrl);

Leanplum.on('showMessage', function(args) {
  console.log('Leanplum', 'showMessage', args.message, args.context);
});

mParticle.initialize();

@DragDropContext(HTML5Backend)
class Application extends Component {
  componentDidMount() {
    setTimeout(() => {
      [
        SubscriptionPage,
        StartTrial,
        AddAccount,
        ChooseCover,
        Login,
        Logout,
        Lists,
        Habits,
        NotFound,
        MonthView,
        WeekView,
        DayView,
        RequestPasswordReset,
        ResetPassword,
        SkinSelect,
        MyAccountRouter,
      ].forEach(component => component.preload());
    });

    errorApi
      .noticeError([VERSION, COMMIT, BUILD_NUMBER], {
        timeZone: moment.tz.guess(),
        userAgent: window.navigator.userAgent,
      })
      .catch(() => null);
  }

  componentDidCatch(err, info) {
    console.error(err, info);
    window.error = err;
    mapStackTrace(err.stack, mappedStack => {
      errorApi.noticeError(mappedStack || err.stack, info);
      Store.dispatch(error('Sorry, an error occurred', err.message));
    });
  }

  render() {
    return (
      <GoogleOAuthProvider clientId={config.googleClient}>
        <Provider store={Store}>
          <Router history={history}>
            <AppContainer>
              <ApplicationLayout>
                <Switch>
                  <Redirector exact path="/" />
                  <SubscribedRoute component={MonthView} exact path="/month" />
                  <SubscribedRoute component={MonthView} exact path="/month/:year/:month" />
                  <SubscribedRoute component={WeekView} exact path="/week" />
                  <SubscribedRoute component={WeekView} exact path="/week/:year/:month/:day" />
                  <SubscribedRoute component={DayView} exact path="/day" />
                  <SubscribedRoute component={DayView} exact path="/day/:year/:month/:day" />
                  <SubscribedRoute component={Habits} exact path="/habit" />
                  <SubscribedRoute component={Habits} exact path="/habit/:year/:month" />
                  <SubscribedRoute component={AddAccount} exact path="/add-accounts" />
                  <SubscribedRoute
                    component={AddAccount}
                    exact
                    path="/add-accounts/outlook/:code"
                  />
                  <SubscribedRoute
                    component={CalendarSettings}
                    exact
                    path="/calendars/:calendarId"
                  />
                  <SubscribedRoute
                    component={ChangePaymentSource}
                    exact
                    path="/change-payment-source"
                  />
                  <SubscribedRoute component={UpgradeToPlus} exact path="/upgrade-to-plus" />
                  <SubscribedRoute
                    component={CalendarSettingsRoute}
                    exact
                    path="/calendar-settings"
                  />
                  <AuthRoute component={ReportAProblemRoute} exact path="/tech-support" />
                  <UnAuthRoute component={Register} exact path="/register" />
                  <UnAuthRoute component={Login} exact path="/login" />
                  <UnAuthRoute component={RequestPasswordReset} exact path="/forgot-password" />
                  <UnAuthRoute component={ResetPassword} exact path="/reset-password/:token" />
                  <AuthRoute component={MyAccountRouter} exact path="/account" />
                  <Route component={Logout} exact path="/logout" />
                  <AuthRoute component={ChooseCover} exact path="/choose-cover-list/:cover" />
                  <AuthRoute component={ChooseCover} exact path="/choose-cover-list" />
                  <AuthRoute component={ChooseCover} exact path="/choose-cover/:cover" />
                  <AuthRoute component={ChooseCover} exact path="/choose-cover" />
                  <AuthRoute component={Lists} exact path="/lists" />
                  <AuthRoute component={SubscriptionPage} exact path="/subscription" />
                  <AuthRoute component={SubscriptionPage} exact path="/trial-with-card" />
                  <AuthRoute component={SwapToStripePage} exact path="/swap-to-stripe" />
                  <AuthRoute roles={[ADMIN]} path="/admin" component={AdminLayout} />
                  <Redirect exact path="/choose-handwriting" to="/month" />
                  <Route component={NotFound} />
                </Switch>
              </ApplicationLayout>
            </AppContainer>
          </Router>
        </Provider>
      </GoogleOAuthProvider>
    );
  }
}
ReactDOM.render(<Application />, appDiv);
console.log('Welcome', VERSION, COMMIT, BUILD_NUMBER);
