import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { success, error } from '~/Redux/Modules/Notifications';
import history from '~/Providers/History';
import { AdminUserApi } from '~/Providers/Api';
import useRevalidate from './useRevalidate';

const DeleteUserButton = ({ userId }) => {
  const dispatch = useDispatch();
  const revalidate = useRevalidate();

  const handleClick = () => {
    return revalidate({
      body: <p className="text-danger">Are you sure you want to delete this user?</p>,
    }).then(password => {
      AdminUserApi.delete(userId, password)
        .then(() => {
          dispatch(success('User deleted'));
          history.push('/admin/users');
        })
        .catch(err => {
          dispatch(error('Unable to delete user', err.message));
        });
    });
  };

  return (
    <button className="btn btn-danger" onClick={handleClick} type="button">
      <FontAwesome name="trash" /> Delete User
    </button>
  );
};

DeleteUserButton.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DeleteUserButton;
