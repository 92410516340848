import React from 'react';
import propTypes from 'prop-types';

import ImageUpload from '../../Form/ImageUpload';
import StickerDropZone from '../StickerDropZone';

import './StickerUploadDialog.scss';

function StickerUploadDialog({
  uploads,
  handleStickerDrop,
  handleUploadComplete,
  handleStickerRemoval,
  handleNext,
  handleCloseModal,
  uploadsComplete,
}) {
  return (
    <section id="sticker-upload-dialog">
      <section id="upload-form">
        <StickerDropZone onDrop={handleStickerDrop} />
      </section>

      <section id="uploads">
        <div className="scroll-guard">
          {uploads.map((upload, index) => (
            <ImageUpload
              key={index}
              upload={upload}
              handleUploadComplete={handleUploadComplete}
              removeSticker={() => handleStickerRemoval(index)}
            />
          ))}
        </div>
      </section>

      <div className="action-buttons">
        <div
          id="done-button"
          className="action-button"
          tabIndex="0"
          role="button"
          onClick={handleCloseModal}
        >
          Done
        </div>

        <div
          id="add-tags-button"
          className={`action-button${!uploadsComplete() && ' disabled'}`}
          tabIndex="0"
          role="button"
          onClick={uploadsComplete() ? handleNext : null}
        >
          Add Tags
        </div>
      </div>
    </section>
  );
}

StickerUploadDialog.propTypes = {
  uploads: propTypes.array,
  handleStickerDrop: propTypes.func.isRequired,
  handleUploadComplete: propTypes.func,
  handleStickerRemoval: propTypes.func.isRequired,
  handleNext: propTypes.func.isRequired,
  handleCloseModal: propTypes.func.isRequired,
  uploadsComplete: propTypes.func.isRequired,
};

export default StickerUploadDialog;
