/* eslint-disable max-len */
import React from 'react';

export default function HabitTVIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m16.027 9.66 3.52-3.441a.684.684 0 0 0 0-1.004.722.722 0 0 0-1.027 0L15 8.656l-3.52-3.441a.721.721 0 0 0-1.027 0 .684.684 0 0 0 0 1.004l3.52 3.441H4V24h22V9.66h-9.973zm4.106 9.32c0 1.147-.953 2.151-2.2 2.151h-8.8c-1.247 0-2.2-1.004-2.2-2.15v-4.302c0-1.22.953-2.151 2.2-2.151h8.8c1.247 0 2.2.931 2.2 2.15v4.302zm2.934-.716c-.44 0-.733-.287-.733-.717s.293-.716.733-.716.733.286.733.716-.293.717-.733.717zm0-2.151c-.44 0-.733-.286-.733-.716s.293-.717.733-.717.733.286.733.716-.293.717-.733.717zm0-2.15c-.44 0-.733-.287-.733-.717s.293-.717.733-.717.733.286.733.717c0 .43-.293.716-.733.716z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
