import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from '~/Providers/Moment';
import { DATE_FORMAT } from '~/Redux/Constants';
import StripePermittedView from './StripePermittedView';

class MobilePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popups: JSON.parse(localStorage.getItem('mobile_notice_popups') || '[]'),
    };
  }

  shouldRender() {
    const { popups } = this.state;
    const { user } = this.props;
    if (!user || !user.isSubscribed) return false;
    if (popups.length >= 1) return false;
    return true;
  }

  handleClick = () => {
    const { popups } = this.state;
    const newPopups = [...popups, moment().format(DATE_FORMAT)];
    localStorage.setItem('mobile_notice_popups', JSON.stringify(newPopups));
    this.setState({ popups: newPopups });
  };

  render() {
    if (!this.shouldRender()) return null;

    if (window.navigator.userAgent.indexOf('Android') !== -1) {
      return (
        <div className="mobile-popup">
          <div className="mobile-popup__dismiss" onClick={this.handleClick}>
            x
          </div>
          <div className="mobile-popup__header">Our mobile app is live!</div>
          <div className="mobile-popup__body">
            <p>
              The Artful Agenda mobile app is now live! Download it{' '}
              <a href="https://play.google.com/store/apps/details?id=com.artfulagenda.app">here</a>.
            </p>
          </div>
        </div>
      );
    } else if (
      window.navigator.userAgent.indexOf('iPad') !== -1 ||
      window.navigator.userAgent.indexOf('iPhone') !== -1
    ) {
      return (
        <div className="mobile-popup">
          <div className="mobile-popup__dismiss" onClick={this.handleClick}>
            x
          </div>
          <div className="mobile-popup__header">Our mobile app is live!</div>
          <div className="mobile-popup__body">
            <p>
              The Artful Agenda mobile app is now live! Download it{' '}
              <a href="https://apps.apple.com/us/app/artful-agenda/id1444781716?ls=1">here</a>.
            </p>
          </div>
        </div>
      );
    } else if (
      // Fallback to prevent showing this on the Mac Wrapper
      window.navigator.userAgent.indexOf('Safari') === -1 &&
      window.navigator.userAgent.indexOf('Chrome') === -1 &&
      window.navigator.userAgent.indexOf('Firefox') === -1 &&
      window.navigator.userAgent.indexOf('Edg') === -1
    ) {
      return null;
    } else {
      return (
        <StripePermittedView>
          <div className="mobile-popup">
            <div className="mobile-popup__dismiss" onClick={this.handleClick}>
              x
            </div>
            <div className="mobile-popup__header">Our mobile app is live!</div>
            <div className="mobile-popup__body">
              <p>
                The Artful Agenda mobile app is now live! Download it in the{' '}
                <a href="https://apps.apple.com/us/app/artful-agenda/id1444781716?ls=1">
                  App Store
                </a>{' '}
                or{' '}
                <a href="https://play.google.com/store/apps/details?id=com.artfulagenda.app">
                  Play Store
                </a>
                .
              </p>
            </div>
          </div>
        </StripePermittedView>
      );
    }
  }
}

MobilePopup.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ User }) => {
  return {
    user: User.data,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobilePopup);
