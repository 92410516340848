export const MUST_DO = 'MUST_DO';
export const CUSTOM = 'CUSTOM';
export const OTHER = 'OTHER';
export const MONTH_TODO = 'MONTH_TODO';
export const WEEK_TODO = 'WEEK_TODO';

export const TYPES = [MUST_DO, OTHER, CUSTOM, MONTH_TODO, WEEK_TODO];

export const CHECKLIST_TYPE_CHECKLIST = 'checklist';
export const CHECKLIST_TYPE_TEXT = 'text';
