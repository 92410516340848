import { SET_TRACKER } from './constants';

const initialState = {
  activeTracker: localStorage.getItem('activeTracker') || 'habit',
};

const trackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRACKER:
      localStorage.setItem('activeTracker', action.payload);
      return {
        ...state,
        activeTracker: action.payload,
      };
    default:
      return state;
  }
};

export default trackerReducer;
