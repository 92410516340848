/* eslint-disable max-len */
import React from 'react';

export default function HabitTeethIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#bgsckaecxa)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M20.204 9.417H20.2c-.534 0-1.331.302-2.034.567-.649.245-1.209.457-1.603.458h-.005c-.394-.002-.954-.213-1.603-.458-.705-.266-1.502-.567-2.037-.567-1.193.003-2.088.411-2.661 1.212-.705.984-.82 2.54-.28 3.782.498 1.15.619 2.05.746 3 .145 1.08.308 2.303 1.078 4.178.592 1.445 1.668 1.83 2.21 1.83.348 0 .594-.196.739-.362.392-.45.527-1.182.361-1.96-.46-2.162-.06-3.185.357-3.662a1.438 1.438 0 0 1 1.093-.504h.002c.42 0 .81.178 1.094.504.417.477.817 1.5.357 3.662-.166.778-.031 1.512.361 1.96.145.166.39.363.739.363.542 0 1.617-.385 2.21-1.83.77-1.876.933-3.1 1.077-4.178.128-.952.248-1.85.747-3 .54-1.243.425-2.799-.28-3.783-.576-.8-1.471-1.209-2.664-1.212zm-9.473 3.414c-.002-.891.216-1.549.649-1.956.303-.285.708-.436 1.173-.436.201 0 .364.153.364.342 0 .189-.163.341-.364.341-.274 0-.49.077-.658.235-.287.27-.437.779-.435 1.472a.33.33 0 0 1-.106.242.378.378 0 0 1-.257.1c-.2 0-.365-.151-.366-.34zM26.4 22.735c-.913.489-1.47 1.404-1.457 2.39.014-.986-.544-1.901-1.457-2.39.913-.49 1.47-1.405 1.457-2.391-.014.986.544 1.901 1.457 2.39zM8.914 8.39c-.913.49-1.47 1.405-1.457 2.391.014-.986-.544-1.9-1.457-2.39C6.913 7.9 7.47 6.986 7.457 6c-.014.986.544 1.901 1.457 2.39z" />
      </g>
      <defs>
        <clipPath id="bgsckaecxa">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
