/* eslint-disable max-len */
import React from 'react';

export default function HabitHappyIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 6.25c-4.813 0-8.75 3.937-8.75 8.75 0 4.813 3.937 8.75 8.75 8.75 4.813 0 8.75-3.937 8.75-8.75 0-4.813-3.937-8.75-8.75-8.75zm-5 6.25c0-.688.562-1.25 1.25-1.25s1.25.562 1.25 1.25-.562 1.25-1.25 1.25A1.255 1.255 0 0 1 10 12.5zm9.187 6.312c-1.125 1.125-2.687 1.75-4.187 1.75-1.5 0-3.062-.563-4.187-1.75a.605.605 0 0 1 0-.875c.25-.25.624-.25.875 0 1.813 1.813 4.813 1.813 6.625 0 .25-.25.624-.25.875 0 .25.25.25.625-.001.875zm-.437-5.063c-.688 0-1.25-.562-1.25-1.25s.562-1.25 1.25-1.25 1.25.562 1.25 1.25-.562 1.25-1.25 1.25z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
