/* eslint-disable max-len */
import React from 'react';

export default function HabitPlantIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.5 24.999h-5l-1.25-5.625h7.5l-1.25 5.625zM15.624 15.624v-1.25H17.5a1.841 1.841 0 0 0 1.875-1.875v-1.875H17.5a1.841 1.841 0 0 0-1.875 1.875V5.625c0-.375-.25-.625-.624-.625-.375 0-.625.25-.625.625v2.5A1.841 1.841 0 0 0 12.5 6.248h-1.875v1.875c0 1.063.812 1.875 1.875 1.875h1.875v5.625h-4.374v2.5h10v-2.5h-4.377z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
