export const FETCH_ALL = 'artful-client/Checklist/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'artful-client/Checklist/FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'artful-client/Checklist/FETCH_ALL_FAILURE';

export const FETCH_ONE = 'artful-client/Checklist/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'artful-client/Checklist/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'artful-client/Checklist/FETCH_ONE_FAILURE';

export const SNOOZE = 'artful-client/Checklist/SNOOZE';
export const SNOOZE_SUCCESS = 'artful-client/Checklist/SNOOZE_SUCCESS';
export const SNOOZE_FAILURE = 'artful-client/Checklist/SNOOZE_FAILURE';

export const UNSNOOZE = 'artful-client/Checklist/UNSNOOZE';
export const UNSNOOZE_SUCCESS = 'artful-client/Checklist/UNSNOOZE_SUCCESS';
export const UNSNOOZE_FAILURE = 'artful-client/Checklist/UNSNOOZE_FAILURE';

export const SET_QUEUE = 'artful-client/Checklist/SET_QUEUE';
export const UPDATE_ITEM = 'artful-client/Checklist/UPDATE_ITEM';
export const START_UPDATING = 'artful-client/Checklist/START_UPDATING';
export const FINISH_UPDATING = 'artful-client/Checklist/FINISH_UPDATING';
export const SET_LAST_UPDATE = 'artful-client/Checklist/SET_LAST_UPDATE';
