import React, { Component } from 'react';
import propTypes from 'prop-types';
import Select from '~/Components/Global/Form/Select';
import { timezones } from './Timezones';

class TimeZoneSelect extends Component {
  handleOnChange = e => {
    this.props.onChange({ [e.target.name]: e.target.value });
  };

  render() {
    const { name, value, label, placeholder } = this.props;
    var timezonesToDisplay = [...timezones];
    if (value && !timezones.find(tz => tz.name == value)) {
      timezonesToDisplay.push({ label: value, name: value });
    }
    return (
      <Select
        name={name}
        label={label}
        inlineLabel
        onChange={this.handleOnChange}
        placeholder={placeholder}
        value={value}
        data={timezonesToDisplay}
        dataValueKey="name"
        className="minimal-input"
        allowPlaceholder={false}
      />
    );
  }
}

TimeZoneSelect.propTypes = {
  onChange: propTypes.func,
  name: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  label: propTypes.string,
  placeholder: propTypes.string,
};

TimeZoneSelect.defaultProps = {
  onChange: () => {},
};

export default TimeZoneSelect;
