import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import Modal from '~/Containers/Global/Modal/Modal';
import { MANAGE_SUBSCRIPTION_MODAL } from '~/Config/Modals';
import { closeModal } from '~/Redux/Modules/Modal';
import { getPricing, planMatchesIap } from '~/Config/Stripe';
import { changeSubscription } from '~/Redux/Modules/Subscription';
import Button from '~/Components/Global/Form/Button';
import StripePermittedView from '~/Components/Global/StripePermittedView';
import Spinner from '~/Components/Global/Loading/Spinner';
import { parseErrors } from '~/Redux/Helpers';
import { getUser } from '~/Redux/Modules/User';
import { useMountEffect } from '~/util';
import { UserApi } from '~/Providers/Api';

import './ManageSubscription.scss';

const ManageSubscription = ({ modalId }) => {
  const dispatch = useDispatch();
  const { modalData } = useSelector(({ Modal }) => Modal);
  const pricingPlans = useCallback(getPricing(), []);
  const [selectedPlan, setSelectedPlan] = useState(pricingPlans.yearlyPlus);
  const isFetching = useSelector(({ Subscription }) => Subscription.isFetching);
  const [errorMessage, setErrorMessage] = useState('');
  const [editingPaymentMethod, setEditingPaymentMethod] = useState(false);
  const user = useSelector(({ User }) => User.data);
  const [historicalPlans, setHistoricalPlans] = useState([]);
  console.log('ManageSubscription', pricingPlans, selectedPlan);

  useMountEffect(() => {
    console.log('ManageSubscription', 'useMountEffect');
    UserApi.historicalPlans()
      .then(({ data }) => {
        console.log('ManageSubscription', 'historical plans', data);
        const plans = data
          .map(plan => {
            return Object.keys(pricingPlans).find(
              key => pricingPlans[key].plan === plan || planMatchesIap(pricingPlans[key], plan)
            );
          })
          .filter(plan => plan && ['yearly', 'yearlyPlus'].indexOf(plan) === -1);
        console.log('ManageSubscription', 'historical plans', plans);
        setHistoricalPlans(plans);
      })
      .catch(err => {
        console.log('ManageSubscription', 'Error fetching historical plans', err);
      });
  });

  const activeSubscription =
    user?.subscriptions?.find(
      subscription => !subscription.ends_at || new Date(subscription.ends_at) > new Date()
    ) || {};

  const activePlan = Object.values(pricingPlans).find(
    ({ plan }) => plan == (activeSubscription.stripe_plan ?? activeSubscription.stripe_price)
  );

  const isUpgrade = activePlan != pricingPlans?.yearlyPlus;

  const upgradeCost = () => {
    if (!activePlan || !pricingPlans) {
      return null;
    }

    const annualPlusCost = parseFloat(pricingPlans.yearlyPlus.price);
    const annualCurrentCost = parseFloat(activePlan.price);
    let difference = 0;
    let roundedDifference = 0;
    let monthlyDifference = 0;

    if (annualCurrentCost >= 44.99) {
      return 'Upgrade to Artful Plus for just $5 more a year!';
    }

    switch (activePlan.billingCycle) {
      case 'monthly':
        difference = annualPlusCost - annualCurrentCost * 12.0;
        roundedDifference = Math.ceil(difference);
        if (difference === roundedDifference) {
          return `Upgrade your agenda for $${roundedDifference} a year!`;
        }

        return `Upgrade your agenda for less than $${roundedDifference} a year!`;
      case 'yearly':
        difference = annualPlusCost - annualCurrentCost;
        monthlyDifference = (difference / 12.0).toFixed(2);
        return `Upgrade your agenda for just $${monthlyDifference} more a month!`;
      default:
        return '';
    }
  };

  const currentPlanText = () => {
    if (!activePlan) {
      return null;
    }
    switch (activePlan.billingCycle) {
      case 'monthly':
        return `$${activePlan.price}/MONTH`;
      case 'yearly':
        return `$${activePlan.price}/YEAR`;
      default:
        return '';
    }
  };

  const currentPlanSubtext = () => {
    if (!activePlan) {
      return null;
    }
    if (activePlan.billingCycle === 'monthly') {
      return `($${(activePlan.price * 12.0).toFixed(2)} per year)`;
    }
    if (parseFloat(activePlan.price) < 44.99) {
      return '($44.99 at current store price)';
    }
    return '';
  };

  const handleClose = result => {
    dispatch(closeModal(modalId));
    setSelectedPlan(pricingPlans.yearlyPlus);
    if (result) {
      modalData.confirmPromise?.resolve();
    } else {
      modalData.confirmPromise?.reject();
    }
  };

  const handleSave = () => {
    setErrorMessage('');
    dispatch(changeSubscription({ plan: selectedPlan.plan, isUpgrade }))
      .then(() => handleClose(true))
      .catch(err => {
        setErrorMessage(parseErrors(err));
      });
  };

  const renderUpgradeView = () => {
    //If the account information is not loaded yet, display the Spinner
    if (!user.id) {
      return (
        <div className="notes">
          <span className="action">
            <Spinner />
          </span>
        </div>
      );
    }

    //If there is no active plan, return null to display nothing
    if (!activePlan && user.id) {
      return (
        <p className="plan__benefits">Please update your subscription via your mobile device</p>
      );
    }

    //otherwise, display the upgradeCost and current plan information
    return (
      <>
        <h1>{upgradeCost()}</h1>
        <div className="plan">
          <>
            <h2>Current Plan</h2>
            <div className="plan__billing">{currentPlanText()}</div>
            <span className="plan__subtext">{currentPlanSubtext()}</span>{' '}
          </>
        </div>
        <div className="plan plan--selected">
          <h2>Artful Plus</h2>
          <p className="plan__benefits">
            Habit Tracker Tab + Daily Tracker
            <br />
            Upload Your Own Stickers
            <br />
            Custom &quot;Quick Select&quot; Colors
            <br />
            Tablet Drawing Mode
          </p>
          <div className="plan__billing">$49.99/year</div>
        </div>
        <p className="notes">
          You&apos;ll pay a prorated amount to upgrade your current plan. You won&apos;t pay the
          full amount until your next billing cycle.
        </p>
      </>
    );
  };

  const renderDowngradeView = () => (
    <>
      <h1>Manage Subscription</h1>
      {[...historicalPlans, 'yearly', 'yearlyPlus'].map(planKey => {
        const plan = pricingPlans[planKey];
        return (
          <div
            key={planKey}
            className={cn('plan', { 'plan--selected': plan === selectedPlan })}
            role="button"
            tabIndex="0"
            onClick={() => setSelectedPlan(plan)}
          >
            <h2>{plan.displayName}</h2>
            <div className="plan__billing">
              ${plan.price}/{plan.billingCycle}
            </div>
          </div>
        );
      })}
      <p className="notes">
        You will continue to have access to your current plan until the end of your billing cycle.
      </p>
    </>
  );

  const handleSetupIntent = () => {
    const editPaymentWindow = window.open(
      `${window.location.origin}/change-payment-source`,
      '_blank',
      'popup,width=400,height=600'
    );

    const editPaymentWindowTimer = setInterval(() => {
      if (editPaymentWindow.closed) {
        setEditingPaymentMethod(false);
        clearInterval(editPaymentWindowTimer);
        dispatch(getUser());
      }
    }, 100);

    setEditingPaymentMethod(editPaymentWindow);
  };

  const renderErrorMessage = () => {
    return (
      <>
        <div className="error-message">{errorMessage}</div>
        <div className="payment-method">
          <span className="brand">
            Current Card: {user.card_brand ?? user.pm_type}{' '}
            {user.card_last_four ?? user.pm_last_four}
          </span>
          {editingPaymentMethod ? (
            <span className="action">
              <Spinner />
            </span>
          ) : (
            <span
              className="action edit"
              onClick={() => handleSetupIntent()}
              role="button"
              tabIndex="0"
            >
              Update
            </span>
          )}
        </div>
      </>
    );
  };

  const renderUpgradeButton = () => (
    <Button
      type="button"
      text="UPGRADE"
      className="pill"
      onClick={() => handleSave()}
      isFetching={isFetching}
      actionText="UPGRADING"
    />
  );

  const renderDowngradeButton = () => (
    <Button
      type="button"
      text="SAVE"
      className="pill"
      onClick={() => handleSave()}
      isFetching={isFetching}
      actionText="SAVING"
    />
  );

  return (
    <Modal
      overlayClassName="manage-subscription-modal"
      id={modalId}
      ariaHideApp={false}
      onClose={() => modalData.confirmPromise?.reject()}
    >
      <StripePermittedView>
        {isUpgrade ? renderUpgradeView() : renderDowngradeView()}
      </StripePermittedView>

      {errorMessage && renderErrorMessage()}

      {activePlan && (
        <div className="actions">{isUpgrade ? renderUpgradeButton() : renderDowngradeButton()}</div>
      )}
    </Modal>
  );
};

ManageSubscription.propTypes = {
  modalId: PropTypes.string,
};

ManageSubscription.defaultProps = {
  modalId: MANAGE_SUBSCRIPTION_MODAL,
};

export default ManageSubscription;
