import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Dropzone from 'react-dropzone';
import Spinner from '~/Components/Global/Loading/Spinner';

import './DropZone.scss';

class DropZone extends Component {
  handleRenderPreview = () => {
    const { dropFile, dropText, isUploading } = this.props;
    if (isUploading)
      return (
        <div>
          Uploading...
          <Spinner />
        </div>
      );
    if (dropFile && dropFile.public_path) return <img src={dropFile.public_path} alt="" />;
    if (dropFile && dropFile.name && dropFile[dropFile.name])
      return <img src={dropFile[dropFile.name].preview} alt="" />;
    if (dropFile && dropFile.preview) return <img src={dropFile.preview} alt="" />;
    return <div> {dropText ? dropText : 'Drop file to start upload'} </div>;
  };

  handleOnDrop = ([file]) => {
    this.props.onDrop({ [this.props.name]: file });
  };
  render() {
    return (
      <Dropzone id="dropzone" name="dropzone" onDrop={this.handleOnDrop}>
        {this.handleRenderPreview()}
      </Dropzone>
    );
  }
}

DropZone.propTypes = {
  dropFile: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  isUploading: propTypes.bool,
  dropText: propTypes.string,
  onDrop: propTypes.func,
  name: propTypes.func,
};

DropZone.defaultProps = {
  name: 'image',
};

export default connect()(DropZone);
