import moment from '~/Providers/Moment';
import { updateChecklistState } from '~/Context/Checklist';
import { DATE_FORMAT } from '~/Redux/Constants';
import { MUST_DO, CUSTOM, OTHER, WEEK_TODO, MONTH_TODO } from '~/Config/Checklists';
import {
  FETCH_ALL,
  FETCH_ALL_SUCCESS,
  FETCH_ALL_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  SNOOZE,
  SNOOZE_SUCCESS,
  SNOOZE_FAILURE,
  UNSNOOZE,
  UNSNOOZE_SUCCESS,
  UNSNOOZE_FAILURE,
  SET_QUEUE,
  UPDATE_ITEM,
  START_UPDATING,
  FINISH_UPDATING,
  SET_LAST_UPDATE,
} from './constants';
import { identifierForChecklist } from './helpers';

const initialState = {
  isFetching: false,
  [MUST_DO]: {},
  [CUSTOM]: {},
  [OTHER]: {},
  [MONTH_TODO]: {},
  [WEEK_TODO]: {},
  actionType: false,
  fetchingIdentifier: false,
  isUpdating: false,
  queue: [],
  lastUpdates: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL:
      return { ...state, isFetching: true };
    case FETCH_ALL_SUCCESS:
      return { ...state, isFetching: false, ...action.data };
    case FETCH_ALL_FAILURE:
      return { ...state, isFetching: false };

    case FETCH_ONE:
      return {
        ...state,
        fetchingIdentifier: action.fetchingIdentifier,
      };

    case FETCH_ONE_SUCCESS:
      return {
        ...state,
        fetchingIdentifier: false,
        ...updateChecklistState(
          state,
          action.data.type,
          moment(action.data.date, DATE_FORMAT).format(DATE_FORMAT),
          action.data
        ),
      };

    case FETCH_ONE_FAILURE:
      return { ...state, isFetching: false, fetchingIdentifier: false };

    case SET_QUEUE:
      return { ...state, queue: action.queue };

    case START_UPDATING:
      return { ...state, isUpdating: true };

    case FINISH_UPDATING:
      return { ...state, isUpdating: false };

    case SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdates: {
          ...state.lastUpdates,
          [identifierForChecklist(action.data)]: action.data,
        },
      };

    case UPDATE_ITEM: {
      const date = moment(action.data.date, DATE_FORMAT).format(DATE_FORMAT);
      const type = action.data.type;
      return {
        ...state,
        ...updateChecklistState(state, type, date, action.data),
      };
    }
    case UNSNOOZE:
    case SNOOZE: {
      return { ...state, isFetching: true, actionType: action.listType };
    }
    case UNSNOOZE_SUCCESS:
    case SNOOZE_SUCCESS: {
      console.log(action.type, state, action);
      return {
        ...state,
        isFetching: false,
        actionType: false,
        ...updateChecklistState(state, action.listType, action.date, action.data),
      };
    }
    case UNSNOOZE_FAILURE:
    case SNOOZE_FAILURE: {
      return { ...state, isFetching: false, actionType: false };
    }

    default:
      return state;
  }
}
