import React, { Component } from 'react';
import propTypes from 'prop-types';

import SideBar from '~/Components/Layout/ApplicationNavigation/SideBar/SideBar';
import AuthRoute from '~/Containers/Global/Routes/AuthRoute';
import { ADMIN } from '~/Config/Roles';
/**
 * Admin Pages
 */
import Dashboard from '~/Components/Pages/Admin/DashBoard/DashBoard';
import AdminUsers from '~/Components/Pages/Admin/AdminUsers/AdminUsers';
import AdminEditUsers from '~/Components/Pages/Admin/AdminUsers/AdminEditUsers';
import AdminSkins from '~/Containers/Pages/Admin/AdminSkins/AdminSkins';
import AdminEditSkins from '~/Containers/Pages/Admin/AdminSkins/AdminEditSkins';
import AdminStickers from '~/Containers/Pages/Admin/AdminStickers/AdminStickers';
import AdminStickerPacks from '~/Containers/Pages/Admin/AdminStickerPacks/AdminStickerPacks';
import AdminEditStickerPacks from '~/Components/Pages/Admin/AdminStickerPacks/AdminEditStickerPacks';

class AdminLayout extends Component {
  componentWillUnmount = () => {
    this.props.clearMenu();
  };

  render() {
    const { match, routes, location } = this.props;
    return (
      <SideBar path={location.pathname} routes={routes}>
        <AuthRoute
          roles={[ADMIN]}
          path={`${match.url}/dashboard`}
          label="Dashboard"
          exact
          component={Dashboard}
        />
        <AuthRoute
          roles={[ADMIN]}
          path={`${match.url}/users`}
          label="Users"
          exact
          component={AdminUsers}
        />
        <AuthRoute
          roles={[ADMIN]}
          path={`${match.url}/users/:userId`}
          exact
          component={AdminEditUsers}
        />
        <AuthRoute
          roles={[ADMIN]}
          path={`${match.url}/stickers`}
          label="Stickers"
          exact
          component={AdminStickers}
        />
        <AuthRoute
          roles={[ADMIN]}
          path={`${match.url}/sticker-packs`}
          label="Sticker Packs"
          exact
          component={AdminStickerPacks}
        />
        <AuthRoute
          roles={[ADMIN]}
          path={`${match.url}/sticker-packs/:stickerPackId`}
          exact
          component={AdminEditStickerPacks}
        />
        <AuthRoute
          roles={[ADMIN]}
          path={`${match.url}/skins`}
          label="Skins"
          exact
          component={AdminSkins}
        />
        <AuthRoute
          roles={[ADMIN]}
          path={`${match.url}/skins/:skinId`}
          exact
          component={AdminEditSkins}
        />
      </SideBar>
    );
  }
}

AdminLayout.propTypes = {
  location: propTypes.object,
  match: propTypes.object,
  routes: propTypes.array,
  clearMenu: propTypes.func,
};

export default AdminLayout;
