import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from '~/Providers/Moment';
import history from '~/Providers/History';
import { DATE_TIME_FORMAT } from '~/Redux/Constants';

class TrialPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dismissed: false,
    };
  }

  shouldRender() {
    const { dismissed } = this.state;
    const { user } = this.props;
    if (dismissed) return false;
    if (!user) return false;
    return user.isOnTrial && !user.subscriptionExists;
  }

  handleClick = () => {
    this.setState({ dismissed: true });
  };

  handleTrialClick = () => {
    this.setState({ dismissed: true });
    history.push('/subscription');
  };

  render() {
    if (!this.shouldRender()) return null;
    const { user } = this.props;
    return (
      <div className="trial-popup">
        <div className="trial-popup__dismiss" onClick={this.handleClick}>
          x
        </div>
        <div className="trial-popup__header">
          Your trial lasts for {moment(user.trial_ends_at, DATE_TIME_FORMAT).diff(moment(), 'days')}{' '}
          more days!
        </div>
        <div className="trial-popup__body">
          <p>
            Make sure you can keep designing your best life and{' '}
            <a onClick={this.handleTrialClick}>select a plan now</a>.
          </p>
        </div>
      </div>
    );
  }
}

TrialPopup.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ User, Account }) => {
  return {
    user: User.data,
    account: Account.data,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TrialPopup);
