import urlParser from 'url';
import queryString from 'query-string';
import axios from 'axios';
import history from '~/Providers/History';
import Storage from '~/Providers/Storage';

export default class Http {
  constructor({ host = false, options = {} }) {
    this.host = host ? urlParser.parse(host).protocol + '//' + urlParser.parse(host).host : false;
    this.options = options;
  }

  // Override this in implementations to set headers at request-time
  getHeaders() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    return headers;
  }

  resolveUrl(url) {
    if (!this.host) return url;

    const parsedUrl = urlParser.parse(url);

    return urlParser.resolve(
      parsedUrl.host ? `${parsedUrl.protocol}//${parsedUrl.host}` : this.host,
      urlParser.parse(url).path
    );
  }

  parseJsonError = (json, status) => {
    if (status === 401) {
      if (!Storage.get('Auth.isAuthenticated') && window.location.pathname !== '/login') {
        history.push('/login');
      }
    }
    throw { ...json.errors, status: status };
  };

  async handleRequest(url, options = {}) {
    const requestOptions = { ...this.options, ...options };
    requestOptions.headers = this.getHeaders();
    const token = localStorage.getItem('access_token');

    if (token) {
      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const response = await fetch(this.resolveUrl(url), requestOptions);
    const json = await response.json();

    // Throw responses that returned HTTP error codes
    if (response.status > 299) throw this.parseJsonError(json, response.status);

    return json;
  }

  handleResponse = response => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
  };

  get(url, queryObject = false) {
    const query = queryObject ? `?${queryString.stringify(queryObject)}` : '';
    return this.handleRequest(url + query);
  }

  post(url, body) {
    return this.handleRequest(url, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        ContentType: 'application/json',
      },
    });
  }

  put(url, body) {
    return this.handleRequest(url, {
      method: 'put',
      body: JSON.stringify(body),
    });
  }

  delete(url) {
    return this.handleRequest(url, { method: 'delete' });
  }

  async upload(url, data, onProgress) {
    url = this.resolveUrl(url);
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (onProgress) {
            onProgress(percentCompleted);
          }
        },
      });

      if (response.status > 299) {
        throw { ...response.data.errors, status: response.status };
      } else {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
}
