import { connect } from 'react-redux';

import ApplicationNavigation from '~/Components/Layout/ApplicationNavigation/ApplicationNavigation';

import { fetchAll } from '~/Services/Container';

import { MY_ACCOUNT_MODAL, REPORT_PROBLEM_MODAL } from '~/Config/Modals';

import { setMonthType } from '~/Redux/Modules/DateRange';
import { openModal } from '~/Redux/Modules/Modal';
import { getCalendarAccounts } from '~/Redux/Modules/CalendarAccounts';
import moment from '~/Providers/Moment';

const mapStateToProps = ({ CalendarAccounts, DateRange, User, UserOptions }) => {
  return {
    accounts: CalendarAccounts.data,
    start: DateRange.start,
    end: DateRange.end,
    currentType: DateRange.currentType,
    user: User.data,
    currentMonth: moment().startOf('month'),
    useIntlDates: UserOptions.data.use_intl_dates,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    monthView: monthStart => dispatch(setMonthType(monthStart.clone())),
    onClickMyAccount: () => dispatch(openModal(MY_ACCOUNT_MODAL)),
    onClickReportProblem: () => dispatch(openModal(REPORT_PROBLEM_MODAL)),
    fetchAll: () => fetchAll([getCalendarAccounts]),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationNavigation);
