/* eslint-disable max-len */
import React from 'react';

export default function HabitBikeIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.558 12.155a6 6 0 0 0-2.1.385l-1.79-2.924.544-1.308h.233c.467 0 .778-.307.778-.769s-.311-.769-.778-.769h-2.333c-.467 0-.777.307-.777.769s.31.769.777.769h.39l-.311.769h-7.08V6h-3.89v1.539h2.334v2.154l-1.4 2.769c-.544-.23-1.088-.307-1.71-.307C3.41 12.155 1 14.539 1 17.539c0 3 2.411 5.385 5.444 5.385 3.034 0 5.445-2.385 5.445-5.385a5.236 5.236 0 0 0-2.334-4.385l.934-1.77 4.9 7h2.8A5.423 5.423 0 0 0 23.556 23C26.59 23 29 20.616 29 17.616c.002-3.077-2.409-5.461-5.442-5.461zm-13.224 5.384c0 2.154-1.71 3.847-3.89 3.847-2.177 0-3.89-1.692-3.89-3.847 0-2.153 1.712-3.847 3.89-3.847.311 0 .7.077 1.011.155l-1.71 3.385c-.078.076-.078.153-.078.307 0 .462.31.769.777.769.311 0 .544-.154.7-.462l1.711-3.385c.856.77 1.48 1.848 1.48 3.078zm9.8-4.154c-1.01.847-1.71 2-1.943 3.385h-1.244l2.022-5.308 1.166 1.923zm.778 1.308 1.244 2.077h-2.41c.155-.846.622-1.539 1.166-2.077zm-5.367 1.23-3.734-5.307h5.834l-2.1 5.308zm8.013 5.463a3.901 3.901 0 0 1-3.811-3.077l3.81-.001c.467 0 .778-.307.778-.769a.582.582 0 0 0-.156-.385l-1.944-3.23c.39-.155.856-.232 1.323-.232 2.177 0 3.89 1.692 3.89 3.847-.002 2.154-1.713 3.847-3.89 3.847z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
