import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { CONFIRM_COLOR_REMOVAL_MODAL } from '~/Config/Modals';
import { addColor } from '~/Redux/Modules/Colors';
import { openModal, setModalData } from '~/Redux/Modules/Modal';

import AddColorButton from './AddColorButton';
import AdjustButton from './AdjustButton';
import ColorButton from './ColorButton';
import EditColorsButton from './EditColorsButton';
import HexInput from './HexInput';

import './ColorButtonGrid.scss';

const MAX_COLORS = 23;

const ColorButtonGrid = ({
  selectedColor,
  colors,
  onSelect,
  circleButtons,
  isAdjustable,
  isGrowable,
  isEditable,
}) => {
  const dispatch = useDispatch();

  const [adjustValue, setAdjustValue] = useState(selectedColor);
  const [addValue, setAddValue] = useState(selectedColor);
  const [isEditing, setIsEditing] = useState(false);

  const maxReached = colors.length >= MAX_COLORS;

  // Listener Callbacks
  const handleClick = ({ target: { dataset } }) => {
    const { id, color } = dataset;

    if (isEditing && id) {
      dispatch(setModalData(dataset));
      dispatch(openModal(CONFIRM_COLOR_REMOVAL_MODAL));
    } else {
      onSelect(color);
    }
  };

  const handleHexChange = useCallback(
    color => {
      onSelect('#' + color);
    },
    [onSelect]
  );

  const handleAdjustInput = ({ target: { value } }) => {
    setAdjustValue(value);
  };

  const handleAddInput = ({ target: { value } }) => {
    if (!maxReached) {
      setAddValue(value);
    }
  };

  const toggleIsEditing = () => {
    setIsEditing(currentValue => !currentValue);
  };

  const handleAddRequest = color => {
    dispatch(addColor(color));
  };

  return (
    <section className="color-button-grid">
      {colors.map((color, index) => {
        return (
          <ColorButton
            key={color + index}
            color={color}
            onClick={handleClick}
            isCircle={circleButtons}
            isSelected={color === selectedColor || color.hex === selectedColor}
            isEditable={isEditing}
          />
        );
      })}
      {isGrowable && !maxReached && (
        <AddColorButton
          value={addValue}
          onInput={handleAddInput}
          onChange={handleAddRequest}
          isCircle={circleButtons}
        />
      )}
      {isEditable && <EditColorsButton onChange={toggleIsEditing} isCircle={circleButtons} />}
      {isAdjustable && (
        <>
          <HexInput value={selectedColor} onChange={handleHexChange} />
          <AdjustButton
            value={adjustValue}
            selectedColor={selectedColor}
            onInput={handleAdjustInput}
            onChange={onSelect}
          />
        </>
      )}
    </section>
  );
};

ColorButtonGrid.propTypes = {
  selectedColor: propTypes.string,
  colors: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.string).isRequired,
    propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string.isRequired,
        hex: propTypes.string.isRequired,
      })
    ),
  ]),
  onSelect: propTypes.func.isRequired,
  circleButtons: propTypes.bool,
  isAdjustable: propTypes.bool,
  isGrowable: propTypes.bool,
  isEditable: propTypes.bool,
};

ColorButtonGrid.defaultProps = {
  circleButtons: false,
  isAdjustable: false,
  isGrowable: false,
  isEditable: false,
};

export default ColorButtonGrid;
