import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '~/Components/Global/Form/Button';
import Checkbox from '~/Components/Global/Form/Checkbox';
import ArtfulCheckbox from '~/Components/Global/Form/ArtfulCheckbox';
import { hideRepeatRulePopup } from '~/Redux/Modules/Tasks';
import { MUST_DO, OTHER, CUSTOM, MONTH_TODO, WEEK_TODO } from '~/Config/Checklists';
import { showConfirmWithSettings } from '~/Redux/Modules/EventConfirmation';
import RepeatRule from './RepeatRule';
import ToggleTaskRollover from './ToggleTaskRollover';

import './RepeatRulePopup.scss';

const RepeatRulePopup = () => {
  const { show, positionRect, repeatable, onSave, onCancel } = useSelector(
    ({ Tasks: { repeatPopup } }) => repeatPopup
  );
  const [repeatInternal, setRepeatInternal] = useState();
  const dispatch = useDispatch();
  const userOptions = useSelector(({ UserOptions }) => UserOptions.data);
  const globalRolloverSetting = userOptions?.auto_rollover;
  const currentBox = useRef();
  const [hasBox, setHasBox] = useState(false);

  useEffect(() => {
    setRepeatInternal({
      ...repeatable,
      // repeat_rule:
      //   repeatable?.repeat_rule || defaultRepeatRulesForType[repeatable?.type || MUST_DO],
    });
  }, [repeatable]);
  if (!show) return null;

  const handleCancel = () => {
    dispatch(hideRepeatRulePopup());
    onCancel?.();
  };

  const onConfirmed = confirm => {
    if (repeatInternal.auto_rollover === undefined) {
      console.log('ugh its undefined');
      repeatInternal.auto_rollover = 0;
    }
    dispatch(hideRepeatRulePopup());
    onSave?.(repeatInternal?.repeat_rule, confirm, repeatInternal.auto_rollover);
  };

  const handleSave = () => {
    if (repeatable.repeat_rule) {
      dispatch(
        showConfirmWithSettings({
          heading: 'Edit recurring task',
          onConfirm: onConfirmed,
          model: 'Task',
        })
      );
    } else {
      onConfirmed('all');
    }
  };

  const handleChange = repeat_rule => {
    setRepeatInternal({ ...repeatInternal, repeat_rule });
    setTimeout(() => {
      setRepeatInternal({ ...repeatInternal, repeat_rule });
    });
  };

  const handleAutoRolloverChange = ar_value => {
    if (ar_value) {
      setRepeatInternal({ ...repeatInternal, auto_rollover: -1 });
    }
    if (!ar_value) {
      setRepeatInternal({ ...repeatInternal, auto_rollover: 1 });
    }
  };
  const autoRolloverValue = () => {
    if (repeatInternal.auto_rollover === 0) {
      return globalRolloverSetting;
    }
    if (repeatInternal.auto_rollover === -1) {
      return false;
    }
    if (repeatInternal.auto_rollover === 1) {
      return true;
    }
    if (!repeatInternal.auto_rollover) {
      return globalRolloverSetting;
    }
  };

  let style = {};
  if (positionRect) {
    let bottomPadding = 250;
    let rightPadding = 125;
    if (currentBox.current) {
      const { width, height } = currentBox.current.getBoundingClientRect();
      bottomPadding = height;
      rightPadding = width / 2;
    }

    style = {
      ...style,
      position: 'absolute',
      top:
        positionRect.top > window.innerHeight - bottomPadding
          ? window.innerHeight - bottomPadding
          : positionRect.top,
      left:
        positionRect.left > window.innerWidth - rightPadding
          ? window.innerWidth - rightPadding
          : positionRect.left,
      transform: 'translateX(-50%)',
    };
  }

  return (
    <div className="repeat-rule-popup-wrapper" onClick={handleCancel}>
      <div
        className="repeat-rule-popup"
        style={style}
        onClick={e => e.stopPropagation()}
        ref={element => {
          if (!currentBox.current) {
            setTimeout(() => setHasBox(true));
          }
          currentBox.current = element;
        }}
      >
        <div className="repeat-rule-popup__body">
          <RepeatRule
            repeatable={repeatInternal}
            onChange={handleChange}
            singleColumn
            weekOnly={repeatable?.type == WEEK_TODO}
            monthOnly={repeatable?.type == MONTH_TODO}
            allDay
            allowNever
          />
        </div>
        <div className={`add-flex-row  ${!repeatInternal.auto_rollover ? `half-opacity` : ''}`}>
          <ToggleTaskRollover
            value={autoRolloverValue()}
            handleAutoRolloverChange={handleAutoRolloverChange}
          />
        </div>

        <div className="repeat-rule-popup__buttons">
          <Button text="CANCEL" className="btn btn-default" onClick={handleCancel} />
          <Button text="SAVE" className="btn btn-default" onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};

export default RepeatRulePopup;
