/* eslint-disable max-len */
import React from 'react';

export default function HabitRunningIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.125 7.5a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M25.688 9.937a.906.906 0 0 0-1.313 0l-2.125 2.125-3.375-3.375c-.187-.188-.437-.25-.687-.25h-3.126c-.25 0-.5.125-.688.25l-3.125 3.125a.906.906 0 0 0 0 1.313.906.906 0 0 0 1.312 0l2.875-2.875h1.937l-5.998 6H6.25c-.688 0-1.25.562-1.25 1.25s.562 1.25 1.25 1.25h5.625c.313 0 .624-.125.875-.374l1.626-1.626 2.062 2.063-.813 4.75a1.22 1.22 0 0 0 1 1.437h.187c.624 0 1.125-.437 1.25-1.063L19 18.625a1.317 1.317 0 0 0-.375-1.125l-1.75-1.75L20 12.626l1.438 1.438c.188.188.438.25.688.25.25 0 .5-.062.688-.25l2.813-2.812c.373-.314.373-.939.06-1.314z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
