/* eslint-disable max-len */
import React from 'react';

export default function HabitPhoneIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.334 5h-6.667C10.722 5 10 5.764 10 6.765v16.47c0 .941.722 1.765 1.667 1.765h6.667c.944 0 1.666-.824 1.666-1.765V6.765c0-1-.722-1.765-1.666-1.765zM15 24.412c-.611 0-1.111-.53-1.111-1.177s.5-1.177 1.111-1.177c.612 0 1.112.53 1.112 1.177 0 .648-.5 1.177-1.112 1.177zm3.889-2.94h-7.777V7.942h7.778l-.001 13.53z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
