import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';

import HabitCheckmarkIcon from '../Assets/Svgs/HabitCheckmarkIcon';

import './ColorButton.scss';

function ColorButton({ color, onClick, isCircle, isSelected, isEditable }) {
  const id = color.id;
  const hex = color.hex ?? color;

  return (
    <div className="color-button-wrapper">
      <div
        className={cn('color-button', { circle: isCircle })}
        role="button"
        tabIndex={0}
        data-id={id}
        data-color={hex}
        onClick={onClick}
        style={{ backgroundColor: hex }}
      >
        <HabitCheckmarkIcon className={cn('checkmark', { visible: isSelected })} />
      </div>
      {isEditable && (
        <div className="delete-icon-wrapper" onClick={onClick} data-id={id} data-color={hex}>
          X
        </div>
      )}
    </div>
  );
}

ColorButton.propTypes = {
  color: propTypes.oneOfType([
    propTypes.string.isRequired,
    propTypes.shape({
      id: propTypes.string.isRequired,
      hex: propTypes.string.isRequired,
    }),
  ]),
  onClick: propTypes.func,
  isCircle: propTypes.bool,
  isSelected: propTypes.bool,
  isEditable: propTypes.bool,
};

ColorButton.defaultProps = {
  isCircle: false,
  isSelected: false,
  isEditable: false,
};

export default ColorButton;
