import Quill from 'quill';
import './QuillOverrides.js';
import Delta from 'quill-delta';
import { merge, cloneDeep } from 'lodash';

const urlRegex = /(https?:\/\/)?([-a-zA-Z0-9@:%_\+~#=]{1,256}\.)+[a-z]{2,6}([-a-zA-Z0-9@:%_\+.~#?!&//=]*)/g;
const linkHandler = function(range) {
  const text = this.quill.getText(0, range.index);
  let delta = new Delta();
  let match;
  let url;
  let prevOffset = 0;
  while ((match = urlRegex.exec(text)) !== null) {
    url = match[0];
    let target = url;
    if (!target.startsWith('http')) target = `http://${target}`;
    delta
      .retain(match.index - prevOffset)
      .delete(url.length)
      .insert(url, { link: target });
    prevOffset = match.index + url.length;
  }
  this.quill.updateContents(delta, Quill.sources.USER);
  prevOffset = range.index;
  return true;
};

const linkMatcher = (node, delta) => {
  if (typeof node.data !== 'string') return;
  const matches = node.data.match(urlRegex);

  if (matches && matches.length > 0) {
    let str = node.data;
    let newDelta = new Delta();
    matches.forEach(function(match) {
      let split = str.split(match);
      let beforeLink = split.shift();
      let target = match;
      if (!target.startsWith('http')) target = `http://${target}`;
      newDelta.insert(beforeLink).insert(match, { link: target });
      str = split.join(match);
    });
    return newDelta.insert(str);
  }

  return delta;
};

const defaults = {
  theme: 'snow',
  formats: [
    'background',
    'bold',
    'color',
    'font',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'image',
    'video',
  ],
  modules: {
    toolbar: false,
    clipboard: {
      matchers: [[window.Node.TEXT_NODE, linkMatcher]],
      matchVisual: false,
    },
    keyboard: {
      bindings: {
        spaceToLink: {
          collapsed: true,
          key: ' ',
          handler: linkHandler,
        },
        enterToLink: {
          collapsed: true,
          key: 13,
          handler: linkHandler,
        },
        'header enter': {
          key: 13,
          collapsed: true,
          format: ['header'],
          suffix: /^$/,
          handler: function(range, context) {
            let [line, offset] = this.quill.getLine(range.index);
            let delta = new Delta()
              .retain(range.index)
              .insert('\n', context.format)
              .retain(line.length() - offset - 1);

            if (context.offset === 0) {
              delta = delta.retain(1);
            } else {
              delta = delta.retain(1, { header: null });
            }

            this.quill.updateContents(delta, Quill.sources.USER);
            this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            this.quill.scrollIntoView();
          },
        },
      },
    },
  },
};

export default (editor, config) => {
  const mergedOptions = merge(cloneDeep(defaults), config);
  if (config.hasOwnProperty('formats')) mergedOptions.formats = config.formats; // overwrite formats rather than using the merged version
  return new Quill(editor, mergedOptions);
};
