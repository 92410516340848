import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import MyAccountSection from '~/Components/Pages/Account/MyAccountSection';
import HorizontalTextInput from '~/Components/Global/Form/HorizontalTextInput';
import Form from '~/Components/Global/Form/Form';
import Spinner from '~/Components/Global/Loading/Spinner';
import { unlockCodeApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';

const UnlockCode = () => {
  const [currentCode, setCurrentCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setIsSubmitting(true);
    unlockCodeApi
      .consume({ code: currentCode })
      .then(() => {
        dispatch(success('Unlock code verified!'));
        setCurrentCode('');
        setIsSubmitting(false);
      })
      .catch(err => {
        console.log(err);
        dispatch(error('Failed to verify unlock code', err.message || parseErrors(err)));
        setIsSubmitting(false);
      });
  };

  return (
    <MyAccountSection header="Redeem Cover Code">
      <Form handleSubmit={handleSubmit} className="form-horizontal">
        <HorizontalTextInput
          label="Cover Code"
          name="unlock_code"
          value={currentCode}
          disabled={isSubmitting}
          handleChange={({ unlock_code }) => {
            setCurrentCode(unlock_code);
          }}
        />
        <div className="text-right">
          <button type="submit" className="btn btn-default" disabled={isSubmitting}>
            {isSubmitting ? <Spinner /> : 'Redeem'}
          </button>
        </div>
      </Form>
    </MyAccountSection>
  );
};

export default UnlockCode;
