import { connect } from 'react-redux';

import RequireAuth from '~/Components/Global/Auth/RequireAuth';

const mapStateToProps = ({ Auth }) => {
  return {
    isAuthenticated: Auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(RequireAuth);
