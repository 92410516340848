import { fontApi } from '~/Providers/Api';

const FETCH = 'artful-client/Google/FETCH';
const FETCH_SUCCESS = 'artful-client/Google/FETCH_SUCCESS';
const FETCH_FAILURE = 'artful-client/Google/FETCH_FAILURE';

const initialState = {
  isFetching: false,
  data: [],
  error: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      return { ...state, isFetching: false, data: action.data };
    case FETCH_FAILURE:
      return { ...state, isFetching: false, error: action.error };
    default:
      return state;
  }
}

export const getFonts = data => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await fontApi.fetch(data);
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const allFonts = state => state.Fonts.data;
