import React from 'react';
import propTypes from 'prop-types';

import TextInput from '~/Components/Global/Form/TextInput';
import Button from '~/Components/Global/Form/Button';
import DropZone from '~/Components/Global/Form/DropZone';

const StickerForm = ({ data, url, onChange, onSubmit, isAdding }) => (
  <div className="margin-top margin-bottom">
    <div className="flex-row">
      <div className="flex-1">
        <TextInput name="name" placeholder="Sticker Name" value={data.name} onChange={onChange} />
      </div>
      <div className="flex-1 center">
        <DropZone
          onDrop={onChange}
          dropFile={data.image}
          dropImagePath={url}
          dropText="Drop or Click to upload image"
          key="image"
          isUploading={isAdding}
        />
      </div>
    </div>
    <Button
      text="Add Sticker"
      actionText="Adding Sticker"
      onClick={onSubmit}
      isFetching={isAdding}
    />
  </div>
);

StickerForm.defaultProps = {
  url: '',
};

StickerForm.propTypes = {
  data: propTypes.object,
  url: propTypes.string,
  onChange: propTypes.func,
  onSubmit: propTypes.func,
  isAdding: propTypes.bool,
};

export default StickerForm;
