import { connect } from 'react-redux';

import SubscribedRoute from '~/Components/Global/Routes/SubscribedRoute';
import { sendGAClientId, sendDeviceId } from '~/Redux/Modules/User';

const mapStateToProps = ({ User, Auth }) => {
  return {
    isAuthenticated: Auth.isAuthenticated,
    isSubscribed: User.data.isSubscribed,
    isFetching: User.isFetching,
    hasFetched: User.hasFetched,
    authIsFetching: Auth.isFetching,
    authHasFetched: Auth.hasFetched,
    user: User.data,
    sentGAClientId: User.sentGAClientId,
    sentDeviceId: User.sentDeviceId,
  };
};

const mapDispatchToProps = dispatch => ({
  sendGAClientId: () => dispatch(sendGAClientId()),
  sendDeviceId: () => dispatch(sendDeviceId()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribedRoute);
