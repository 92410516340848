import React, { useState } from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';
import { DropTarget, DragSource } from 'react-dnd';
import FontAwesome from 'react-fontawesome';
import Dropzone from 'react-dropzone';
import cn from 'classnames';

import TextInput from '~/Components/Global/Form/TextInput';
import Checkbox from '~/Components/Global/Form/Checkbox';
import Button from '~/Components/Global/Form/Button';
import { guid } from '~/util';

const StickerPackForm = ({
  onChange,
  data,
  stickers,
  isUpdating,
  onSubmit,
  buttonText,
  actionText,
}) => {
  const [dragging, setDragging] = useState(false);

  // eslint-disable-next-line react/prop-types
  const renderOption = ({ id, name, url }) => {
    return (
      <div className="sticker-option" key={id}>
        <img src={url} />
        <span>{name}</span>
      </div>
    );
  };

  const onChangeSticker = sticker => {
    onChange({
      ...data,
      stickers: data.stickers.map(s => (s.id === sticker.id ? sticker : s)),
    });
  };

  const onDropped = (next, item) => {
    console.log('onDropped', 'place', item, 'before', next);

    const indexOfItem = data.stickers.indexOf(item);
    const stickersWithoutItem = [
      ...data.stickers.slice(0, indexOfItem),
      ...data.stickers.slice(indexOfItem + 1),
    ];
    const indexOfNext = next ? stickersWithoutItem.indexOf(next) : stickersWithoutItem.length;
    const newStickers = [
      ...stickersWithoutItem.slice(0, indexOfNext),
      item,
      ...stickersWithoutItem.slice(indexOfNext),
    ];
    onChange({
      stickers: newStickers.map((sticker, index) => ({
        ...sticker,
        order: index,
      })),
    });
  };

  const addStickers = files => {
    Promise.all(
      files.map(file => {
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            resolve(reader.result);
          });
          reader.readAsDataURL(file);
        });
      })
    ).then(results => {
      onChange({
        ...data,
        stickers: [
          ...data.stickers,
          ...files.map((image, i) => ({
            image: results[i],
            url: image.preview,
            id: `new/${guid()}`,
          })),
        ],
      });
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    onSubmit().catch(err => console.error('StickerPackForm', err));
  };

  return (
    <form
      className={cn('sticker-pack-form margin-top margin-bottom', { dragging })}
      onSubmit={handleSubmit}
    >
      <TextInput name="name" label="Name" value={data.name} onChange={onChange} />
      <TextInput name="price" label="Price" value={data.price} onChange={onChange} />
      <TextInput name="sku" label="Mobile Product Id" value={data.sku} onChange={onChange} />
      <div>
        <Checkbox label="Active Status?" name="status" value={data.status} onChange={onChange} />
      </div>
      <div>
        <Checkbox
          label="Fundraiser?"
          name="is_fundraiser"
          value={data.is_fundraiser}
          onChange={onChange}
        />
      </div>
      <TextInput
        name="unlock_code"
        label="Unlock Code"
        value={data.unlock_code}
        onChange={onChange}
      />

      {stickers && (
        <fieldset>
          <legend>Stickers</legend>

          <div className="stickers">
            {(data.stickers || [])
              .filter(({ _remove }) => !_remove)
              .map((sticker, i) => (
                <StickerRow
                  key={sticker.id || i}
                  sticker={sticker}
                  onChangeSticker={onChangeSticker}
                  onDropped={onDropped}
                  setDragging={setDragging}
                />
              ))}
            {dragging && <EndStickerRow onDropped={onDropped} />}
          </div>

          <Dropzone
            name="newStickers"
            className="sticker-drop"
            onDrop={files => addStickers(files)}
            accept="image/jpeg, image/png"
            disableClick
            disabled={dragging}
          >
            {({ isDragActive }) => {
              return (
                <>
                  <label htmlFor="stickers">Add Stickers</label>
                  <Select
                    closeOnSelect={false}
                    placeholder="Select Sticker or Drag and Drop Files"
                    name="stickers"
                    onChange={newValue =>
                      onChange({
                        stickers: [
                          ...data.stickers,
                          {
                            ...newValue,
                          },
                        ],
                      })
                    }
                    optionRenderer={renderOption}
                    valueRenderer={renderOption}
                    options={stickers.data.filter(
                      ({ id }) => !data.stickers.filter(sticker => sticker.id === id).length
                    )}
                  />
                  {isDragActive && (
                    <div className="sticker-drop-overlay">Drop files to add stickers</div>
                  )}
                </>
              );
            }}
          </Dropzone>
        </fieldset>
      )}
      <div className="actions">
        <Button type="submit" text={buttonText} actionText={actionText} isFetching={isUpdating} />
      </div>
    </form>
  );
};

StickerPackForm.defaultProps = {
  url: '',
};

StickerPackForm.propTypes = {
  data: propTypes.object,
  onChange: propTypes.func,
  onSubmit: propTypes.func,
  stickers: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.oneOfType([propTypes.number, propTypes.string]),
      name: propTypes.string,
      url: propTypes.string,
    })
  ),
  getNewRecords: propTypes.func,
  isUpdating: propTypes.bool,
  buttonText: propTypes.string,
  actionText: propTypes.string,
};

const _StickerRow = ({
  sticker,
  connectDropTarget,
  connectDragPreview,
  connectDragSource,
  isDragging,
  isOver,
  onChangeSticker,
}) => {
  const { name, tags, url } = sticker;
  return connectDropTarget(
    connectDragPreview(
      <div className={cn('sticker-row', { dragging: isDragging, over: isOver })}>
        {connectDragSource(
          <div className="drag-handle">
            <FontAwesome name="bars" />
          </div>
        )}
        <img className="sticker-image" src={url} />
        <TextInput
          name="name"
          label="Name"
          placeholder="Name"
          labelClassName="sr-only"
          value={name}
          required
          onChange={change => onChangeSticker({ ...sticker, ...change })}
        />
        <TextInput
          name="tags"
          placeholder="Tags"
          label="Tags"
          labelClassName="sr-only"
          value={tags}
          onChange={change => onChangeSticker({ ...sticker, ...change })}
        />
        <Button
          className="remove-sticker"
          onClick={() => onChangeSticker({ ...sticker, _remove: true })}
          text="Remove"
        />
      </div>
    )
  );
};

const _EndStickerRow = ({ connectDropTarget, isOver }) => {
  return connectDropTarget(<div className={cn('sticker-row end-row', { over: isOver })} />);
};

const stickerTarget = {
  drop: (component, monitor) => {
    console.log('StickerPackForm', 'drop', component, monitor);
    component.onDropped(component.sticker, monitor.getItem().sticker);
  },
};

const stickerDrag = {
  beginDrag: ({ sticker, setDragging }) => {
    setDragging(true);
    return {
      sticker,
    };
  },
  endDrag: ({ setDragging }) => {
    setDragging(false);
  },
};

const dragCollect = (connect, monitor) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const StickerRow =
  _StickerRow
  |> DropTarget('ADMIN_STICKERS', stickerTarget, dropCollect)
  |> DragSource('ADMIN_STICKERS', stickerDrag, dragCollect);

const EndStickerRow = _EndStickerRow |> DropTarget('ADMIN_STICKERS', stickerTarget, dropCollect);

export default StickerPackForm;
