/* eslint-disable max-len */
import React from 'react';

export default function HabitDollarSignIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.918 13.213c-1.89-.705-2.667-1.169-2.667-1.896 0-.618.467-1.236 1.911-1.236 1.6 0 2.624.507 3.201.75l.645-2.492c-.733-.354-1.734-.662-3.223-.728V5.67h-2.177v2.097c-2.377.464-3.755 1.985-3.755 3.927 0 2.14 1.623 3.242 4 4.036 1.645.551 2.355 1.08 2.355 1.92 0 .882-.866 1.367-2.132 1.367-1.446 0-2.756-.463-3.689-.97l-.667 2.58c.844.486 2.288.882 3.777.95v2.093h2.178v-2.248c2.554-.442 3.955-2.118 3.955-4.08-.002-1.99-1.07-3.202-3.712-4.128z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
