/* eslint-disable max-len */
import React from 'react';

export default function HabitHomeIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.631 14.922a.502.502 0 0 0 .107.361l.952 1.136a.519.519 0 0 0 .322.17.575.575 0 0 0 .368-.108l10.451-8.715a.276.276 0 0 1 .339 0l10.45 8.714a.47.47 0 0 0 .322.107h.046a.525.525 0 0 0 .322-.169l.952-1.136a.496.496 0 0 0 .107-.36.443.443 0 0 0-.168-.33l-3.362-2.793V5.537a.475.475 0 0 0-.49-.49h-2.947a.476.476 0 0 0-.492.49V8.53L16.166 5.4A1.79 1.79 0 0 0 15 5c-.45 0-.839.133-1.166.398L2.798 14.592a.444.444 0 0 0-.167.33z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.336 16.153c1.204-1.01 8.494-7.038 8.494-7.038a.277.277 0 0 1 .34 0l8.502 7.086c.093.078.169.24.169.361v7.337c0 .55 0 .826-.138.963-.137.138-.412.138-.962.138H7.26c-.549 0-.824 0-.961-.138-.138-.137-.138-.412-.138-.963v-.49c.001-2.312.003-6.386.005-6.894 0-.121.076-.284.17-.362zm6.934-.26a.675.675 0 0 0-.675.675v7.141c0 .124.1.225.224.225h4.499c.124 0 .225-.1.225-.225v-7.141a.675.675 0 0 0-.675-.675H13.27z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
