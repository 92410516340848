import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '~/Components/Global/Loading/Spinner';
import MyAccountSection from '~/Components/Pages/Account/MyAccountSection';

const Referrals = ({ user, account, isFetching }) => (
  <MyAccountSection header={'Referrals'}>
    <div className="referrals">
      <div className="referrals__info">
        <div className="referrals__info__code">
          <div className="referrals__info__code__title">Referral Code</div>
          <div className="referrals__info__code__value">{user.referral_code}</div>
        </div>
        {isFetching && <Spinner />}
        {!isFetching && (
          <div className="referrals__info__balance">
            <div className="referrals__info__balance__title">Stripe Balance</div>
            <div className="referrals__info__balance__value">
              ${((account.stripeAccountBalance || 0) / -100).toFixed(2)}
            </div>
          </div>
        )}
        {!isFetching && account.credit_amount_cents != 0 && (
          <div className="referrals__info__balance">
            <div className="referrals__info__balance__title">Credit Balance</div>
            <div className="referrals__info__balance__value">
              ${((account.credit_amount_cents || 0) / 100).toFixed(2)}
            </div>
          </div>
        )}
        <div className="referrals__info__explain">
          You earn 10% of each referral&apos;s first payment.
        </div>
      </div>
      <div className="referrals__details">
        <div className="referrals__details__heading">REFERRED USERS</div>
        {isFetching && <Spinner />}
        {(account.referredUsers || []).map(referred_user => (
          <div key={referred_user.id} className="referrals__details__user">
            <span className="referrals__details__user__name" style={{ textTransform: 'none' }}>
              {referred_user.name}
            </span>
            <span className="referrals__details__user__email" style={{ textTransform: 'none' }}>
              ({referred_user.email})
            </span>
            <span
              className="referrals__details__user__value"
              style={{ textTransform: 'uppercase' }}
            >
              {referred_user.referral_status?.replace('_', ' ')}
            </span>
          </div>
        ))}
      </div>
    </div>
  </MyAccountSection>
);

Referrals.propTypes = {
  user: PropTypes.object,
  account: PropTypes.object,
  closeReferrals: PropTypes.func,
  isFetching: PropTypes.bool,
};

const mapStateToProps = ({ User, Account }) => {
  return {
    user: User.data,
    account: Account.data,
    isFetching: Account.isFetching,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
