import { AccountApi, appleApi, googleApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';
import { handleLogin, setAccessToken } from '~/Redux/Modules/Auth';
import { parseErrors, reduxUpdate } from '~/Redux/Helpers';
import Storage from '~/Providers/Storage';
import mParticle from '~/Providers/mParticle';
import { CONSENT_TYPE_EMAIL } from '~/Components/Global/ConsentRequiredView';
import history from '~/Providers/History';

const GET = 'artful-client/Account/GET';
const GET_SUCCESS = 'artful-client/Account/GET_SUCCESS';
const GET_FAILURE = 'artful-client/Account/GET_FAILURE';

const CREATE = 'artful-client/Account/CREATE';
const CREATE_SUCCESS = 'artful-client/Account/CREATE_SUCCESS';
const CREATE_FAILURE = 'artful-client/Account/CREATE_FAILURE';
const UPDATE_REGISTER_INPUT = 'artful-client/Account/UPDATE_REGISTER_INPUT';

const UPDATE = 'artful-client/Account/UPDATE';
export const UPDATE_SUCCESS = 'artful-client/Account/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'artful-client/Account/UPDATE_FAILURE';
const UPDATE_FIELD_INPUT = 'artful-client/Account/UPDATE_FIELD_INPUT';

const UPDATE_PASSWORD = 'artful-client/Account/UPDATE_PASSWORD';
const UPDATE_PASSWORD_SUCCESS = 'artful-client/Account/UPDATE_PASSWORD_SUCCESS';
const UPDATE_PASSWORD_FAILURE = 'artful-client/Account/UPDATE_PASSWORD_FAILURE';
const UPDATE_PASSWORD_FIELD_INPUT = 'artful-client/Account/UPDATE_PASSWORD_FIELD_INPUT';

export const SET_ACCOUNT = 'artful-client/Account/SET_ACCOUNT';

const initialState = {
  isFetching: false,
  isRegistering: false,
  data: {},
  error: [],
  fieldsRegister: {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    referral_code: '',
    timezone: {
      name: '',
    },
  },
  fieldsUpdate: { name: '', email: '' },
  fieldsUpdatePassword: {
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET:
      return { ...state, isFetching: true };
    case GET_SUCCESS:
      return { ...state, isFetching: false, data: action.data };
    case GET_FAILURE:
      return { ...state, isFetching: false, error: action.error };
    case CREATE:
      return { ...state, isRegistering: true };
    case CREATE_SUCCESS:
      return {
        ...state,
        isRegistering: false,
        fieldsRegister: initialState.fieldsRegister,
      };
    case CREATE_FAILURE:
      return { ...state, isRegistering: false, error: action.error };
    case UPDATE_REGISTER_INPUT:
      return {
        ...state,
        fieldsRegister: reduxUpdate(state.fieldsRegister, action.update),
      };
    case UPDATE:
      return { ...state, isFetching: true };
    case UPDATE_SUCCESS:
      return { ...state, isFetching: false };
    case UPDATE_FAILURE:
      return { ...state, isFetching: false, error: action.error };
    case UPDATE_FIELD_INPUT:
      return {
        ...state,
        fieldsUpdate: { ...state.fieldsUpdate, ...action.update },
      };
    case UPDATE_PASSWORD:
      return { ...state, isFetching: true };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fieldsUpdatePassword: initialState.fieldsUpdatePassword,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        fieldsUpdatePassword: initialState.fieldsUpdatePassword,
      };
    case UPDATE_PASSWORD_FIELD_INPUT:
      return {
        ...state,
        fieldsUpdatePassword: {
          ...state.fieldsUpdatePassword,
          ...action.update,
        },
      };
    case SET_ACCOUNT:
      return { ...state, data: action.account };
    default:
      return state;
  }
}

export const handleGet = () => async dispatch => {
  dispatch({ type: GET });
  try {
    const response = await AccountApi.get();
    dispatch({ type: GET_SUCCESS, data: response.data });
    dispatch({ type: UPDATE_FIELD_INPUT, update: response.data });
    return response;
  } catch (err) {
    dispatch({ type: GET_FAILURE, error: err });
  }
};

export const handleCreate = data => async dispatch => {
  dispatch({ type: CREATE });
  try {
    const response = await AccountApi.register({
      ...data,
      adid: Storage.get('adid'),
      tracking_status: Storage.get('tracking_status'),
    });
    dispatch({ type: CREATE_SUCCESS });
    dispatch(success('Registration success'));
    Storage.set('referralCode', data.referral_code);

    if (data[CONSENT_TYPE_EMAIL]) {
      mParticle.recordMarketingConsent(!data.marketing_opt_out || !data.marketing_opt_out_top);
    }

    dispatch(
      handleLogin(
        {
          current: {
            email: data.email,
            password: data.password,
          },
        },
        `/add-accounts`
      )
    );
    if (window.fbq) {
      console.log('Facebook track lead');
      window.fbq('track', 'Lead');
    }
    return response;
  } catch (err) {
    dispatch({ type: CREATE_FAILURE, error: err });
    dispatch(error('Registration failure', parseErrors(err)));
  }
};

export const handleAppleCreate = (data, redirect = false) => async dispatch => {
  dispatch({ type: CREATE });
  try {
    const response = await appleApi.register({
      ...data,
      client: 'com.artfulagenda.api',
      tracking_status: Storage.get('tracking_status'),
    });
    Storage.set('referralCode', response.data.user.referral_code);
    if (data[CONSENT_TYPE_EMAIL]) {
      mParticle.recordMarketingConsent(!data.marketing_opt_out);
    }
    dispatch(setAccessToken(response.data.token.accessToken));
    dispatch({ type: CREATE_SUCCESS });
    dispatch(success('Registration success'));
    redirect ? history.push(redirect) : history.go('/');

    if (window.fbq) {
      console.log('Facebook track lead');
      window.fbq('track', 'Lead');
    }
    return response;
  } catch (err) {
    console.log(err);
    dispatch({ type: CREATE_FAILURE, error: err });
    if (err.status == 422 && err.message?.includes('already exists')) {
      dispatch(error('Account already exists, please log in'));
      history.push('/login');
      return;
    }
    if (err.message?.includes('cannot be null')) {
      dispatch(
        error('Unable to get registration data from Apple, please try again or register manually.')
      );
      return;
    }
    dispatch(error('Registration failure', parseErrors(err)));
  }
};

export const handleGoogleCreate = (data, redirect = false) => async dispatch => {
  dispatch({ type: CREATE });
  try {
    const response = await googleApi.register({
      ...data,
      code: data.code,
      tracking_status: Storage.get('tracking_status'),
    });
    Storage.set('referralCode', response.data.user.referral_code);
    if (data[CONSENT_TYPE_EMAIL]) {
      mParticle.recordMarketingConsent(!data.marketing_opt_out);
    }
    dispatch(setAccessToken(response.data.token.accessToken));
    dispatch({ type: CREATE_SUCCESS });
    dispatch(success('Registration success'));
    redirect ? history.push(redirect) : history.go('/');

    if (window.fbq) {
      console.log('Facebook track lead');
      window.fbq('track', 'Lead');
    }
    return response;
  } catch (err) {
    if (err.status == 422) {
      dispatch({ type: CREATE_FAILURE, error: err });
      dispatch(error('Account already exists, please log in'));
      history.push('/login');
      return;
    }
    console.log(err);
    dispatch({ type: CREATE_FAILURE, error: err });
    dispatch(error('Registration failure', parseErrors(err)));
  }
};

export const updateRegisterField = update => dispatch =>
  dispatch({ type: UPDATE_REGISTER_INPUT, update });

export const handleUpdate = data => async dispatch => {
  const {
    fieldsUpdate: { ...account },
  } = data;
  dispatch({ type: UPDATE });
  try {
    let response = await AccountApi.update(account);
    dispatch({ type: UPDATE_SUCCESS, data: response.data });
    dispatch(success('Account updated succcessfully'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_FAILURE, error: err });
    dispatch(error('Account update failed', parseErrors(err)));
  }
  dispatch(handleGet());
};

export const handleUpdateFieldInput = update => dispatch =>
  dispatch({ type: UPDATE_FIELD_INPUT, update });

export const handleUpdatePassword = data => async dispatch => {
  const {
    fieldsUpdatePassword: { ...passwords },
  } = data;
  dispatch({ type: UPDATE_PASSWORD });
  try {
    let response = await AccountApi.updatePassword(passwords);
    dispatch({ type: UPDATE_PASSWORD_SUCCESS });
    dispatch(success('Password updated succcessfully'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_PASSWORD_FAILURE, error: err });
    dispatch(error('Password update failed', parseErrors(err)));
  }
};

export const handleUpdatePasswordFieldInput = update => dispatch =>
  dispatch({ type: UPDATE_PASSWORD_FIELD_INPUT, update });
export const setAccount = account => dispatch => dispatch({ type: SET_ACCOUNT, account });
