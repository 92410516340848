import { MONTH, WEEK, DAY, HABIT, periodForDateType } from '~/Config/DateRangeTypes';
import moment from '~/Providers/Moment';

import {
  SET_RANGE_TYPE,
  INCREMENT,
  DECREMENT,
  SET_SYNC_TOKEN,
  CLEAR_SYNC_TOKEN,
  SET_LOCALE,
} from './constants';

const initialState = {
  timezone: moment.tz.guess(),
  start: moment().startOf(MONTH),
  end: moment().endOf(MONTH),
  month: moment().month(),
  week: moment().startOf(WEEK),
  day: moment().startOf(DAY),
  habit: moment().month(),
  today: moment(),
  pin: moment(),
  currentType: MONTH,
  syncToken: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_RANGE_TYPE: {
      const userTimeZone = state.timezone;
      let newPin = action.pin;
      let period = periodForDateType[action.dateType];
      console.log(`Period: ${period} & Action Date Type: ${action.dateType}`, periodForDateType);
      let newStart =
        moment.tz(action.start, userTimeZone) ||
        moment.tz(state.start.startOf(period), userTimeZone);
      if (!newPin) {
        newPin = moment.tz(state.pin.clone(), userTimeZone);
        while (newPin.isAfter(newStart, period)) {
          newPin.subtract(1, period);
        }
        while (newPin.isBefore(newStart, period)) {
          newPin.add(1, period);
        }
      } else {
        newPin = moment.tz(action.pin, userTimeZone);
      }
      return {
        ...state,
        syncToken: null,
        currentType: action.dateType,
        start: newStart,
        end: newStart.clone().add(1, period),
        month: action.start.month(),
        week: action.start.week(),
        day: action.start.weekday(),
        habit: action.start.month(),
        pin: newPin,
      };
    }
    case SET_LOCALE: {
      return {
        ...state,
        start: state.start.locale(action.locale),
        pin: state.pin.locale(action.locale),
        end: state.end.locale(action.locale),
      };
    }
    case INCREMENT:
      return {
        ...state,
        syncToken: null,
        start: action.start,
        end: action.start.clone().add(1, periodForDateType[state.currentType]),
        month: action.start.month(),
        week: action.start.week(),
        day: action.start.weekday(),
        habit: action.start.month(),
        pin: state.pin.clone().add(1, periodForDateType[state.currentType]),
      };
    case DECREMENT:
      return {
        ...state,
        syncToken: null,
        start: action.start,
        end: action.start.clone().add(1, periodForDateType[state.currentType]),
        month: action.start.month(),
        week: action.start.week(),
        day: action.start.weekday(),
        habit: action.start.month(),
        pin: state.pin.clone().subtract(1, periodForDateType[state.currentType]),
      };
    case SET_SYNC_TOKEN:
      return {
        ...state,
        syncToken: action.syncToken,
      };
    case CLEAR_SYNC_TOKEN:
      return {
        ...state,
        syncToken: null,
      };
    default:
      return state;
  }
}
