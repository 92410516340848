import { createSelector } from 'reselect';
import semver from 'semver';

const featureFlags = state => state.FeatureFlags;
const currentVersion = state => (BUILD_NUMBER ? `${VERSION}+${BUILD_NUMBER}` : VERSION);

const featuresEnabled = createSelector(
  featureFlags,
  currentVersion,
  (featureFlags, currentVersion) =>
    featureFlags.filter(({ versions, name, enabled }) => {
      console.log(
        `feature: ${name}, versions: ${versions}, currentVersion: ${currentVersion}`,
        enabled && (!versions || semver.satisfies(currentVersion, versions))
      );
      return enabled && (!versions || semver.satisfies(currentVersion, versions));
    })
);

export const featureEnabled = (state, featureName) => {
  return featuresEnabled(state).some(({ name }) => name === featureName);
};
