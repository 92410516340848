/* eslint-disable max-len */
import React from 'react';

export default function HabitDumbell(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.784 10.124h-1.219V8.231c0-.326-.127-.64-.355-.87A1.21 1.21 0 0 0 25.348 7h-3.651c-.324 0-.633.13-.862.36-.228.23-.355.545-.355.87v5.54H9.522V8.23c0-.325-.127-.64-.355-.87A1.21 1.21 0 0 0 8.305 7H4.654c-.324 0-.633.13-.862.36-.228.23-.355.545-.355.87v1.894H2.218c-.322 0-.632.13-.86.361-.229.23-.357.544-.357.87v7.29c0 .326.128.64.357.87.228.23.538.36.86.36h1.219v1.894c0 .326.127.64.355.87.229.231.538.361.862.361h3.651c.324 0 .633-.13.862-.36.228-.23.355-.545.355-.87v-5.54h10.956l.002 5.54c0 .325.127.64.355.87.229.23.538.36.862.36h3.651c.324 0 .633-.13.862-.36.228-.23.355-.545.355-.87v-1.894h1.219c.322 0 .632-.13.86-.361.229-.23.357-.544.357-.87v-7.29c0-.326-.128-.64-.357-.87a1.21 1.21 0 0 0-.86-.36z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
