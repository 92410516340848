import { userOptionsApi } from '~/Providers/Api';
import { success } from '~/Redux/Modules/Notifications';
import moment, { setMondayStart } from '~/Providers/Moment';
import mParticle, {
  RenameMealPrepSectionEvent,
  RenameTaskSectionEvent,
  SelectCoverEvent,
  SelectFontEvent,
} from '~/Providers/mParticle';
import { MUST_DO, OTHER, CUSTOM, MONTH_TODO, WEEK_TODO } from '~/Config/Checklists';
import { reloadCurrentView, setLocale } from '../DateRange/actions';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_TIMEZONE,
  UPDATE_TIMEZONE_SUCCESS,
  UPDATE_TIMEZONE_FAILURE,
  UPDATE_FONT,
  UPDATE_FONT_SUCCESS,
  UPDATE_FONT_FAILURE,
  UPDATE_SKIN,
  UPDATE_SKIN_SUCCESS,
  UPDATE_SKIN_FAILURE,
  MEAL_PREPS,
} from './constants';

export const getUserOptions = updates => async dispatch => {
  dispatch({ type: FETCH, updates });
  if (updates?.hasOwnProperty('use_monday_start')) {
    setMondayStart(updates.use_monday_start);
  }
  try {
    let response = await userOptionsApi.fetch();
    dispatch({ type: FETCH_SUCCESS, data: response.data });

    if (!response.data) {
      dispatch(createUserOptions());
    } else {
      setMondayStart(response.data.use_monday_start);
      dispatch(setLocale(moment.locale()));
      dispatch(reloadCurrentView());
    }
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const createUserOptions = (data = {}) => async dispatch => {
  dispatch({ type: STORE });
  try {
    let response = await userOptionsApi.store(data);
    dispatch({ type: STORE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
  }
};

const sectionTitleMapping = {
  [MUST_DO]: 'priority_tasks_title',
  [OTHER]: 'other_tasks_title',
  [CUSTOM]: 'custom_list_title',
  [MONTH_TODO]: 'monthly_tasks_title',
  [WEEK_TODO]: 'weekly_tasks_title',
  [MEAL_PREPS]: 'meals_title',
};

export const updateSectionTitle = (id, type, value) => async dispatch => {
  if (type === MEAL_PREPS) {
    mParticle.logEvent(new RenameMealPrepSectionEvent());
  } else {
    mParticle.logEvent(new RenameTaskSectionEvent(type));
  }
  dispatch(updateUserOptions(id, { [sectionTitleMapping[type]]: value }));
};

export const updateUserOptions = (id, update) => async dispatch => {
  dispatch({ type: UPDATE, update });
  try {
    let response = await userOptionsApi.update(id, update);
    dispatch({ type: UPDATE_SUCCESS, data: response.data });
    dispatch(success('Saved!'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_FAILURE, error: err });
  }
};

export const updateUserTimezone = (id, update) => async dispatch => {
  dispatch({ type: UPDATE_TIMEZONE, update });
  try {
    let response = await userOptionsApi.updateTimezone(id, {
      timezone: update['timezone.name'],
    });
    dispatch({ type: UPDATE_TIMEZONE_SUCCESS, data: response.data });
    dispatch(success('Saved!'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_TIMEZONE_FAILURE, error: err });
  }
};

export const updateFont = (id, { id: fontId, css: name }) => async dispatch => {
  dispatch({ type: UPDATE_FONT });
  try {
    let response = await userOptionsApi.updateFont(id, fontId);
    mParticle.logEvent(new SelectFontEvent(name));
    dispatch({ type: UPDATE_FONT_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_FONT_FAILURE, error: err });
  }
};

export const updateSkin = (id, skinId, { product: { id: productId } }) => async dispatch => {
  dispatch({ type: UPDATE_SKIN });
  try {
    let response = await userOptionsApi.updateSkin(id, skinId);
    mParticle.logEvent(new SelectCoverEvent(productId));
    dispatch({ type: UPDATE_SKIN_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_SKIN_FAILURE, error: err });
  }
};
