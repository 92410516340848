/* eslint-disable max-len */
import React from 'react';

export default function HabitCleaningIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.757 6.65h6.056l1.543-1.543a10.16 10.16 0 0 0 1.873 5.205l.133.185 1.232-.882-.133-.185a8.64 8.64 0 0 1-1.62-5.052h2.272V1.35h-6.057a5.3 5.3 0 0 0-5.299 5.3zM9 23.282l10.598-1.514v-1.499L9 21.783v1.5zM9 28.604h10.598v-5.307L9 24.81v3.793z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M17.706 15.355a6.46 6.46 0 0 1-1.893-4.568V8.164h-3.028v2.623a6.46 6.46 0 0 1-1.892 4.568A6.46 6.46 0 0 0 9 19.925v.328l10.489-1.498a6.46 6.46 0 0 0-1.783-3.4z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
