import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import AnimateHeight from 'react-animate-height';

import TextInput from '~/Components/Global/Form/TextInput';
import history from '~/Providers/History';
import Button from '~/Components/Global/Form/Button';
import TotalWithTax from '~/Components/Global/Stripe/TotalWithTax';
import Spinner from '~/Components/Global/Loading/Spinner';
import { useMountEffect, useResizeEffect } from '~/util';

const SetupIntentForm = ({
  user,
  isFetching,
  intent,
  setSetupIntentId,
  amount,
  plan,
  onSubmit,
  coupon,
  onCouponChanged,
  updateField,
  showCoupon,
  postalCode,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setSubmitting] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [applePayReady, setApplePayReady] = useState(false);
  const [isLoading, setLoading] = useState(intent?.status !== 'succeeded');
  const stripeRef = useRef();
  const [height, setHeight] = useState(250);

  const showForm = intent?.status !== 'succeeded' || intent?.payment_method?.link;
  useEffect(() => {
    if (intent?.status === 'succeeded' && isSubmitting) {
      setSubmitting(false);
    }
  }, [isSubmitting, setSubmitting, intent]);

  const handleSubmit = async event => {
    event.preventDefault();
    onSubmit(intent.payment_method);
  };

  const handleCompletePayment = async () => {
    setSubmitting(true);
    setStripeError(null);
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (error) {
      console.error('Stripe error', error);
      setStripeError(error.message);
      setSubmitting(false);
    } else if (setupIntent) {
      setSetupIntentId(setupIntent.id);
    }
  };

  const renderPaymentMethod = paymentMethod => {
    console.log('renderPaymentMethod', paymentMethod);
    if (!paymentMethod) return null;
    if (paymentMethod.card) {
      return (
        <div
          className="payment-method"
          tabIndex="0"
          role="button"
          onClick={() => {
            setSetupIntentId(null);
            history.push(window.location.pathname);
          }}
        >
          <span className="payment-method__brand">{paymentMethod.card.brand}</span>
          <span className="payment-method__last4">{paymentMethod.card.last4}</span>
          <span className="payment-method__exp">
            Exp {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
          </span>
        </div>
      );
    }
    return null;
  };

  const resizeContainer = () => {
    if ((stripeRef.current?.scrollHeight ?? 0) < 50 || !showForm) {
      return;
    }
    setHeight(stripeRef.current?.scrollHeight);
    if (stripeRef.current?.scrollHeight === height) {
      return;
    }
  };

  useMountEffect(() => {
    const interval = setInterval(() => {
      resizeContainer();
    }, 100);
    return () => {
      clearInterval(interval);
    };
  });

  console.log('SetupIntentForm', 'height', height);

  return (
    <form className="payment-intent-form" onSubmit={handleSubmit}>
      <AnimateHeight height={showForm ? height : 'auto'} duration={1000}>
        {renderPaymentMethod(intent.payment_method)}
        {showForm && (
          <div className="payment-intent-form__payment-element-container">
            <div ref={stripeRef}>
              <PaymentElement
                readOnly={isSubmitting}
                onBlur={() => console.log('PaymentElement', 'onBlur')}
                onChange={event => {
                  console.log('PaymentElement', 'onChange', event);
                  if (event.complete && event.value?.type === 'apple_pay') {
                    setApplePayReady(true);
                  } else if (event.complete) {
                    handleCompletePayment();
                  } else if (applePayReady) {
                    setApplePayReady(false);
                  }
                }}
                onClick={event => console.log('PaymentElement', 'onClick', event)}
                onFocus={event => console.log('PaymentElement', 'onFocus', event)}
                onReady={event => {
                  console.log('PaymentElement', 'onReady', event);
                  setLoading(false);
                }}
              />
            </div>
            {(isSubmitting || isLoading) && (
              <div className="payment-intent-form__payment-element-container__spinner">
                <Spinner />
              </div>
            )}
          </div>
        )}
      </AnimateHeight>
      {stripeError && <div className="text-danger">{stripeError}</div>}
      {applePayReady && (
        <Button
          type="button"
          className="pill apple-pay"
          text="Continue with Apple Pay"
          onClick={handleCompletePayment}
        />
      )}
      {showCoupon && (
        <TextInput
          onChange={updateField}
          placeholder="Promo Code (Optional)"
          name="coupon"
          className="mobile-font-fix"
          showLabel={false}
          value={coupon}
        />
      )}
      {intent?.payment_method?.link && (
        <TextInput
          onChange={updateField}
          placeholder="Postal Code"
          name="postal_code"
          className="mobile-font-fix"
          showLabel={false}
          value={postalCode}
        />
      )}
      {plan && (
        <TotalWithTax
          amount={amount}
          zip={intent?.payment_method?.billing_details?.address?.postal_code}
          coupon={coupon}
          onCouponChanged={onCouponChanged}
          plan={plan}
        />
      )}
      {onSubmit && (
        <Button
          type="submit"
          className="pill"
          isFetching={isFetching}
          text={user.trial_ends_at == null ? 'COMPLETE MY REGISTRATION' : 'SUBSCRIBE NOW'}
          actionText="SUBSCRIBING"
          disabled={
            !(intent?.status === 'succeeded' && plan) ||
            isFetching ||
            isSubmitting ||
            (intent.payment_method.link && !postalCode)
          }
        />
      )}
    </form>
  );
};

SetupIntentForm.propTypes = {
  user: propTypes.object.isRequired,
  isFetching: propTypes.bool,
  intent: propTypes.object,
  amount: propTypes.number,
  plan: propTypes.object,
  onSubmit: propTypes.func,
  coupon: propTypes.string,
  onCouponChanged: propTypes.func,
  updateField: propTypes.func,
  showCoupon: propTypes.bool,
  setSetupIntentId: propTypes.func.isRequired,
  postalCode: propTypes.string,
};

export default SetupIntentForm;
