import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import moment from 'moment';

import { getUser } from '~/Redux/Modules/User';

import Modal from '~/Containers/Global/Modal/Modal';
import { HABIT_DELETE_STOP_TRACKING_MODAL } from '~/Config/Modals';
import { deleteHabit, updateHabit } from '~/Redux/Modules/Habits';
import './HabitModalDeleteStopTracking.scss';
import { closeModal, setModalData } from '~/Redux/Modules/Modal';
import habitIcons from './HabitIconsLibrary';

function HabitModalDeleteStopTracking({
  modalId = HABIT_DELETE_STOP_TRACKING_MODAL,
  user,
  fetchUser,
  habit,
  isDelete,
  start,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchUser();
  }, []);

  if (!user) return null;

  if (!habit) return null;

  const getConfirmationMessageAndActionText = (deleteFlag, habit) => {
    let confirmationMessage;
    let actionText;

    if (deleteFlag) {
      confirmationMessage = 'Are you sure you want to delete this habit?';
      actionText = 'Delete';
    } else if (habit.end !== null) {
      confirmationMessage = 'Are you sure you want to continue tracking this habit?';
      actionText = 'Track';
    } else {
      confirmationMessage = 'Are you sure you want to stop tracking this habit?';
      actionText = 'Stop';
    }

    return {
      confirmationMessage,
      actionText,
    };
  };

  const { confirmationMessage, actionText } = getConfirmationMessageAndActionText(isDelete, habit);

  const handleCloseModal = () => {
    dispatch(setModalData({ habit: null }));
    dispatch(closeModal(HABIT_DELETE_STOP_TRACKING_MODAL));
  };

  const handleHabitAction = () => {
    if (isDelete) {
      dispatch(deleteHabit(habit)).then(response => {
        handleCloseModal();
      });
    } else if (habit.end === null) {
      let habitToUpdate = { ...habit };
      const startOfMonth = moment(start).startOf('month');
      const lastDayOfMonth = startOfMonth.clone().endOf('month');
      const lastDayOfMonthString = lastDayOfMonth.format('YYYY-MM-DD');
      habitToUpdate.end = new Date(lastDayOfMonthString);
      dispatch(updateHabit(habitToUpdate)).then(response => {
        handleCloseModal();
      });
    } else {
      let habitToUpdate = { ...habit };
      habitToUpdate.end = null;
      dispatch(updateHabit(habitToUpdate)).then(response => {
        handleCloseModal();
      });
    }
  };

  return (
    <Modal id={modalId} overlayClassName="habit-delete-stop-tracking-modal" showCloseButton={false}>
      <div className="habit-modal-message">{confirmationMessage}</div>
      <div className="habit-confirmation">
        <div className="habit-confirmation-icon">
          {React.cloneElement(habitIcons[habit.icon], { fill: habit.color })}
        </div>
        <div className="habit-confirmation-title">{habit.title}</div>
      </div>
      <div className="habit-delete-stop-tracking-action-buttons">
        <div
          className="cancel-button"
          tabIndex="0"
          role="button"
          onClick={() => handleCloseModal()}
        >
          Cancel
        </div>
        <div className="save-button" tabIndex="0" role="button" onClick={() => handleHabitAction()}>
          {actionText}
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ User, Modal, DateRange }) => ({
  user: User.data,
  habit: Modal.modalData?.habit,
  isDelete: Modal.modalData?.isDelete,
  start: DateRange.start,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(getUser()),
});

HabitModalDeleteStopTracking.propTypes = {
  modalId: propTypes.string,
  user: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  fetchUser: propTypes.func.isRequired,
  habit: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  isDelete: propTypes.bool,
  start: propTypes.object,
};

HabitModalDeleteStopTracking.defaultProps = {
  modalId: HABIT_DELETE_STOP_TRACKING_MODAL,
};

export default connect(mapStateToProps, mapDispatchToProps)(HabitModalDeleteStopTracking);
