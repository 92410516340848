import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CONFIRM_STICKER_REMOVAL_MODAL } from '~/Config/Modals';
import Modal from '~/Containers/Global/Modal/Modal';
import { closeModal, setModalData } from '~/Redux/Modules/Modal';
import { deleteSticker } from '~/Redux/Modules/Products';

import './ConfirmStickerRemovalModal.scss';

function ConfirmStickerRemovalModal() {
  const dispatch = useDispatch();

  const sticker = useSelector(state => state.Modal.modalData?.sticker);

  const handleCancel = () => {
    dispatch(closeModal(CONFIRM_STICKER_REMOVAL_MODAL));
    dispatch(setModalData(null));
  };

  const handleConfirm = () => {
    dispatch(closeModal(CONFIRM_STICKER_REMOVAL_MODAL));
    dispatch(deleteSticker(sticker));
  };

  return (
    <Modal
      id={CONFIRM_STICKER_REMOVAL_MODAL}
      overlayClassName="confirm-sticker-removal-modal"
      showCloseButton={false}
    >
      {sticker && <img className="sticker" src={sticker.url} />}

      <h1 className="heading">Hold On!</h1>
      <p>Are you sure you want to delete this sticker?</p>

      <div id="cancel-button" role="button" tabIndex="0" onClick={handleCancel}>
        Cancel
      </div>

      <div id="confirm-button" role="button" tabIndex="0" onClick={handleConfirm}>
        Delete Sticker
      </div>
    </Modal>
  );
}

export default ConfirmStickerRemovalModal;
