/* eslint-disable max-len */
import React from 'react';

export default function HabitDogIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#1cznbf7gia)">
        <path
          className="fill"
          d="M14.462 15.438 5.466 6.89a.539.539 0 0 1 0-.79.61.61 0 0 1 .833 0l9.827 9.337h-1.664zm0 0 3.626 3.444-.147.14v4.519c0 .77-.658 1.396-1.47 1.396-.813 0-1.471-.625-1.471-1.396v-2.042l-5.882-.931v2.973c0 .77-.659 1.396-1.471 1.396s-1.47-.625-1.47-1.396v-4.192c0-.824.268-1.589.725-2.219l-.093-.224a.588.588 0 0 0-.547-.351h-.674c-.325 0-.588-.25-.588-.559 0-.309.263-.559.588-.559h.674c.628 0 1.2.316 1.515.816a4.241 4.241 0 0 1 2.517-.816h4.168zm1.764 0 4.534-4.307a.61.61 0 0 1 .832 0 .54.54 0 0 1 0 .79l-.907.863 4.142 3.935c.23.218.23.572 0 .79l-.81.77a2.441 2.441 0 0 1-3.328 0l-.983-.933-.786.746-2.794-2.654h.1z"
          fill={props.fill ? props.fill : '#000'}
        />
      </g>
      <defs>
        <clipPath id="1cznbf7gia">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
