import React from 'react';
import propTypes from 'prop-types';
import { useGoogleLogin } from '@react-oauth/google';
import cn from 'classnames';

import FontAwesome from 'react-fontawesome';
import Spinner from '~/Components/Global/Loading/Spinner';

const GoogleLogo = require('~/Components/Pages/AddAccount/google-logo-9822.png');
const GoogleButton = require('~/Components/Pages/AddAccount/GoogleSignInButton.png');

const GoogleSignInWrapper = ({
  onSuccess,
  onError,
  buttonText,
  account,
  isUpdating,
  disabled,
  actionText,
  addAccountScreen,
}) => {
  const googleLogin = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onError,
    theme: 'filled_blue',
    width: '160',
    ux_mode: 'popup',
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email',
  });
  return (
    <div>
      {account ? (
        <a onClick={isUpdating || disabled ? null : googleLogin} className="update-account">
          {isUpdating && <Spinner />}
          {account.error_count > 0 && !isUpdating && <FontAwesome name="exclamation-triangle" />}
          Refresh Google Account Credentials
        </a>
      ) : addAccountScreen ? (
        <div onClick={isUpdating || disabled ? null : googleLogin}>
          <img src={GoogleButton} className="google-button-add-account" />
          {isUpdating && (
            <div className="add-account-spinner">
              <Spinner />
            </div>
          )}
        </div>
      ) : (
        <div
          className={cn('google-button', { 'google-button-disabled': disabled })}
          role="button"
          tabIndex="0"
          onClick={isUpdating || disabled ? null : googleLogin}
        >
          <img src={GoogleLogo} className="google-logo" />
          {isUpdating ? (
            <span className="google-button-text">
              {actionText} <Spinner />
            </span>
          ) : (
            <span className="google-button-text">{buttonText}</span>
          )}
        </div>
      )}
    </div>
  );
};

GoogleSignInWrapper.propTypes = {
  onSuccess: propTypes.func.isRequired,
  onError: propTypes.func.isRequired,
  buttonText: propTypes.string.isRequired,
  actionText: propTypes.string.isRequired,
  account: propTypes.object,
  isUpdating: propTypes.bool,
  disabled: propTypes.bool,
  isFetching: propTypes.bool,
  addAccountScreen: propTypes.bool,
  isRegistering: propTypes.bool,
};

export default GoogleSignInWrapper;
