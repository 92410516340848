/* eslint-disable max-len */
import React from 'react';

export default function HabitMedicalIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#sj4u952f6a)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M23.06 11h-4.332V6h-7.333v5H6.06v3.125h4.76l.591-1.661a.318.318 0 0 1 .337-.213c.15.008.276.111.306.25l.426 1.999 1.598-4.32c.05-.136.196-.219.345-.21a.325.325 0 0 1 .3.266l1.131 7.056 1.557-4.207a.33.33 0 0 1 .337-.21c.15.01.275.113.305.251l.42 1.97.27-.758a.333.333 0 0 1 .317-.213h5V11h-1z" />
        <path d="M18.71 16.41a.333.333 0 0 1-.337.214.326.326 0 0 1-.306-.251l-.427-1.999-1.598 4.32a.333.333 0 0 1-.315.21h-.03a.325.325 0 0 1-.3-.265l-1.132-7.056-1.556 4.206a.325.325 0 0 1-.338.21.328.328 0 0 1-.304-.251l-.42-1.97-.27.758a.332.332 0 0 1-.317.214h-5L6 17.875h5.394v5h7.333v-5h5.333V14.75H19.3l-.59 1.66z" />
      </g>
      <defs>
        <clipPath id="sj4u952f6a">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
