/* eslint-disable max-len */
import React from 'react';

export default function HabitBathIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.369 14.308a.903.903 0 0 0-.64-.28c-.93-.002-1.859.068-2.778.21-.644.1-1.327.219-2.157.382-.312.056-.624.119-.932.179-.886.176-1.832.361-2.774.51-3.461.546-6.998.348-10.376-.58l-.166-.06a.9.9 0 0 0-.687.07.906.906 0 0 0 .176 1.66c.18.052.362.099.54.148l1.04 4.095a3.56 3.56 0 0 0 2.302 2.499l-.202.332a.997.997 0 0 0 1.135 1.483.996.996 0 0 0 .61-.465l.687-1.168h7.745l.663 1.165a1.005 1.005 0 0 0 1.746-.996l-.239-.411a3.568 3.568 0 0 0 2.124-2.439l1.22-4.791h.306a.906.906 0 0 0 .664-1.533l-.007-.01zM9.903 24.163a.351.351 0 0 1-.22.169.368.368 0 0 1-.447-.259.38.38 0 0 1 .036-.275l.299-.515c.166.024.333.037.5.037h.333l-.501.843zm10.83-.365a.364.364 0 0 1-.421.534.36.36 0 0 1-.22-.166l-.477-.846h.1c.23 0 .462-.023.69-.07l.328.548zm4.244-8.857a.265.265 0 0 1-.262.26h-.614a16.67 16.67 0 0 0-1.99.188 58.23 58.23 0 0 0-3.034.551h-.073c-.899.176-1.825.359-2.747.501-1.338.21-2.69.313-4.045.312a25.046 25.046 0 0 1-6.169-.733c-.275-.07-.547-.146-.816-.223a.25.25 0 0 1-.18-.132.26.26 0 0 1-.022-.2.255.255 0 0 1 .249-.185h.076l.17.05a25.333 25.333 0 0 0 10.654.6 61.335 61.335 0 0 0 2.8-.517l.926-.18c.82-.159 1.5-.278 2.134-.375.89-.136 1.788-.199 2.688-.189a.262.262 0 0 1 .255.266v.006zM9.753 11.607a2.16 2.16 0 1 0 0-4.32 2.16 2.16 0 0 0 0 4.32zm0-3.674a1.517 1.517 0 1 1 0 3.034 1.517 1.517 0 0 1 0-3.034zM14.296 8.926a1.957 1.957 0 1 0 .02-3.914 1.957 1.957 0 0 0-.02 3.914zm0-3.269a1.327 1.327 0 1 1 .032 2.654 1.327 1.327 0 0 1-.032-2.654zM12.348 12.104a1.532 1.532 0 1 0 3.065 0 1.532 1.532 0 0 0-3.065 0zm2.422 0a.888.888 0 1 1-1.776 0 .888.888 0 0 1 1.776 0z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M17.448 14.546a1.235 1.235 0 1 0 0-2.47 1.235 1.235 0 0 0 0 2.47zm0-1.821a.591.591 0 1 1 0 1.182.591.591 0 0 1 0-1.182z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
