/* eslint-disable max-len */
import React from 'react';

export default function HabitRepeatIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.008 10.552c-.614 0-1.112.498-1.112 1.112v6.673a2.227 2.227 0 0 1-2.224 2.224H11.31v-2.216l-5.761 3.328L11.308 25v-2.215h10.364a4.454 4.454 0 0 0 4.448-4.448v-6.673c0-.613-.497-1.112-1.112-1.112zM6.104 18.336v-6.672c0-1.226.998-2.224 2.224-2.224H18.69v2.216l5.761-3.328L18.692 5v2.216H8.327a4.454 4.454 0 0 0-4.448 4.448v6.672a1.111 1.111 0 1 0 2.224 0z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
