/* eslint-disable max-len */
import React from 'react';

export default function HabitMeditateIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10.645a2.322 2.322 0 1 0 0-4.644 2.322 2.322 0 0 0 0 4.644zm8.01 10.106c.079.203.119.418.119.636A2.613 2.613 0 0 1 20.516 24H9.484a2.613 2.613 0 0 1-2.612-2.613 1.766 1.766 0 0 1 2.401-1.647c2.845 1.098 4.754 1.647 5.727 1.647.973 0 2.882-.55 5.727-1.647a1.766 1.766 0 0 1 2.284 1.01zm-7.903-9.525a1.74 1.74 0 0 1 1.396.7l.032.037.03.04 3.97 5.297 1.325.19c.495.07.863.494.863.994a.87.87 0 0 1-.87.871h-2.034c-.267 0-.514-.09-.71-.242a1.145 1.145 0 0 1-.24-.205l-.06-.073-1.39-1.845.416 3.025a.58.58 0 0 1-.423.637c-1.1.296-1.904.445-2.412.445-.507 0-1.31-.149-2.411-.445a.58.58 0 0 1-.423-.637l.416-3.022-1.388 1.842a1.139 1.139 0 0 1-.31.284 1.163 1.163 0 0 1-.703.236H8.15a.87.87 0 0 1-.87-.871c0-.5.366-.924.861-.994l1.329-.19 3.97-5.297.04-.05a1.74 1.74 0 0 1 1.415-.727h.213z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
