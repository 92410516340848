export const timezones = [
  { label: '(GMT-09:00) Alaska Time', name: 'America/Anchorage' },
  { label: '(GMT-08:00) Pacific Time', name: 'America/Los_Angeles' },
  { label: '(GMT-07:00) Mountain Time', name: 'America/Denver' },
  { label: '(GMT-06:00) Central Time', name: 'America/Chicago' },
  { label: '(GMT-05:00) Eastern Time', name: 'America/New_York' },
  { label: '(GMT-04:00) Puerto Rico', name: 'America/Puerto_Rico' },
  { label: '(GMT-11:00) Niue', name: 'Pacific/Niue' },
  { label: '(GMT-11:00) Pago Pago', name: 'Pacific/Pago_Pago' },
  { label: '(GMT-10:00) Hawaii Time', name: 'Pacific/Honolulu' },
  { label: '(GMT-10:00) Rarotonga', name: 'Pacific/Rarotonga' },
  { label: '(GMT-10:00) Tahiti', name: 'Pacific/Tahiti' },
  { label: '(GMT-09:30) Marquesas', name: 'Pacific/Marquesas' },
  { label: '(GMT-09:00) Gambier', name: 'Pacific/Gambier' },
  { label: '(GMT-08:00) Pacific Time - Tijuana', name: 'America/Tijuana' },
  { label: '(GMT-08:00) Pacific Time - Vancouver', name: 'America/Vancouver' },
  { label: '(GMT-08:00) Pacific Time - Whitehorse', name: 'America/Whitehorse' },
  { label: '(GMT-08:00) Pitcairn', name: 'Pacific/Pitcairn' },
  { label: '(GMT-07:00) Mountain Time - Dawson Creek', name: 'America/Dawson_Creek' },
  { label: '(GMT-07:00) Mountain Time - Edmonton', name: 'America/Edmonton' },
  { label: '(GMT-07:00) Mountain Time - Hermosillo', name: 'America/Hermosillo' },
  { label: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan', name: 'America/Mazatlan' },
  { label: '(GMT-07:00) Mountain Time - Arizona', name: 'America/Phoenix' },
  { label: '(GMT-07:00) Mountain Time - Yellowknife', name: 'America/Yellowknife' },
  { label: '(GMT-06:00) Belize', name: 'America/Belize' },
  { label: '(GMT-06:00) Costa Rica', name: 'America/Costa_Rica' },
  { label: '(GMT-06:00) El Salvador', name: 'America/El_Salvador' },
  { label: '(GMT-06:00) Guatemala', name: 'America/Guatemala' },
  { label: '(GMT-06:00) Managua', name: 'America/Managua' },
  { label: '(GMT-06:00) Central Time - Mexico City', name: 'America/Mexico_City' },
  { label: '(GMT-06:00) Central Time - Regina', name: 'America/Regina' },
  { label: '(GMT-06:00) Central Time - Tegucigalpa', name: 'America/Tegucigalpa' },
  { label: '(GMT-06:00) Central Time - Winnipeg', name: 'America/Winnipeg' },
  { label: '(GMT-06:00) Galapagos', name: 'Pacific/Galapagos' },
  { label: '(GMT-05:00) Bogota', name: 'America/Bogota' },
  { label: '(GMT-05:00) America Cancun', name: 'America/Cancun' },
  { label: '(GMT-05:00) Cayman', name: 'America/Cayman' },
  { label: '(GMT-05:00) Guayaquil', name: 'America/Guayaquil' },
  { label: '(GMT-05:00) Havana', name: 'America/Havana' },
  { label: '(GMT-05:00) Eastern Time - Iqaluit', name: 'America/Iqaluit' },
  { label: '(GMT-05:00) Jamaica', name: 'America/Jamaica' },
  { label: '(GMT-05:00) Lima', name: 'America/Lima' },
  { label: '(GMT-05:00) Nassau', name: 'America/Nassau' },
  { label: '(GMT-05:00) Panama', name: 'America/Panama' },
  { label: '(GMT-05:00) Port-au-Prince', name: 'America/Port-au-Prince' },
  { label: '(GMT-05:00) Rio Branco', name: 'America/Rio_Branco' },
  { label: '(GMT-05:00) Eastern Time - Toronto', name: 'America/Toronto' },
  { label: '(GMT-05:00) Easter Island', name: 'Pacific/Easter' },
  { label: '(GMT-04:30) Caracas', name: 'America/Caracas' },
  { label: '(GMT-03:00) Asuncion', name: 'America/Asuncion' },
  { label: '(GMT-04:00) Barbados', name: 'America/Barbados' },
  { label: '(GMT-04:00) Boa Vista', name: 'America/Boa_Vista' },
  { label: '(GMT-03:00) Campo Grande', name: 'America/Campo_Grande' },
  { label: '(GMT-03:00) Cuiaba', name: 'America/Cuiaba' },
  { label: '(GMT-04:00) Curacao', name: 'America/Curacao' },
  { label: '(GMT-04:00) Grand Turk', name: 'America/Grand_Turk' },
  { label: '(GMT-04:00) Guyana', name: 'America/Guyana' },
  { label: '(GMT-04:00) Atlantic Time - Halifax', name: 'America/Halifax' },
  { label: '(GMT-04:00) La Paz', name: 'America/La_Paz' },
  { label: '(GMT-04:00) Manaus', name: 'America/Manaus' },
  { label: '(GMT-04:00) Martinique', name: 'America/Martinique' },
  { label: '(GMT-04:00) Port of Spain', name: 'America/Port_of_Spain' },
  { label: '(GMT-04:00) Porto Velho', name: 'America/Porto_Velho' },
  { label: '(GMT-04:00) Santo Domingo', name: 'America/Santo_Domingo' },
  { label: '(GMT-04:00) Thule', name: 'America/Thule' },
  { label: '(GMT-04:00) Bermuda', name: 'Atlantic/Bermuda' },
  { label: '(GMT-03:30) Newfoundland Time - St. Johns', name: 'America/St_Johns' },
  { label: '(GMT-03:00) Araguaina', name: 'America/Araguaina' },
  { label: '(GMT-03:00) Buenos Aires', name: 'America/Argentina/Buenos_Aires' },
  { label: '(GMT-03:00) Salvador', name: 'America/Bahia' },
  { label: '(GMT-03:00) Belem', name: 'America/Belem' },
  { label: '(GMT-03:00) Cayenne', name: 'America/Cayenne' },
  { label: '(GMT-03:00) Fortaleza', name: 'America/Fortaleza' },
  { label: '(GMT-03:00) Godthab', name: 'America/Godthab' },
  { label: '(GMT-03:00) Maceio', name: 'America/Maceio' },
  { label: '(GMT-03:00) Miquelon', name: 'America/Miquelon' },
  { label: '(GMT-03:00) Montevideo', name: 'America/Montevideo' },
  { label: '(GMT-03:00) Paramaribo', name: 'America/Paramaribo' },
  { label: '(GMT-03:00) Recife', name: 'America/Recife' },
  { label: '(GMT-03:00) Santiago', name: 'America/Santiago' },
  { label: '(GMT-02:00) Sao Paulo', name: 'America/Sao_Paulo' },
  { label: '(GMT-03:00) Palmer', name: 'Antarctica/Palmer' },
  { label: '(GMT-03:00) Rothera', name: 'Antarctica/Rothera' },
  { label: '(GMT-03:00) Stanley', name: 'Atlantic/Stanley' },
  { label: '(GMT-02:00) Noronha', name: 'America/Noronha' },
  { label: '(GMT-02:00) South Georgia', name: 'Atlantic/South_Georgia' },
  { label: '(GMT-01:00) Scoresbysund', name: 'America/Scoresbysund' },
  { label: '(GMT-01:00) Azores', name: 'Atlantic/Azores' },
  { label: '(GMT-01:00) Cape Verde', name: 'Atlantic/Cape_Verde' },
  { label: '(GMT+00:00) Abidjan', name: 'Africa/Abidjan' },
  { label: '(GMT+00:00) Accra', name: 'Africa/Accra' },
  { label: '(GMT+00:00) Bissau', name: 'Africa/Bissau' },
  { label: '(GMT+00:00) Casablanca', name: 'Africa/Casablanca' },
  { label: '(GMT+00:00) El Aaiun', name: 'Africa/El_Aaiun' },
  { label: '(GMT+00:00) Monrovia', name: 'Africa/Monrovia' },
  { label: '(GMT+00:00) Danmarkshavn', name: 'America/Danmarkshavn' },
  { label: '(GMT+00:00) Canary Islands', name: 'Atlantic/Canary' },
  { label: '(GMT+00:00) Faeroe', name: 'Atlantic/Faroe' },
  { label: '(GMT+00:00) Reykjavik', name: 'Atlantic/Reykjavik' },
  { label: 'UTC', name: 'UTC' },
  { label: '(GMT+00:00) GMT (no daylight saving)', name: 'Etc/GMT' },
  { label: '(GMT+00:00) Dublin', name: 'Europe/Dublin' },
  { label: '(GMT+00:00) Lisbon', name: 'Europe/Lisbon' },
  { label: '(GMT+00:00) London', name: 'Europe/London' },
  { label: '(GMT+01:00) Algiers', name: 'Africa/Algiers' },
  { label: '(GMT+01:00) Ceuta', name: 'Africa/Ceuta' },
  { label: '(GMT+01:00) Lagos', name: 'Africa/Lagos' },
  { label: '(GMT+01:00) Ndjamena', name: 'Africa/Ndjamena' },
  { label: '(GMT+01:00) Tunis', name: 'Africa/Tunis' },
  { label: '(GMT+02:00) Windhoek', name: 'Africa/Windhoek' },
  { label: '(GMT+01:00) Amsterdam', name: 'Europe/Amsterdam' },
  { label: '(GMT+01:00) Andorra', name: 'Europe/Andorra' },
  { label: '(GMT+01:00) Central European Time - Belgrade', name: 'Europe/Belgrade' },
  { label: '(GMT+01:00) Berlin', name: 'Europe/Berlin' },
  { label: '(GMT+01:00) Brussels', name: 'Europe/Brussels' },
  { label: '(GMT+01:00) Budapest', name: 'Europe/Budapest' },
  { label: '(GMT+01:00) Copenhagen', name: 'Europe/Copenhagen' },
  { label: '(GMT+01:00) Gibraltar', name: 'Europe/Gibraltar' },
  { label: '(GMT+01:00) Luxembourg', name: 'Europe/Luxembourg' },
  { label: '(GMT+01:00) Madrid', name: 'Europe/Madrid' },
  { label: '(GMT+01:00) Malta', name: 'Europe/Malta' },
  { label: '(GMT+01:00) Monaco', name: 'Europe/Monaco' },
  { label: '(GMT+01:00) Oslo', name: 'Europe/Oslo' },
  { label: '(GMT+01:00) Paris', name: 'Europe/Paris' },
  { label: '(GMT+01:00) Central European Time - Prague', name: 'Europe/Prague' },
  { label: '(GMT+01:00) Rome', name: 'Europe/Rome' },
  { label: '(GMT+01:00) Stockholm', name: 'Europe/Stockholm' },
  { label: '(GMT+01:00) Tirane', name: 'Europe/Tirane' },
  { label: '(GMT+01:00) Vienna', name: 'Europe/Vienna' },
  { label: '(GMT+01:00) Warsaw', name: 'Europe/Warsaw' },
  { label: '(GMT+01:00) Zurich', name: 'Europe/Zurich' },
  { label: '(GMT+02:00) Cairo', name: 'Africa/Cairo' },
  { label: '(GMT+02:00) Johannesburg', name: 'Africa/Johannesburg' },
  { label: '(GMT+02:00) Maputo', name: 'Africa/Maputo' },
  { label: '(GMT+02:00) Tripoli', name: 'Africa/Tripoli' },
  { label: '(GMT+02:00) Amman', name: 'Asia/Amman' },
  { label: '(GMT+02:00) Beirut', name: 'Asia/Beirut' },
  { label: '(GMT+02:00) Damascus', name: 'Asia/Damascus' },
  { label: '(GMT+02:00) Gaza', name: 'Asia/Gaza' },
  { label: '(GMT+02:00) Jerusalem', name: 'Asia/Jerusalem' },
  { label: '(GMT+02:00) Nicosia', name: 'Asia/Nicosia' },
  { label: '(GMT+02:00) Athens', name: 'Europe/Athens' },
  { label: '(GMT+02:00) Bucharest', name: 'Europe/Bucharest' },
  { label: '(GMT+02:00) Chisinau', name: 'Europe/Chisinau' },
  { label: '(GMT+02:00) Helsinki', name: 'Europe/Helsinki' },
  { label: '(GMT+02:00) Moscow-01 - Kaliningrad', name: 'Europe/Kaliningrad' },
  { label: '(GMT+02:00) Kiev', name: 'Europe/Kiev' },
  { label: '(GMT+02:00) Riga', name: 'Europe/Riga' },
  { label: '(GMT+02:00) Sofia', name: 'Europe/Sofia' },
  { label: '(GMT+02:00) Tallinn', name: 'Europe/Tallinn' },
  { label: '(GMT+02:00) Vilnius', name: 'Europe/Vilnius' },
  { label: '(GMT+03:00) Istanbul', name: 'Europe/Istanbul' },
  { label: '(GMT+03:00) Khartoum', name: 'Africa/Khartoum' },
  { label: '(GMT+03:00) Nairobi', name: 'Africa/Nairobi' },
  { label: '(GMT+03:00) Syowa', name: 'Antarctica/Syowa' },
  { label: '(GMT+03:00) Baghdad', name: 'Asia/Baghdad' },
  { label: '(GMT+03:00) Qatar', name: 'Asia/Qatar' },
  { label: '(GMT+03:00) Riyadh', name: 'Asia/Riyadh' },
  { label: '(GMT+03:00) Minsk', name: 'Europe/Minsk' },
  { label: '(GMT+03:00) Moscow+00 - Moscow', name: 'Europe/Moscow' },
  { label: '(GMT+03:30) Tehran', name: 'Asia/Tehran' },
  { label: '(GMT+04:00) Baku', name: 'Asia/Baku' },
  { label: '(GMT+04:00) Dubai', name: 'Asia/Dubai' },
  { label: '(GMT+04:00) Tbilisi', name: 'Asia/Tbilisi' },
  { label: '(GMT+04:00) Yerevan', name: 'Asia/Yerevan' },
  { label: '(GMT+04:00) Moscow+01 - Samara', name: 'Europe/Samara' },
  { label: '(GMT+04:00) Mahe', name: 'Indian/Mahe' },
  { label: '(GMT+04:00) Mauritius', name: 'Indian/Mauritius' },
  { label: '(GMT+04:00) Reunion', name: 'Indian/Reunion' },
  { label: '(GMT+04:30) Kabul', name: 'Asia/Kabul' },
  { label: '(GMT+05:00) Mawson', name: 'Antarctica/Mawson' },
  { label: '(GMT+05:00) Aqtau', name: 'Asia/Aqtau' },
  { label: '(GMT+05:00) Aqtobe', name: 'Asia/Aqtobe' },
  { label: '(GMT+05:00) Ashgabat', name: 'Asia/Ashgabat' },
  { label: '(GMT+05:00) Dushanbe', name: 'Asia/Dushanbe' },
  { label: '(GMT+05:00) Karachi', name: 'Asia/Karachi' },
  { label: '(GMT+05:00) Tashkent', name: 'Asia/Tashkent' },
  { label: '(GMT+05:00) Moscow+02 - Yekaterinburg', name: 'Asia/Yekaterinburg' },
  { label: '(GMT+05:00) Kerguelen', name: 'Indian/Kerguelen' },
  { label: '(GMT+05:00) Maldives', name: 'Indian/Maldives' },
  { label: '(GMT+05:30) India Standard Time', name: 'Asia/Calcutta' },
  { label: '(GMT+05:30) Colombo', name: 'Asia/Colombo' },
  { label: '(GMT+05:45) Katmandu', name: 'Asia/Katmandu' },
  { label: '(GMT+06:00) Vostok', name: 'Antarctica/Vostok' },
  { label: '(GMT+06:00) Almaty', name: 'Asia/Almaty' },
  { label: '(GMT+06:00) Bishkek', name: 'Asia/Bishkek' },
  { label: '(GMT+06:00) Dhaka', name: 'Asia/Dhaka' },
  { label: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk', name: 'Asia/Omsk' },
  { label: '(GMT+06:00) Thimphu', name: 'Asia/Thimphu' },
  { label: '(GMT+06:00) Chagos', name: 'Indian/Chagos' },
  { label: '(GMT+06:30) Rangoon', name: 'Asia/Rangoon' },
  { label: '(GMT+06:30) Cocos', name: 'Indian/Cocos' },
  { label: '(GMT+07:00) Davis', name: 'Antarctica/Davis' },
  { label: '(GMT+07:00) Bangkok', name: 'Asia/Bangkok' },
  { label: '(GMT+07:00) Hovd', name: 'Asia/Hovd' },
  { label: '(GMT+07:00) Jakarta', name: 'Asia/Jakarta' },
  { label: '(GMT+07:00) Moscow+04 - Krasnoyarsk', name: 'Asia/Krasnoyarsk' },
  { label: '(GMT+07:00) Hanoi', name: 'Asia/Saigon' },
  { label: '(GMT+07:00) Christmas', name: 'Indian/Christmas' },
  { label: '(GMT+08:00) Casey', name: 'Antarctica/Casey' },
  { label: '(GMT+08:00) Brunei', name: 'Asia/Brunei' },
  { label: '(GMT+08:00) Choibalsan', name: 'Asia/Choibalsan' },
  { label: '(GMT+08:00) Hong Kong', name: 'Asia/Hong_Kong' },
  { label: '(GMT+08:00) Moscow+05 - Irkutsk', name: 'Asia/Irkutsk' },
  { label: '(GMT+08:00) Kuala Lumpur', name: 'Asia/Kuala_Lumpur' },
  { label: '(GMT+08:00) Macau', name: 'Asia/Macau' },
  { label: '(GMT+08:00) Makassar', name: 'Asia/Makassar' },
  { label: '(GMT+08:00) Manila', name: 'Asia/Manila' },
  { label: '(GMT+08:00) China Time - Beijing', name: 'Asia/Shanghai' },
  { label: '(GMT+08:00) Singapore', name: 'Asia/Singapore' },
  { label: '(GMT+08:00) Taipei', name: 'Asia/Taipei' },
  { label: '(GMT+08:00) Ulaanbaatar', name: 'Asia/Ulaanbaatar' },
  { label: '(GMT+08:00) Western Time - Perth', name: 'Australia/Perth' },
  { label: '(GMT+08:30) Pyongyang', name: 'Asia/Pyongyang' },
  { label: '(GMT+09:00) Dili', name: 'Asia/Dili' },
  { label: '(GMT+09:00) Jayapura', name: 'Asia/Jayapura' },
  { label: '(GMT+09:00) Seoul', name: 'Asia/Seoul' },
  { label: '(GMT+09:00) Tokyo', name: 'Asia/Tokyo' },
  { label: '(GMT+09:00) Moscow+06 - Yakutsk', name: 'Asia/Yakutsk' },
  { label: '(GMT+09:00) Palau', name: 'Pacific/Palau' },
  { label: '(GMT+10:30) Central Time - Adelaide', name: 'Australia/Adelaide' },
  { label: '(GMT+09:30) Central Time - Darwin', name: 'Australia/Darwin' },
  { label: "(GMT+10:00) Dumont D'Urville", name: 'Antarctica/DumontDUrville' },
  { label: '(GMT+10:00) Moscow+07 - Magadan', name: 'Asia/Magadan' },
  { label: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk', name: 'Asia/Vladivostok' },
  { label: '(GMT+10:00) Eastern Time - Brisbane', name: 'Australia/Brisbane' },
  { label: '(GMT+11:00) Eastern Time - Hobart', name: 'Australia/Hobart' },
  { label: '(GMT+11:00) Eastern Time - Melbourne, Sydney', name: 'Australia/Sydney' },
  { label: '(GMT+10:00) Truk', name: 'Pacific/Chuuk' },
  { label: '(GMT+10:00) Guam', name: 'Pacific/Guam' },
  { label: '(GMT+10:00) Port Moresby', name: 'Pacific/Port_Moresby' },
  { label: '(GMT+11:00) Efate', name: 'Pacific/Efate' },
  { label: '(GMT+11:00) Guadalcanal', name: 'Pacific/Guadalcanal' },
  { label: '(GMT+11:00) Kosrae', name: 'Pacific/Kosrae' },
  { label: '(GMT+11:00) Norfolk', name: 'Pacific/Norfolk' },
  { label: '(GMT+11:00) Noumea', name: 'Pacific/Noumea' },
  { label: '(GMT+11:00) Ponape', name: 'Pacific/Pohnpei' },
  { label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy', name: 'Asia/Kamchatka' },
  { label: '(GMT+13:00) Auckland', name: 'Pacific/Auckland' },
  { label: '(GMT+13:00) Fiji', name: 'Pacific/Fiji' },
  { label: '(GMT+12:00) Funafuti', name: 'Pacific/Funafuti' },
  { label: '(GMT+12:00) Kwajalein', name: 'Pacific/Kwajalein' },
  { label: '(GMT+12:00) Majuro', name: 'Pacific/Majuro' },
  { label: '(GMT+12:00) Nauru', name: 'Pacific/Nauru' },
  { label: '(GMT+12:00) Tarawa', name: 'Pacific/Tarawa' },
  { label: '(GMT+12:00) Wake', name: 'Pacific/Wake' },
  { label: '(GMT+12:00) Wallis', name: 'Pacific/Wallis' },
  { label: '(GMT+14:00) Apia', name: 'Pacific/Apia' },
  { label: '(GMT+13:00) Enderbury', name: 'Pacific/Enderbury' },
  { label: '(GMT+13:00) Fakaofo', name: 'Pacific/Fakaofo' },
  { label: '(GMT+13:00) Tongatapu', name: 'Pacific/Tongatapu' },
  { label: '(GMT+14:00) Kiritimati', name: 'Pacific/Kiritimati' },
];
