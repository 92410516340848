import { connect } from 'react-redux';

import RequireUnAuth from '~/Components/Global/Auth/RequireUnAuth';

const mapStateToProps = ({ Auth }) => {
  return {
    isAuthenticated: Auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(RequireUnAuth);
