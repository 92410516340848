import LogRocket from 'logrocket';
import ReactGA from 'react-ga';
import config from '~/Config';
import { UserApi } from '~/Providers/Api';
import mParticle from '~/Providers/mParticle';

import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import { UPDATE_SUCCESS } from '~/Redux/Modules/Account';
import Storage from '~/Providers/Storage';

const FETCH = 'artful-client/User/FETCH';
const FETCH_SUCCESS = 'artful-client/User/FETCH_SUCCESS';
const FETCH_FAILURE = 'artful-client/User/FETCH_FAILURE';

const UPDATE_PAYMENT = 'artful-client/User/UPDATE_PAYMENT';
const UPDATE_PAYMENT_SUCCESS = 'artful-client/User/UPDATE_PAYMENT_SUCCESS';
const UPDATE_PAYMENT_FAILURE = 'artful-client/User/UPDATE_PAYMENT_FAILURE';

const SENT_GA_CLIENT_ID = 'artful-client/User/SENT_GA_CLIENT_ID';
const SENT_DEVICE_ID = 'artful-client/User/SENT_DEVICE_ID';

export const UPDATE_TRACKING_STATUS = 'artful-client/User/UPDATE_TRACKING_STATUS';

export const SET_USER = 'artful-client/User/SET_USER';

export const TRACKING_STATUS_NOT_DETERMINED = 'TrackingStatus.notDetermined';
export const TRACKING_STATUS_RESTRICTED = 'TrackingStatus.restricted';
export const TRACKING_STATUS_DENIED = 'TrackingStatus.denied';
export const TRACKING_STATUS_AUTHORIZED = 'TrackingStatus.authorized';
export const TRACKING_STATUS_NOT_SUPPORTED = 'TrackingStatus.notSupported';

const initialState = {
  isFetching: false,
  hasFetched: false,
  isUpdating: false,
  sentGAClientId: false,
  sentAdid: false,
  deviceTrackingStatus: localStorage.getItem('tracking_status') || TRACKING_STATUS_NOT_DETERMINED,
  data: {
    isSubscribed: false,
    isAdmin: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      if (action.data) {
        window.Rollbar && Rollbar.configure({ payload: { person: { id: action.data.id } } });
        window.profitwell && profitwell('start', { user_email: action.data.email });
        mParticle.identify(action.data);

        if (
          config.logrocket.enabled &&
          config.logrocket.userIds.filter(id => id === action.data.id).length
        ) {
          console.log('setup logrocket');
          LogRocket.init(config.logrocket.key, {
            release: `${VERSION}-${COMMIT}-${BUILD_NUMBER}`,
          });
          LogRocket.identify(action.data.id);
          console.log('done with setup');
        }
      }
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        data: action.data,
      };
    case FETCH_FAILURE:
      return { ...state, isFetching: false, hasFetched: true };

    case UPDATE_PAYMENT:
      return { ...state, isUpdating: true };
    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        hasFetched: true,
        data: action.data,
      };
    case UPDATE_PAYMENT_FAILURE:
      return { ...state, isUpdating: false, hasFetched: true };

    case SET_USER:
      return { ...state, data: action.user };

    case SENT_GA_CLIENT_ID:
      return { ...state, sentGAClientId: true };

    case SENT_DEVICE_ID:
      return { ...state, sentAdid: true };

    case UPDATE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, email: action.data.email, name: action.data.name },
      };

    case UPDATE_TRACKING_STATUS:
      return {
        ...state,
        deviceTrackingStatus: action.status,
        data: {
          ...state.data,
          tracking_status:
            action.data?.tracking_status || state.data?.tracking_status || action.status,
        },
      };

    default:
      return state;
  }
}

export const getUser = () => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await UserApi.fetch();
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const setUser = user => dispatch => dispatch({ type: SET_USER, user });

export const sendGAClientId = () => dispatch => {
  dispatch({ type: SENT_GA_CLIENT_ID });
  if (config.gaProperty) {
    ReactGA.ga(tracker => {
      console.log(`tracker: ${tracker.get('clientId')}`);
      UserApi.setGAClientId({ gaClientId: tracker.get('clientId') });
    });
  }
};

export const sendDeviceId = () => dispatch => {
  const deviceIdPair = document.cookie
    .split('; ')
    .map(cookiePair => cookiePair.split('='))
    .filter(cookiePair => cookiePair[0] == 'afUserId')[0];
  if (deviceIdPair) {
    UserApi.setDeviceId(deviceIdPair.join('=')).then(() => {
      dispatch({ type: SENT_DEVICE_ID });
    });
  }
};

export const updateTrackingStatus = status => async dispatch => {
  if (status != TRACKING_STATUS_NOT_DETERMINED) {
    try {
      let response = await UserApi.updateTrackingStatus(status);
      dispatch({ type: UPDATE_TRACKING_STATUS, data: response.data, status });
      return response;
    } catch (err) {
      dispatch({ type: UPDATE_TRACKING_STATUS, status });
    }
  } else {
    dispatch({ type: UPDATE_TRACKING_STATUS, status });
  }
};
