import React, { Component } from 'react';
import { mapStackTrace } from 'sourcemapped-stacktrace';

import { ConfirmDisconnectAccount } from '~/Components/ConfirmDisconnectAccount';
import ConfirmStickerRemovalModal from '~/Components/Global/Stickers/ConfirmStickerRemovalModal';
import UploadStickersModal from '~/Components/Global/Stickers/UploadStickersModal/UploadStickersModal';
import MyAccount from '~/Components/Pages/Account/MyAccount';
import CreateEvent from '~/Components/Pages/CreateEvent/CreateEvent';
import ReportProblemModal from '~/Components/Pages/Account/ReportProblemModal';
import HabitModalCreateEdit from '~/Components/Pages/Habits/HabitModalCreateEdit';
import { RevalidatePassword } from '~/Components/RevalidatePassword';
import PaymentModal from '~/Containers/Global/Stripe/PaymentModal';
import { errorApi } from '~/Providers/Api';
import { error } from '~/Redux/Modules/Notifications';
import Store from '~/Redux/Store';
import HabitModalDeleteStopTracking from '~/Components/Pages/Habits/HabitModalDeleteStopTracking';
import { ManageSubscription } from '~/Components/ManageSubscription';
import ConfirmColorRemovalModal from '~/Components/Global/ColorPicker/ConfirmColorRemovalModal';
import StickerEditTagsModal from '~/Components/Global/Stickers/StickerEditTagsModal';
import CalendarDisplayError from '~/Components/Pages/CalendarSettings/CalendarDisplayError';
import ChangeDisplayWarning from '~/Components/ChangeDisplay/ChangeDisplayWarning';

class GlobalModals extends Component {
  render() {
    return (
      <>
        <CreateEvent key="a" />
        <MyAccount key="b" />
        <PaymentModal key="c" />
        <ReportProblemModal key="d" />
        <RevalidatePassword key="e" />
        <ConfirmDisconnectAccount key="f" />
        <HabitModalCreateEdit key="g" />
        <UploadStickersModal key="h" />
        <ConfirmStickerRemovalModal key="i" />
        <HabitModalDeleteStopTracking key="j" />
        <ManageSubscription key="k" />
        <ConfirmColorRemovalModal key="l" />
        <StickerEditTagsModal key="m" />
        <CalendarDisplayError key="n" />
        <ChangeDisplayWarning key="o" />
      </>
    );
  }

  componentDidCatch(err, info) {
    console.error(err, info);
    window.error = err;
    mapStackTrace(err.stack, mappedStack => {
      errorApi.noticeError(mappedStack || err.stack, info);
      Store.dispatch(error('Sorry, an error occurred', err.message));
    });
  }
}

export default GlobalModals;
