import { createSelector } from 'reselect';
import moment from '~/Providers/Moment';
import { DATE_FORMAT } from '~/Redux/Constants';

export const formattedTasks = createSelector(
  ({
    Tasks: {
      taskCollection: { tasks },
    },
  }) => tasks,
  tasks =>
    tasks
      .map(task => ({
        ...task,
        startDate: moment(task.start, DATE_FORMAT),
        startString: task.start.substring(0, 10),
      }))
      .sort((a, b) => {
        if (a.priority < b.priority) return -1;
        if (a.priority > b.priority) return 1;
        return 0;
      })
);

export const allExceptType = (tasks, typeToFilter) =>
  tasks.filter(({ type }) => type !== typeToFilter);

export const allForDayAndType = (state, day, typeToSelect) =>
  formattedTasks(state).filter(({ type, startDate }) => {
    return typeToSelect === type && day.isSame(startDate, 'day');
  });

export const fetchingTasks = createSelector(
  ({
    Tasks: {
      taskCollection: { fetching },
    },
  }) => fetching,
  fetching => fetching
);
