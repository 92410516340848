import flatten from 'lodash.flatten';
import uniqBy from 'lodash.uniqby';
import moment from '~/Providers/Moment';
import { DATE_FORMAT, DATE_TIME_FORMAT, DEFAULT_TIME_ZONE } from '~/Redux/Constants';

/**
 * Get an array of all the all-day events in the given `events` object,
 * where events are sorted by their start date and end date. This sort is so that
 * longer events that start on a day are sorted before shorter events, e.g.
 * an event that start on 1/20 and ends 1/23 should be sorted before an event that starts on
 * 1/20 and ends on 1/21.
 *
 * @param {object} events An object of all events currently loaded,
 *                        with the key being the date that the event started on
 * @param {boolean} requireAllDay whether to filter out events that are not all day
 */
export const sortedAllDayEvents = (events, requireAllDay) => {
  events = flatten(Object.values(events));
  if (requireAllDay) events = events.filter(e => e.isAllDay);
  events = uniqBy(events, ({ id, startForRecurrence }) => `${id} ${startForRecurrence}`);
  return events.sort((one, two) => {
    if (one.startInZone.isBefore(two.startInZone)) return -1;
    if (one.startInZone.isAfter(two.startInZone)) return 1;
    if (one.endInZone.isBefore(two.endInZone)) return 1;
    if (one.endInZone.isAfter(two.endInZone)) return -1;
    if (one.summary < two.summary) return -1;
    if (one.summary > two.summary) return 1;
    return one.id - two.id;
  });
};

/**
 * Generate a matrix of event IDs to be rendered in specific indexes in the week view for the calendar.
 * The purpose of doing this is so that we can render events as individual elements in their days, but
 * have them appear as a single element (i.e. a 5 day event that's rendered as the second element in it's column should
 * continue as the second element across all 5 days)
 *
 * This function will return an array of arrays, where each inner array is seven items long, and the entries indicate
 * the event ID that should occupy that position in the UI. For example, the following array could be returned
 *
 * [
 *  [10, 10, 10, null, 15, 15, 15],
 *  [null, 12, 12, 12, null, 11, 11],
 *  [null, null, null, null, 13, 13, null]
 * ]
 *
 * This function assumes that weeks start on Sunday.
 *
 * @param {array} events A sorted array of events that occur during the given days
 * @param {moment} start Start date to generate indexes for
 * @return {array}
 */
export const weekEventIndexes = (events, start, requireAllDay = true) => {
  const weekStart = moment(start, DATE_TIME_FORMAT).startOf('week');
  const weekEnd = weekStart.clone().add(1, 'week');
  const sortedEvents = sortedAllDayEvents(events, requireAllDay);
  const eventRows = [];

  // Initialize eventIndexes with a single row
  // Each array in eventRows is an array of length 7, where each item represents a day
  // in that week, starting with `start` as the first day of the week
  eventRows.push(Array(7).fill(null));

  for (let event of sortedEvents) {
    // the [) below indicates an inclusive match for the start and exclusive for the end
    const days = eventDays(event)
      .filter(day => day.isBetween(weekStart, weekEnd, null, '[)'))
      .map(day => day.weekday());

    const eventInserted = eventRows.find((row, index) => {
      // See if every day in the event can fit into the current row
      const eventFitsInRow = days.every(day => !row[day]);

      // Update rows event IDs if it does
      if (eventFitsInRow) {
        days.forEach(
          day =>
            (eventRows[index][day] = {
              id: event.id,
              startForRecurrence: event.startForRecurrence ?? null,
            })
        );
      }

      return eventFitsInRow;
    });

    if (!eventInserted) {
      // If we get here and haven't found a slot for the event,
      // add a new row and inset the event into that row
      eventRows.push(Array(7).fill(null));
      days.forEach(
        day =>
          (eventRows[eventRows.length - 1][day] = {
            id: event.id,
            startForRecurrence: event.startForRecurrence ?? null,
          })
      );
    }
  }
  return eventRows;
};

/**
 * Provides the same functionality as weekEventIndexes, but for months.
 *
 * Returns a three-dimensional array with indexes for event position for each week in the month
 *
 * @param {*} events
 * @param {*} start
 * @param {*} end
 */
let savedMonthEventIndexes = null;
let cachedArgs = [];
export const monthEventIndexes = (events, start, end, locale) => {
  console.time(`monthEventIndexes ${locale}`);
  if (
    !savedMonthEventIndexes ||
    !(
      cachedArgs[0] === events &&
      cachedArgs[1].isSame(start, 'day') &&
      cachedArgs[2].isSame(end, 'day') &&
      cachedArgs[3] === locale
    )
  ) {
    console.log(`monthEventIndexes ${locale}: recalculating `);
    const overlappingEvents = overLappingEvents(events, start.clone(), end.clone());
    const monthStart = moment(start, DATE_TIME_FORMAT).startOf('month');
    const monthEnd = monthStart.clone().endOf('month');
    const curDate = monthStart.startOf('week');

    const weeks = [];

    while (curDate.isSameOrBefore(monthEnd)) {
      weeks.push(weekEventIndexes(overlappingEvents, curDate, false));
      curDate.add(1, 'week');
    }

    cachedArgs = [events, start, end, locale];
    savedMonthEventIndexes = weeks;
  }
  console.timeEnd(`monthEventIndexes ${locale}`);
  return savedMonthEventIndexes;
};

/**
 * Generate an array of moment objects for each day that this event spans,
 * from displayStart to displayEnd
 *
 * @param {object} event
 * @return {array} Array of moment objects for each day the event spans
 */
export const eventDays = event => {
  const currentDay = event.startInZone
    .clone()
    .locale(moment.locale())
    .startOf('day');
  let lastDay =
    event.endInZone.format('HHmmss') == '000000'
      ? event.endInZone
          .clone()
          .locale(moment.locale())
          .add(-1, 'day')
      : event.endInZone
          .clone()
          .locale(moment.locale())
          .startOf('day');
  if (lastDay.isBefore(currentDay)) lastDay = currentDay.clone();

  const days = [];

  while (currentDay.isSameOrBefore(lastDay)) {
    days.push(currentDay.clone());
    currentDay.add(1, 'day');
  }

  if (days.length == 0)
    console.warn(
      `No days for event ${event.id} (${event.startInZone.format(
        DATE_TIME_FORMAT
      )} - ${event.endInZone.format(DATE_TIME_FORMAT)})`
    );

  return days;
};

/**
 * returns all events thats are greater than 24 hours
 * and overlap those t
 * @param events
 * @param start
 * @param end
 * @param allDay
 */
export const overLappingEvents = (events, start, end, allDay = false) => {
  events = flatten(Object.values(events));
  events = uniqBy(events, ({ id, startForRecurrence }) => `${id} ${startForRecurrence}`);
  if (end.diff(start, 'days') > 7) {
    start.startOf('week');
    end.endOf('week');
  }
  const dateRange = moment.range(start, end);
  events = events.filter(event => {
    if (allDay && !event.isAllDay) return false;
    const eventRange = moment.range(event.startInZone, event.endInZone);
    return (
      eventRange.overlaps(dateRange) || (!event.isAllDay && dateRange.contains(event.endInZone))
    );
  });
  return events;
};

export const findUserTimeZone = () => moment.tz.guess() || DEFAULT_TIME_ZONE;

export const formatEvents = events => {
  const userTimeZone = findUserTimeZone();
  return events
    .map(event => {
      event = {
        ...event,
        startInZone: event.isAllDay
          ? moment(event.start, DATE_TIME_FORMAT).startOf('day')
          : moment
              .tz(
                event.start,
                DATE_TIME_FORMAT,
                (event.timezone && event.timezone.name) || DEFAULT_TIME_ZONE
              )
              .tz(userTimeZone),
        endInZone: event.isAllDay
          ? (() => {
              const eventEnd = moment(event.end, DATE_TIME_FORMAT);
              if (eventEnd.isSame(eventEnd.clone().startOf('day'))) {
                return eventEnd.startOf('day');
              } else {
                return moment
                  .tz(
                    event.end,
                    DATE_TIME_FORMAT,
                    (event.timezone && event.timezone.name) || DEFAULT_TIME_ZONE
                  )
                  .tz(userTimeZone);
              }
            })()
          : moment
              .tz(
                event.end,
                DATE_TIME_FORMAT,
                (event.timezone && event.timezone.name) || DEFAULT_TIME_ZONE
              )
              .tz(userTimeZone),
      };
      if (
        !event.isAllDay &&
        !event.startInZone.isSame(event.endInZone.subtract(1, 'second'), 'day')
      ) {
        event = { ...event, multiDayTimed: true };
      }
      if (!event.isAllDay) {
        event = {
          ...event,
          timeboxEnd:
            event.endInZone.diff(event.startInZone, 'minutes') < 30
              ? event.startInZone.clone().add(30, 'minutes')
              : event.endInZone.clone(),
        };
      }
      return event;
    })
    .sort((a, b) => {
      if (a.startInZone.isSame(b.startInZone, 'minute')) {
        if (a.endInZone.isSame(b.endInZone, 'minute')) {
          if (a.summary === b.summary) {
            return a.id - b.id;
          }
          return a.summary < b.summary ? -1 : 1;
        }
        return a.endInZone.isBefore(b.endInZone) ? -1 : 1;
      }
      return a.startInZone.isBefore(b.startInZone) ? -1 : 1;
    })
    .reduce((a, e) => {
      let returnVal = {
        ...a,
      };
      var day = e.startInZone.clone().startOf('day');
      var end = e.endInZone
        .clone()
        .subtract(1, 'minute')
        .endOf('day');
      if (end.isBefore(day)) {
        end = day.clone();
      }
      while (day.isSameOrBefore(end)) {
        returnVal = {
          ...returnVal,
          [day.format(DATE_FORMAT)]: [...(returnVal[day.format(DATE_FORMAT)] || []), e],
        };
        day.add(1, 'day');
      }
      return returnVal;
    }, {});
};

export const applyEventUpdates = (events, updates) => {
  return events.map(event =>
    (updates || [])
      .filter(update => {
        return (
          `${update.event.id}` === `${event.id}` &&
          update.event.startForRecurrence === event.startForRecurrence
        );
      })
      .reduce((a, e) => ({ ...a, ...e.diff }), event)
  );
};
