import propTypes from 'prop-types';

/**
 * requireUnAuth can be a boolean or a string
 */
const RequireUnAuth = ({ isAuthenticated, children }) => {
  //if the user is unauthenticated return the content
  if (!isAuthenticated) return children;
  return false;
};

RequireUnAuth.propTypes = {
  children: propTypes.node,
  isAuthenticated: propTypes.bool,
};

export default RequireUnAuth;
