import React from 'react';
import propTypes from 'prop-types';

/**
 * requireAuth can be a boolean or a string
 */
const RequireAuth = ({ isAuthenticated, children, requireAuth, renderOnFail: RenderOnFail }) => {
  // If requireAuth is not set or set to 'any', always return
  if (!requireAuth || requireAuth === 'any') return children;

  // If set to 'noAuth', only return if we are not Authenticated
  if (requireAuth === 'noAuth' && !isAuthenticated) return children;

  // If set to true or 'Auth', must be Authenticated to return children
  if ((requireAuth === true || requireAuth === 'Auth') && isAuthenticated) return children;

  // Return false or our NotAutenticatedComponent, if it was provided
  return RenderOnFail === false ? false : <RenderOnFail />;
};

RequireAuth.propTypes = {
  requireAuth: propTypes.oneOfType([propTypes.bool, propTypes.string]),
  children: propTypes.node,
  isAuthenticated: propTypes.bool,
  renderOnFail: propTypes.oneOfType([propTypes.node, propTypes.func, propTypes.bool]),
};

RequireAuth.defaultProps = {
  requireAuth: true,
  renderOnFail: false,
};

export default RequireAuth;
