import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CONFIRM_COLOR_REMOVAL_MODAL } from '~/Config/Modals';
import Modal from '~/Containers/Global/Modal/Modal';
import { closeModal, setModalData } from '~/Redux/Modules/Modal';
import { deleteColor } from '~/Redux/Modules/Colors';

import './ConfirmColorRemovalModal.scss';

function ConfirmColorRemovalModal() {
  const dispatch = useDispatch();

  const id = useSelector(state => state.Modal.modalData?.id);
  const color = useSelector(state => state.Modal.modalData?.color);

  const handleCancel = event => {
    event.stopPropagation();
    dispatch(closeModal(CONFIRM_COLOR_REMOVAL_MODAL));
    dispatch(setModalData(null));
  };

  const handleConfirm = () => {
    dispatch(deleteColor(id));
    dispatch(closeModal(CONFIRM_COLOR_REMOVAL_MODAL));
    dispatch(setModalData(null));
  };

  return (
    <Modal
      id={CONFIRM_COLOR_REMOVAL_MODAL}
      overlayClassName="confirm-color-removal-modal"
      showCloseButton={false}
    >
      <div className="modal-click-area" data-stop-drop-down-close>
        {color && <div className="color" style={{ backgroundColor: color }} />}

        <h1 className="heading">Hold On!</h1>
        <p>Are you sure you want to delete this color?</p>

        <div id="cancel-button" role="button" tabIndex="0" onClick={handleCancel}>
          Cancel
        </div>

        <div id="confirm-button" role="button" tabIndex="0" onClick={handleConfirm}>
          Delete Color
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmColorRemovalModal;
