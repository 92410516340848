/* eslint-disable max-len */
import React from 'react';

export default function HabitHangerIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#fnd6sgt5fa)">
        <path
          d="M26.558 20.948a2.784 2.784 0 0 0-.209-.13l-11.164-6.811v-.85c0-.652.353-1.243.921-1.544a2.552 2.552 0 0 0 1.384-2.392c-.06-1.273-1.212-2.398-2.516-2.456a2.658 2.658 0 0 0-1.95.711 2.53 2.53 0 0 0-.817 1.866c0 .182.15.328.335.328a.331.331 0 0 0 .336-.328c0-.53.217-1.024.61-1.391.393-.366.91-.555 1.454-.53.972.044 1.831.882 1.875 1.83.035.747-.36 1.431-1.032 1.785a2.391 2.391 0 0 0-1.273 2.122v.892L3.549 20.902l-.067.038c-.396.237-.566.65-.442 1.078.126.438.524.744.967.744.163 0 .325-.04.48-.12l.788-.404c.026.007.054.01.083.01h19.285a.352.352 0 0 0 .104-.015l.77.395c.156.08.318.12.482.12.428 0 .819-.292.953-.71s-.017-.836-.394-1.09zm-20.023.645 8.481-4.344 8.482 4.344H6.535z"
          fill={props.fill ? props.fill : '#000'}
          className="fill"
        />
      </g>
      <defs>
        <clipPath id="fnd6sgt5fa">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
