/* eslint-disable max-len */
import React from 'react';

export default function HabitPauseIcon(props) {
  return (
    <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.3334 6C9.81109 6 5.33337 10.4777 5.33337 16C5.33337 21.5223 9.81109 26 15.3334 26C20.8557 26 25.3334 21.5223 25.3334 16C25.3334 10.4777 20.8557 6 15.3334 6ZM14.0354 20.2857H12.0566V11.7143H14.0365L14.0354 20.2857ZM18.6114 20.2857H16.6315L16.6326 11.7143H18.6125L18.6114 20.2857Z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
