/* eslint-disable max-len */
import React from 'react';

export default function HabitWaterGlass(props) {
  return (
    <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45697 10.5212C6.00697 10.2669 5.84524 9.69504 6.09955 9.24387C6.35384 8.79387 6.92572 8.63331 7.37689 8.88645L8.17024 9.33411C8.53234 9.53801 8.97415 9.53801 9.33508 9.33411L10.5233 8.66496C11.5698 8.07668 12.8483 8.07668 13.8947 8.66496L14.9002 9.2298C15.376 9.49698 15.9561 9.49698 16.433 9.2298L17.4385 8.66496C18.485 8.07668 19.7635 8.07668 20.8099 8.66496L21.8154 9.2298C22.2912 9.49698 22.8713 9.49698 23.3482 9.2298L23.9588 8.88645C24.41 8.63449 24.983 8.79387 25.2361 9.24387C25.4904 9.69504 25.3287 10.2681 24.8787 10.5212L24.267 10.8646C23.2205 11.4528 25.3133 12.9551 24.267 12.3668V15.1793C23.7924 14.9121 23.4175 20.0871 22.9417 20.3543V27.3293C21.8952 27.9176 20.9365 28.3676 19.8901 27.7793L17.7667 28.3418C17.2921 28.0746 15.376 27.5121 14.9002 27.7793L9.33508 28.3418C8.40226 28.8668 8.18437 11.4927 7.25149 10.9677L6.45697 10.5212Z"
        fill={props.fill ? props.fill : 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8361 4.6349L23.5861 27.1349C23.5335 27.6633 23.3482 29.1048 23.3482 29.1048C23.3482 29.1048 22.0032 29.1048 21.4099 29.1048H9.922C9.32874 29.1048 7.97917 29.1048 7.97917 29.1048C7.97917 29.1048 7.79839 27.6633 7.74583 27.1349L5.49583 4.6349C5.43372 4.01966 5.27917 2.23022 5.27917 2.23022C5.27917 2.23022 7.05562 2.23022 7.6732 2.23022H23.6611C24.2787 2.23022 26.0917 2.23022 26.0917 2.23022C26.0917 2.23022 25.9006 4.01966 25.8385 4.6349H25.8361ZM23.9716 4.44858C23.9798 4.36069 23.9517 4.27397 23.8919 4.20717C23.8333 4.14272 23.749 4.10522 23.6611 4.10522H7.67318C7.58412 4.10522 7.50091 4.14272 7.44232 4.20717C7.38256 4.27397 7.35326 4.36069 7.36263 4.44858L9.61263 26.9486C9.62904 27.1091 9.76381 27.2298 9.92436 27.2298H21.4123C21.5728 27.2298 21.7076 27.1091 21.724 26.9486L23.974 4.44858H23.9716Z"
        fill={props.fill ? props.fill : 'black'}
      />
    </svg>
  );
}
