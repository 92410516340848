import mParticle from '@mparticle/web-sdk';
import leanplumKit from '@mparticle/web-leanplum-kit';
import Leanplum from 'leanplum-sdk';

import config from '~/Config';
import {
  TRACKING_STATUS_AUTHORIZED,
  TRACKING_STATUS_DENIED,
  TRACKING_STATUS_RESTRICTED,
} from '~/Redux/Modules/User';

class MParticleProvider {
  initialize = () => {
    if (config.mParticle.apiKey) {
      console.log('mParticle', mParticle);
      const mParticleConfig = {
        isDevelopmentMode: config.mParticle.devMode,
        dataPlan: {
          planId: config.mParticle.planId,
          planVersion: config.mParticle.planVersion,
        },
        v1SecureServiceUrl: 'mp.artfulagenda.com/webevents/v1/JS/',
        v2SecureServiceUrl: 'mp.artfulagenda.com/webevents/v2/JS/',
        v3SecureServiceUrl: 'mp.artfulagenda.com/webevents/v3/JS/',
        configUrl: 'mp.artfulagenda.com/tags/JS/v2/',
        identityUrl: 'mp.artfulagenda.com/identity/v1/',
        aliasUrl: 'mp.artfulagenda.com/webevents/v1/identity/',
        identityCallback: result => {
          console.log('mParticle', 'identityCallback', result);

          if (!localStorage.getItem('recordedMParticleConsent')) {
            switch (localStorage.getItem('tracking_status')) {
              case TRACKING_STATUS_AUTHORIZED:
                this.recordTrackingConsent(true);
                break;
              case TRACKING_STATUS_DENIED:
              case TRACKING_STATUS_RESTRICTED:
                this.recordTrackingConsent(false);
                break;
            }
          }
        },
      };
      leanplumKit.register(mParticleConfig);
      mParticle.init(config.mParticle.apiKey, mParticleConfig);
    }
  };

  identify = ({ id, email }) => {
    mParticle.Identity.identify(
      {
        userIdentities: {
          email: email,
          customerid: `${config.mParticle.prefix}${id}`,
        },
      },
      result => {
        console.log('mParticle', 'identity result', result);
        Leanplum.forceContentUpdate();
        Leanplum.getVariables();
      }
    );
    window?.AF?.('pba', 'setCustomerUserId', `${config.mParticle.prefix}${id}`);
  };

  logEvent = event => {
    console.log('mParticle', 'logEvent', event);
    event.logTo(mParticle);
  };

  recordConsent = (purpose, consented) => {
    console.log('mParticle', 'recordConsent', purpose, consented);
    const user = mParticle.Identity.getCurrentUser();
    if (!user) {
      console.log('mParticle', 'recordConsent', 'no user');
      return false;
    }
    const consent = mParticle.Consent.createConsentState(consented, Date.now());
    const consentState = mParticle.Consent.createConsentState(purpose, consent);
    user.setConsentState(consentState);
    return true;
  };

  recordTrackingConsent = consented => {
    if (this.recordConsent('tracking', consented)) {
      localStorage.setItem('recordedMParticleConsent', true);
    }
  };

  recordMarketingConsent = consented => this.recordConsent('marketing', consented);
}

class MParticleEvent {
  constructor(eventName, eventAttributes = {}) {
    this.eventName = eventName;
    this.eventAttributes = eventAttributes;
  }

  logTo = mpInstance => {
    mpInstance.logEvent(this.eventName, mParticle.EventType.Other, this.eventAttributes);
    window?.AF?.('pba', 'event', {
      eventType: 'EVENT',
      eventName: this.eventName,
      eventValue: this.eventAttributes,
    });
  };
}

export class SocialRegistrationPresentedEvent extends MParticleEvent {
  constructor(allowSocialLogin) {
    super('Social Registration Presented', { socialLogin: allowSocialLogin });
  }
}

export class PricingGroupPresented extends MParticleEvent {
  constructor(pricingGroup) {
    super('Pricing Group - Presented', { pricingGroup: pricingGroup });
  }
}

export class StartTrialEvent extends MParticleEvent {
  constructor(productName, pricingGroup) {
    super('User - Start Trial', { productName: productName, pricingGroup: pricingGroup });
  }
}

export class UpgradeEvent extends MParticleEvent {
  constructor(productName) {
    super('User - Upgrade', { productName: productName });
  }
}

export class DowngradeEvent extends MParticleEvent {
  constructor(productName) {
    super('User - Downgrade', { productName: productName });
  }
}

export class ScreenViewEvent extends MParticleEvent {
  constructor(screenName) {
    super(screenName);
  }

  logTo = mpInstance => {
    console.log('mParticle', 'ScreenView', this.eventName);
    // mpInstance.logPageView(this.eventName);
    // window?.AF?.('pba', 'event', {
    //   eventType: 'EVENT',
    //   eventName: `Screen View - ${this.eventName}`,
    //   eventValue: this.eventAttributes,
    // });
  };
}

export class CreateEventEvent extends MParticleEvent {
  constructor() {
    super('Events - Create');
  }
}

export class CreateHabitEvent extends MParticleEvent {
  constructor() {
    super('Habits - Create');
  }
}

export class CreateHabitTrackedEvent extends MParticleEvent {
  constructor() {
    super('HabitTracked - Create');
  }
}

export class CreateTaskEvent extends MParticleEvent {
  constructor() {
    super('Tasks - Create');
  }
}

export class CompleteTaskEvent extends MParticleEvent {
  constructor() {
    super('Tasks - Complete');
  }
}

export class RenameTaskSectionEvent extends MParticleEvent {
  constructor(taskType) {
    super('Tasks - Rename Section', { taskType });
  }
}

export class MealPrepUpdateEvent extends MParticleEvent {
  constructor() {
    super('Meal Preps - Update');
  }
}

export class RenameMealPrepSectionEvent extends MParticleEvent {
  constructor() {
    super('Meal Preps - Rename Section');
  }
}

export class WaterTrackedEvent extends MParticleEvent {
  constructor() {
    super('Water - Tracked');
  }
}

export class ListCreateEvent extends MParticleEvent {
  constructor() {
    super('Lists - Create');
  }
}

export class FolderCreateEvent extends MParticleEvent {
  constructor() {
    super('Folders - Create');
  }
}

export class SupportRequestEvent extends MParticleEvent {
  constructor(category) {
    super('Support Request', { category });
  }
}

export class SelectCoverEvent extends MParticleEvent {
  constructor(productId) {
    super('Theme - Select Cover', { productId: productId?.toString() });
  }
}

export class SelectFontEvent extends MParticleEvent {
  constructor(name) {
    super('Theme - Select Font', { name });
  }
}

export class CalendarAccountExpandedEvent extends MParticleEvent {
  constructor(provider) {
    super('Accounts - Expanded', { provider: provider });
  }
}

export class CalendarAccountConnectedEvent extends MParticleEvent {
  constructor(provider) {
    super('Accounts - Connected', { provider: provider });
  }
}

export class CalendarAccountDisconnectedEvent extends MParticleEvent {
  constructor(provider) {
    super('Accounts - Disconnected', { provider: provider });
  }
}

export class StartPlanningEvent extends MParticleEvent {
  constructor() {
    super('Start Planning');
  }
}

export class ViewProductEvent extends MParticleEvent {
  constructor(product) {
    super('');
    this.product = product;
  }

  logTo = mpInstance => {
    const { description, productable_id, productable_type, price } = this.product;
    mpInstance.eCommerce.logProductAction(mParticle.ProductActionType.ViewDetail, {
      Id: `${productable_type}_${productable_id}`,
      Name: description,
      Quantity: 1,
      Price: price,
    });

    window?.AF?.('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'Product - View Detail',
      eventValue: {
        Id: `${productable_type}_${productable_id}`,
        Name: description,
        Quantity: 1,
        Price: price,
      },
    });
  };
}

// TODO:
// - Tracking Consent

export default new MParticleProvider();
