import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import Button from '~/Components/Global/Form/Button';
import {
  TRACKING_STATUS_AUTHORIZED,
  TRACKING_STATUS_DENIED,
  TRACKING_STATUS_NOT_DETERMINED,
  updateTrackingStatus,
} from '~/Redux/Modules/User';
import config from '~/Config';
import history from '~/Providers/History';
import mParticle from '~/Providers/mParticle';
import { CONSENT_TYPE_COOKIES } from './ConsentRequiredView';
import { useConsentSettings } from './ConsentRequiredView/useConsentSettings';

const TrackingPopup = () => {
  const deviceStatus = useSelector(
    ({ User }) => User?.deviceTrackingStatus || TRACKING_STATUS_NOT_DETERMINED
  );
  const dispatch = useDispatch();
  const consentSettings = useConsentSettings();

  const enableTracking = () => {
    window?.gtag?.('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
    window?.vgo?.('process', 'allowTracking');
    window?.fbq?.('consent', 'grant');

    if (config.gaProperty) {
      ReactGA.initialize(config.gaProperty);
      history.listen(location => {
        ReactGA.pageview(location.pathname);
      });
      ReactGA.pageview(window.location.pathname);
    }
  };

  useEffect(() => {
    if (consentSettings && !consentSettings?.[CONSENT_TYPE_COOKIES]) {
      enableTracking();
    }
  }, [consentSettings]);

  useEffect(() => {
    if (deviceStatus === TRACKING_STATUS_AUTHORIZED) {
      enableTracking();
    }
  }, [deviceStatus]);

  if (!consentSettings?.[CONSENT_TYPE_COOKIES]) return null;

  if (deviceStatus != TRACKING_STATUS_NOT_DETERMINED) return null;

  const acceptTracking = () => {
    localStorage.setItem('tracking_status', TRACKING_STATUS_AUTHORIZED);
    mParticle.recordTrackingConsent(true);
    enableTracking();
    return dispatch(updateTrackingStatus(TRACKING_STATUS_AUTHORIZED)).catch(() => {});
  };

  const declineTracking = () => {
    localStorage.setItem('tracking_status', TRACKING_STATUS_DENIED);
    mParticle.recordTrackingConsent(false);
    window?.gtag?.('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
    window?.fbq?.('consent', 'revoke');
    window?.AF_SDK?.PLUGINS?.PBA?.disableMeasurement?.();
    if (config.gaProperty) {
      window[`ga-disable-${config.gaProperty}`] = true;
    }
    return dispatch(updateTrackingStatus(TRACKING_STATUS_DENIED)).catch(() => {});
  };

  return (
    <div className="tracking-popup">
      <div className="tracking-popup__header">This site uses cookies</div>
      <div className="tracking-popup__body">
        <p>
          We use cookies to personalize your experience, improve our services, and analyze the use
          of our site.
        </p>
        <p>
          Please review our{' '}
          <a target="_blank" href="https://artfulagenda.com/privacy">
            Privacy Policy
          </a>{' '}
          for more information.
        </p>
      </div>
      <div className="tracking-popup__actions">
        <Button
          text="DECLINE"
          className="btn btn-default btn-secondary"
          onClick={declineTracking}
        />
        <Button text="GOT IT!" className="btn btn-default btn-lg" onClick={acceptTracking} />
      </div>
    </div>
  );
};

TrackingPopup.propTypes = {};

export default TrackingPopup;
