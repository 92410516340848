import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import FontAwesome from 'react-fontawesome';
import moment from '~/Providers/Moment';
import { ManageAlarms } from '~/Components/ManageAlarms';

import {
  updateField,
  updateTimezone,
  createEvent,
  updateEvent,
  deleteEvent,
  clearEvent,
  setCurrentEvent,
} from '~/Redux/Modules/Events';
import {
  findCalendarInList,
  mapCalendarsToList,
  findCalendarAccount,
} from '~/Redux/Modules/CalendarAccounts';
import { showConfirm, hideConfirm } from '~/Redux/Modules/EventConfirmation';
import { DATE_TIME_FORMAT, DATE_FORMAT, DEFAULT_TIME_ZONE } from '~/Redux/Constants';
import createQuill from '~/Components/Quill/Quill';

import Modal from '~/Containers/Global/Modal/Modal';
import DatePicker from '~/Components/Global/DatePicker/DatePicker';
import TimeZoneSelect from '~/Components/Global/TimeZoneSelect/TimeZoneSelect';
import TextInput from '~/Components/Global/Form/TextInput';
import Button from '~/Components/Global/Form/Button';
import Checkbox from '~/Components/Global/Form/Checkbox';
import GeoSuggest from '~/Components/Global/Form/GeoSuggest';
import CalendarSelect from '~/Containers/Global/Form/CalendarSelect';
import ColorPicker from '~/Components/Global/ColorPicker/ColorPicker';
import { EVENT_CREATE_MODAL } from '~/Config/Modals';
import {
  humanDateFormats,
  HUMAN_DATE_FORMAT,
  HUMAN_TIME_FORMAT,
} from '~/Redux/Modules/UserOptions';
import { ChangeDisplay } from '~/Components/ChangeDisplay';
import { RepeatRule } from '../Calendar/RepeatRule';
import Attendees from './Attendees';

import './CreateEvent.scss';

const timedAlarms = alarms => (alarms || []).filter(({ all_day }) => !all_day);
const allDayAlarms = alarms => (alarms || []).filter(({ all_day }) => all_day);
const alarmsForCalendar = (calendar, allDay) =>
  allDay ? allDayAlarms(calendar.alarms) : timedAlarms(calendar.alarms);

export class CreateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allDay: false,
      confirming: false,
      repeat: false,
      originalRepeat: false,
      summary: false,
      location: false,
      description: false,
      alarms: [],
      displayMonthView: null,
      displayWeekView: null,
      displayDayView: null,
    };
  }

  handleDateChange = (newMoment, name) => {
    if (!newMoment) return;
    const { start, end } = this.props.currentEvent;
    const { humanDateFormats } = this.props;
    if (newMoment._f === humanDateFormats[HUMAN_TIME_FORMAT]) {
      newMoment = moment(
        `${moment(this.props.currentEvent[name], DATE_TIME_FORMAT).format(
          DATE_FORMAT
        )} ${newMoment.format(humanDateFormats[HUMAN_TIME_FORMAT])}`,
        `${DATE_FORMAT} ${humanDateFormats[HUMAN_TIME_FORMAT]}`
      );
    }

    if (this.state.allDay && name === 'end') {
      if (newMoment._f === humanDateFormats[HUMAN_DATE_FORMAT])
        newMoment.add(1, 'day').subtract(1, 'minute');
      newMoment.add(1, 'minute');
    }

    const mStart = moment(start, DATE_TIME_FORMAT);
    const mEnd = moment(end, DATE_TIME_FORMAT);
    const diff = mEnd.diff(mStart, 'minutes');

    if (name === 'start') {
      this.props.updateField({
        start: newMoment.format(DATE_TIME_FORMAT),
        end: newMoment
          .clone()
          .add(diff, 'minutes')
          .format(DATE_TIME_FORMAT),
      });
      return;
    }

    if (name === 'end') {
      if (
        newMoment.diff(mStart, 'minutes') < 0 ||
        (this.state.allDay && newMoment.diff(mStart, 'days') < 1)
      ) {
        this.props.updateField({
          end: newMoment.format(DATE_TIME_FORMAT),
          start: newMoment
            .clone()
            .add(-1 * diff, 'minutes')
            .format(DATE_TIME_FORMAT),
        });
        return;
      }
    }

    this.props.updateField({ [name]: newMoment.format(DATE_TIME_FORMAT) });
  };

  handleUpdateCalendar = ({ calendar_id }) => {
    const oldCalendar = findCalendarInList(
      this.props.calendars,
      this.props.currentEvent.calendar_id
    );
    const calendar = findCalendarInList(this.props.calendars, calendar_id);
    this.props.updateField({ calendar_id: calendar_id, calendar: calendar });
    if (calendar.timezone && calendar.timezone.name) {
      this.props.updateTimezone({ name: calendar.timezone.name });
    } else {
      this.props.updateTimezone({ name: this.props.userTimeZone });
    }
    if (
      calendar.color &&
      (!oldCalendar ||
        oldCalendar.color?.toLowerCase() === this.props.currentEvent.color?.toLowerCase())
    ) {
      this.props.updateField({ color: calendar.color });
    }
    if (
      JSON.stringify(alarmsForCalendar(oldCalendar, this.state.allDay)) ==
      JSON.stringify(this.state.alarms)
    ) {
      this.setState({
        alarms: JSON.parse(JSON.stringify(alarmsForCalendar(calendar, this.state.allDay))),
      });
    }
  };

  handleSave = () => {
    let updates = {};
    const calendar = findCalendarInList(this.props.calendars, this.props.currentEvent.calendar_id);
    if (this.state.summary !== false && this.state.summary !== this.props.currentEvent.summary)
      updates.summary = this.state.summary;
    if (this.state.location !== false && this.state.location !== this.props.currentEvent.location)
      updates.location = this.state.location;
    if (
      this.state.description !== false &&
      this.state.description !== this.props.currentEvent.description
    )
      updates.description = this.state.description;
    if (this.props.currentEvent.color?.toLowerCase() === calendar.color?.toLowerCase()) {
      updates.color = '';
      updates.calendarColor = calendar.color;
    }
    if (
      JSON.stringify(this.state.alarms) ==
      JSON.stringify(alarmsForCalendar(calendar, this.state.allDay))
    ) {
      updates.alarms = [];
      updates.useCalendarAlarms = true;
    } else {
      updates.alarms = this.state.alarms;
      updates.useCalendarAlarms = false;
    }
    if (this.state.displayMonthView !== this.props.currentEvent.displayMonthView)
      updates.displayMonthView = this.state.displayMonthView;
    if (this.state.displayWeekView !== this.props.currentEvent.displayWeekView)
      updates.displayWeekView = this.state.displayWeekView;
    if (this.state.displayDayView !== this.props.currentEvent.displayDayView)
      updates.displayDayView = this.state.displayDayView;
    this.props.createEvent(
      {
        ...this.props.currentEvent,
        ...updates,
      },
      this.props.currentView,
      this.props.currentViewStart
    );
  };

  handleUpdate = () => {
    if (this.state.originalRepeat) {
      this.setState({ confirming: 'update' });
      this.props.showConfirm('Edit recurring event', this.handleConfirm, () =>
        this.setState({ confirming: false })
      );
    } else {
      this.doUpdate();
    }
  };

  doUpdate = confirmResult => {
    let updates = {};
    const calendar = findCalendarInList(this.props.calendars, this.props.currentEvent.calendar_id);
    if (this.state.summary !== false && this.state.summary !== this.props.currentEvent.summary)
      updates.summary = this.state.summary;
    if (this.state.location !== false && this.state.location !== this.props.currentEvent.location)
      updates.location = this.state.location;
    if (
      this.state.description !== false &&
      this.state.description !== this.props.currentEvent.description
    )
      updates.description = this.state.description;
    if (this.props.currentEvent.color?.toLowerCase() === calendar.color?.toLowerCase()) {
      updates.color = '';
      updates.calendarColor = calendar.color;
    }

    updates.alarms = this.state.alarms;
    updates.useCalendarAlarms =
      (this.props.currentEvent.use_calendar_alarms || !this.props.currentEvent.id) &&
      JSON.stringify(this.state.alarms) ==
        JSON.stringify(alarmsForCalendar(calendar, this.props.currentEvent.isAllDay));

    if (this.state.displayMonthView !== this.props.currentEvent.displayMonthView)
      updates.displayMonthView = this.state.displayMonthView;
    if (this.state.displayWeekView !== this.props.currentEvent.displayWeekView)
      updates.displayWeekView = this.state.displayWeekView;
    if (this.state.displayDayView !== this.props.currentEvent.displayDayView)
      updates.displayDayView = this.state.displayDayView;

    if (confirmResult) {
      updates.confirm = confirmResult;
    }
    this.props.updateEvent(
      {
        ...this.props.currentEvent,
        ...updates,
      },
      this.props.currentView,
      this.props.currentViewStart
    );
  };

  handleDelete = () => {
    if (this.state.originalRepeat) {
      this.setState({ confirming: 'delete' });
      this.props.showConfirm('Delete recurring event', this.handleConfirm, () =>
        this.setState({ confirming: false })
      );
    } else if (window.confirm('Are you sure you want to delete this event?')) {
      this.doDelete();
    }
  };

  doDelete = confirmResult => {
    this.props.deleteEvent(
      { ...this.props.currentEvent, confirm: confirmResult },
      this.props.currentView,
      this.props.currentViewStart
    );
  };

  handleConfirm = result => {
    console.log(`${this.state.confirming}: ${result}`);
    this.setState({ confirming: false });
    if (this.state.confirming === 'update') {
      this.doUpdate(result);
    } else {
      this.doDelete(result);
    }
  };

  handleAllDay = () => {
    const {
      updateField,
      currentEvent,
      updateTimezone,
      calendars,
      defaultCalendar,
      userTimeZone,
      humanDateFormats,
    } = this.props;
    const { allDay } = this.state;

    const currentCalendar = findCalendarInList(calendars, currentEvent.calendar_id);
    let alarms =
      (currentEvent.use_calendar_alarms || !currentEvent.id) && currentCalendar?.alarms
        ? JSON.parse(JSON.stringify(alarmsForCalendar(currentCalendar, !allDay)))
        : [];

    if (!allDay) {
      const currentStart = moment(currentEvent.start, DATE_TIME_FORMAT);
      updateField({
        start: currentStart.startOf('day').format(DATE_TIME_FORMAT),
        end: currentStart
          .clone()
          .add(1, 'day')
          .startOf('day')
          .format(DATE_TIME_FORMAT),
      });
      return this.setState({ allDay: true, alarms: alarms });
    }

    const newStart = moment(
      `${moment(currentEvent.start, DATE_TIME_FORMAT).format(DATE_FORMAT)} ${moment()
        .round(30, 'minutes')
        .format(humanDateFormats[HUMAN_TIME_FORMAT])}`,
      `${DATE_FORMAT} ${humanDateFormats[HUMAN_TIME_FORMAT]}`
    );
    const curEnd = moment(currentEvent.end, DATE_TIME_FORMAT).subtract(1, 'minute');
    updateField({
      start: newStart.format(DATE_TIME_FORMAT),
      end: curEnd.isSame(newStart, 'day')
        ? moment(newStart)
            .add(1, 'hour')
            .format(DATE_TIME_FORMAT)
        : moment(
            `${moment(currentEvent.end, DATE_TIME_FORMAT)
              .subtract(1, 'minute')
              .format(DATE_FORMAT)} ${moment(newStart, DATE_TIME_FORMAT)
              .add(1, 'hour')
              .format(humanDateFormats[HUMAN_TIME_FORMAT])}`,
            `${DATE_FORMAT} ${humanDateFormats[HUMAN_TIME_FORMAT]}`
          ).format(DATE_TIME_FORMAT),
    });

    updateTimezone({
      name:
        (currentCalendar && currentCalendar.timezone && currentCalendar.timezone.name) ||
        (defaultCalendar && defaultCalendar.timezone && defaultCalendar.timezone.name) ||
        userTimeZone,
    });
    this.setState({ allDay: false, alarms: alarms });
  };

  handleRepeat = () => {
    const { repeat } = this.state;
    this.setState({
      repeat: !repeat,
    });
    this.props.updateField({
      repeat_rule: !repeat ? 'FREQ=DAILY' : '',
      repeats: !repeat,
    });
  };

  handleAddGoogleMeet = () => {
    this.props.updateField({
      add_google_meet: !this.props.currentEvent.add_google_meet,
    });
  };

  handleChangeDisplay = displayOptions => {
    const { display_month_view, display_week_view, display_day_view } = displayOptions;
    if (display_month_view !== undefined) {
      this.setState({ displayMonthView: display_month_view });
      this.props.updateField({ display_month_view: display_month_view });
    }

    if (display_week_view !== undefined) {
      this.setState({ displayWeekView: display_week_view });
      this.props.updateField({ display_week_view: display_week_view });
    }

    if (display_day_view !== undefined) {
      this.setState({ displayDayView: display_day_view });
      this.props.updateField({ display_day_view: display_day_view });
    }
  };

  handleClearEvent = () => {
    window?.webkit?.messageHandlers?.iosListener?.postMessage(
      JSON.stringify({ name: 'closeColorPicker' })
    );
    this.props.clearEvent();
    this.setState({
      repeat: false,
      allDay: false,
      summary: false,
      location: false,
      description: false,
      confirming: false,
      alarms: [],
      originalDescription: false,
    });
  };

  initializeQuill = r => {
    if (!r) return;
    this.editor = r;
    this.quill = createQuill(this.editor, {
      modules: {
        toolbar: false,
        keyboard: { bindings: { noEnter: { key: 13, handler: () => true } } },
      },
    });

    this.quill.on('text-change', () => {
      console.log('Events', 'text-change');
      if (this.didSetContents) {
        console.log('Events', 'text-change', 'didSetContents', this.handleGetCurrentContents());
        this.didSetContents = false;
        this.setState({ originalDescription: this.handleGetCurrentContents() });
      } else {
        this.debouncedUpdate({ description: this.handleGetCurrentContents() });
      }
    });

    const formatAngleLinks = text => {
      if (!text) return text;
      const linkMatcher = new RegExp(
        /\<(((https?:\/\/)?([-a-zA-Z0-9@:%_\+~#=]{1,256}\.)+[a-z]{2,6}([-a-zA-Z0-9@:%_\+.~#?!&//=]*))|(tel\:.+))\>/g
      );
      Array.from(text.matchAll(linkMatcher)).forEach(match => {
        console.log('formatAngleLinks', match);
        text = text.replace(match[0], `&lt;${match[1]}&gt;`);
      });
      return text;
    };

    if (this.props.currentEvent) {
      this.handleSetCurrentContents(formatAngleLinks(this.props.currentEvent.description));
    }
  };

  handleGetCurrentContents = () => {
    if (!this.quill) return '';
    return this.quill.container.querySelector('.ql-editor').innerHTML.replace(/>\s+</g, '>&nbsp;<');
  };

  handleSetCurrentContents = html => {
    if (!this.quill) return;
    console.log('Events', 'handleSetCurrentContents', html);
    this.didSetContents = true;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('Events', 'handleSetCurrentContents', 'clear didSetContents flag');
      this.didSetContents = false;
    }, 100);
    this.quill.setContents(this.quill.clipboard.convert(html));
    this.quill.history.clear();
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.currentEvent.id != this.props.currentEvent.id ||
      (nextProps.currentEvent.forceUpdate && !this.props.currentEvent.forceUpdate)
    ) {
      const { calendars, currentEvent } = nextProps;
      let currentCalendar = calendars.find(({ id }) => id == currentEvent.calendar_id);

      let alarms =
        (currentEvent.use_calendar_alarms || !currentEvent.id) && currentCalendar?.alarms
          ? JSON.parse(
              JSON.stringify(alarmsForCalendar(currentCalendar, nextProps.currentEvent.isAllDay))
            )
          : currentEvent.alarms;
      this.setState({
        allDay: nextProps.currentEvent.isAllDay,
        repeat: !!nextProps.currentEvent.repeats,
        originalRepeat: !!nextProps.currentEvent.repeats,
        summary: false,
        location: false,
        description: false,
        alarms: alarms || [],
      });
      this.handleSetCurrentContents(nextProps.currentEvent.description);
    }
  };

  renderRepeatForm = () => {
    if (!this.state.repeat) return;
    const { updateField, currentEvent } = this.props;

    return (
      <RepeatRule
        repeatable={currentEvent}
        onChange={rule => updateField({ repeat_rule: rule })}
        allDay={this.state.allDay}
      />
    );
  };

  debouncedUpdate = ({ summary, location, description }) => {
    let stateUpdate = {};
    if (summary !== undefined) stateUpdate.summary = summary;
    if (location !== undefined) stateUpdate.location = location;
    if (
      description !== undefined &&
      description != this.props.currentEvent?.description &&
      description != this.state.originalDescription
    )
      stateUpdate.description = description;
    console.log(
      'Events',
      'debouncedUpdate',
      description,
      this.props.currentEvent.description,
      this.state.originalDescription,
      description == this.props.currentEvent.description,
      Object.keys(stateUpdate)
    );
    this.setState({ ...stateUpdate });
    this.debouncer(stateUpdate);
  };

  debouncer = debounce(update => {
    this.props.updateField(update);
  }, 200);

  handleClick = e => {
    console.log('handleClick', e.target, e.target.tagName);
    if (e.target.tagName === 'A') window.open(e.target.href, '_blank');
  };

  handleDuplicate = () => {
    const { setCurrentEvent, currentEvent } = this.props;
    let event = JSON.parse(JSON.stringify(currentEvent));
    event.id = null;
    event.ics = null;
    setCurrentEvent(event);
  };

  render() {
    const {
      id,
      location,
      summary,
      start,
      end,
      contact_events,
      timezone,
      calendar_id,
      color,
      add_google_meet,
    } = this.props.currentEvent;
    const { isUpdating, isDeleting, isFetching, calendarAccounts, calendars } = this.props;

    const calendarAccount = findCalendarAccount(
      calendarAccounts,
      {
        calendar_account_id: null,
        ...findCalendarInList(calendars, calendar_id),
      }.calendar_account_id
    );

    return (
      <Modal
        showCloseButton
        overlayClassName="event-action-modal"
        id={this.props.modalId}
        callback={this.handleClearEvent}
        buttons={
          !!id && (
            <button
              disabled={isDeleting || isFetching}
              className="btn btn-default clone-btn"
              onClick={() => this.handleDuplicate()}
            >
              <FontAwesome name="clone" />
            </button>
          )
        }
      >
        <div className="event-modal" key="event-modal">
          <div className="flex-row">
            <div className="flex-1 event-label">
              <TextInput
                className="minimal-input"
                placeholder="EVENT NAME"
                value={this.state.summary === false ? summary : this.state.summary}
                name="summary"
                onChange={this.debouncedUpdate}
                autoFocus
              />
            </div>
            <ColorPicker
              selectedColor={color}
              onChange={this.props.updateField}
              showPreview
              isColorPickerInEventModal
            />
          </div>
          <div className="flex-row">
            <div className="flex-1">
              <GeoSuggest
                name="location"
                label="at"
                onChange={this.debouncedUpdate}
                value={this.state.location === false ? location : this.state.location}
                placeholder="Start Typing any address"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <DatePicker
                className="minimal-input inline-input"
                name="start"
                label="from"
                value={start}
                onChange={m => this.handleDateChange(m, 'start')}
                showTimeSelect={!this.state.allDay}
              />
              <DatePicker
                className="minimal-input inline-input"
                name="end"
                label="until"
                wrapperClassName="inline"
                value={
                  this.state.allDay
                    ? moment(end, DATE_TIME_FORMAT)
                        .subtract(1, 'minute')
                        .format(DATE_TIME_FORMAT)
                    : end
                }
                onChange={m => this.handleDateChange(m, 'end')}
                showTimeSelect={!this.state.allDay}
              />
            </div>
            <div className="col-sm-6 calendar-timezone-area">
              <CalendarSelect
                name="calendar_id"
                label="Calendar"
                onChange={this.handleUpdateCalendar}
                value={calendar_id}
              />
              {this.state.allDay || (
                <TimeZoneSelect
                  label="Time Zone"
                  name="name"
                  value={timezone ? timezone.name : false}
                  onChange={this.props.updateTimezone}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              {calendarAccount?.driver === 'GOOGLE' && id == null && (
                <Checkbox
                  className="no-margin-left"
                  name="add-google-meet"
                  type="checkbox"
                  label="Add Google Meet"
                  value={add_google_meet}
                  onChange={this.handleAddGoogleMeet}
                />
              )}
            </div>
            <div className="col-sm-6">
              <Checkbox
                className="no-margin-left"
                name="all-day"
                type="checkbox"
                label="All Day"
                value={this.state.allDay}
                onChange={this.handleAllDay}
              />
              {!this.props.currentEvent?.recurrence_id && (
                <span className="margin-left">
                  <Checkbox
                    type="checkbox"
                    name="repeat"
                    label="Repeat"
                    value={this.state.repeat}
                    onChange={this.handleRepeat}
                  />
                </span>
              )}
            </div>
          </div>
          {this.renderRepeatForm()}
          <div className="row margin-top">
            <div className="col-sm-6">
              <Attendees
                attendees={contact_events || []}
                updateField={this.props.updateField}
                calendar={findCalendarInList(calendars, calendar_id)}
                calendarAccount={calendarAccount}
              />
              <label htmlFor="manageAlarms" className="manage-alarms-label">
                Alarms
              </label>
              <ManageAlarms
                alarms={this.state.alarms}
                updateField={({ alarms }) => {
                  console.log('CreateEvent', 'alarms updated');
                  this.setState({ alarms });
                }}
                allDay={this.state.allDay}
              />
              <label htmlFor="changeDisplay" className="change-display-label">
                Change Display
              </label>
              <ChangeDisplay
                event={this.props.currentEvent}
                currentCalendar={findCalendarInList(calendars, calendar_id)}
                onSave={this.handleChangeDisplay}
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="description">Description</label>
              <div className="description" ref={this.initializeQuill} onClick={this.handleClick} />
            </div>
          </div>
          <div className="row margin-top">
            {id ? (
              [
                <div key="a" className="col-xs-6 align-right">
                  <Button
                    text="SAVE"
                    actionText="SAVING"
                    isFetching={isUpdating}
                    disabled={isDeleting || isFetching}
                    className="btn btn-default"
                    onClick={this.handleUpdate}
                  />
                </div>,
                <div key="b" className="col-xs-6 align-left">
                  <Button
                    text="DELETE"
                    actionText="DELETING"
                    isFetching={isDeleting}
                    disabled={isUpdating || isFetching}
                    className="btn btn-default"
                    onClick={this.handleDelete}
                  />
                </div>,
              ]
            ) : (
              <div className="center">
                <Button
                  text="SAVE"
                  actionText="SAVING"
                  isFetching={isFetching}
                  disabled={isUpdating || isDeleting}
                  className="btn btn-default"
                  onClick={this.handleSave}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

CreateEvent.propTypes = {
  modalId: propTypes.string,
  currentEvent: propTypes.object,
  updateField: propTypes.func,
  createEvent: propTypes.func,
  updateEvent: propTypes.func,
  deleteEvent: propTypes.func,
  clearEvent: propTypes.func,
  updateTimezone: propTypes.func,
  isFetching: propTypes.bool,
  isUpdating: propTypes.bool,
  isDeleting: propTypes.bool,
  calendars: propTypes.array,
  userTimeZone: propTypes.string,
  currentView: propTypes.string,
  currentViewStart: propTypes.object,
  showConfirm: propTypes.func,
  defaultCalendar: propTypes.object,
  calendarAccounts: propTypes.array,
  setCurrentEvent: propTypes.func,
  humanDateFormats: propTypes.object.isRequired,
};

CreateEvent.defaultProps = {
  modalId: EVENT_CREATE_MODAL,
  currentEvent: {
    id: false,
  },
};

const mapStateToProps = ({ Events, UserOptions, CalendarAccounts, DateRange }) => {
  let calendars = mapCalendarsToList(CalendarAccounts);
  let defaultCalendar =
    findCalendarInList(calendars, UserOptions.data.calendar_id) ||
    calendars.filter(calendar => calendar.active)[0] ||
    {};
  let userTimeZone = moment.tz.guess() || DEFAULT_TIME_ZONE;

  let currentEventTimeZone = (Events.currentEvent.timezone.name && Events.currentEvent.timezone) ||
    defaultCalendar.timezone || { name: userTimeZone };

  let calendarId =
    Events.currentEvent.calendar_id || UserOptions.data.calendar_id || defaultCalendar.id;

  return {
    currentEvent: {
      ...Events.currentEvent,
      calendar_id: calendarId,
      calendar: findCalendarInList(calendars, calendarId),
      timezone: currentEventTimeZone,
      color:
        Events.currentEvent.color ||
        (Events.currentEvent.calendar && Events.currentEvent.calendar.color) ||
        defaultCalendar.color,
    },
    currentView: DateRange.currentType,
    currentViewStart: DateRange.start.clone(),
    defaultCalendar: defaultCalendar,
    calendars: calendars,
    calendarAccounts: CalendarAccounts,
    isFetching: Events.isFetching,
    isUpdating: Events.isUpdating,
    isDeleting: Events.isDeleting,
    userTimeZone: userTimeZone,
    humanDateFormats: humanDateFormats({ UserOptions }),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateField: data => dispatch(updateField(data)),
    updateTimezone: data => dispatch(updateTimezone(data)),
    createEvent: (data, currentView, currentViewStart) =>
      dispatch(createEvent(data, currentView, currentViewStart)),
    updateEvent: (data, currentView, currentViewStart) =>
      dispatch(updateEvent(data, currentView, currentViewStart)),
    deleteEvent: (data, currentView, currentViewStart) =>
      dispatch(deleteEvent(data, currentView, currentViewStart)),
    clearEvent: () => dispatch(clearEvent()),
    showConfirm: (heading, onConfirm, onCancel) =>
      dispatch(showConfirm(heading, onConfirm, onCancel)),
    hideConfirm: () => dispatch(hideConfirm()),
    setCurrentEvent: event => dispatch(setCurrentEvent(event, false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
