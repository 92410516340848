import { useEffect } from 'react';

export const guid = () => {
  var buf = new Uint16Array(8);
  window.crypto.getRandomValues(buf);
  function s4(num) {
    var ret = num.toString(16);
    while (ret.length < 4) {
      ret = '0' + ret;
    }
    return ret;
  }
  return (
    s4(buf[0]) +
    s4(buf[1]) +
    '-' +
    s4(buf[2]) +
    '-' +
    s4(buf[3]) +
    '-' +
    s4(buf[4]) +
    '-' +
    s4(buf[5]) +
    s4(buf[6]) +
    s4(buf[7])
  );
};

export const useMountEffect = func => useEffect(func, []);

export const useResizeEffect = func => {
  useMountEffect(() => {
    window.addEventListener('resize', func);
    return () => window.removeEventListener('resize', func);
  });
};
