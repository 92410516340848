import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';

import { error } from '~/Redux/Modules/Notifications';
import { addSticker } from '~/Redux/Modules/Products';
import { useMountEffect } from '~/util';

import CheckClosed from '../Assets/Svgs/CheckClosed';
import CloseIcon from '../Assets/Svgs/CloseIcon';

import './ImageUpload.scss';

export default function ImageUpload({
  upload,
  handleUploadComplete,
  removeSticker,
  name = 'image',
}) {
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  const [fileError, setFileError] = useState();

  const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes

  useMountEffect(() => {
    uploadImage(upload);
  });

  const uploadImage = upload => {
    if (upload.file.type.includes('svg')) {
      setFileError(`Cannot upload file type: SVG`);

      upload.status = 'COMPLETE';

      return handleUploadComplete?.(upload);
    }

    if (upload.file.size > maxFileSize) {
      dispatch(error(`Sticker files cannot exceed ${maxFileSize / 1024 / 1024}MB.`));
      setFileError('Image size is too large.');

      upload.status = 'COMPLETE';

      return handleUploadComplete?.(upload);
    }

    dispatch(addSticker({ [name]: upload.file }, handleProgress))
      .then(({ sticker }) => {
        upload.sticker = sticker;
        upload.status = 'COMPLETE';

        handleUploadComplete?.(upload);
      })
      .catch(err => {
        console.log(err);
        dispatch(error('Error uploading sticker.'));
        upload.status = 'COMPLETE';
        const errorMessage = err?.response?.data?.errors?.message ?? 'Error uploading sticker.';
        setFileError(`${errorMessage} Please try again later.`);
        handleUploadComplete?.(upload);
      });
  };

  const handleProgress = progress => {
    setProgress(progress);
  };

  return (
    <article className="image-upload">
      <div className="image-container">
        <img src={upload['file']['preview']} />
      </div>

      <section className="upload-data">
        <div className="top">
          <span className="file-name">{upload['file']['name']}</span>

          {!fileError &&
            (progress == 100 ? (
              <CheckClosed className="upload-complete-icon" />
            ) : (
              <span>{progress}%</span>
            ))}

          <div className="remove-sticker-button" tabIndex="0" role="button" onClick={removeSticker}>
            <CloseIcon className="remove-sticker-button-icon" />
          </div>
        </div>

        {fileError ? (
          <span className="error-text">{fileError}</span>
        ) : (
          <div className="progress-bar">
            <div className="progress-amount" style={{ width: `${progress}%` }} />
          </div>
        )}
      </section>
    </article>
  );
}

ImageUpload.propTypes = {
  upload: propTypes.object,
  removeSticker: propTypes.func,
  handleUploadComplete: propTypes.func,
  name: propTypes.string,
};
