import React from 'react';
import PropTypes from 'prop-types';
import moment from '~/Providers/Moment';
import { HUMAN_DATE_FORMAT, HUMAN_TIME_FORMAT } from '~/Redux/Modules/UserOptions';

const AdminHumanDateTime = ({ label, date, className }) => (
  <span className={`admin-user-date-time ${className}`}>
    {label}{' '}
    {moment
      .tz(date, 'UTC')
      .local()
      .format(HUMAN_DATE_FORMAT)}{' '}
    {moment
      .tz(date, 'UTC')
      .local()
      .format(HUMAN_TIME_FORMAT)}
  </span>
);

AdminHumanDateTime.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  date: PropTypes.string.isRequired,
};

export default AdminHumanDateTime;
