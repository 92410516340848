/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';

export default function Today(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      style={{ enableBackground: 'new 0 0 300 300' }}
      xmlSpace="preserve"
      {...props}
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            '\n\t.st0{display:none;}\n\t.st1{display:inline;}\n\t.st2{fill:none;stroke:#999999;stroke-width:25;stroke-miterlimit:10;}\n\t.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#999999;}\n\t.st4{fill:#999999;}\n\t.st5{fill:#231F20;}\n\t.st6{fill:#555555;}\n\t.st7{fill:#DDDDDD;}\n\t.st8{fill:#4D4D4D;}\n\t.st9{fill:none;stroke:#999999;stroke-width:15;stroke-miterlimit:10;}\n',
        }}
      />
      <g id="Letter_Shapes" className="st0" />
      <g id="Mobile_Logo" />
      <g id="Today">
        <g id="Medical_ID_Tab_Bar_Icon_Group_9_">
          <path
            className="st4"
            d="M150,0C93.1,0,46.9,46.2,46.9,103.1c0,65.6,69.6,116.4,103.1,196.9c33.5-80.5,103.1-131.2,103.1-196.9
          			C253.1,46.2,206.9,0,150,0z M66,81.7h64.4L150,18.6l19.6,63.2H234l-51.9,34.9l27.6,60.5L150,139.5l-59.8,37.6l27.7-60.5L66,81.7z"
          />
        </g>
      </g>
      <g id="Plate__x26__Silverware" />
      <g id="Fork_Knife_Spoon" />
      <g id="Fork__x26__Knife" />
      <g id="Closed_Apple" />
      <g id="Open_Apple" />
      <g id="Filled_Water" />
      <g id="Empty_Water" />
      <g id="Background_Color" />
      <g id="Font_Color" />
      <g id="Account" />
      <g id="Calendar_Styling" />
      <g id="Calendar_Settings" />
      <g id="Left_Justified" />
      <g id="Justified" />
      <g id="Right_Align" />
      <g id="Center_Align" />
      <g id="Left_Align" />
      <g id="Bulleted_List" />
      <g id="Numbered_List" />
      <g id="Increase_Size" />
      <g id="Decrease_Size" />
      <g id="Strikethrough" />
      <g id="Underline" />
      <g id="Italic" />
      <g id="Bold" />
      <g id="Trash" />
      <g id="New" />
      <g id="Edit" />
      <g id="Check_Closed" />
      <g id="Check_Open" />
      <g id="Heading" />
    </svg>
  );
}
/* eslint-enable no-mixed-spaces-and-tabs */
