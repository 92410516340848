/**
 * This component will render a nav link
 * if a child is passed it will render that as the label such a a font awesome element
 * other is it will just render the label.
 */
import React, { forwardRef } from 'react';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Spinner from '~/Components/Global/Loading/Spinner';

import RequireAuth from '~/Containers/Global/Auth/RequireAuth';
import RequireUnAuth from '~/Containers/Global/Auth/RequireUnAuth';

const NavItem = forwardRef(function NavItem(
  { auth, unAuth, label, path, children, className, onClick, onMouseLeave, isFetching },
  ref
) {
  const renderNavLink = (path, children, label) => {
    return path ? (
      <NavLink to={path}>{children ? children : label}</NavLink>
    ) : children ? (
      children
    ) : isFetching ? (
      <span>
        {label} <Spinner />
      </span>
    ) : (
      <span>{label}</span>
    );
  };

  const handleOnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  return auth ? (
    <RequireAuth>
      <li ref={ref} onMouseLeave={onMouseLeave} onClick={handleOnClick} className={className}>
        {renderNavLink(path, children, label)}
      </li>
    </RequireAuth>
  ) : unAuth ? (
    <RequireUnAuth>
      <li ref={ref} onMouseLeave={onMouseLeave} onClick={handleOnClick} className={className}>
        {renderNavLink(path, children, label)}
      </li>
    </RequireUnAuth>
  ) : (
    <li ref={ref} onMouseLeave={onMouseLeave} onClick={handleOnClick} className={className}>
      {renderNavLink(path, children, label)}
    </li>
  );
});

NavItem.propTypes = {
  auth: propTypes.bool,
  unAuth: propTypes.bool,
  label: propTypes.string,
  path: propTypes.string,
  className: propTypes.string,
  children: propTypes.node,
  onClick: propTypes.func,
  onMouseLeave: propTypes.func,
  isFetching: propTypes.bool,
};

NavItem.defaultProps = {
  onClick: () => {},
  isFetching: false,
};

export default NavItem;
