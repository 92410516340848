/* eslint-disable max-len */
import Quill from 'quill';

import { showSnooze } from '~/Redux/Modules/SnoozePopup';
import Store from '~/Redux/Store';
import moment from '~/Providers/Moment';
import { DATE_FORMAT } from '~/Redux/Constants';
import { createTask } from '~/Redux/Modules/Tasks';
import { MONTH_TODO, WEEK_TODO } from '~/Config/Checklists';

import './QuillOverrides.scss';
import { toStartOfWeekForChecklist } from '~/Context/Checklist';

const Container = Quill.import('blots/container');
const ListItem = Quill.import('formats/list/item');
const Parchment = Quill.import('parchment');

class CheckList extends Container {
  static create(value) {
    let tagName = value === 'ordered' ? 'OL' : 'UL';
    let node = super.create(tagName);
    if (value === 'checked' || value === 'unchecked') {
      node.setAttribute('data-checked', value === 'checked');
    }
    return node;
  }

  static formats(domNode) {
    if (domNode.tagName === 'OL') return 'ordered';
    if (domNode.tagName === 'UL') {
      if (domNode.hasAttribute('data-checked')) {
        return domNode.getAttribute('data-checked') === 'true' ? 'checked' : 'unchecked';
      } else {
        return 'bullet';
      }
    }
    return undefined;
  }

  constructor(domNode) {
    const listEventHandler = e => {
      if (e.target.parentNode !== domNode) return;
      let format = this.statics.formats(domNode);
      let blot = Parchment.find(e.target);
      console.log('listEventHandler', e, e.offsetX, blot);
      let indentation = 0;
      let base = 22;
      if (blot.domNode.classList.contains('ql-indent-1')) {
        indentation = 1;
      } else if (blot.domNode.classList.contains('ql-indent-2')) {
        indentation = 2;
      } else if (blot.domNode.classList.contains('ql-indent-3')) {
        indentation = 3;
      } else if (blot.domNode.classList.contains('ql-indent-4')) {
        indentation = 4;
      } else if (blot.domNode.classList.contains('ql-indent-5')) {
        indentation = 5;
      } else if (blot.domNode.classList.contains('ql-indent-6')) {
        indentation = 6;
      } else if (blot.domNode.classList.contains('ql-indent-7')) {
        indentation = 7;
      } else if (blot.domNode.classList.contains('ql-indent-8')) {
        indentation = 8;
      }
      console.log(
        'indentation',
        indentation,
        base + indentation * 48 - 20,
        base + indentation * 48
      );
      if (format !== 'checked' && format !== 'unchecked') return;
      if (e.offsetX >= base + indentation * 48 - 20 && e.offsetX <= base + indentation * 48) {
        if (format === 'checked') {
          blot.format('list', 'unchecked');
        } else if (format === 'unchecked') {
          blot.format('list', 'checked');
        }
      } else if (e.offsetX > e.target.offsetWidth - 21) {
        console.log('delete clicked');
        blot.remove();
      } else if (e.offsetX > e.target.offsetWidth - 44) {
        console.log('snooze clicked');
        const { bottom, left, width } = e.target.getBoundingClientRect();
        const {
          UserLists: {
            lists: {
              current: { id },
            },
          },
        } = Store.getState();
        Store.dispatch(
          showSnooze(
            moment(),
            { top: bottom, left: left + width - 10 },
            (snoozeToDay, type) => {
              console.log('snooze to', snoozeToDay.format(DATE_FORMAT), blot.domNode.innerText);
              if (type == MONTH_TODO) snoozeToDay.startOf('month');
              if (type == WEEK_TODO) snoozeToDay = toStartOfWeekForChecklist(snoozeToDay);
              Store.dispatch(
                createTask({
                  priority: 'm',
                  start: snoozeToDay.format(DATE_FORMAT),
                  type: type,
                  summary: blot.domNode.innerText,
                  user_list_id: id,
                })
              );
            },
            () => {},
            true
          )
        );
      }
    };
    domNode.addEventListener('mousedown', listEventHandler);
    domNode.addEventListener('touchstart', listEventHandler);
    super(domNode);
  }

  format(name, value) {
    if (this.children.length > 0) {
      this.children.tail.format(name, value);
    }
  }

  formats() {
    // We don't inherit from FormatBlot
    return { [this.statics.blotName]: this.statics.formats(this.domNode) };
  }

  insertBefore(blot, ref) {
    if (blot instanceof ListItem) {
      super.insertBefore(blot, ref);
    } else {
      let index = ref == null ? this.length() : ref.offset(this);
      let after = this.split(index);
      after.parent.insertBefore(blot, after);
    }
  }

  optimize(context) {
    super.optimize(context);
    let next = this.next;
    if (
      next != null &&
      next.prev === this &&
      next.statics.blotName === this.statics.blotName &&
      next.domNode.tagName === this.domNode.tagName &&
      next.domNode.getAttribute('data-checked') === this.domNode.getAttribute('data-checked')
    ) {
      next.moveChildren(this);
      next.remove();
    }
  }

  replace(target) {
    if (target.statics.blotName !== this.statics.blotName) {
      let item = Parchment.create(this.statics.defaultChild);
      target.moveChildren(item);
      this.appendChild(item);
    }
    super.replace(target);
  }
}
CheckList.blotName = 'list';
CheckList.scope = Parchment.Scope.BLOCK_BLOT;
CheckList.tagName = ['OL', 'UL'];
CheckList.defaultChild = 'list-item';
CheckList.allowedChildren = [ListItem];

Quill.register(CheckList, true);

// https://github.com/quilljs/quill/blob/develop/assets/icons/list-check.svg
Quill.import('ui/icons')['list'][
  'check'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><g id="Check_Open" data-name="Check Open"><path class="cls-1" d="M150,17.65C223,17.65,282.35,77,282.35,150S223,282.35,150,282.35,17.65,223,17.65,150,77,17.65,150,17.65M150,0A150,150,0,1,0,300,150,150,150,0,0,0,150,0Z"/><polygon class="cls-1" points="227.08 79.17 118.75 187.5 72.92 141.67 56.25 158.33 102.08 204.17 118.75 220.83 135.42 204.17 243.75 95.83 227.08 79.17 227.08 79.17"/><polygon class="cls-1" points="135.42 204.17 135.42 204.17 118.75 220.83 135.42 204.17"/></g></svg>`;
Quill.import('ui/icons')['list'][
  'ordered'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><title>Numbered List</title><g id="Numbered_List" data-name="Numbered List"><path class="cls-1" d="M25,13.51l-8.85,2.1L13.33,4.37,28.71,0H39.3V52.92H25Z"/><path class="cls-1" d="M5.51,165.59l19.67-15c4.88-3.83,6.83-6.3,6.83-9.46,0-3.38-2.33-5.4-5.92-5.4s-6.31,2.1-10.44,6.83l-9.83-8.19c5.63-7.28,11.25-11.25,21.39-11.25,11.48,0,19.37,6.83,19.37,16.74V140c0,8.4-4.35,12.75-12.17,18.39l-9,6.3H47.11v11.86H5.51Z"/><path class="cls-1" d="M5,290.7l9.23-9.16c3.76,3.83,7.66,6,12.54,6,4.13,0,6.68-2.1,6.68-5.33v-.15c0-3.37-3-5.4-8.56-5.4H18.72l-2-7.58,12-10.89h-20V246.41H46.57v10.35L34,267.58c7.51,1.64,13.59,5.78,13.59,14.78v.15c0,10.66-8.56,17.49-20.28,17.49C17.22,300,10.17,296.33,5,290.7Z"/><path class="cls-1" d="M295,282H81.73V263.19H295Zm0-122H81.73V141.2H295Zm0-122H81.73V19.22H295Z"/></g></svg>`;
Quill.import('ui/icons')['list'][
  'bullet'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><title>Bulleted List</title><g id="Bulleted_List" data-name="Bulleted List"><path class="cls-1" d="M55.39,298.37H0V243H55.39ZM300,280H89V261.35H300ZM55.39,177.71H0V122.3H55.39ZM300,159.32H89V140.69H300ZM55.39,57.05H0V1.63H55.39ZM300,38.66H89V20H300Z"/></g></svg>`;
Quill.import('ui/icons')['header'][
  '2'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><title>Heading</title><g id="Heading"><path class="cls-1" d="M260.25,264.71h-57V155.79H96.34V264.71H39.74V0h56.6V103.35h107V0h57Zm-39.31-17.65H242.6V17.65H220.94V121H78.69V17.65H57.39V247.06h21.3V138.14H220.94Z"/><rect class="cls-1" x="39.71" y="282.35" width="220.59" height="17.65"/></g></svg>`;
Quill.import('ui/icons')[
  'bold'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;fill-rule:evenodd;}</style></defs><title>Bold</title><g id="Bold"><path class="cls-1" d="M66.9,0H168.71a79.16,79.16,0,0,1,56.11,23.32l0,.05a79.22,79.22,0,0,1,23.37,56.06h0a79.13,79.13,0,0,1-21.54,54.25c1.17,1.06,2.31,2.15,3.42,3.25A95.56,95.56,0,0,1,162.53,300H41.9V0ZM168.71,50H91.9v58.86h76.81a29.52,29.52,0,0,0,20.81-8.63l-.05,0a29.35,29.35,0,0,0,8.68-20.75h0a29.27,29.27,0,0,0-8.58-20.76h-.1A29.35,29.35,0,0,0,168.71,50Zm-5.6,108.86H91.9V250h70.63a45.57,45.57,0,0,0,.58-91.14Z"/></g></svg>`;
Quill.import('ui/icons')[
  'italic'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;fill-rule:evenodd;}</style></defs><title>Italic</title><g id="Italic"><polygon class="cls-1" points="128.63 0 233.03 0 233.03 26.47 191.78 26.47 138.32 273.53 171.36 273.53 171.36 300 66.97 300 66.97 273.53 111.44 273.53 164.91 26.47 128.63 26.47 128.63 0"/></g></svg>`;
Quill.import('ui/icons')[
  'underline'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;fill-rule:evenodd;}</style></defs><title>Underline</title><g id="Underline"><path class="cls-1" d="M45.37,273.45H254.63V300H45.37V273.45ZM267.9,0V129a117.56,117.56,0,0,1-34.63,83.21l-.05.06A117.58,117.58,0,0,1,150,246.92h0A118.22,118.22,0,0,1,32.1,129V0H58.65V129A91.67,91.67,0,0,0,150,220.37h0A91.64,91.64,0,0,0,241.36,129V0Z"/></g></svg>`;
Quill.import('ui/icons')[
  'strike'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><title>Strikethrough</title><g id="Strikethrough"><path class="cls-1" d="M153.34,300a116.86,116.86,0,0,1-20.66-1.88c-38.49-7-68.49-33.7-76.45-68.11a79,79,0,0,1-2.07-18H78.48a54.83,54.83,0,0,0,1.43,12.47c5.78,25,28.19,44.44,57.1,49.67,43.67,7.88,75.18-16.92,82.69-44.28,6.09-22.17-3-51.49-44.08-71.64h-143V133.93h84.1C88.67,119.81,66.85,99.77,71.56,62.8,77.17,18.56,118.32-2.29,155.88.2c36,2.27,74.71,26.36,74.71,73.87H206.28c0-32-26.09-48-51.93-49.6C128.85,23,99.58,35.12,95.69,65.87c-4.08,32,23.39,44.62,69.09,61.59l5,1.85c3.95,1.46,7.69,3,11.28,4.62h86.32v24.33H218.53c26.3,24.86,30.94,55,24.6,78.08C234.05,269.42,200.47,300,153.34,300Z"/></g></svg>`;
Quill.import('ui/icons')['align'][
  'right'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><title>Right Align</title><g id="Right_Align" data-name="Right Align"><path class="cls-1" d="M291.52,300H8.48V283h283Zm0-70.76H87.73v-17H291.52Zm0-70.75H42.44v-17H291.52Zm0-70.75H87.73v-17H291.52Zm0-70.75H8.48V0h283Z"/></g></svg>`;
Quill.import('ui/icons')['align'][
  ''
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><title>Left Align</title><g id="Left_Align" data-name="Left Align"><path class="cls-1" d="M291.52,300H8.48V283h283Zm-79.25-70.76H8.48v-17H212.27Zm45.29-70.75H8.48v-17H257.56ZM212.27,87.74H8.48v-17H212.27ZM291.52,17H8.48V0h283Z"/></g></svg>`;
Quill.import('ui/icons')['align'][
  'justify'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><title>Justify</title><g id="Justified"><path class="cls-1" d="M291.52,300H8.48V283h283Zm0-70.76H8.48v-17h283Zm0-70.75H8.48v-17h283Zm0-70.75H8.48v-17h283Zm0-70.75H8.48V0h283Z"/></g></svg>`;
Quill.import('ui/icons')['align'][
  'center'
] = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><defs><style>.cls-1{fill:#999;}</style></defs><title>Center</title><g id="Center_Align" data-name="Center Align"><path class="cls-1" d="M291.52,300H8.48V283h283ZM235.6,229.24H64.41v-17H235.6Zm19-70.75H45.38v-17H254.62Zm-19-70.75H64.41v-17H235.6ZM291.52,17H8.48V0h283Z"/></g></svg>`;
Quill.import('ui/icons')[
  'color'
] = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"> <style type="text/css"> .st0{display:none;} .st1{display:inline;} .st2{fill:none;stroke:#999999;stroke-width:25;stroke-miterlimit:10;} .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#999999;} .st4{fill:#999999;} .st5{fill:#4D4D4D;} </style> <g id="Letter_Shapes" class="st0"> </g> <g id="Background_Color"> </g> <g id="Font_Color"> <g> <g> <rect x="45.3" y="259.5" class="st3" width="209.4" height="40.6"/> </g> <path class="st4" d="M212.4,17.1C197,6.1,176.5,0.1,154.7,0.1c-21.9,0-42.4,6-57.7,16.9C79,29.8,69.2,48.6,69.2,70.1h25.9 c0-30.3,30.9-44,59.6-44c28.7,0,59.6,13.8,59.6,44v21.3c-13.5-3-34-6.4-59.1-6.4c-54.3,0-95.3,33-95.3,76.7 c0,47.2,35.8,77.7,91.2,77.7c25.4,0,47.4-9.5,63.2-25.7v23.1h25.9v-90.7v-47V70.1C240.1,48.6,230.3,29.8,212.4,17.1z M151,213.5 c-20,0-36.7-4.8-48.2-13.9c-11.4-9-17.1-21.7-17.1-38c0-17.7,10.3-29.2,19-35.7c12.9-9.7,30.9-15.1,50.5-15.1 c26.8,0,47.9,4.3,59.1,7.1v28.1C214.2,185.8,188.3,213.5,151,213.5z"/> </g> </g> </svg>`;
Quill.import('ui/icons')[
  'background'
] = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"> <style type="text/css"> .st0{display:none;} .st1{display:inline;} .st2{fill:none;stroke:#999999;stroke-width:25;stroke-miterlimit:10;} .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#999999;} .st4{fill:#999999;} .st5{fill:#4D4D4D;} </style> <g id="Letter_Shapes" class="st0"> </g> <g id="Background_Color"> <g> <path class="st4" d="M104.7,156.2c-8.6,6.5-19,18-19,35.7c0,16.2,5.8,29,17.1,38c11.5,9.1,28.1,13.9,48.2,13.9 c37.2,0,63.2-27.7,63.2-67.4v-28.1c-11.1-2.8-32.3-7.1-59.1-7.1C135.6,141.1,117.7,146.5,104.7,156.2z"/> <path class="st4" d="M26.3,0l1,300h246.3V0H26.3z M240.1,129.4v47V267h-25.9V244c-15.8,16.1-37.8,25.7-63.2,25.7 c-55.4,0-91.2-30.5-91.2-77.7c0-43.7,41-76.7,95.3-76.7c25.1,0,45.6,3.3,59.1,6.4v-21.3c0-30.3-30.9-44-59.6-44 c-28.7,0-59.6,13.8-59.6,44H69.2c0-21.4,9.9-40.3,27.8-53c15.4-10.9,35.9-16.9,57.7-16.9c21.9,0,42.4,6,57.7,16.9 c17.9,12.7,27.8,31.6,27.8,53V129.4z"/> </g> </g> </svg> `;
