import throttle from 'lodash.throttle';
import { userListsApi } from '~/Providers/Api';
import { error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import Store from '~/Redux/Store';
import mParticle, { ListCreateEvent } from '~/Providers/mParticle';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  CLEAR_LISTS,
  SET_CURRENT,
  UNSET_CURRENT,
  START_UPDATING,
  FINISH_UPDATING,
  SET_LAST_UPDATE,
  SET_QUEUE,
  TOGGLE_RECOVER,
} from './constants';

export const getUserLists = () => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await userListsApi.fetch();
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    if (response.data) {
      dispatch(setCurrent(response.data[0]));
    }
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
    dispatch(error('Unable to fetch lists', parseErrors(err)));
  }
};

export const getDeletedUserLists = () => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await userListsApi.fetchDeleted();
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    if (response.data) {
      dispatch(setCurrent(response.data[0]));
    }
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
    dispatch(error('Unable to fetch lists', parseErrors(err)));
  }
};

export const recoverUserList = (id, toggle) => async dispatch => {
  dispatch({ type: FETCH_ONE, id });
  try {
    let response = await userListsApi.recoverDeleted(id);
    dispatch({ type: FETCH_ONE_SUCCESS, data: response.data, id });
    if (response.data) {
      dispatch(getUserLists());
      dispatch(setCurrent(response.data));
      dispatch(toggleRecover(toggle));
    }
    return response;
  } catch (err) {
    dispatch({ type: FETCH_ONE_FAILURE, error: err, id });
    dispatch(error('Unable to recover list', parseErrors(err)));
  }
};

export const getUserList = id => async dispatch => {
  dispatch({ type: FETCH_ONE, id });
  try {
    let response = await userListsApi.fetchOne(id);
    dispatch({ type: FETCH_ONE_SUCCESS, data: response.data, id });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_ONE_FAILURE, error: err, id });
    dispatch(error('Unable to fetch list', parseErrors(err)));
  }
};

export const createList = data => async dispatch => {
  dispatch({ type: STORE });
  try {
    let response = await userListsApi.store(data);
    mParticle.logEvent(new ListCreateEvent());
    dispatch({ type: STORE_SUCCESS, data: response.data });
    if (response.data) {
      dispatch(setCurrent(response.data));
    }
    return response;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
    dispatch(error('List was not created', parseErrors(err)));
  }
};

export const deleteList = data => async dispatch => {
  dispatch({ type: DELETE });
  try {
    let response = await userListsApi.delete(data);
    dispatch({ type: DELETE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, error: err });
    dispatch(error('List was not deleted', parseErrors(err)));
  }
};

export const setCurrent = data => dispatch => {
  dispatch({ type: SET_CURRENT, data });
};

export const unsetCurrent = () => dispatch => {
  dispatch({ type: UNSET_CURRENT });
};

export const clearList = () => dispatch => {
  dispatch({ type: CLEAR_LISTS });
};

export const toggleRecover = toggle => {
  return { type: TOGGLE_RECOVER, payload: !toggle };
};

const updateThrottlers = {};

export const updateList = data => dispatch => {
  const {
    UserLists: {
      updates: { isUpdating, queue },
    },
  } = Store.getState();

  const toUpdate = { ...data };

  dispatch({
    type: UPDATE,
    data: toUpdate,
  });

  if (isUpdating) {
    // enqueue an update
    console.log(`enqueue – ${toUpdate.id}.${toUpdate.updated_at}`);
    dispatch({
      type: SET_QUEUE,
      queue: [...queue.filter(update => update.id !== toUpdate.id), toUpdate],
    });
  } else {
    // call api
    console.log(`update immediately – ${toUpdate.id}.${toUpdate.updated_at}`);
    dispatch(listUpdate(toUpdate));
  }
};

const listUpdate = data => dispatch => {
  const {
    UserLists: {
      updates: { lastUpdates },
    },
  } = Store.getState();
  let toUpdate = data;

  const identifier = data.id;
  if (
    lastUpdates[identifier] &&
    (!data.updated_at || lastUpdates[identifier].updated_at > data.updated_at)
  ) {
    console.log('listUpdate - safety valve');
    toUpdate = {
      ...data,
      updated_at: lastUpdates[identifier].updated_at,
    };
  }

  console.log(`start update – ${toUpdate.id}.${toUpdate.updated_at}`, toUpdate, lastUpdates);

  dispatch({ type: START_UPDATING });
  userListsApi
    .update(toUpdate)
    .then(result => {
      if (result && result.data) {
        dispatch({ type: SET_LAST_UPDATE, data: result.data, original: toUpdate });
        dispatch({ type: UPDATE_SUCCESS, data: { ...result.data, confirm: toUpdate.confirm } });
      }
      return result;
    })
    .then(result => {
      const {
        UserLists: {
          updates: { queue },
        },
      } = Store.getState();

      if (!queue.length) {
        dispatch({ type: FINISH_UPDATING });
        return;
      }

      let [nextUpdate, ...rest] = queue;
      dispatch({ type: SET_QUEUE, queue: rest });
      if (result && result.data && nextUpdate.id === result.data.id) {
        nextUpdate = { ...nextUpdate, updated_at: result.data.updated_at };
      }
      dispatch(listUpdate(nextUpdate));
    })
    .catch(err => {
      dispatch({ type: UPDATE_FAILURE, error: err });
      console.log(`error for update update – ${toUpdate.id}.${toUpdate.updated_at}`);
      updateThrottlers[data.id] =
        updateThrottlers[data.id] ||
        throttle(
          err => {
            dispatch(
              error(
                'List was not updated',
                err.status === 422
                  ? 'The list you are trying to update is out of date. Please wait a moment.'
                  : parseErrors(err)
              )
            );
          },
          15000,
          { trailing: false }
        );
      updateThrottlers[data.id](err);
      dispatch(getUserList(data.id))
        .then(({ data }) => {
          dispatch({ type: UNSET_CURRENT });
          dispatch({ type: SET_CURRENT, data });
        })
        .finally(() => {
          const {
            UserLists: {
              updates: { queue },
            },
          } = Store.getState();

          let updatedQueue = queue.filter(item => item.id !== data.id);
          if (updatedQueue.length) {
            let [nextUpdate, ...rest] = updatedQueue;
            dispatch({ type: SET_QUEUE, queue: rest });
            dispatch(listUpdate(nextUpdate));
          } else {
            dispatch({ type: SET_QUEUE, queue: [] });
            dispatch({ type: FINISH_UPDATING });
          }
        });
    });
};
