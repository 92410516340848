/* eslint-disable max-len */
import React from 'react';

export default function HabitNailBitingIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.853 2.627c-.002.001-.002.005-.002.006-.068.67-.374 4.051-.077 7.26.149 1.608.447 3.14.983 4.26l.058.118c.096-.084.22-.183.38-.28.401-.246 1.006-.484 1.835-.484.83 0 1.434.238 1.836.484.159.097.285.196.38.28l.06-.118c.535-1.121.834-2.652.983-4.26.296-3.209-.012-6.59-.078-7.26-.002-.001-.002-.005-.002-.006-.02-.019-.202-.392-.43-.554-.312.155-.43-.066-.623-.156-.234-.11-.397.104-.622 0-.284-.131-.368-.261-.467-.31-.203-.103-.304.087-.622 0-.286-.08-.075.193-.467.155-.152-.015-.027-.208-.622-.156-.358.032-.669.311-.777.311-.43 0-.135-.205-.467-.155-.294.044-.397-.223-.622-.156-.325.098-.115.359-.311.467-.252.138-.25.483-.326.554zm3.177 13.281c-.774 0-1.387-.377-1.871-1.061a2.048 2.048 0 0 1 .38-.296c.307-.19.793-.39 1.491-.39.699 0 1.185.2 1.493.39.155.094.267.19.337.255l.042.043c-.485.682-1.096 1.06-1.872 1.06zM11.054 4.531a5.422 5.422 0 0 0-1.573 3.736l-.345 19.976h11.57l-.343-19.976A5.427 5.427 0 0 0 19.02 4.78c.066 1.426.092 3.325-.08 5.174-.15 1.631-.458 3.257-1.045 4.482-.588 1.23-1.499 2.127-2.864 2.127-1.365 0-2.275-.898-2.865-2.127-.587-1.225-.893-2.85-1.043-4.482-.182-1.959-.143-3.975-.069-5.423zm1.248 16.726c0-.182.146-.328.327-.328h4.584c.181 0 .327.146.327.328a.326.326 0 0 1-.327.327h-4.584a.326.326 0 0 1-.327-.328zm-.546 1.419a.326.326 0 1 0 0 .655h6.113a.327.327 0 0 0 0-.655h-6.113z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
