export const ADD_ROUTE = 'reviews/menus/ADD_ROUTE';
export const CLEAR_ROUTES = 'reviews/menus/CLEAR_ROUTES';

const initialState = {
  routes: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLEAR_ROUTES:
      return { ...state, routes: [] };
    case ADD_ROUTE:
      return { ...state, routes: [...state.routes, action.route] };
    default:
      return state;
  }
}

export const addRoute = route => dispatch => {
  dispatch({ type: ADD_ROUTE, route });
};

export const clearMenus = () => dispatch => {
  dispatch({ type: CLEAR_ROUTES });
};
