/* eslint-disable max-len */
import React from 'react';

export default function HabitJournalIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.667 5C7 5 7 5 7 7.727v12.728C7 25 7 25 11.444 25h8.89C23 25 23 25 23 22.273V10.455c0-1.82 0-1.82-1.777-1.82h-4.445c-.889 0-.889 0-.889.91V18.6a.1.1 0 0 1-.179.062c-.282-.363-.808-1.034-.894-1.122l-1.28-1.497c-.086-.088-.313-.315-.313-.315s-.228.227-.315.316l-1.28 1.496c-.085.088-.61.76-.893 1.122a.1.1 0 0 1-.18-.062V9.545c0-.91 0-.91-.888-.91-.782 0-.89 0-.89-.908s0-.909.89-.909H22.11c.889 0 .889-.407.889-.91C23 5.408 23 5 22.11 5H9.668z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
