import { PAYMENT_MODAL } from '~/Config/Modals';
import { STICKER_PACKS, SKINS } from '~/Config/Products';

import { productApi, stickerPacksApi, skinsApi, userStickersApi } from '~/Providers/Api';
import mParticle, { ViewProductEvent } from '~/Providers/mParticle';
import Storage from '~/Providers/Storage';

import { parseErrors } from '~/Redux/Helpers';

import { closeModal, openModal } from '~/Redux/Modules/Modal';
import { success, error } from '~/Redux/Modules/Notifications';
import { setActivePack } from '~/Redux/Modules/StickerPacksUi';

const PURCHASE = 'artful-client/Stripe/PURCHASE';
const PURCHASE_SUCCESS = 'artful-client/Stripe/PURCHASE_SUCCESS';
const PURCHASE_FAILURE = 'artful-client/Stripe/PURCHASE_FAILURE';

const FETCH_STICKER_PACKS = 'artful-client/Stripe/FETCH_STICKER_PACKS';
const FETCH_STICKER_PACKS_SUCCESS = 'artful-client/Stripe/FETCH_STICKER_PACKS_SUCCESS';
const FETCH_STICKER_PACKS_FAILURE = 'artful-client/Stripe/FETCH_STICKER_PACKS_FAILURE';

const ADD_STICKER = 'artful-client/Sticker/ADD_STICKER';
const ADD_STICKER_SUCCESS = 'artful-client/Sticker/ADD_STICKER_SUCCESS';
const ADD_STICKER_FAILURE = 'artful-client/Sticker/ADD_STICKER_FAILURE';

const UPDATE_STICKER = 'artful-client/Sticker/UPDATE_STICKER';
const UPDATE_STICKER_SUCCESS = 'artful-client/Sticker/UPDATE_STICKER_SUCCESS';
const UPDATE_STICKER_FAILURE = 'artful-client/Sticker/UPDATE_STICKER_FAILURE';

const DELETE_STICKER = 'artful-client/Sticker/DELETE_STICKER';
const DELETE_STICKER_SUCCESS = 'artful-client/Sticker/DELETE_STICKER_SUCCESS';
const DELETE_STICKER_FAILURE = 'artful-client/Sticker/DELETE_STICKER_FAILURE';

const FETCH_SKINS = 'artful-client/Stripe/FETCH_SKINS';
const FETCH_SKINS_SUCCESS = 'artful-client/Stripe/FETCH_SKINS_SUCCESS';
const FETCH_SKINS_FAILURE = 'artful-client/Stripe/FETCH_SKINS_FAILURE';

const SET_PRODUCT = 'artful-client/Stripe/SET_PRODUCT';

const initialState = {
  isFetching: false,
  isBuying: false,
  [STICKER_PACKS]: [],
  [SKINS]: [],
  current: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PURCHASE:
      return { ...state, isBuying: true };
    case PURCHASE_SUCCESS:
      return {
        ...state,
        isBuying: false,
        [action.currentType]: state[action.currentType].map(p => {
          if (p.product && p.product.id === action.data.id) {
            p.product = action.data;
            return p;
          }
          return p;
        }),
      };
    case PURCHASE_FAILURE:
      return { ...state, isBuying: false };

    case FETCH_STICKER_PACKS:
      return { ...state, isFetching: true };
    case FETCH_STICKER_PACKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [STICKER_PACKS]: action.data.filter(
          pack =>
            pack.user_id ||
            !pack.product ||
            !pack.product.unlock_code ||
            pack.product.price ||
            pack.product.purchased
        ),
      };
    case FETCH_STICKER_PACKS_FAILURE:
      return { ...state, isFetching: false };

    case ADD_STICKER:
      return { ...state, isFetching: true };
    case ADD_STICKER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [STICKER_PACKS]: addToCustomStickerPack(state, action.data),
      };
    case ADD_STICKER_FAILURE:
      return { ...state, isFetching: false };

    case UPDATE_STICKER:
      return { ...state, isFetching: true };
    case UPDATE_STICKER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [STICKER_PACKS]: updateCustomSticker(state, action.data),
      };
    case UPDATE_STICKER_FAILURE:
      return { ...state, isFetching: false };

    case DELETE_STICKER:
      return { ...state, isFetching: true };
    case DELETE_STICKER_SUCCESS:
      return {
        ...state,
        [STICKER_PACKS]: removeFromCustomStickerPack(state, action.data),
      };
    case DELETE_STICKER_FAILURE:
      return { ...state, isFetching: false };

    case FETCH_SKINS:
      return { ...state, isFetching: true };
    case FETCH_SKINS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [SKINS]: action.data.filter(
          skin => !skin.product.unlock_code || skin.product.price || skin.product.purchased
        ),
      };
    case FETCH_SKINS_FAILURE:
      return { ...state, isFetching: false };

    case SET_PRODUCT:
      return {
        ...state,
        current: action.product,
        currentType: action.currentType,
      };
    default:
      return state;
  }
}

const addToCustomStickerPack = (state, { sticker, stickerPack }) => {
  let customStickerPack = state[STICKER_PACKS].find(pack => pack.id === stickerPack['id']);

  // If no custom sticker pack is present, create it and add the sticker
  if (!customStickerPack) {
    customStickerPack = { ...stickerPack, stickers: [sticker] };
    return [...state[STICKER_PACKS], customStickerPack];
  }

  // Otherwise, add the sticker to the existing custom sticker pack
  customStickerPack = {
    ...customStickerPack,
    stickers: [...customStickerPack.stickers, sticker],
  };

  return state[STICKER_PACKS].map(pack => {
    return pack.id === customStickerPack.id ? customStickerPack : pack;
  });
};

const updateCustomSticker = (state, { sticker }) => {
  let customStickerPack = state[STICKER_PACKS].find(pack => pack.user_id === sticker.user_id);

  // Replace the existing sticker with the updated one
  customStickerPack = {
    ...customStickerPack,
    stickers: customStickerPack.stickers.map(existingSticker => {
      if (existingSticker.id != sticker.id) {
        return existingSticker;
      }

      return sticker;
    }),
  };

  return state[STICKER_PACKS].map(pack => {
    return pack.id === customStickerPack.id ? customStickerPack : pack;
  });
};

const removeFromCustomStickerPack = (state, sticker) => {
  let customStickerPack = state[STICKER_PACKS].find(pack => pack.user_id != null);
  customStickerPack = {
    ...customStickerPack,
    stickers: customStickerPack.stickers.filter(existingSticker => {
      return existingSticker.id != sticker.id;
    }),
  };

  return state[STICKER_PACKS].map(pack => {
    return pack.id === customStickerPack.id ? customStickerPack : pack;
  });
};

export const makePurchase = (data, type) => async dispatch => {
  dispatch({ type: PURCHASE });
  try {
    let response = await productApi.purchase(data.id, { adid: Storage.get('adid') });
    dispatch({
      type: PURCHASE_SUCCESS,
      data: response.data,
      currentType: type,
    });
    dispatch(closeModal(PAYMENT_MODAL));
    dispatch(success(`${data.description} Purchased`));
    return response;
  } catch (err) {
    dispatch(error('Not purchased', parseErrors(err)));
    dispatch({ type: PURCHASE_FAILURE, error: err });
  }
};

export const setProduct = (product, type) => dispatch => {
  dispatch({ type: SET_PRODUCT, product, currentType: type });
  dispatch(openModal(PAYMENT_MODAL));
  mParticle.logEvent(new ViewProductEvent(product));
};

export const getStickerPacks = () => async (dispatch, state) => {
  dispatch({ type: FETCH_STICKER_PACKS });
  try {
    let response = await stickerPacksApi.fetch();
    dispatch({ type: FETCH_STICKER_PACKS_SUCCESS, data: response.data });
    if (!state().StickerPacksUi.activePack) {
      dispatch(setActivePack(response.data[0].id));
    }

    return response;
  } catch (err) {
    dispatch({ type: FETCH_STICKER_PACKS_FAILURE, error: err });
  }
};

export const addSticker = (data, onProgress) => async dispatch => {
  dispatch({ type: ADD_STICKER });
  try {
    const response = await userStickersApi.store(data, onProgress);
    dispatch({ type: ADD_STICKER_SUCCESS, data: response.data });
    dispatch(success('STICKER ADDED!'));

    return response.data;
  } catch (err) {
    dispatch({ type: ADD_STICKER_FAILURE, error: err });
    dispatch(error('STICKER NOT ADDED.', parseErrors(err)));
    throw err;
  }
};

export const updateSticker = data => async dispatch => {
  dispatch({ type: UPDATE_STICKER });
  try {
    const response = await userStickersApi.update(data);
    dispatch({ type: UPDATE_STICKER_SUCCESS, data: response.data });
    dispatch(success('STICKER UPDATED!'));

    return response;
  } catch (err) {
    dispatch({ type: UPDATE_STICKER_FAILURE, error: err });
    dispatch(error('STICKER NOT UPDATED.', parseErrors(err)));
  }
};

export const deleteSticker = sticker => async dispatch => {
  dispatch({ type: DELETE_STICKER });
  try {
    const response = await userStickersApi.delete(sticker);
    dispatch({ type: DELETE_STICKER_SUCCESS, data: response.data });
    dispatch(success('STICKER REMOVED!'));

    return response;
  } catch (err) {
    dispatch({ type: DELETE_STICKER_FAILURE, error: err });
    dispatch(error('STICKER NOT REMOVED.', parseErrors(err)));
  }
};

export const getSkins = () => async dispatch => {
  dispatch({ type: FETCH_SKINS });
  try {
    let response = await skinsApi.fetch();
    dispatch({ type: FETCH_SKINS_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_SKINS_FAILURE, error: err });
  }
};
