import { combineReducers } from 'redux';
import {
  FETCH_ALL_SUCCESS,
  FETCH_ONE_SUCCESS,
  SET_QUEUE,
  SET_LAST_UPDATE,
  START_UPDATING,
  FINISH_UPDATING,
  UPDATE_ITEM,
  RESTORE,
  RESTORE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  CREATE_SUCCESS,
  RESTORE_SUCCESS,
  CREATE,
  CREATE_FAILURE,
  ADD_TEMP_UPDATE,
  REMOVE_TEMP_UPDATE,
  ADD_TEMP_DELETE,
  REMOVE_TEMP_DELETE,
  SHOW_REPEAT_POPUP,
  HIDE_REPEAT_POPUP,
  UPDATE_SUCCESS,
  FETCH_ALL,
  SET_TASK_ERROR,
} from './constants';
import { allExceptType } from './selectors';
import { identifierForTask } from './helpers';

const taskCollectionInitialState = {
  tasks: [],
  requests: {},
  fetching: {},
};

const taskCollectionReducer = (state = taskCollectionInitialState, action) => {
  switch (action.type) {
    case FETCH_ALL:
      console.log(FETCH_ALL, action.taskType, action.stamp);
      return {
        ...state,
        requests: { ...state.requests, [action.taskType]: action.stamp },
        fetching: { ...state.fetching, [action.taskType]: true },
      };
    case FETCH_ALL_SUCCESS: {
      const currentStamp = state.requests[action.taskType];
      if (currentStamp && currentStamp > action.stamp) {
        console.log(
          FETCH_ALL_SUCCESS,
          `stale request ${action.taskType} ${currentStamp} > ${action.stamp}`
        );
        return state;
      }
      console.log(
        FETCH_ALL_SUCCESS,
        `updating ${action.taskType} ${currentStamp} <= ${action.stamp}`
      );
      return {
        ...state,
        tasks: [...allExceptType(state.tasks, action.taskType), ...action.data],
        fetching: { ...state.fetching, [action.taskType]: false },
      };
    }
    case FETCH_ONE_SUCCESS:
      return {
        ...state,
        tasks: [...state.tasks.filter(({ id }) => id !== action.data.id), action.data],
      };
    case UPDATE_SUCCESS:
    case UPDATE_ITEM: {
      const { confirm, id } = action.data;
      if (confirm?.startsWith('this')) {
        return {
          ...state,
          tasks: [
            ...state.tasks.filter(
              task => identifierForTask(task) !== identifierForTask(action.data)
            ),
            action.data,
          ],
        };
      }

      if (confirm?.startsWith('following')) {
        return {
          ...state,
          tasks: state.tasks.map(task => {
            if (task.id !== id || identifierForTask(task) < identifierForTask(action.data)) {
              return task;
            }
            return { ...action.data, start: task.start, recurrence_id: task.recurrence_id };
          }),
        };
      }

      return {
        ...state,
        tasks: state.tasks.map(task => {
          if (task.id !== id) {
            return task;
          }
          return { ...action.data, start: task.start, recurrence_id: task.recurrence_id };
        }),
      };
    }
    case RESTORE_FAILURE:
    case DELETE: {
      const { confirm, ics } = action.data;
      if (confirm?.startsWith('this')) {
        return {
          ...state,
          tasks: state.tasks.filter(
            task => identifierForTask(task) !== identifierForTask(action.data)
          ),
        };
      }

      if (confirm?.startsWith('following')) {
        return {
          ...state,
          tasks: state.tasks.filter(
            task => task.ics !== ics || identifierForTask(task) < identifierForTask(action.data)
          ),
        };
      }

      return {
        ...state,
        tasks: state.tasks.filter(task => task.ics !== ics),
      };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        tasks: state.tasks.map(task => {
          if (task.id === action.data.id) {
            return {
              ...task,
              repeat_rule: action.data.repeat_rule,
              updated_at: action.data.updated_at,
            };
          } else {
            return task;
          }
        }),
      };
    }
    case DELETE_FAILURE:
    case RESTORE:
    case CREATE:
      return {
        ...state,
        tasks: [...state.tasks, action.data],
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        tasks: [...state.tasks.filter(({ id }) => id !== action.id), action.data],
      };
    case CREATE_FAILURE:
      return { ...state, tasks: [...state.tasks.filter(({ id }) => id !== action.id)] };
  }
  return state;
};

const updatesInitialState = {
  queue: [],
  lastUpdates: {},
  isUpdating: false,
  taskError: null,
};

const updatesReducer = (state = updatesInitialState, action) => {
  switch (action.type) {
    case START_UPDATING:
      return { ...state, isUpdating: true };
    case FINISH_UPDATING:
      return { ...state, isUpdating: false };
    case SET_QUEUE:
      return { ...state, queue: action.queue };

    case SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdates: {
          ...state.lastUpdates,
          [action.original.id]: { updated_at: action.data.updated_at },
          [identifierForTask(action.data)]: action.data,
        },
      };

    case SET_TASK_ERROR:
      console.log(SET_TASK_ERROR, action.data);
      return { ...state, taskError: action.data };
  }
  return state;
};

const tempUpdatesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TEMP_UPDATE:
      return { ...state, [action.data.id]: action.data };
    case REMOVE_TEMP_UPDATE:
      return { ...state, [action.data.id]: null };
  }
  return state;
};

const tempDeletesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TEMP_DELETE:
      return { ...state, [action.data.id]: action.data };
    case REMOVE_TEMP_DELETE:
      return { ...state, [action.data.id]: null };
  }
  return state;
};

const repeatPopupInitialState = {
  show: false,
  repeatable: null,
  positionRect: null,
  onSave: null,
  onCancel: null,
};

const repeatPopupReducer = (state = repeatPopupInitialState, action) => {
  switch (action.type) {
    case SHOW_REPEAT_POPUP: {
      const { repeatable, positionRect, onSave, onCancel } = action;
      return { ...state, show: true, repeatable, positionRect, onSave, onCancel };
    }
    case HIDE_REPEAT_POPUP:
      return repeatPopupInitialState;
  }
  return state;
};

export default combineReducers({
  taskCollection: taskCollectionReducer,
  updates: updatesReducer,
  tempUpdates: tempUpdatesReducer,
  tempDeletes: tempDeletesReducer,
  repeatPopup: repeatPopupReducer,
});
