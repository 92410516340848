import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import moment from '~/Providers/Moment';
import { AdminUserApi } from '~/Providers/Api';
import LoadingMessage from '~/Components/Global/Loading/LoadingMessage';
import { HUMAN_DATE_FORMAT, HUMAN_TIME_FORMAT } from '~/Redux/Modules/UserOptions';
import { success, error } from '~/Redux/Modules/Notifications';
import { AdminHumanDateTime, AdminEditableField, DeleteUserButton } from '../Components';

import './AdminEditUsers.scss';

const AdminEditUsers = ({ match }) => {
  const [user, setUser] = useState({});
  const [isFetching, setFetching] = useState(true);
  const dispatch = useDispatch();
  const {
    params: { userId },
  } = match;

  const loadUser = () => {
    setFetching(true);
    AdminUserApi.fetchOne(userId).then(response => {
      setUser(response.data);
      setFetching(false);
    });
  };

  const handleChange = (attribute, value, password) => {
    AdminUserApi.update(user.id, { [attribute]: value, password_confirmation: password })
      .then(() => {
        console.log('AdminEditUsers', 'Success!');
        dispatch(success('User updated successfully!'));
        loadUser();
      })
      .catch(err => {
        console.log('AdminEditUsers', 'Error', err);
        dispatch(error('Unable to update user', err.message));
      });
  };

  useEffect(() => {
    loadUser();
  }, [userId]);

  if (isFetching) {
    return <LoadingMessage messages="Loading..." />;
  }

  return (
    <div className="admin-edit-user-wrapper">
      <div style={{ float: 'right' }}>
        <DeleteUserButton userId={userId} />
      </div>
      <div className="user-info">
        <h1 className="user-info__name">{user.name}</h1>

        <AdminEditableField
          className="user-info__email"
          value={user.email}
          name="email"
          onChange={({ value, password }) => handleChange('email', value, password)}
          revalidate
        />
        {user.stripe_id && (
          <span className="user-info__stripe-id">
            <a href={`https://dashboard.stripe.com/customers/${user.stripe_id}`} target="_blank">
              {user.stripe_id}
            </a>
          </span>
        )}
        <AdminEditableField
          className="user-info__password"
          value={''}
          type="password"
          name="password"
          label="Password"
          onChange={({ value, password }) => handleChange('password', value, password)}
          revalidate
          hidden
        />
        <AdminEditableField
          className="user-info__trial"
          value={user.trial_ends_at}
          name="trial_ends_at"
          onChange={({ value, password }) => handleChange('trial_ends_at', value, password)}
          hidden
          label={
            user.trial_ends_at ? (
              <AdminHumanDateTime date={user.trial_ends_at} label="Trial ends" />
            ) : (
              'Set trial'
            )
          }
          type="datetime"
        />
        <span className="user-info__tax-percentage">Tax rate {user.taxPercentage}%</span>
        <span className="user-info__address">
          {user.address_zip} {user.address_state} {user.address_country}
        </span>
        <span className="user-info__subscription-level">
          Subscription level: {user.subscriptionLevel}
        </span>
      </div>
      <div className="subscriptions">
        <h2 className="subscriptions__title">Subscriptions</h2>
        <div className="subscriptions__list">
          {user.subscriptions.map(subscription => (
            <div key={subscription.id} className="subscription">
              <div className="subscription-top">
                <span className="subscription__platform">Stripe</span>

                <span className="subscription__plan">
                  - {subscription.stripe_plan ?? subscription.stripe_price}
                </span>
                {subscription.trial_ends_at &&
                  moment().isBefore(moment(subscription.trial_ends_at)) && (
                    <AdminHumanDateTime
                      className="subscription__trial"
                      date={subscription.trial_ends_at}
                      label={'- Trial ends'}
                    />
                  )}
                <span className="subscription__id">
                  <a
                    href={`https://dashboard.stripe.com/subscriptions/${subscription.stripe_id}`}
                    target="_blank"
                  >
                    {subscription.stripe_id}
                  </a>
                </span>
              </div>
              <div className="subscription-bottom">
                <AdminHumanDateTime
                  className="subscription__created"
                  date={subscription.created_at}
                  label="Started"
                />

                {subscription.ends_at && (
                  <AdminHumanDateTime
                    className="subscription__ends"
                    date={subscription.ends_at}
                    label="Ends"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="subscriptions">
        <h2 className="subscriptions__title">In App Subscriptions</h2>
        <div className="subscriptions__list">
          {user.iap_subscriptions.map(subscription => (
            <div key={subscription.id} className="subscription">
              <div className="subscription-top">
                <span className="subscription__platform">{subscription.platform}</span>
                <span className="subscription__plan">- {subscription.sku}</span>
                <span className="subscription__id">{subscription.order_id}</span>
              </div>
              <div className="subscription-bottom">
                <span className="subscription__created">
                  Started {moment(subscription.created_at).format(HUMAN_DATE_FORMAT)}{' '}
                  {moment(subscription.created_at).format(HUMAN_TIME_FORMAT)}
                </span>
                {subscription.ends_at && (
                  <span className="subscription__ends">
                    Ends {moment(subscription.ends_at).format(HUMAN_DATE_FORMAT)}{' '}
                    {moment(subscription.ends_at).format(HUMAN_TIME_FORMAT)}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="calendars">
          <h2 className="calendars__title">Calendar Accounts</h2>
          {user.calendar_accounts.map(calendarAccount => (
            <div key={calendarAccount.id} className="calendar-account">
              <h3>
                {calendarAccount.driver} - {calendarAccount.email}
              </h3>
              {calendarAccount.calendars.map(calendar => (
                <div key={calendar.id} className="calendar">
                  <div className="calendar-top">
                    <span className="calendar__color">
                      <span
                        className="color-box"
                        style={{
                          borderColor: calendar.color,
                          backgroundColor: calendar.active ? calendar.color : 'white',
                        }}
                      />
                      {calendar.color}
                    </span>
                    <span className="calendar__hidden">
                      <FontAwesome
                        name={`check-circle ${calendar.is_hidden ? 'inactive' : 'active'}`}
                      />
                    </span>
                    <span className="calendar__name">{calendar.display_name}</span>
                    <span className="calendar__calendar-id">{calendar.calendar_id}</span>
                  </div>

                  {calendar.last_success_at && (
                    <AdminHumanDateTime
                      className="calendar__last-success"
                      date={calendar.last_success_at}
                      label="Last Success"
                    />
                  )}
                  <div className={'calendar-bottom'}>
                    <span className="calendar__error-count">
                      Error count: {calendar.error_count}
                    </span>
                    {calendar.last_failed_at && (
                      <AdminHumanDateTime
                        className="calendar__last-failed"
                        date={calendar.last_failed_at}
                        label={'- Last Failed'}
                      />
                    )}
                    <span className="calendar__last-error">{calendar.last_error}</span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AdminEditUsers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  }).isRequired,
};

export default AdminEditUsers;
