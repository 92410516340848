import { combineReducers } from 'redux';
import {
  FETCH_ALL,
  FETCH_ALL_SUCCESS,
  FETCH_ONE_SUCCESS,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAILURE,
} from './constants';

const habitTrackedCollectionInitialState = {
  habitsTracked: [],
  requests: {},
  fetching: {},
};

const habitTrackedCollectionReducer = (state = habitTrackedCollectionInitialState, action) => {
  switch (action.type) {
    case FETCH_ALL:
      console.log(FETCH_ALL);
      return {
        ...state,
        requests: { ...state.requests },
        fetching: { ...state.fetching },
      };
    case FETCH_ALL_SUCCESS: {
      console.log(FETCH_ALL_SUCCESS);
      return {
        ...state,
        habitsTracked: [...action.data],
        fetching: { ...state.fetching },
      };
    }
    case FETCH_ONE_SUCCESS:
      return {
        ...state,
        habitsTracked: [
          ...state.habitsTracked.filter(({ id }) => id !== action.data.id),
          action.data,
        ],
      };
    case DELETE: {
      return { ...state };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        habitsTracked: state.habitsTracked.filter(
          habitTracked => habitTracked.id !== action.data.id
        ),
      };
    }
    case DELETE_FAILURE:
      return { ...state, error: action.error };
    case CREATE:
      return {
        ...state,
        habitsTracked: [...state.habitsTracked, action.data],
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        habitsTracked: [...state.habitsTracked.filter(({ id }) => id !== action.id), action.data],
      };
    case CREATE_FAILURE:
      return {
        ...state,
        habitsTracked: [...state.habitsTracked.filter(({ id }) => id !== action.id)],
      };
  }
  return state;
};

export default combineReducers({
  habitTrackedCollection: habitTrackedCollectionReducer,
});
