/* eslint-disable max-len */
import React from 'react';

export default function HabitScalesIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.25 4.687c0 1.25-1.875 1.25-1.875 0s1.875-1.25 1.875 0zM5.625 4.687c0 1.25-1.875 1.25-1.875 0s1.875-1.25 1.875 0z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M28.125 4.219V1.875H4.219c-.653 0-2.386-.042-2.386-.042s.043 1.744.042 2.386V28.125H28.125V4.219zm-19.219.937c0-.777.63-1.406 1.407-1.406h9.375c.777 0 1.406.63 1.406 1.406v1.875c0 .777-.63 1.406-1.406 1.406h-9.375c-.777 0-1.407-.629-1.407-1.406V5.156zM24.375 15v.937H18.75V15h5.625zM11.25 15v.937H5.625V15h5.625zM2.812 4.687a1.874 1.874 0 1 1 3.749 0 1.874 1.874 0 0 1-3.748 0zm8.438 16.875H5.625v-.937h5.625v.937zm1.406-2.812H4.22v-.938h8.437v.938zm2.813 6.583h-.938v-14h.938v14zm8.906-3.77H18.75v-.938h5.625v.937zm1.406-2.813h-8.437v-.938h8.437v.938zm-.468-12.188a1.874 1.874 0 1 1 0-3.748 1.874 1.874 0 0 1 0 3.748z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M10.313 4.688h9.374c.26 0 .47.258.47.468v1.875c0 .26-.21.469-.47.469h-9.375c-.258 0-.468-.259-.468-.469V5.156c0-.259.21-.468.469-.468z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
