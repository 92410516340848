import { combineReducers } from 'redux';
import {
  FETCH_ALL,
  FETCH_ALL_SUCCESS,
  FETCH_ONE_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  RESTORE,
  RESTORE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAILURE,
} from './constants';

const habitCollectionInitialState = {
  habits: [],
  requests: {},
  fetching: {},
};

const habitCollectionReducer = (state = habitCollectionInitialState, action) => {
  switch (action.type) {
    case FETCH_ALL:
      console.log(FETCH_ALL);
      return {
        ...state,
        requests: { ...state.requests },
        fetching: { ...state.fetching },
      };
    case FETCH_ALL_SUCCESS: {
      console.log(FETCH_ALL_SUCCESS);
      return {
        ...state,
        habits: [...action.data],
        fetching: { ...state.fetching },
      };
    }
    case FETCH_ONE_SUCCESS:
      return {
        ...state,
        habits: [...state.habits.filter(({ id }) => id !== action.data.id), action.data],
      };
    case UPDATE_SUCCESS: {
      const { id } = action.data;
      return {
        ...state,
        habits: state.habits.map(habit => {
          if (habit.id !== id) {
            return habit;
          }
          return { ...action.data };
        }),
      };
    }
    case RESTORE_FAILURE:
    case DELETE: {
      return { ...state };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        habits: state.habits.filter(habit => habit.id !== action.data.id),
      };
    }
    case DELETE_FAILURE:
      return { ...state, error: action.error };
    case RESTORE:
    case CREATE:
      return {
        ...state,
        habits: [...state.habits, action.data],
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        habits: [...state.habits.filter(({ id }) => id !== action.id), action.data],
      };
    case CREATE_FAILURE:
      return { ...state, habits: [...state.habits.filter(({ id }) => id !== action.id)] };
  }
  return state;
};

export default combineReducers({
  habitCollection: habitCollectionReducer,
});
