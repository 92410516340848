import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

class TopNav extends React.Component {
  render() {
    const { routes, menuId, alignment, path } = this.props;

    if (!routes.length) return false;

    return (
      <div className="top-nav">
        <nav className="navbar navbar-default navbar-static-top">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target={`#${menuId}`}
            >
              <span className="sr-only">Toggle Navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          </div>

          <div className="collapse navbar-collapse" id={menuId}>
            <ul className={`nav navbar-nav navbar-${alignment}`}>
              {routes.map((route, index) => {
                return (
                  <li className={path === route.path ? 'nav-item active' : 'nav-item'} key={index}>
                    <Link to={route.path}>{route.label}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

TopNav.propTypes = {
  menuId: propTypes.string,
  alignment: propTypes.string,
  path: propTypes.string,
  routes: propTypes.array,
};

export default TopNav;
