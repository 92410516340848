import { calendarAccountsApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import { getUserOptions } from '~/Redux/Modules/UserOptions';
import mParticle, { CalendarAccountConnectedEvent } from '~/Providers/mParticle';

import {
  SET_ACCOUNT,
  UPDATE_ACCOUNT as UPDATE_CALENDAR_ACCOUNT,
} from '~/Redux/Modules/CalendarAccounts';
import { APPLE } from '~/Config/Drivers';

const ADD_ACCOUNT = 'artful-client/Apple/ADD_ACCOUNT';
const ADD_ACCOUNT_SUCCESS = 'artful-client/Apple/ADD_ACCOUNT_SUCCESS';
const ADD_ACCOUNT_FAILURE = 'artful-client/Apple/ADD_ACCOUNT_FAILURE';

const UPDATE_ACCOUNT = 'artful-client/Apple/UPDATE_ACCOUNT';
const UPDATE_ACCOUNT_SUCCESS = 'artful-client/Apple/UPDATE_ACCOUNT_SUCCESS';
const UPDATE_ACCOUNT_FAILURE = 'artful-client/Apple/UPDATE_ACCOUNT_FAILURE';

const UPDATE_FIELD = 'artful-client/Apple/UPDATE_FIELD';

const initialState = {
  isFetching: false,
  isUpdating: false,
  accountForm: {
    email: '',
    password: '',
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ACCOUNT:
      return { ...state, isFetching: true };
    case ADD_ACCOUNT_SUCCESS:
      return { ...state, isFetching: false };
    case ADD_ACCOUNT_FAILURE:
      return { ...state, isFetching: false };
    case UPDATE_ACCOUNT:
      return { ...state, isUpdating: true };
    case UPDATE_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_FAILURE:
      return { ...state, isUpdating: false };
    case UPDATE_FIELD:
      return {
        ...state,
        accountForm: { ...state.accountForm, ...action.update },
      };
    default:
      return state;
  }
}

export const addAppleAccount = data => async dispatch => {
  dispatch({ type: ADD_ACCOUNT });
  try {
    let response = await calendarAccountsApi.linkAccount('apple', data);
    mParticle.logEvent(new CalendarAccountConnectedEvent(APPLE));
    dispatch({ type: ADD_ACCOUNT_SUCCESS });
    dispatch({ type: SET_ACCOUNT, account: response.data });
    dispatch(getUserOptions());
    dispatch(success('Apple account added!'));
    return response;
  } catch (err) {
    dispatch({ type: ADD_ACCOUNT_FAILURE, error: err });
    dispatch(error('Apple account add failed', parseErrors(err)));
  }
};

export const updateAppleAccount = data => async dispatch => {
  dispatch({ type: UPDATE_ACCOUNT });
  try {
    let response = await calendarAccountsApi.updateAccount({ id: data.id }, data);
    dispatch({ type: UPDATE_ACCOUNT_SUCCESS });
    dispatch({ type: UPDATE_CALENDAR_ACCOUNT, account: response.data });
    dispatch(getUserOptions());
    dispatch(success('Apple account updated!'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_FAILURE, error: err });
    dispatch(error('Apple account update failed', parseErrors(err)));
    throw err;
  }
};

export const updateField = update => dispatch => dispatch({ type: UPDATE_FIELD, update });

export const appleAccount = ({ CalendarAccounts }) =>
  CalendarAccounts.data.find(c => c.driver === APPLE);
