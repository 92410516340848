import { waterApi } from '~/Providers/Api';
import mParticle, { WaterTrackedEvent } from '~/Providers/mParticle';

const FETCH = 'artful-client/Water/FETCH';
const FETCH_SUCCESS = 'artful-client/Water/FETCH_SUCCESS';
const FETCH_FAILURE = 'artful-client/Water/FETCH_FAILURE';

const STORE = 'artful-client/Water/STORE';
const STORE_SUCCESS = 'artful-client/Water/STORE_SUCCESS';
const STORE_FAILURE = 'artful-client/Water/STORE_FAILURE';

const DRINK = 'artful-client/Water/DRINK';
const DRINK_SUCCESS = 'artful-client/Water/DRINK_SUCCESS';
const DRINK_FAILURE = 'artful-client/Water/DRINK_FAILURE';

const POUR = 'artful-client/Water/POUR';
const POUR_SUCCESS = 'artful-client/Water/POUR_SUCCESS';
const POUR_FAILURE = 'artful-client/Water/POUR_FAILURE';

const CLEAR_WATER = 'artful-client/Water/CLEAR_WATER';

const initialState = {
  isFetching: false,
  fetched: false,
  data: false,
  error: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH:
    case POUR:
    case DRINK:
    case STORE: {
      return { ...state, isFetching: true };
    }
    case FETCH_SUCCESS:
      return { ...state, isFetching: false, fetched: true, data: action.data };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        error: action.error,
      };

    case STORE_SUCCESS:
      return { ...state, isFetching: false, data: action.data };
    case DRINK_SUCCESS:
      return { ...state, isFetching: false, data: action.data };
    case POUR_SUCCESS:
      return { ...state, isFetching: false, data: action.data };

    case POUR_FAILURE:
    case DRINK_FAILURE:
    case STORE_FAILURE: {
      return { ...state, isFetching: false, error: action.error };
    }

    case CLEAR_WATER:
      return initialState;
    default:
      return state;
  }
}

export const getWater = date => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await waterApi.fetchByDate({ date: date });
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const updateWater = (drink, date, id = false) => async dispatch => {
  if (!id) {
    const response = await dispatch(createWaterEntry({ date: date }));
    id = response.data.id;
  }
  if (drink) {
    return dispatch(drinkWater(id));
  }
  return dispatch(pourWater(id));
};

export const pourWater = id => async dispatch => {
  dispatch({ type: POUR });
  try {
    let response = await waterApi.pour(id);
    dispatch({ type: POUR_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: POUR_FAILURE, error: err });
  }
};
export const drinkWater = id => async dispatch => {
  dispatch({ type: DRINK });
  try {
    let response = await waterApi.drink(id);
    mParticle.logEvent(new WaterTrackedEvent());
    dispatch({ type: DRINK_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: DRINK_FAILURE, error: err });
  }
};

export const createWaterEntry = date => async dispatch => {
  dispatch({ type: STORE });
  try {
    let response = await waterApi.store(date);
    dispatch({ type: STORE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
  }
};

export const clearWater = () => dispatch => {
  dispatch({ type: CLEAR_WATER });
};
