/* eslint-disable max-len */
import React from 'react';

export default function HabitLaptopIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#kgefx4bd8a)">
        <path
          d="M25.54 20.506V8.067c0-.54-.464-.98-1.038-.98H5.498c-.574 0-1.037.44-1.037.98v12.439H3c0 .97.834 1.581 1.862 1.581h20.276c1.028 0 1.862-.61 1.862-1.581h-1.46zm-9.12.602H13.58c-.352 0-.637-.27-.637-.602h4.112c0 .331-.285.602-.637.602zm7.823-1.77H5.757V8.256h18.486v11.084z"
          fill={props.fill ? props.fill : '#000'}
          className="fill"
        />
      </g>
      <defs>
        <clipPath id="kgefx4bd8a">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
