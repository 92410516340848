import React, { useCallback, useRef } from 'react';
import propTypes from 'prop-types';

import { useMountEffect } from '~/util';

import './AdjustButton.scss';

function AdjustButton({ value, selectedColor, onInput, onChange }) {
  const inputRef = useRef(null);

  // This is necessary to achieve Javascript's default onChange behavior,
  // because React makes no distinction between onChange and onInput in JSX nodes.
  useMountEffect(() => {
    const input = inputRef.current;
    input.addEventListener('change', handleChange);

    return () => {
      input.removeEventListener(handleChange);
    };
  });

  const handleChange = useCallback(
    ({ target: { value } }) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className="color-input-wrapper">
      <input
        ref={inputRef}
        id="adjust-color-input"
        type="color"
        value={value}
        onClick={e => e.stopPropagation()}
        onChange={onInput} // This is effectively onInput
      />
      <div className="adjust-color-button">
        <div className="color-preview" style={{ backgroundColor: selectedColor }} />
        <label htmlFor="color-input">Adjust...</label>
      </div>
    </div>
  );
}

AdjustButton.propTypes = {
  value: propTypes.string.isRequired,
  selectedColor: propTypes.string.isRequired,
  onInput: propTypes.func,
  onChange: propTypes.func.isRequired,
};

export default AdjustButton;
