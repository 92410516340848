import moment from '~/Providers/Moment';

export const parseChecklist = list => {
  if (!list) return { items: [] };
  list.description = list.description || '';
  if (list.description.startsWith('[')) {
    return list.description.split('\n');
  } else if (list.description.length > 0) {
    return [`[ ] ${list.description}`];
  } else {
    return [];
  }
};

export const updateChecklistState = (state, type, date, currentItem) => {
  return {
    [type]: {
      ...state[type],
      [date]: currentItem,
    },
  };
};

const checklistAdjustmentForLocale = {
  en: 0,
  'en-gb': -1,
};

export const toStartOfWeekForChecklist = date =>
  date
    .clone()
    .startOf('week')
    .add(checklistAdjustmentForLocale[moment.locale()], 'days');
