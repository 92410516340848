import { reduxUpdate } from '~/Redux/Helpers';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_TIMEZONE,
  UPDATE_TIMEZONE_SUCCESS,
  UPDATE_TIMEZONE_FAILURE,
  UPDATE_FONT,
  UPDATE_FONT_SUCCESS,
  UPDATE_FONT_FAILURE,
  UPDATE_SKIN,
  UPDATE_SKIN_SUCCESS,
  UPDATE_SKIN_FAILURE,
} from './constants';

const initialState = {
  isFetching: false,
  fetched: false,
  data: {
    calendar_id: false,
    timezone: {
      name: false,
    },
    font: false,
    skin: false,
    custom_list_title: false,
  },
  error: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case STORE:
    case UPDATE_FONT:
    case UPDATE_SKIN: {
      return { ...state, isFetching: true };
    }

    case FETCH: {
      return {
        ...state,
        isFetching: true,
        fetched: false,
        data: { ...state.data, ...action.updates },
      };
    }

    case UPDATE:
    case UPDATE_TIMEZONE: {
      return {
        ...state,
        isFetching: true,
        data: reduxUpdate(state.data, action.update),
      };
    }

    case STORE_SUCCESS:
    case UPDATE_SUCCESS:
    case UPDATE_FONT_SUCCESS:
    case UPDATE_SKIN_SUCCESS:
    case UPDATE_TIMEZONE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        fetched: true,
        data: action.data ? action.data : initialState.data,
      };
    }

    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        data: action.data ? action.data : initialState.data,
      };

    case STORE_FAILURE:
    case UPDATE_TIMEZONE_FAILURE:
    case UPDATE_FONT_FAILURE:
    case UPDATE_SKIN_FAILURE: {
      return { ...state, isFetching: false, error: action.error };
    }

    case FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        fetched: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
}
