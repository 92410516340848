import { connect } from 'react-redux';
import { fetchAll } from '~/Services/Container';

import { closeModal, openModal } from '~/Redux/Modules/Modal';
import Modal from '~/Components/Global/Modal/Modal';

const mapStateToProps = ({ Modal }) => {
  return {
    modalsOpen: Modal.modalsOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAll: () => fetchAll(),
    openModal: data => dispatch(openModal(data)),
    closeModal: data => dispatch(closeModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
