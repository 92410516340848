import { mapStackTrace } from 'sourcemapped-stacktrace';
import mParticle from '@mparticle/web-sdk';
import { AuthApi, errorApi, googleApi, appleApi } from '~/Providers/Api';
import Storage from '~/Providers/Storage';
import { success, error } from '~/Redux/Modules/Notifications';

import { asyncActionSet, asyncActionCreator } from '~/Redux/Generators';
import { OAUTH_CREDENTIALS } from '~/Config/Keys';
import history from '~/Providers/History';
import { parseErrors } from '~/Redux/Helpers';

export const SET_ACCESS_TOKEN = 'artful-client/Auth/SET_ACCESS_TOKEN';
const UNSET_ACCESS_TOKEN = 'artful-client/Auth/UNSET_ACCESS_TOKEN';
const NO_ACCESS_TOKEN = 'artful-client/Auth/NO_ACCESS_TOKEN';

const GET_STATUS = asyncActionSet('artful-client/Auth/GET_STATUS');
const REQUEST_LOGIN = 'artful-client/Auth/REQUEST_LOGIN';
const REQUEST_LOGIN_SUCCESS = 'artful-client/Auth/REQUEST_LOGIN_SUCCESS';
const REQUEST_LOGIN_FAILURE = 'artful-client/Auth/REQUEST_LOGIN_FAILURE';
const REQUEST_PASSWORD_RESET = 'artful-client/Auth/REQUEST_PASSWORD_RESET';
const REQUEST_PASSWORD_RESET_SUCCESS = 'artful-client/Auth/REQUEST_PASSWORD_RESET_SUCCESS';
const REQUEST_PASSWORD_RESET_FAILURE = 'artful-client/Auth/REQUEST_PASSWORD_RESET_FAILURE';
export const LOGOUT = 'artful-client/Auth/LOGOUT';
const UPDATE_FIELD = 'artful-client/Auth/UPDATE_FIELD';

const initialState = {
  isAuthenticated: false,
  isFetching: false,
  isLogging: false,
  hasFetched: false,
  accessToken: Storage.get('access_token'),
  user: {},
  error: {}, // TODO how to handle errors
  current: {
    email: '',
    password: '',
  },
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_STATUS.send:
      return { ...state, isFetching: true };
    case GET_STATUS.success:
      Storage.set('Auth.isAuthenticated', true);
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        isAuthenticated: true,
        user: action.user,
      };
    case GET_STATUS.failed:
      Storage.set('Auth.isAuthenticated', false);
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        isAuthenticated: false,
        user: {},
      };
    case UPDATE_FIELD:
      return { ...state, current: { ...state.current, ...action.update } };
    case REQUEST_LOGIN:
      Storage.set('Auth.isAuthenticated', false);
      return { ...state, isLogging: true, isAuthenticated: false };
    case REQUEST_LOGIN_SUCCESS:
      Storage.set('Auth.isAuthenticated', true);
      return {
        ...state,
        isLogging: false,
        hasFetched: true,
        isAuthenticated: true,
        current: initialState.current,
      };
    case REQUEST_LOGIN_FAILURE:
      Storage.set('Auth.isAuthenticated', false);
      return {
        ...state,
        isLogging: false,
        hasFetched: true,
        isAuthenticated: false,
        error: action.error,
      };
    case REQUEST_PASSWORD_RESET:
      return { ...state, isLogging: true };
    case REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isLogging: false,
        error: action.error,
        current: initialState.current,
      };
    case REQUEST_PASSWORD_RESET_FAILURE:
      return { ...state, isLogging: false, error: action.error };
    case SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.accessToken };
    case UNSET_ACCESS_TOKEN:
      return { ...state, accessToken: null };
    case NO_ACCESS_TOKEN:
      Storage.set('Auth.isAuthenticated', false);
      return { ...state, hasFetched: true, isAuthenticated: false };
    case LOGOUT:
      Storage.set('Auth.isAuthenticated', false);
      return { ...state, hasFetched: true, isAuthenticated: false };
    default:
      return state;
  }
}

// TODO Find a place for refresh token storage
export const setAccessToken = token => dispatch => {
  Storage.set('access_token', token);
  dispatch({ type: SET_ACCESS_TOKEN, accessToken: token });
};

export const unsetAccessToken = () => dispatch => {
  Storage.remove('access_token');
  dispatch({ type: UNSET_ACCESS_TOKEN });
};

export const getAuthStatus = asyncActionCreator(GET_STATUS, AuthApi.status);

export const requestToken = (requestBody, redirect = false) => async dispatch => {
  dispatch(unsetAccessToken());
  try {
    let response = await AuthApi.createToken(requestBody);
    mParticle.Identity.login(
      {
        userIdentities: {
          email: requestBody.username,
        },
      },
      result => {
        console.log('mParticle', 'login result', result);
      }
    );
    dispatch(setAccessToken(response.access_token));
    redirect ? history.push(redirect) : history.push('/');
    dispatch({ type: REQUEST_LOGIN_SUCCESS });
    return response;
  } catch (err) {
    dispatch({ type: REQUEST_LOGIN_FAILURE, error: err });
    if (err.stack) {
      mapStackTrace(err.stack, mappedStack => {
        errorApi.noticeError(mappedStack || err.stack, {
          userAgent: window.navigator.userAgent,
          message: err.message,
        });
        dispatch(error('Sorry, an error occurred', err.message));
      });
    } else {
      dispatch(error('Login failure', 'Incorrect email or password'));
    }
  }
};

export const requestGoogleToken = (requestBody, redirect = false) => async dispatch => {
  dispatch(unsetAccessToken());
  try {
    let response = await googleApi.createToken(requestBody.code);
    dispatch(setAccessToken(response.data.accessToken));
    redirect ? history.push(redirect) : history.push('/');
    dispatch({ type: REQUEST_LOGIN_SUCCESS });
    return response;
  } catch (err) {
    dispatch({ type: REQUEST_LOGIN_FAILURE, error: err });
    if (err.stack) {
      mapStackTrace(err.stack, mappedStack => {
        errorApi.noticeError(mappedStack || err.stack, {
          userAgent: window.navigator.userAgent,
          message: err.message,
        });
        dispatch(error('Sorry, an error occurred', err.message));
      });
    } else if (err.message == 'User not found') {
      dispatch(error('Account not found, please sign up for an account'));
      history.push('/register');
      console.log(err.message);
    } else {
      dispatch(error('Login failure', 'Incorrect email or password'));
    }
  }
};

export const requestAppleToken = (requestBody, redirect = false) => async dispatch => {
  dispatch(unsetAccessToken());
  try {
    let response = await appleApi.createToken(requestBody);
    dispatch(setAccessToken(response.data.accessToken));
    redirect ? history.push(redirect) : history.push('/');
    dispatch({ type: REQUEST_LOGIN_SUCCESS });
    return response;
  } catch (err) {
    dispatch({ type: REQUEST_LOGIN_FAILURE, error: err });
    if (err.stack) {
      mapStackTrace(err.stack, mappedStack => {
        errorApi.noticeError(mappedStack || err.stack, {
          userAgent: window.navigator.userAgent,
          message: err.message,
        });
        dispatch(error('Sorry, an error occurred', err.message));
      });
    } else if (err.message == 'User not found') {
      dispatch(error('Account not found, please sign up for an account'));
      history.push('/register');
      console.log(err.message);
    } else {
      dispatch(error('Login failure', 'Incorrect email or password'));
    }
  }
};

export const handleLogin = (data, redirect = false) => dispatch => {
  let { current } = data;
  dispatch({ type: REQUEST_LOGIN, current });
  const requestBody = {
    username: current.email,
    password: current.password,
    grant_type: 'password',
    client_id: OAUTH_CREDENTIALS.CLIENT_ID,
    client_secret: OAUTH_CREDENTIALS.CLIENT_SECRET,
    scope: '*',
  };
  dispatch(requestToken(requestBody, redirect));
};

export const handleGoogleLogin = (data, redirect = false) => dispatch => {
  let { current } = data;
  dispatch({ type: REQUEST_LOGIN, current });
  const requestBody = {
    code: data,
  };
  dispatch(requestGoogleToken(requestBody, redirect));
};

export const handleAppleLogin = (data, redirect = false) => dispatch => {
  let {
    authorization: { code },
  } = data;
  dispatch({ type: REQUEST_LOGIN });
  const requestBody = {
    code,
    client: 'com.artfulagenda.api',
  };
  dispatch(requestAppleToken(requestBody, redirect));
};

export const handleRequestPasswordReset = data => async dispatch => {
  let { current } = data;
  dispatch({ type: REQUEST_PASSWORD_RESET, current });
  try {
    await AuthApi.requestPasswordReset({ email: current.email });
    dispatch({ type: REQUEST_PASSWORD_RESET_SUCCESS });
    dispatch(success('Success', 'Password reset email sent'));
  } catch (err) {
    dispatch({ type: REQUEST_PASSWORD_RESET_FAILURE, error: err });
    dispatch(error('Failure', parseErrors(err)));
  }
};

export const handleResetPassword = data => async dispatch => {
  let { current } = data;
  dispatch({ type: REQUEST_PASSWORD_RESET, current });
  try {
    await AuthApi.resetPassword(current);
    dispatch({ type: REQUEST_PASSWORD_RESET_SUCCESS });
    dispatch(success('Success', 'Password updated'));
    history.push('/login');
  } catch (err) {
    dispatch({ type: REQUEST_PASSWORD_RESET_FAILURE, error: err });
    dispatch(error('Failure', parseErrors(err)));
  }
};

export const handleLogout = () => dispatch => {
  dispatch(unsetAccessToken());
  dispatch({ type: LOGOUT });
  mParticle.Identity.logout({}, result => {
    console.log('mParticle', 'logout result', result);
  });
  window?.zE?.('messenger', 'logoutUser');
};

export const probeAuthStatus = () => (dispatch, getState) => {
  if (!getState().Auth.accessToken) {
    dispatch({ type: NO_ACCESS_TOKEN });
    return false;
  }
  return true;
};

export const updateField = update => dispatch => dispatch({ type: UPDATE_FIELD, update });
