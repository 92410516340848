import React from 'react';
import propTypes from 'prop-types';
import Checkbox from './Checkbox';
import './ArtfulCheckbox.scss';

const ArtfulCheckbox = props => {
  return (
    <div className="artful-checkbox-wrapper">
      <Checkbox {...props} />
    </div>
  );
};

ArtfulCheckbox.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  value: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  isDisabled: propTypes.bool,
  onChange: propTypes.func,
  artful: propTypes.bool,
  checklistStyle: propTypes.string,
};

ArtfulCheckbox.defaultProps = {
  isDisabled: false,
  formatted: true,
  value: false,
  className: '',
};

export default ArtfulCheckbox;
