import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, connect } from 'react-redux';
import moment from '~/Providers/Moment';
import { DATE_TIME_FORMAT } from '~/Redux/Constants';
import { MONTH_TODO, WEEK_TODO } from '~/Config/Checklists';
import { setMonthType, setWeekType, setDayType } from '~/Redux/Modules/DateRange';
import history from '~/Providers/History';
import { getUserLists, setCurrent } from '~/Redux/Modules/UserLists';
import { humanDateFormats } from '~/Redux/Modules/UserOptions';

import './SearchResult.scss';
import { setCurrentEvent } from '~/Redux/Modules/Events';

const humanTypes = {
  event: 'Event',
  meal_prep: 'Meal Prep',
  user_list: 'List',
  task: 'Task',
};

const SearchResult = ({ id, summary, description, start, type, subtype, clear, events }) => {
  const dispatch = useDispatch();
  const dateFormats = useSelector(humanDateFormats);
  const startString = useMemo(() => {
    if (type === 'user_list') return '';
    if (type === 'event') {
      const parsed = moment(start, DATE_TIME_FORMAT);
      if (parsed.hour === 0 && parsed.minute === 0) return parsed.format(dateFormats['M/D/YYYY']);
      const formattedDate = parsed.format(`${dateFormats['M/D/YYYY']} h:mm`);
      const amOrPm = parsed.format('a').substring(0, 1);
      return `${formattedDate}${amOrPm}`;
    }
    return moment(start, DATE_TIME_FORMAT).format(dateFormats['M/D/YYYY']);
  }, [start, type, dateFormats]);

  const handleClick = () => {
    if (type === 'task') {
      if (subtype === MONTH_TODO) {
        dispatch(setMonthType(moment(start, DATE_TIME_FORMAT)));
      } else if (subtype === WEEK_TODO) {
        dispatch(setWeekType(moment(start, DATE_TIME_FORMAT)));
      } else {
        dispatch(setDayType(moment(start, DATE_TIME_FORMAT)));
      }
      clear();
    } else if (type === 'user_list') {
      // navigate & select list
      dispatch(getUserLists())
        .then(({ data }) => {
          var list = data.filter(l => `${l.id}` === id)[0];
          clear();
          if (list) return dispatch(setCurrent(list));
        })
        .then(() => history.push('/lists'));
    } else {
      const startKey = moment(start, DATE_TIME_FORMAT).format('YYYY-MM-DD');
      const dayEvents = events[startKey];
      const fullEvent = dayEvents.find(event => String(event.id) === id);
      dispatch(setCurrentEvent(fullEvent, true));
      clear();
    }
  };

  return (
    <div className={`search-result search-result-${type}`} onClick={() => handleClick()}>
      <div className="search-result-row">
        <span className="summary" dangerouslySetInnerHTML={{ __html: summary }} />
        <span className="start">{startString}</span>
      </div>
      <div className="search-result-row">
        <span className="description" dangerouslySetInnerHTML={{ __html: description }} />
        <span className="type">{humanTypes[type]}</span>
      </div>
    </div>
  );
};

SearchResult.propTypes = {
  summary: PropTypes.string.isRequired,
  description: PropTypes.string,
  start: PropTypes.string,
  type: PropTypes.string.isRequired,
  subtype: PropTypes.string,
  id: PropTypes.string.isRequired,
  clear: PropTypes.func.isRequired,
  events: PropTypes.object,
};

const mapStateToProps = ({ Events }) => {
  return {
    events: Events.formattedData,
  };
};

export default connect(mapStateToProps)(SearchResult);
