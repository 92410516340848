import { calendarAccountsApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import { getUserOptions } from '~/Redux/Modules/UserOptions';

import {
  SET_ACCOUNT,
  UPDATE_ACCOUNT as UPDATE_CALENDAR_ACCOUNT,
} from '~/Redux/Modules/CalendarAccounts';
import { OUTLOOK } from '~/Config/Drivers';
import mParticle, { CalendarAccountConnectedEvent } from '~/Providers/mParticle';

const ADD_ACCOUNT = 'artful-client/Outlook/ADD_ACCOUNT';
const ADD_ACCOUNT_SUCCESS = 'artful-client/Outlook/ADD_ACCOUNT_SUCCESS';
const ADD_ACCOUNT_FAILURE = 'artful-client/Outlook/ADD_ACCOUNT_FAILURE';

const UPDATE_ACCOUNT = 'artful-client/Outlook/UPDATE_ACCOUNT';
const UPDATE_ACCOUNT_SUCCESS = 'artful-client/Outlook/UPDATE_ACCOUNT_SUCCESS';
const UPDATE_ACCOUNT_FAILURE = 'artful-client/Outlook/UPDATE_ACCOUNT_FAILURE';

const initialState = {
  isFetching: false,
  isUpdating: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ACCOUNT:
      return { ...state, isFetching: true };
    case ADD_ACCOUNT_SUCCESS:
      return { ...state, isFetching: false };
    case ADD_ACCOUNT_FAILURE:
      return { ...state, isFetching: false };
    case UPDATE_ACCOUNT:
      return { ...state, isUpdating: true };
    case UPDATE_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_FAILURE:
      return { ...state, isUpdating: false };
    default:
      return state;
  }
}

export const outlookCallback = data => async dispatch => {
  dispatch({ type: ADD_ACCOUNT });
  try {
    let response = await calendarAccountsApi.linkAccount('outlook', data);
    mParticle.logEvent(new CalendarAccountConnectedEvent(OUTLOOK));
    dispatch({ type: ADD_ACCOUNT_SUCCESS });
    dispatch({ type: SET_ACCOUNT, account: response.data });
    dispatch(getUserOptions());
    dispatch(success('Microsoft account added!'));
    return response;
  } catch (err) {
    dispatch({ type: ADD_ACCOUNT_FAILURE, error: err });
    dispatch(error('Microsoft account failed', parseErrors(err)));
  }
};

export const updateOutlookAccount = (account, data) => async dispatch => {
  dispatch({ type: UPDATE_ACCOUNT });
  try {
    let response = await calendarAccountsApi.updateAccount(account, data);
    dispatch({ type: UPDATE_ACCOUNT_SUCCESS });
    dispatch({ type: UPDATE_CALENDAR_ACCOUNT, account: response.data });
    dispatch(getUserOptions());
    dispatch(success('Microsoft account updated!'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_ACCOUNT_FAILURE, error: err });
    dispatch(error('Microsoft account update failed', parseErrors(err)));
  }
};

export const outlookAccount = ({ CalendarAccounts }) =>
  CalendarAccounts.data.find(c => c.driver === OUTLOOK);
