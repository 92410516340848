/* eslint-disable max-len */
import React from 'react';

export default function HabitRunningTwoIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a9ndoicl8a)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M21.823 8.794c-.476-.216-1.05-.034-1.285.413l-1.26 2.414-1.464-1.69a.967.967 0 0 0-.747-.321v-.015h-2.995v.02c-.18-.005-.364.019-.528.11l-3.2 1.8a.896.896 0 0 0-.466.773V15c0 .5.43.898.958.898.532 0 .957-.403.957-.898v-2.189l1.92-1.08v3.27c0 .24.103.465.282.638l3.558 3.336v4.128c0 .499.43.897.958.897.532 0 .957-.403.957-.897V18.6a.885.885 0 0 0-.282-.638l-1.76-1.651a.832.832 0 0 0 .122-.413V12.49l1.173 1.354c.184.21.46.33.747.33.036 0 .072 0 .108-.004a.946.946 0 0 0 .752-.5l1.92-3.68c.246-.442.051-.98-.425-1.196zM17.56 7.349c0 .745-.645 1.349-1.44 1.349-.794 0-1.438-.604-1.438-1.35 0-.744.644-1.348 1.438-1.348.795 0 1.44.604 1.44 1.349z" />
        <path d="m12.158 19.075-4.404.907c-.517.106-.844.586-.732 1.07.097.423.497.706.937.706a.986.986 0 0 0 .205-.019l4.797-.989a.971.971 0 0 0 .61-.403l1.566-2.371-1.392-1.306-1.587 2.405z" />
      </g>
      <defs>
        <clipPath id="a9ndoicl8a">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
