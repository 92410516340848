/* eslint-disable max-len */
import React from 'react';

export default function HabitBedIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#9db7t8hc5a)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M29.09 15.431H1.819V5.908a.909.909 0 1 0-1.818 0v18.184a.909.909 0 1 0 1.818 0v-3.27h26.364v3.27a.909.909 0 1 0 1.817 0L30 16.34a.91.91 0 0 0-.91-.909z" />
        <path d="M4.028 14.8h5.497c.837 0 1.516-.68 1.516-1.516v-.643c0-.837-.68-1.517-1.516-1.517l-5.497-.001c-.836 0-1.517.68-1.517 1.517v.643c0 .837.681 1.516 1.517 1.516z" />
      </g>
      <defs>
        <clipPath id="9db7t8hc5a">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
