/* eslint-disable max-len */
import React from 'react';

export default function HabitLaundryIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.25 5H8.75C7.5 5 7.5 5 7.5 6.25V8.21h15V6.25C22.5 5 22.5 5 21.25 5zM10.833 7.416h-1.25v-.833h1.25v.833zm2.5 0h-1.25v-.833h1.25v.833zM7.499 23.75c0 1.25 0 1.25 1.25 1.25h12.5c1.25 0 1.25 0 1.25-1.25v-1.5h-15v1.5zM7.499 21.417h15L22.5 9.041H7.5v12.376zm7.5-11A4.57 4.57 0 0 1 19.584 15 4.57 4.57 0 0 1 15 19.583 4.57 4.57 0 0 1 10.416 15 4.57 4.57 0 0 1 15 10.417z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M17.5 15a2.5 2.5 0 1 1-5.002-.001 2.5 2.5 0 0 1 5.002 0z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
