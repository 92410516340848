import moment from '~/Providers/Moment';

import { DATE_TIME_FORMAT, DATE_FORMAT } from '~/Redux/Constants';
import { applyEventUpdates, formatEvents } from '~/Context/Event';

import { applyEventCommands } from './helpers';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  SYNC,
  SYNC_SUCCESS,
  SYNC_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  SET_EDIT_EVENT,
  SET_DRAG_EVENT,
  CLEAR_DRAG_EVENT,
  UPDATE_EVENTS_STATE_COLOR,
  UPDATE_INPUT,
  UPDATE_TIMEZONE,
  FILTER_NON_ACTIVE_EVENTS,
  CLEAR,
} from './constants';

const initialEvent = {
  summary: '',
  location: '',
  description: '',
  start: moment().format(DATE_TIME_FORMAT),
  end: moment()
    .add(1, 'day')
    .format(DATE_TIME_FORMAT),
  attendees: [],
  modify: false,
  invite: false,
  view: false,
  timezone: {
    name: false,
  },
  calendar_id: 0,
  isAllDay: false,
  localOnly: true,
};

const initialState = {
  isFetching: false,
  isSyncing: false,
  isUpdating: false,
  isDeleting: false,
  isDragging: false,
  data: [],
  formattedData: {},
  currentEvenDate: moment().format(DATE_TIME_FORMAT),
  currentEvent: initialEvent,
  lastFetch: false,
  currentSync: false,
  updatesInProgress: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      console.log(FETCH, action.stamp);
      return { ...state, isFetching: true, lastFetch: action.stamp };
    case SYNC:
      console.log(SYNC, action.stamp, action.start);
      return {
        ...state,
        isSyncing: true,
        currentSync: action.start,
        currentSyncStamp: action.stamp,
      };
    case UPDATE: {
      const newData = state.data.map(event =>
        action.event.id === event.id && action.event.startForRecurrence === event.startForRecurrence
          ? action.event
          : event
      );
      return {
        ...state,
        isUpdating: true,
        data: newData,
        formattedData: formatEvents(newData),
        updatesInProgress: [...state.updatesInProgress, action],
      };
    }
    case DELETE:
      return { ...state, isDeleting: true };
    case FETCH_SUCCESS: {
      console.log(FETCH_SUCCESS, state.lastFetch, action.stamp);
      if (state.lastFetch && state.lastFetch > action.stamp) return state;
      console.log(FETCH_SUCCESS, 'updating', state.lastFetch, action.stamp);
      return {
        ...state,
        isFetching: false,
        data: applyEventUpdates(action.data, state.updatesInProgress),
        formattedData: formatEvents(applyEventUpdates(action.data, state.updatesInProgress)),
      };
    }
    case STORE_SUCCESS: {
      const newData = applyEventUpdates(
        applyEventCommands(state.data, action.data),
        state.updatesInProgress
      );
      return {
        ...state,
        isFetching: false,
        currentEvent: initialEvent,
        data: newData,
        formattedData: formatEvents(newData),
      };
    }
    case SYNC_SUCCESS:
      console.log(SYNC_SUCCESS, state.lastFetch, action.stamp);
      if (state.lastFetch && state.lastFetch > action.stamp) return state;
      console.log(SYNC_SUCCESS, 'updating', state.lastFetch, action.stamp);
      return {
        ...state,
        isSyncing: false,
        isFetching: false,
        data: applyEventUpdates(action.data, state.updatesInProgress),
        formattedData: formatEvents(applyEventUpdates(action.data, state.updatesInProgress)),
        lastFetch: state.lastFetch + 1,
      };
    case UPDATE_SUCCESS: {
      if (action.data.oldId) {
        const thisUpdate = state.updatesInProgress.filter(
          update => update.stamp === action.stamp
        )[0];
        const newData = applyEventUpdates(
          [
            ...state.data.filter(
              e =>
                `${e.id}` !== `${action.data.oldId}` ||
                (thisUpdate && thisUpdate.event.startForRecurrence !== e.startForRecurrence)
            ),
            action.data.event,
          ],
          state.updatesInProgress
        );
        return {
          ...state,
          isUpdating: false,
          data: newData,
          formattedData: formatEvents(newData),
          updatesInProgress: state.updatesInProgress.filter(
            update => update.stamp !== action.stamp
          ),
        };
      }
      const newData = applyEventUpdates(
        applyEventCommands(state.data, action.data),
        state.updatesInProgress
      );
      return {
        ...state,
        isUpdating: false,
        data: newData,
        formattedData: formatEvents(newData),
        updatesInProgress: state.updatesInProgress.filter(update => update.stamp !== action.stamp),
      };
    }
    case DELETE_SUCCESS: {
      const newData = applyEventUpdates(
        applyEventCommands(state.data, action.data),
        state.updatesInProgress
      );
      return {
        ...state,
        isDeleting: false,
        currentEvent: initialEvent,
        data: newData,
        formattedData: formatEvents(newData),
      };
    }
    case FILTER_NON_ACTIVE_EVENTS: {
      const newData = applyEventUpdates(
        state.data.filter(e => e.calendar_id !== action.calendarId),
        state.updatesInProgress
      );
      return { ...state, data: newData, formattedData: formatEvents(newData) };
    }
    case UPDATE_EVENTS_STATE_COLOR: {
      console.log(UPDATE_EVENTS_STATE_COLOR);
      const newData = applyEventUpdates(
        state.data.map(e =>
          (!e.color || e.color?.toLowerCase() === action.oldColor?.toLowerCase()) &&
          e.calendar_id === action.calendar.id
            ? { ...e, color: action.newColor }
            : e
        ),
        state.updatesInProgress
      );
      return { ...state, data: newData, formattedData: formatEvents(newData) };
    }
    case FETCH_FAILURE:
      return { ...state, error: action.error, isFetching: false };
    case SYNC_FAILURE:
      return { ...state, error: action.error, isSyncing: false };
    case STORE:
      return { ...state, error: action.error, isFetching: true };
    case STORE_FAILURE:
      return { ...state, error: action.error, isFetching: false };
    case UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isUpdating: false,
        updatesInProgress: state.updatesInProgress.filter(update => update.stamp !== action.stamp),
        currentSync: false,
      };
    case DELETE_FAILURE:
      return { ...state, error: action.error, isDeleting: false };

    case SET_EDIT_EVENT:
      return {
        ...state,
        localOnly: true,
        currentEvent: { ...state.currentEvent, ...action.event },
        currentEvenDate: moment(action.event.start, DATE_TIME_FORMAT).format(DATE_FORMAT),
      };
    case UPDATE_INPUT:
      console.log(
        'Events',
        'UPDATE_INPUT',
        action.update,
        state.localOnly,
        Object.keys(action.update).reduce(
          (a, key) =>
            a &&
            (key === 'color' ||
              key === 'alarms' ||
              key === 'display_month_view' ||
              key === 'display_week_view' ||
              key === 'display_day_view' ||
              (key === 'description' &&
                action.update.description === state.currentEvent.description)),
          state.localOnly
        )
      );
      return {
        ...state,
        localOnly: Object.keys(action.update).reduce(
          (a, key) =>
            a &&
            (key === 'color' ||
              key === 'alarms' ||
              key === 'display_month_view' ||
              key === 'display_week_view' ||
              key === 'display_day_view' ||
              (key === 'description' &&
                action.update.description === state.currentEvent.description)),
          state.localOnly
        ),
        currentEvent: { ...state.currentEvent, ...action.update },
      };
    case UPDATE_TIMEZONE:
      return {
        ...state,
        localOnly: false,
        currentEvent: {
          ...state.currentEvent,
          timezone: { ...state.currentEvent.timezone, ...action.update },
        },
      };
    case SET_DRAG_EVENT:
      return { ...state, isDragging: true };
    case CLEAR_DRAG_EVENT:
      return { ...state, isDragging: false };
    case CLEAR:
      return {
        ...state,
        currentEvent: initialEvent,
        currentEvenDate: moment().format(DATE_TIME_FORMAT),
      };
    default:
      return state;
  }
}
