/* eslint-disable max-len */
import React from 'react';

export default function HabitMedicineIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#0c915kdwza)">
        <path
          d="m18.326 6.635-.315-.118c-1.971-.742-4.14.158-4.834 2.006L9.36 18.669c-.695 1.848.342 3.954 2.314 4.695l.315.119c1.971.741 4.14-.158 4.834-2.006L20.64 11.33c.695-1.848-.343-3.953-2.314-4.695zM16.03 21.178c-.54 1.437-2.227 2.137-3.76 1.56l-.315-.118c-1.533-.577-2.34-2.215-1.8-3.652l1.909-5.073 3.768 1.418 1.864-4.958c.113-.298.465-.444.784-.325.318.12.487.463.375.761l-1.864 4.957.948.357-1.908 5.073h-.001z"
          fill={props.fill ? props.fill : '#000'}
          className="fill"
        />
      </g>
      <defs>
        <clipPath id="0c915kdwza">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
