export const FETCH = 'artful-client/Events/FETCH';
export const FETCH_SUCCESS = 'artful-client/Events/FETCH_SUCCESS';
export const FETCH_FAILURE = 'artful-client/Events/FETCH_FAILURE';

export const SYNC = 'artful-client/Events/SYNC';
export const SYNC_SUCCESS = 'artful-client/Events/SYNC_SUCCESS';
export const SYNC_FAILURE = 'artful-client/Events/SYNC_FAILURE';

export const STORE = 'artful-client/Events/STORE';
export const STORE_SUCCESS = 'artful-client/Events/STORE_SUCCESS';
export const STORE_FAILURE = 'artful-client/Events/STORE_FAILURE';

export const UPDATE = 'artful-client/Events/UPDATE';
export const UPDATE_SUCCESS = 'artful-client/Events/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'artful-client/Events/UPDATE_FAILURE';

export const DELETE = 'artful-client/Events/DELETE';
export const DELETE_SUCCESS = 'artful-client/Events/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/Events/DELETE_FAILURE';

export const SET_EDIT_EVENT = 'artful-client/Events/SET_EDIT_EVENT';
export const SET_DRAG_EVENT = 'artful-client/Events/SET_DRAG_EVENT';
export const CLEAR_DRAG_EVENT = 'artful-client/Events/CLEAR_DRAG_EVENT';

export const UPDATE_EVENTS_STATE_COLOR = 'artful-client/Events/UPDATE_EVENTS_STATE_COLOR';

export const UPDATE_INPUT = 'artful-client/Events/UPDATE_INPUT';

export const UPDATE_TIMEZONE = 'artful-client/Events/UPDATE_TIMEZONE';

export const FILTER_NON_ACTIVE_EVENTS = 'artful-client/Events/FILTER_NON_ACTIVE_EVENTS';

export const CLEAR = 'artful-client/Events/CLEAR';

export const DAY_EVENT_HEIGHT = 18;
