import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AdminPage from '~/Components/Layout/AdminPage/AdminPage';
import StickerForm from '~/Components/Pages/Admin/AdminStickers/StickerForm';
import Button from '~/Components/Global/Form/Button';

import './AdminStickers.scss';

class AdminStickers extends Component {
  constructor() {
    super();
    this.columns = [
      { header: 'Name', accessor: s => s.name },
      {
        header: 'Icon',
        accessor: s => <img className="sticker-preview" src={s.url} />,
      },
      {
        header: 'Actions',
        accessor: s => {
          return (
            <Link className="actions center-button" to={`${this.props.match.path}/${s.id}`}>
              {this.generateDeleteButton(s)}
            </Link>
          );
        },
      },
    ];
  }
  generateDeleteButton = sticker => {
    const { actionId, deleteSticker, isDeleting } = this.props;
    return (
      <Button
        text="Delete"
        actionText="Deleting"
        onClick={() => deleteSticker(sticker.id)}
        isFetching={isDeleting && sticker.id === actionId}
      />
    );
  };

  render() {
    const {
      stickers,
      isFetching,
      isAdding,
      getStickers,
      actionSticker,
      updateForm,
      addSticker,
    } = this.props;
    return (
      <div className="admin-stickers">
        <AdminPage
          header="Stickers"
          dataObject={stickers}
          isFetching={isFetching}
          columns={this.columns}
          getData={getStickers}
          dataName="Sticker"
        >
          <StickerForm
            isAdding={isAdding}
            onSubmit={() => addSticker(actionSticker)}
            data={actionSticker}
            onChange={updateForm}
          />
        </AdminPage>
      </div>
    );
  }
}

AdminStickers.propTypes = {
  stickers: propTypes.oneOfType([propTypes.array, propTypes.bool]),
  actionSticker: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  isFetching: propTypes.bool,
  isAdding: propTypes.bool,
  isDeleting: propTypes.bool,
  match: propTypes.object,
  addSticker: propTypes.func,
  updateForm: propTypes.func,
  getStickers: propTypes.func,
  deleteSticker: propTypes.func,
  actionId: propTypes.oneOfType([propTypes.number, propTypes.bool]),
};

export default AdminStickers;
