import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Modal from '~/Containers/Global/Modal/Modal';
import { CALENDAR_DISPLAY_ERROR_MODAL } from '~/Config/Modals';
import { closeModal } from '~/Redux/Modules/Modal';
import Button from '~/Components/Global/Form/Button';

import './CalendarDisplayError.scss';

const CalendarDisplayError = ({ modalId }) => {
  const dispatch = useDispatch();
  console.log('CalendarDisplayError');

  const handleCloseModal = () => {
    dispatch(closeModal(modalId));
  };

  const renderCloseButton = () => {
    return (
      <Button
        type="button"
        text="OK"
        className="pill"
        onClick={() => handleCloseModal()}
        actionText="OK"
      />
    );
  };

  return (
    <Modal
      overlayClassName="calendar-display-error-modal"
      id={modalId}
      ariaHideApp={false}
      onClose={() => {}}
    >
      <>
        <h1>Note.</h1>
        <p className="notes">
          To hide this calendar from all views, simply tap the colored square located beside the
          calendar title under Calendar Settings.
        </p>
        <div className="actions">{renderCloseButton()}</div>
      </>
    </Modal>
  );
};

CalendarDisplayError.propTypes = {
  modalId: PropTypes.string,
};

CalendarDisplayError.defaultProps = {
  modalId: CALENDAR_DISPLAY_ERROR_MODAL,
};

export default CalendarDisplayError;
