/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';

const CalendarStyle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <title>Calendar Styling</title>
    <g id="Calendar_Styling" data-name="Calendar Styling">
      <g id="Customization_Icon" data-name="Customization Icon">
        <path
          className="cls-1"
          d="M268.43,300H20.65A15.5,15.5,0,0,1,5.17,284.51V26.41A15.49,15.49,0,0,1,20.65,10.93H206.49a5.16,5.16,0,0,1,0,10.32H20.65a5.17,5.17,0,0,0-5.16,5.16v258.1a5.17,5.17,0,0,0,5.16,5.17H268.43a5.17,5.17,0,0,0,5.16-5.17V98.68a5.17,5.17,0,0,1,10.33,0V284.51A15.51,15.51,0,0,1,268.43,300Z"
        />
        <path
          className="cls-1"
          d="M269,10.32a15.49,15.49,0,0,1,11,26.44l-157,157L90.18,204.66l10.95-32.85,157-156.95A15.47,15.47,0,0,1,269,10.32M269,0a25.64,25.64,0,0,0-18.25,7.56l-156.95,157-1.72,1.73-.77,2.31-11,32.85L73.86,221l19.58-6.53,32.86-11,2.31-.77,1.72-1.73L287.28,44.06A25.81,25.81,0,0,0,269,0Z"
        />
      </g>
    </g>
  </svg>
);

export default CalendarStyle;
