/* eslint-disable max-len */
import React from 'react';

export default function HabitZzzIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#5tm0lqwnea)"
        fill={props.fill ? props.fill : '#000'}
        stroke={props.fill ? props.fill : '#000'}
        strokeWidth=".5"
        className="fill stroke"
      >
        <path d="m8.295 13.91 5.438-6.655H8.422V6h6.997v1.09l-5.299 6.655h5.312V15H8.294v-1.09zM17.342 18.273l3.625-4.436h-3.54V13h4.664v.727l-3.532 4.436h3.54V19h-4.757v-.727zM8.756 23.152l4.23-5.176H8.853V17h5.443v.848l-4.121 5.176h4.13V24h-5.55v-.848z" />
      </g>
      <defs>
        <clipPath id="5tm0lqwnea">
          <rect width="30" height="30" rx="15" fill="#fff" className="fill" />
        </clipPath>
      </defs>
    </svg>
  );
}
