import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MyAccountSection from '~/Components/Pages/Account/MyAccountSection';
import Button from '~/Components/Global/Form/Button';
import {
  TRACKING_STATUS_NOT_DETERMINED,
  TRACKING_STATUS_AUTHORIZED,
  TRACKING_STATUS_DENIED,
  TRACKING_STATUS_RESTRICTED,
  TRACKING_STATUS_NOT_SUPPORTED,
  updateTrackingStatus,
} from '~/Redux/Modules/User';
import TrackingPopup from '~/Components/Global/TrackingPopup';

const trackingStatusLabels = {
  [TRACKING_STATUS_NOT_DETERMINED]: 'Not Determined',
  [TRACKING_STATUS_AUTHORIZED]: 'Authorized',
  [TRACKING_STATUS_DENIED]: 'Denied',
  [TRACKING_STATUS_RESTRICTED]: 'Restricted',
  [TRACKING_STATUS_NOT_SUPPORTED]: 'Not Supported',
};

const TrackingStatus = () => {
  const deviceStatus = useSelector(
    ({ User }) => User?.deviceTrackingStatus || TRACKING_STATUS_NOT_DETERMINED
  );
  const globalStatus = useSelector(
    ({ User }) => User?.data?.tracking_status || TRACKING_STATUS_NOT_DETERMINED
  );
  const dispatch = useDispatch();

  const changeStatus = () => {
    localStorage.removeItem('tracking_status');
    return dispatch(updateTrackingStatus(TRACKING_STATUS_NOT_DETERMINED));
  };

  return (
    <MyAccountSection header={'Tracking'}>
      <div className="tracking-status">
        <div className="tracking-status__device">
          <div className="tracking-status__device__label">Device Setting</div>
          <div className="tracking-status__device__value">{trackingStatusLabels[deviceStatus]}</div>
        </div>
        <div className="tracking-status__global">
          <div className="tracking-status__global__label">Global Setting</div>
          <div className="tracking-status__global__value">{trackingStatusLabels[globalStatus]}</div>
        </div>
        <div className="tracking-status__actions">
          <Button text="Change" onClick={changeStatus} />
        </div>
      </div>
      <TrackingPopup />
    </MyAccountSection>
  );
};

export default TrackingStatus;
