import React from 'react';
import propTypes from 'prop-types';
import ReactSelect from 'react-select';

import 'react-select/dist/react-select.css';
import './Select.scss';

const Select = ({
  name,
  label,
  data,
  className,
  dataValueKey,
  dataLabelKey,
  onChange,
  disabled,
  hidden,
  value,
  placeholder,
  inlineLabel,
  allowPlaceholder,
}) => {
  if (!data.length) return false;

  if (typeof value === 'undefined' || value === null || value === '') value = 'false';

  value = value.toString();

  const options = data.map(data => {
    return {
      value: data[dataValueKey].toString(),
      label: data[dataLabelKey],
      clearableValue: false,
    };
  });

  if (allowPlaceholder) {
    options.unshift({
      value: 'false',
      label: placeholder,
      clearableValue: false,
    });
  }

  return (
    <div
      style={hidden ? { display: 'none' } : {}}
      className={`react-select ${inlineLabel ? 'flex-row' : ''}`}
    >
      {label && (
        <label className={inlineLabel ? 'inline-label' : ''} htmlFor={name}>
          {label}
        </label>
      )}
      <ReactSelect
        clearable={false}
        name={name}
        value={value}
        className={className}
        disabled={disabled}
        onChange={val => onChange({ target: { value: val, name: name } })}
        options={options}
        simpleValue
      />
    </div>
  );
};

Select.defaultProps = {
  disabled: false,
  dataValueKey: 'value',
  dataLabelKey: 'label',
  hidden: false,
  placeholder: 'Any',
  inlineLabel: false,
  allowPlaceholder: true,
};

Select.propTypes = {
  name: propTypes.string,
  label: propTypes.string,
  value: propTypes.oneOfType([propTypes.bool, propTypes.string, propTypes.number]),
  data: propTypes.oneOfType([propTypes.array, propTypes.bool]),
  dataValueKey: propTypes.string,
  dataLabelKey: propTypes.string,
  onChange: propTypes.func,
  disabled: propTypes.bool,
  hidden: propTypes.bool,
  className: propTypes.string,
  placeholder: propTypes.string,
  inlineLabel: propTypes.bool,
  allowPlaceholder: propTypes.bool,
};

export default Select;
