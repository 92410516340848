import { AdminUserApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';

const FETCH = 'artful-client/Admin/Users/FETCH';
const FETCH_SUCCESS = 'artful-client/Admin/Users/FETCH_SUCCESS';
const FETCH_FAILURE = 'artful-client/Admin/Users/FETCH_FAILURE';

const UPDATE = 'artful-client/Admin/Users/UPDATE';
const UPDATE_SUCCESS = 'artful-client/Admin/Users/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'artful-client/Admin/Users/UPDATE_FAILURE';

const DELETE = 'artful-client/Admin/Users/DELETE';
const DELETE_SUCCESS = 'artful-client/Admin/Users/DELETE_SUCCESS';
const DELETE_FAILURE = 'artful-client/Admin/Users/DELETE_FAILURE';

const CLEAR = 'artful-client/Admin/Users/CLEAR';

const initialState = {
  isFetching: false,
  hasFetched: false,
  isDeleting: false,
  isUpdating: false,
  data: {
    current_page: 1,
    per_page: 20,
    total: 20,
    data: [],
  },
  error: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true, hasFetched: false };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        data: action.data,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        error: action.error,
      };

    case UPDATE:
      return { ...state, isUpdating: true };
    case UPDATE_SUCCESS:
      return { ...state, isUpdating: false };
    case UPDATE_FAILURE:
      return { ...state, isUpdating: false };

    case DELETE:
      return { ...state, isDeleting: true, actionId: action.actionId };
    case DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        actionId: false,
        data: {
          ...state.data,
          data: state.data.data.filter(s => s.id !== action.data.id),
        },
      };
    case DELETE_FAILURE:
      return { ...state, isDeleting: false, error: action.error };

    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

export const getUsers = (page = 1, search = '') => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await AdminUserApi.fetch(page, search);
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const clear = () => dispatch => {
  dispatch({ type: CLEAR });
};

export const deleteUser = id => async dispatch => {
  dispatch({ type: DELETE, actionId: id });
  try {
    let response = await AdminUserApi.delete(id);
    dispatch({ type: DELETE_SUCCESS, data: response.data });
    dispatch(success('User Deleted!'));
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, error: err });
  }
};

export const updateUser = data => async dispatch => {
  dispatch({ type: UPDATE, actionId: data.id });
  try {
    let response = await AdminUserApi.update(data.id, data);
    dispatch({ type: UPDATE_SUCCESS, data: response.data });
    dispatch(success('User Updated!'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_FAILURE, error: err });
    dispatch(error('Update Failed', err));
  }
};
