/* eslint-disable max-len */
import React from 'react';

export default function HabitCheckmarkIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className="fill"
        d="M12.1136 20.7643L6.26359 15.5344C5.91214 15.2202 5.91214 14.7108 6.26359 14.3965L7.53636 13.2587C7.88781 12.9444 8.4577 12.9444 8.80915 13.2587L12.75 16.7817L21.1908 9.23565C21.5423 8.92145 22.1122 8.92145 22.4636 9.23565L23.7364 10.3735C24.0879 10.6877 24.0879 11.1972 23.7364 11.5114L13.3864 20.7644C13.0349 21.0786 12.4651 21.0786 12.1136 20.7643Z"
        fill="black"
      />
    </svg>
  );
}
