export const nextPriority = (after, before) => {
  const afterPriority = after?.priority || 0;
  const beforePriority = before?.priority || 0;

  if (afterPriority === 0 && beforePriority === 0) {
    return 10000;
  }

  if (afterPriority === 0) {
    return beforePriority + 10000;
  }

  if (afterPriority !== 0 && beforePriority !== 0) {
    return Math.floor((beforePriority + afterPriority) / 2);
  }

  return Math.floor(afterPriority / 2);
};
