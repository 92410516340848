/* eslint-disable max-len */
import React from 'react';

export default function HabitHeartIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25 8.125c-.689-1.25-5.001-5.438-10 .624-5.25-6.062-9.313-1.875-10-.624-1.25 2.312-.5 5.812 1.25 7.5l8.75 8.75 8.751-8.75c1.749-1.688 2.499-5.187 1.248-7.5z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
