import { calendarAccountsApi } from '~/Providers/Api';
import { getEvents, filterNonActiveEvents, updateEventsStateColor } from '~/Redux/Modules/Events';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import mParticle, { CalendarAccountDisconnectedEvent } from '~/Providers/mParticle';

export const FETCH_ALL = 'artful-client/CalendarAccounts/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'artful-client/CalendarAccounts/FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'artful-client/CalendarAccounts/FETCH_ALL_FAILURE';

export const DELETE = 'artful-client/CalendarAccounts/DELETE';
export const DELETE_SUCCESS = 'artful-client/CalendarAccounts/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/CalendarAccounts/DELETE_FAILURE';

export const SYNC = 'artful-client/CalendarAccounts/SYNC';
export const SYNC_SUCCESS = 'artful-client/CalendarAccounts/SYNC_SUCCESS';
export const SYNC_FAILURE = 'artful-client/CalendarAccounts/SYNC_FAILURE';

export const CHANGE_COLOR = 'artful-client/CalendarAccounts/CHANGE_COLOR';
export const CHANGE_COLOR_SUCCESS = 'artful-client/CalendarAccounts/CHANGE_COLOR_SUCCESS';
export const CHANGE_COLOR_FAILURE = 'artful-client/CalendarAccounts/CHANGE_COLOR_FAILURE';

export const TOGGLE_CALENDAR_STATUS = 'artful-client/CalendarAccounts/TOGGLE_CALENDAR_STATUS';
export const TOGGLE_CALENDAR_STATUS_SUCCESS =
  'artful-client/CalendarAccounts/TOGGLE_CALENDAR_STATUS_SUCCESS';
export const TOGGLE_CALENDAR_STATUS_FAILURE =
  'artful-client/CalendarAccounts/TOGGLE_CALENDAR_STATUS_FAILURE';

export const TOGGLE_CALENDAR_HIDDEN = 'artful-client/CalendarAccounts/TOGGLE_CALENDAR_HIDDEN';
export const TOGGLE_CALENDAR_HIDDEN_SUCCESS =
  'artful-client/CalendarAccounts/TOGGLE_CALENDAR_HIDDEN_SUCCESS';
export const TOGGLE_CALENDAR_HIDDEN_FAILURE =
  'artful-client/CalendarAccounts/TOGGLE_CALENDAR_HIDDEN_FAILURE';

export const TOGGLE_USE_CALENDAR_TIMEZONES =
  'artful-client/CalendarAccounts/TOGGLE_USE_CALENDAR_TIMEZONES';
export const TOGGLE_USE_CALENDAR_TIMEZONES_SUCCESS =
  'artful-client/CalendarAccounts/TOGGLE_USE_CALENDAR_TIMEZONES_SUCCESS';
export const TOGGLE_USE_CALENDAR_TIMEZONES_FAILURE =
  'artful-client/CalendarAccounts/TOGGLE_USE_CALENDAR_TIMEZONES_FAILURE';

export const UPDATE_CALENDAR_ALARMS = 'artful-client/CalendarAccounts/UPDATE_CALENDAR_ALARMS';
export const UPDATE_CALENDAR_ALARMS_SUCCESS =
  'artful-client/CalendarAccounts/UPDATE_CALENDAR_ALARMS_SUCCESS';
export const UPDATE_CALENDAR_ALARMS_FAILURE =
  'artful-client/CalendarAccounts/UPDATE_CALENDAR_ALARMS_FAILURE';

export const SET_ACCOUNT = 'artful-client/CalendarAccounts/SET_ACCOUNT';
export const UPDATE_ACCOUNT = 'artful-client/CalendarAccounts/UPDATE_ACCOUNT';

export const CHANGE_DISPLAY_VIEW_SUCCESS =
  'artful-client/CalendarAccounts/CHANGE_DISPLAY_VIEW_SUCCESS';
export const CHANGE_DISPLAY_VIEW_FAILURE =
  'artful-client/CalendarAccounts/CHANGE_DISPLAY_VIEW_FAILURE';

const initialState = {
  isFetching: false,
  isSyncing: false,
  data: [],
  isFetched: false,
  togglingCalendars: [],
  isUpdatingAlarms: false,
  calendarMap: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL:
      return { ...state, isFetching: true };
    case SYNC:
      return { ...state, isSyncing: true };
    case FETCH_ALL_SUCCESS: {
      let calendarMap = createCalendarMap(action.data);
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        data: action.data,
        calendarMap: calendarMap,
      };
    }
    case SYNC_SUCCESS:
      return { ...state, isSyncing: false, isFetched: true, data: action.data };
    case FETCH_ALL_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        error: action.error,
      };

    case SYNC_FAILURE:
      return {
        ...state,
        isSyncing: false,
        isFetched: true,
        error: action.error,
      };

    case UPDATE_CALENDAR_ALARMS:
      return { ...state, isUpdatingAlarms: true };
    case UPDATE_CALENDAR_ALARMS_SUCCESS:
    case UPDATE_CALENDAR_ALARMS_FAILURE:
      return { ...state, isUpdatingAlarms: false };

    case TOGGLE_CALENDAR_STATUS:
    case TOGGLE_CALENDAR_HIDDEN:
      return {
        ...state,
        isFetching: true,
        data: state.data.map(calendarAccount => {
          let calendars = calendarAccount.calendars.map(calendar => {
            if (calendar.id == action.calendar_id) {
              return { ...calendar, active: !calendar.active };
            }
            return calendar;
          });
          return { ...calendarAccount, calendars: calendars };
        }),
        togglingCalendars: [...state.togglingCalendars, action.calendar_id],
      };
    case TOGGLE_CALENDAR_STATUS_SUCCESS:
    case TOGGLE_CALENDAR_HIDDEN_SUCCESS:
    case CHANGE_COLOR:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        data: state.data.map(a => {
          if (a.id === action.calendar.calendar_account_id) {
            let calendars = a.calendars.map(c => {
              if (c.id === action.calendar.id) {
                return action.calendar;
              }
              return c;
            });
            return { ...a, calendars: calendars };
          }
          return a;
        }),
        togglingCalendars: state.togglingCalendars.filter(
          calendarId => calendarId != action.calendar.id
        ),
      };
    case TOGGLE_CALENDAR_HIDDEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        error: action.error,
        togglingCalendars: state.togglingCalendars.filter(
          calendarId => calendarId != action.calendar_id
        ),
      };
    case TOGGLE_CALENDAR_STATUS_FAILURE:
      return {
        ...state,
        isFetching: false,
        togglingCalendars: state.togglingCalendars.filter(
          calendarId => calendarId != action.calendar_id
        ),
      };
    case CHANGE_COLOR_SUCCESS:
      return { ...state, isSyncing: false, isFetched: true };
    case SET_ACCOUNT:
      return { ...state, data: [...state.data, action.account] };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        data: state.data.map(account =>
          account.id == action.account.id ? action.account : account
        ),
      };

    case TOGGLE_USE_CALENDAR_TIMEZONES:
      return {
        ...state,
        isFetching: true,
        data: state.data.map(account =>
          account.id == action.account.id ? action.account : account
        ),
      };
    case TOGGLE_USE_CALENDAR_TIMEZONES_SUCCESS:
    case TOGGLE_USE_CALENDAR_TIMEZONES_FAILURE:
      return state;

    case DELETE:
      return { ...state, isFetching: true };
    case DELETE_FAILURE:
      return { ...state, isFetching: false };

    case CHANGE_DISPLAY_VIEW_SUCCESS:
      return {
        ...state,
        data: state.data.map(calendarAccount => {
          let calendars = calendarAccount.calendars.map(calendar => {
            if (calendar.id === action.calendar.id) {
              return action.calendar;
            }
            return calendar;
          });
          return { ...calendarAccount, calendars: calendars };
        }),
      };
    case CHANGE_DISPLAY_VIEW_FAILURE:
      return state;
    default:
      return state;
  }
}

export const getCalendarAccounts = () => async dispatch => {
  dispatch({ type: FETCH_ALL });
  try {
    let response = await calendarAccountsApi.fetchAll();
    dispatch({ type: FETCH_ALL_SUCCESS, data: response.data });
  } catch (err) {
    dispatch({ type: FETCH_ALL_FAILURE, error: err });
  }
};

export const deleteCalendarAccount = account => async dispatch => {
  dispatch({ type: DELETE });
  try {
    let response = await calendarAccountsApi.deleteAccount(account);
    mParticle.logEvent(new CalendarAccountDisconnectedEvent(account.driver));
    dispatch({ type: DELETE_SUCCESS });
    dispatch(success('Calendar Account Removed'));
    dispatch(getCalendarAccounts());
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE });
    dispatch(error('Calendar Account Not Removed', parseErrors(err)));
  }
};

export const syncCalendarAccounts = start => async dispatch => {
  dispatch({ type: SYNC });
  try {
    let response = await calendarAccountsApi.sync();
    dispatch({ type: SYNC_SUCCESS, data: response.data });
    dispatch(
      getEvents(
        start
          .clone()
          .startOf('month')
          .subtract(1, 'month')
          .startOf('week'),
        start
          .clone()
          .startOf('month')
          .add(2, 'months')
          .startOf('week')
          .add(1, 'week')
      )
    );
  } catch (err) {
    dispatch({ type: SYNC_FAILURE, error: err });
  }
};

export const changeColor = (calendar, color, oldColor) => async dispatch => {
  dispatch({
    type: CHANGE_COLOR,
    calendar: { ...calendar, color: color.color },
  });
  try {
    let response = await calendarAccountsApi.color(calendar.id, color);
    dispatch({ type: CHANGE_COLOR_SUCCESS, calendar: response.data });
    dispatch(updateEventsStateColor(calendar, color.color, oldColor));
  } catch (err) {
    dispatch({ type: CHANGE_COLOR_FAILURE, error: err });
  }
};

export const toggleCalendarStatus = (
  status,
  calendar_id,
  start,
  shouldGetEvents = true
) => async dispatch => {
  dispatch({ type: TOGGLE_CALENDAR_STATUS, calendar_id });
  try {
    let response;
    if (status) {
      response = await calendarAccountsApi.activate(calendar_id);
      dispatch({
        type: TOGGLE_CALENDAR_STATUS_SUCCESS,
        calendar: response.data,
      });
      if (shouldGetEvents) {
        dispatch(
          getEvents(
            start
              .clone()
              .startOf('month')
              .subtract(1, 'month')
              .startOf('week'),
            start
              .clone()
              .startOf('month')
              .add(2, 'months')
              .startOf('week')
              .add(1, 'week')
          )
        );
      }
    } else {
      response = await calendarAccountsApi.deactivate(calendar_id);
      dispatch(filterNonActiveEvents(calendar_id));
      dispatch({
        type: TOGGLE_CALENDAR_STATUS_SUCCESS,
        calendar: response.data,
      });
    }
  } catch (err) {
    dispatch({ type: TOGGLE_CALENDAR_STATUS_FAILURE, error: err, calendar_id });
  }
};

export const toggleCalendarHidden = (status, calendar_id) => async dispatch => {
  // dispatch({ type: TOGGLE_CALENDAR_HIDDEN });
  try {
    let response;
    if (status) {
      response = await calendarAccountsApi.hide(calendar_id);
      dispatch({
        type: TOGGLE_CALENDAR_HIDDEN_SUCCESS,
        calendar: response.data,
      });
    } else {
      response = await calendarAccountsApi.unhide(calendar_id);
      dispatch({
        type: TOGGLE_CALENDAR_HIDDEN_SUCCESS,
        calendar: response.data,
      });
    }
  } catch (err) {
    dispatch({ type: TOGGLE_CALENDAR_HIDDEN_FAILURE, error: err, calendar_id });
  }
};

export const updateCalendarAlarms = calendar => async dispatch => {
  dispatch({ type: UPDATE_CALENDAR_ALARMS });
  try {
    await calendarAccountsApi.updateAlarms(calendar);
    dispatch({
      type: UPDATE_CALENDAR_ALARMS_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: UPDATE_CALENDAR_ALARMS_FAILURE });
    dispatch(error('Calendar Alarms Not Updated', parseErrors(err)));
  } finally {
    dispatch(getCalendarAccounts());
  }
};

export const toggleUseCalendarTimezones = account => async dispatch => {
  dispatch({ type: TOGGLE_USE_CALENDAR_TIMEZONES, account });

  try {
    await calendarAccountsApi.toggleUseCalendarTimezones(account);
    dispatch({ type: TOGGLE_USE_CALENDAR_TIMEZONES_SUCCESS });
  } catch (err) {
    dispatch({ type: TOGGLE_USE_CALENDAR_TIMEZONES_FAILURE });
    dispatch(error('Calendar Timezones Not Updated', parseErrors(err)));
  } finally {
    dispatch(getCalendarAccounts());
  }
};

export const mapCalendarsToList = container => {
  let calendars = [];
  container.data.map(account => {
    calendars = [...calendars, ...account.calendars];
  });
  return calendars;
};

export const findCalendarInList = (calendars, id) =>
  calendars.filter(cal => `${cal.id}` === `${id}`)[0];

export const findCalendarAccount = (container, id) =>
  container.data.filter(calAcct => `${calAcct.id}` === `${id}`)[0];

export const changeDisplayView = calendar => async dispatch => {
  try {
    let response;
    response = await calendarAccountsApi.changeDisplayView(calendar);
    dispatch({ type: CHANGE_DISPLAY_VIEW_SUCCESS, calendar: response.data });
  } catch (error) {
    dispatch({ type: CHANGE_DISPLAY_VIEW_FAILURE, error: error });
  }
};

const createCalendarMap = data => {
  const calendarMap = {};
  data.forEach(account => {
    account.calendars.forEach(calendar => {
      calendarMap[calendar.id] = calendar;
    });
  });
  return calendarMap;
};
