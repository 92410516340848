/* eslint-disable max-len */
import React from 'react';

export default function HabitVacuumIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.86 25.193c-2.833-.487-5.41.764-5.758 2.797l.201-.091 5.82-2.66-.263-.046z"
        fill="#000"
      />
      <path
        d="M16.018 24.47c-2.305-1.786-4.67-2.69-7.03-2.69-4.186 0-7.168 2.866-8.151 4.793l-.044.085 8.669 1.491.069.012.012-.069c.352-2.05 2.621-3.595 5.276-3.595.381 0 .767.033 1.143.097l.278.048-.221-.172zM26.154 7.825 15.62 23.442l-.035.053 1.073.9L27.293 8.417l.047-.07-1.186-.522z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M25.583 7.616c-.013-.005-1.263-.446-2.964-.446-1 0-1.957.15-2.847.445-2.984.99-10.516 12.823-10.834 13.325l-.078.122.145-.015c.002 0 .17-.016.454-.016.996 0 3.548.205 5.729 2.106l.06.052 10.42-15.542-.085-.03zM26.228 2.735 23.239 6.62l1.793.154 1.97-2.778 2.698-.249v-1.01h-3.472z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
