import moment from '~/Providers/Moment';
import { ADD_UNDOABLE, CLEAR_UNDOABLE } from './constants';

export const addUndo = undo => dispatch => {
  const { callback } = undo;
  const stamp = moment().unix();
  dispatch({
    type: ADD_UNDOABLE,
    undoable: {
      ...undo,
      stamp,
      callback: () => {
        callback();
        dispatch(clearUndo({ stamp }));
      },
    },
  });
  setTimeout(() => {
    dispatch(clearUndo({ stamp }));
  }, 15 * 1000 /* 15 seconds */);
};

export const clearUndo = ({ stamp }) => dispatch => {
  dispatch({ type: CLEAR_UNDOABLE, stamp });
};
