import { connect } from 'react-redux';
import { fetchAll } from '~/Services/Container';

import CalendarSelect from '~/Components/Global/Form/CalendarSelect';

const mapStateToProps = ({ CalendarAccounts }) => {
  let calendars = [];
  CalendarAccounts.data.map(account => {
    calendars = [...calendars, ...account.calendars.filter(calendar => !calendar.is_hidden)];
  });
  return {
    calendars: calendars,
  };
};

const mapDispatchToProps = () => {
  return {
    fetchAll: () => fetchAll(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSelect);
