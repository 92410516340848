import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import ReactDOM from 'react-dom';

import DropDown from '~/Components/Layout/ApplicationNavigation/DropDown';
import NavItem from '~/Components/Layout/ApplicationNavigation/NavItem';
import CalendarSettingsSvg from '~/Components/Global/Assets/Svgs/CalendarSettings';
import Spinner from '~/Components/Global/Loading/Spinner';
import CalendarColor from '~/Components/Layout/ApplicationNavigation/RouteSections/Calendars/CalendarColor';
import ToggleAutoRollover from '~/Components/Pages/Account/UserSettings/Profile/ToggleAutoRollover';
import ToggleCompletedTasksToBottom from '~/Components/Pages/Account/UserSettings/Profile/ToggleCompletedTasksToBottom';
import ToggleIntlDates from '~/Components/Pages/Account/UserSettings/Profile/ToggleIntlDates';
import ToggleMondayStart from '~/Components/Pages/Account/UserSettings/Profile/ToggleMondayStart';
import history from '~/Providers/History';
import { useMountEffect } from '~/util';

import './CalendarRoute.scss';

function CalendarsRoute({
  start,
  currentType,
  syncCalendarAccounts,
  changeColor,
  toggleCalendarStatus,
  accounts,
  fetchUserLists,
  setCurrentList,
  isSyncing,
  togglingCalendars,
  path,
}) {
  const [actionCalendar, setActionCalendar] = useState(0);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const dropDownRef = useRef(null);

  useMountEffect(() => {
    document.addEventListener('click', handleClickOutside);
    window.addEventListener('flutter.syncCalendars', handleSyncCalendars);
    window.addEventListener('flutter.calendarActivated', handleCalendarActivated);
    window.addEventListener('flutter.calendarDeactivated', handleCalendarDeactivated);
    window.addEventListener('flutter.calendarColorChanged', handleCalendarColorChanged);
    window.addEventListener('flutter.listSearchResult', handleListSearchResult);
    window.addEventListener('native.openCalendarDropdown', handleOpenCalendarDropdown);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      window.removeEventListener('flutter.syncCalendars', handleSyncCalendars);
      window.removeEventListener('flutter.calendarActivated', handleCalendarActivated);
      window.removeEventListener('flutter.calendarDeactivated', handleCalendarDeactivated);
      window.removeEventListener('flutter.calendarColorChanged', handleCalendarColorChanged);
      window.removeEventListener('flutter.listSearchResult', handleListSearchResult);
      window.removeEventListener('native.openCalendarDropdown', handleOpenCalendarDropdown);
    };
  });

  useEffect(() => {
    setColorPickerOpen(false);
  }, [path]);

  function toggleColorPicker(id) {
    if (colorPickerOpen && id === actionCalendar) {
      setActionCalendar(0);
      setColorPickerOpen(false);
      return;
    }

    setActionCalendar(id);
    setColorPickerOpen(true);
  }

  function generateCalendarColor(calendar) {
    return (
      <CalendarColor
        calendar={calendar}
        actionCalendar={actionCalendar}
        toggleColorPicker={toggleColorPicker}
        open={colorPickerOpen}
        onChange={color => changeColor(calendar, color)}
      />
    );
  }

  function handleSyncCalendars() {
    syncCalendarAccounts(start, currentType);
  }

  function handleCalendarActivated({ detail }) {
    console.log('handleCalendarActivated', detail);
    // toggleCalendarStatus flips the active status internally, so `active` is reversed here
    toggleCalendarStatus({ id: detail.calendar_id, active: false }, start, true);
  }

  function handleCalendarDeactivated({ detail }) {
    console.log('handleCalendarDeactivated', detail);
    // toggleCalendarStatus flips the active status internally, so `active` is reversed here
    toggleCalendarStatus({ id: detail.calendar_id, active: true }, start, true);
  }

  function handleCalendarColorChanged({ detail }) {
    console.log('handleCalendarColorChanged', detail, calendar);
    const calendar = accounts
      .map(account => account.calendars.find(calendar => calendar.id === detail.calendar_id))
      .filter(calendar => !!calendar)[0];
    changeColor(calendar, { color: detail.color });
  }

  function handleListSearchResult({ detail }) {
    console.log('handleListSearchResult', detail);
    fetchUserLists()
      .then(({ data }) => {
        const list = data.filter(l => `${l.id}` === detail.list_id)[0];
        if (list) return setCurrentList(list);
      })
      .then(() => history.push('/lists'));
  }

  function handleOpenCalendarDropdown() {
    console.log('[CalendarsRoute] handleOpenCalendarDropdown ran.');
    const dropdownToggle = dropDownRef.current;

    if (dropdownToggle) {
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(dropdownToggle)
        ?.querySelector('.dropdown-toggle')
        ?.click?.();
    }
  }

  function handleClickOutside({ target }) {
    console.log('[CalendarsRoute] handleClickOutside ran.');
    if (dropDownRef.current?.contains(target)) {
      console.log('[CalendarsRoute] ref contains target.');
      return;
    }

    let element = target;
    while (element) {
      if (element.dataset.stopDropDownClose) {
        console.log('[CalendarsRoute] stopDropDownClose found.', element);
        return;
      }
      element = element.parentElement;
    }

    setActionCalendar(0);
    setColorPickerOpen(false);
  }

  function isToggling(calendar) {
    togglingCalendars.filter(id => id == calendar.id).length > 0;
  }

  function closeDropdown() {
    const dropdownToggle = dropDownRef.current;

    if (dropdownToggle) {
      // eslint-disable-next-line react/no-find-dom-node
      const toggleElement = ReactDOM.findDOMNode(dropdownToggle)?.querySelector('.dropdown-toggle');
      if (toggleElement) {
        toggleElement.click();
      }
    }
  }

  return (
    <DropDown className="calendars-route" ref={dropDownRef} svg={CalendarSettingsSvg} auth>
      <NavItem auth label="Manage Calendars" path="/add-accounts" onClick={closeDropdown} />
      <NavItem
        label="Sync Calendars"
        isFetching={isSyncing}
        onClick={() => syncCalendarAccounts(start, currentType)}
        auth
      />
      <ToggleMondayStart />
      <ToggleAutoRollover />
      <ToggleIntlDates />
      <ToggleCompletedTasksToBottom />

      {accounts.map(account => {
        return account.calendars
          .filter(calendar => !calendar.is_hidden)
          .map(calendar => {
            return (
              <NavItem key={calendar.calendar_id} auth>
                <span className="calendar-row">
                  <div
                    className="calendar-color"
                    onClick={() => toggleCalendarStatus(calendar, start, currentType)}
                    style={
                      calendar.active
                        ? {
                            backgroundColor: calendar.color,
                            borderColor: calendar.color,
                          }
                        : {}
                    }
                  />
                  <div className="calendar-name">
                    {isToggling(calendar) && <Spinner />}
                    {calendar.display_name}
                  </div>
                  {generateCalendarColor(calendar)}
                </span>
              </NavItem>
            );
          });
      })}
    </DropDown>
  );
}

CalendarsRoute.propTypes = {
  accounts: propTypes.array,
  toggleCalendarStatus: propTypes.func,
  syncCalendarAccounts: propTypes.func,
  changeColor: propTypes.func,
  start: propTypes.object,
  currentType: propTypes.string,
  path: propTypes.string,
  isSyncing: propTypes.bool,
  togglingCalendars: propTypes.array,
  fetchUserLists: propTypes.func,
  setCurrentList: propTypes.func,
};

export default CalendarsRoute;
