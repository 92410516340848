import { quoteApi } from '~/Providers/Api';

const FETCH = 'artful-client/Quote/FETCH';
const FETCH_SUCCESS = 'artful-client/Quote/FETCH_SUCCESS';
const FETCH_FAILURE = 'artful-client/Quote/FETCH_FAILURE';

const CLEAR_QUOTE = 'artful-client/Quote/CLEAR_QUOTE';

const initialState = {
  isFetching: false,
  data: false,
  error: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      return { ...state, isFetching: false, data: action.data };
    case FETCH_FAILURE:
      return { ...state, isFetching: false, error: action.error };

    case CLEAR_QUOTE:
      return initialState;
    default:
      return state;
  }
}

export const getQuoteByDate = date => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await quoteApi.fetch({ date: date });
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const clearQuote = () => dispatch => {
  dispatch({ type: CLEAR_QUOTE });
};
