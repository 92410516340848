/* eslint-disable max-len */
import React from 'react';

export default function HabitSparkleIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.116 25c0-2.482-2.518-5-5-5 2.482 0 5-2.518 5-5 0 2.482 2.518 5 5 5-2.482 0-5 2.518-5 5z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M16.116 25c0-2.482-2.518-5-5-5 2.482 0 5-2.518 5-5 0 2.482 2.518 5 5 5-2.482 0-5 2.518-5 5zM17.902 12.143c0-1.772-1.8-3.571-3.572-3.571 1.772 0 3.572-1.8 3.572-3.572 0 1.771 1.8 3.572 3.571 3.572-1.771 0-3.571 1.8-3.571 3.571z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M17.902 12.143c0-1.772-1.8-3.571-3.572-3.571 1.772 0 3.572-1.8 3.572-3.572 0 1.771 1.8 3.572 3.571 3.572-1.771 0-3.571 1.8-3.571 3.571zM11.83 16.429c0-1.24-1.26-2.5-2.5-2.5 1.24 0 2.5-1.261 2.5-2.5 0 1.239 1.26 2.5 2.5 2.5-1.24 0-2.5 1.26-2.5 2.5z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M11.83 16.429c0-1.24-1.26-2.5-2.5-2.5 1.24 0 2.5-1.261 2.5-2.5 0 1.239 1.26 2.5 2.5 2.5-1.24 0-2.5 1.26-2.5 2.5zM23.795 16.785a1.428 1.428 0 1 1-2.857.001 1.428 1.428 0 0 1 2.857 0zM10.223 9.286c0 1.428-2.143 1.428-2.143 0 0-1.429 2.143-1.429 2.143 0zM12.366 5.714c0 .953-1.429.953-1.429 0s1.429-.953 1.429 0zM21.652 12.857c0 .953-1.429.953-1.429 0s1.429-.953 1.429 0z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
