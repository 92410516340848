import { AdminStickerPacksApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';

const FETCH = 'artful-client/Admin/StickerPacks/FETCH';
const FETCH_SUCCESS = 'artful-client/Admin/StickerPacks/FETCH_SUCCESS';
const FETCH_FAILURE = 'artful-client/Admin/StickerPacks/FETCH_FAILURE';

const FETCH_ALL = 'artful-client/Admin/StickerPacks/FETCH_ALL';
const FETCH_ALL_SUCCESS = 'artful-client/Admin/StickerPacks/FETCH_ALL_SUCCESS';
const FETCH_ALL_FAILURE = 'artful-client/Admin/StickerPacks/FETCH_ALL_FAILURE';

const STORE = 'artful-client/Admin/StickerPacks/STORE';
const STORE_SUCCESS = 'artful-client/Admin/StickerPacks/STORE_SUCCESS';
const STORE_FAILURE = 'artful-client/Admin/StickerPacks/STORE_FAILURE';

const DELETE = 'artful-client/Admin/StickerPacks/DELETE';
const DELETE_SUCCESS = 'artful-client/Admin/StickerPacks/DELETE_SUCCESS';
const DELETE_FAILURE = 'artful-client/Admin/StickerPacks/DELETE_FAILURE';

const UPDATE = 'artful-client/Admin/StickerPacks/UPDATE';
const UPDATE_SUCCESS = 'artful-client/Admin/StickerPacks/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'artful-client/Admin/StickerPacks/UPDATE_FAILURE';

const UPDATE_FORM = 'artful-client/Admin/StickerPacks/UPDATE_FORM';

const CLEAR = 'artful-client/Admin/StickerPacks/CLEAR';
const CLEAR_STICKER_PACK = 'artful-client/Admin/StickerPacks/CLEAR_STICKER_PACK';

const initialState = {
  isFetching: false,
  isAdding: false,
  isDeleting: false,
  hasFetched: false,
  isUpdating: false,
  data: {
    current_page: 1,
    per_page: 20,
    total: 20,
    data: [],
  },
  error: '',
  actionStickerPack: {
    name: '',
    price: 0.0,
    status: true,
    stickers: [],
    is_fundraiser: false,
    unlock_code: '',
  },
  actionId: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL:
      return { ...state, isFetching: true, hasFetched: false };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        data: action.data,
      };
    case FETCH_ALL_FAILURE:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        error: action.error,
      };

    case FETCH:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        actionStickerPack: {
          ...action.data,
          price: action.data.product && action.data.product.price,
          sku: action.data.product && action.data.product.sku,
        },
      };
    case FETCH_FAILURE:
      return { ...state, isFetching: false, error: action.error };

    case STORE:
      return { ...state, isAdding: true };
    case STORE_SUCCESS:
      return {
        ...state,
        isAdding: false,
        actionStickerPack: initialState.actionStickerPack,
        data: { ...state.data, data: [...state.data.data, action.data] },
      };
    case STORE_FAILURE:
      return { ...state, isAdding: false, error: action.error };

    case DELETE:
      return { ...state, isDeleting: true, actionId: action.actionId };
    case DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        actionId: false,
        data: {
          ...state.data,
          data: state.data.data.filter(s => s.id !== action.data.id),
        },
      };
    case DELETE_FAILURE:
      return { ...state, isDeleting: false, error: action.error };

    case UPDATE_FORM:
      return {
        ...state,
        actionStickerPack: { ...state.actionStickerPack, ...action.update },
      };

    case UPDATE:
      return { ...state, isUpdating: true, actionId: action.actionId };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        actionId: false,
        data: {
          ...state.data,
          data: state.data.data.map(s => (s.id === action.data.id ? action.data : s)),
        },
      };
    case UPDATE_FAILURE:
      return { ...state, isUpdating: false, error: action.error };

    case CLEAR_STICKER_PACK:
      return { ...state, actionStickerPack: initialState.actionStickerPack };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

export const getStickerPacks = (page = 1, search = '') => async dispatch => {
  dispatch({ type: FETCH_ALL });
  try {
    let response = await AdminStickerPacksApi.fetchAll(page, search);
    dispatch({ type: FETCH_ALL_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_ALL_FAILURE, error: err });
  }
};

export const getStickerPack = stickerPackId => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await AdminStickerPacksApi.fetch(stickerPackId);
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const deleteStickerPack = id => async dispatch => {
  dispatch({ type: DELETE, actionId: id });
  try {
    let response = await AdminStickerPacksApi.delete(id);
    dispatch({ type: DELETE_SUCCESS, data: response.data });
    dispatch(success('StickerPack Deleted!'));
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, error: err });
  }
};

export const addStickerPack = data => async dispatch => {
  dispatch({ type: STORE });
  try {
    let response = await AdminStickerPacksApi.store(data);
    dispatch({ type: STORE_SUCCESS, data: response.data });
    dispatch(success('StickerPack Added!'));
    return response;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
    dispatch(error('StickerPack not added!', parseErrors(err)));
  }
};

export const updateForm = update => dispatch => {
  dispatch({ type: UPDATE_FORM, update });
};

export const clear = () => dispatch => {
  dispatch({ type: CLEAR });
};

export const clearStickerPack = () => dispatch => {
  dispatch({ type: CLEAR_STICKER_PACK });
};

export const updateStickerPack = data => async dispatch => {
  dispatch({ type: UPDATE });
  try {
    let response = await AdminStickerPacksApi.update({
      ...data,
      stickers: data.stickers.map(data => ({
        ...data,
        id: data.id.toString().startsWith('new/') ? null : data.id,
      })),
    });
    dispatch({ type: UPDATE_SUCCESS, data: response.data });
    dispatch(success('Sticker Pack Updated!'));
    return response;
  } catch (err) {
    dispatch({ type: UPDATE_FAILURE, error: err });
    dispatch(error('Sticker Pack not updated', parseErrors(err)));
    throw err;
  }
};
