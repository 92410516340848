import { createSelector } from 'reselect';
import { MUST_DO, OTHER, CUSTOM, MONTH_TODO, WEEK_TODO } from '~/Config/Checklists';
import { allFonts } from '../Fonts';

import {
  HUMAN_DATE_FORMAT,
  HUMAN_DATE_TIME_FORMAT,
  HUMAN_TIME_FORMAT,
  MEAL_PREPS,
} from './constants';

export const userOptions = state => state.UserOptions.data;
export const selectedFont = createSelector([allFonts, userOptions], (fonts, { font_id }) =>
  (fonts || []).find(({ id }) => id == font_id)
);
export const fontNameForEvents = createSelector(
  [selectedFont, userOptions],
  (selectedFont, userOptions) =>
    userOptions.use_font_for_events ? selectedFont?.css || 'Raleway' : 'Raleway'
);

export const humanDateFormats = createSelector([userOptions], ({ use_intl_dates }) =>
  use_intl_dates
    ? {
        [HUMAN_DATE_FORMAT]: 'DD/MM/YYYY',
        [HUMAN_DATE_TIME_FORMAT]: 'hh:mma on DD/MM/YYYY',
        [HUMAN_TIME_FORMAT]: HUMAN_TIME_FORMAT,
        'M/D/YYYY': 'D/M/YYYY',
        'h:mmp': 'h:mmp',
        'MMMM DD, Y': 'DD MMMM Y',
        'MMM D': 'D MMM',
      }
    : {
        [HUMAN_DATE_FORMAT]: HUMAN_DATE_FORMAT,
        [HUMAN_DATE_TIME_FORMAT]: HUMAN_DATE_TIME_FORMAT,
        [HUMAN_TIME_FORMAT]: HUMAN_TIME_FORMAT,
        'M/D/YYYY': 'M/D/YYYY',
        'h:mmp': 'h:mmp',
        'MMMM DD, Y': 'MMMM DD, Y',
        'MMM D': 'MMM D',
      }
);

export const titlesForSections = createSelector(
  [userOptions],
  ({
    custom_list_title,
    priority_tasks_title,
    other_tasks_title,
    monthly_tasks_title,
    weekly_tasks_title,
    meals_title,
  }) => ({
    [MUST_DO]: priority_tasks_title,
    [OTHER]: other_tasks_title,
    [CUSTOM]: custom_list_title,
    [MONTH_TODO]: monthly_tasks_title,
    [WEEK_TODO]: weekly_tasks_title,
    [MEAL_PREPS]: meals_title,
  })
);
