import React, { forwardRef, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';

import { useMountEffect } from '~/util';

import NavItem from './NavItem';

import './DropDown.scss';

/**
 * This component will render a nav link.
 * If a child is passed, it will render that as the label such as a font awesome element.
 * Otherwise, it will just render the label.
 */

const DropDown = forwardRef(function DropDown(
  { auth, unAuth, label, svg, children, className, onMouseLeave, subMenu, pathname },
  ref
) {
  const [open, setOpen] = useState(false);

  useMountEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  useEffect(() => {
    console.log('[DropDown] pathname changed.');
    closeDropDown();
  }, [pathname]);

  function closeDropDown() {
    console.log('[DropDown] closeDropDown ran.');
    setOpen(false);
  }

  function toggleOpen() {
    console.log('[DropDown] toggleOpen ran.');
    if (!open) {
      // Open the dropdown
      setOpen(true);
    } else {
      // Close the dropdown
      closeDropDown();
    }
  }

  // If clicking on a DOM element should not close the DropDown,
  // put a 'data-stop-drop-down-close' attribute on the top level element.
  function handleClickOutside({ target }) {
    console.log('[DropDown] handleClickOutside ran.');
    if (ref?.current?.contains(target)) {
      console.log('[DropDown] ref contains target.');
      return;
    }

    let element = target;
    while (element) {
      if (element.dataset.stopDropDownClose) {
        console.log('[DropDown] stopDropDownClose found.', element);
        return;
      }
      element = element.parentElement;
    }

    closeDropDown();
  }

  return (
    <NavItem
      className={`dropdown ${className} ${open && 'open'}`}
      ref={ref}
      auth={auth}
      unAuth={unAuth}
      onMouseLeave={onMouseLeave}
    >
      <Link
        className="dropdown-toggle"
        role="button"
        to="#"
        aria-expanded="false"
        data-toggle="dropdown"
        onClick={toggleOpen}
      >
        {svg
          ? [React.createElement(svg, { key: 'a' }), subMenu]
          : [
              <span key="a" className="menu-label">
                {label}
              </span>,
              <FontAwesome
                key="b"
                className="color-arrow caret-right"
                name={subMenu ? 'caret-right' : 'caret'}
              />,
            ]}
      </Link>

      <ul className={subMenu ? 'dropdown-menu sub-menu' : 'dropdown-menu'} role="menu">
        {children}
      </ul>
    </NavItem>
  );
});

DropDown.propTypes = {
  auth: propTypes.bool,
  unAuth: propTypes.bool,
  label: propTypes.string,
  children: propTypes.node,
  svg: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  path: propTypes.string,
  className: propTypes.string,
  onMouseLeave: propTypes.func,
  subMenu: propTypes.bool,
  pathname: propTypes.string,
};

DropDown.defaultProps = {
  className: '',
  subMenu: false,
  svg: false,
};

export default DropDown;
