import React, { Component } from 'react';
import propTypes from 'prop-types';

import NotificationSystem from 'react-notification-system';
import { notificationRemoved } from '~/Redux/Modules/Notifications';

/**
 * Use this notification component to abstract success, error, and warning messages from forms and
 * other interactive Components.
 *
 * See Components/~/Redux/Actions/notification for available functions.
 *
 * Typically, notification actions should be called from other actions; e.g. if I submit a form
 * and it returns an error, dispatch the error() method in the returned promise from the form submission in the action.
 * Doing it like this simplifies Components and will help standardize how we handle errors across the application.
 *
 * For examples of use, look at the Reservation and BasicMonthlyRequest action files.
 *
 * Wraps the react notification system componente to integrate it into Redux.
 * This component should be included in the top level of each Layout element,
 * as per the documentation at https://github.com/igorprado/react-notification-system
 *
 * This component is mostly necessary because we need a ref to the notification system
 */
export default class Notification extends Component {
  constructor() {
    super();
    const width = 600 < document.body.clientWidth - 100 ? 'auto' : document.body.clientWidth * 0.8;
    this.style = {
      Containers: {
        DefaultStyle: {
          width,
          top: '6.5rem',
          maxWidth: document.body.clientWidth * 0.8,
        },
      },
      NotificationItem: {
        DefaultStyle: {
          fontSize: '1rem',
          padding: '1.5rem 5.0rem 1.5rem 1.5rem',
          borderRadius: '0.5rem',
        },
        success: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderTop: '0.1rem solid #CACACB',
          border: '0.1rem solid #CACACB',
          color: '#292727',
          WebkitBoxShadow: '0 0.5rem 1.0rem rgba(0, 0, 0, 0.12)',
          MozBoxShadow: '0 0.5rem 1.0rem rgba(0, 0, 0, 0.12)',
          boxShadow: '0 0.5rem 1.0rem rgba(0, 0, 0, 0.12)',
        },
        error: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderTop: '0.1rem solid #CACACB',
          border: '0.1rem solid #CACACB',
          color: '#292727',
          WebkitBoxShadow: '0 0.5rem 1.0rem rgba(0, 0, 0, 0.12)',
          MozBoxShadow: '0 0.5rem 1.0rem rgba(0, 0, 0, 0.12)',
          boxShadow: '0 0.5rem 1.0rem rgba(0, 0, 0, 0.12)',
        },
      },
      Title: {
        DefaultStyle: {
          fontSize: '1.4rem',
          textTransform: 'uppercase',
          fontWeight: '300',
          margin: '0',
        },
        success: {
          color: '#292727',
        },
        error: {
          color: '#292727',
        },
      },
      MessageWrapper: {
        DefaultStyle: {
          marginTop: '0.5rem',
          whiteSpace: 'pre-wrap',
        },
      },
      Dismiss: {
        DefaultStyle: {
          color: 'transparent',
          backgroundColor: 'transparent',
        },
      },
      Action: {
        error: {
          backgroundColor: 'transparent',
          color: '#696969',
          textTransform: 'uppercase',
          fontSize: '1.3rem',
          fontWeight: '400',
          padding: '0',
        },
      },
    };
  }

  componentDidMount = () => {
    this.componentWillReceiveProps(this.props);
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.clear) {
      nextProps.displayed.forEach(uid => {
        this.notificationSystem.removeNotification(uid);
        this.props.dispatch(notificationRemoved(uid));
      });
      return;
    }

    // Else display notifications in the queue
    nextProps.queued.forEach(notification => {
      this.notificationSystem.addNotification(notification);
    });
  };

  render() {
    return <NotificationSystem ref={ref => (this.notificationSystem = ref)} style={this.style} />;
  }
}

// Declare propType
Notification.propTypes = {
  dispatch: propTypes.func,
  clear: propTypes.bool,
  queued: propTypes.array,
  displayed: propTypes.array,
};
