import PropTypes from 'prop-types';

export const StripePermittedView = ({ children, disabledView }) => {
  const isStripeEnabled = !window.disableStripe;
  if (!isStripeEnabled) return disabledView || null;
  return children;
};

StripePermittedView.propTypes = {
  children: PropTypes.node.isRequired,
  disabledView: PropTypes.node,
};

export default StripePermittedView;
