import React from 'react';
import HabitAlarmClockIcon from '~/Components/Global/Assets/Svgs/HabitAlarmClockIcon';
import HabitAlarmClockIcon2 from '~/Components/Global/Assets/Svgs/HabitAlarmClockIcon2';
import HabitBathIcon from '~/Components/Global/Assets/Svgs/HabitBathIcon';
import HabitBathroomScrubIcon from '~/Components/Global/Assets/Svgs/HabitBathroomScrubIcon';
import HabitBedIcon from '~/Components/Global/Assets/Svgs/HabitBedIcon';
import HabitBellIcon from '~/Components/Global/Assets/Svgs/HabitBellIcon';
import HabitBikeIcon from '~/Components/Global/Assets/Svgs/HabitBikeIcon';
import HabitBriefcaseIcon from '~/Components/Global/Assets/Svgs/HabitBriefcaseIcon';
import HabitBroomIcon from '~/Components/Global/Assets/Svgs/HabitBroomIcon';
import HabitCalendarIcon from '~/Components/Global/Assets/Svgs/HabitCalendarIcon';
import HabitCartIcon from '~/Components/Global/Assets/Svgs/HabitCartIcon';
import HabitCatIcon from '~/Components/Global/Assets/Svgs/HabitCatIcon';
import HabitChatIcon from '~/Components/Global/Assets/Svgs/HabitChatIcon';
import HabitCheckmarkCircleIcon from '~/Components/Global/Assets/Svgs/HabitCheckmarkCircleIcon';
import HabitCleaningIcon from '~/Components/Global/Assets/Svgs/HabitCleaningIcon';
import HabitCloudIcon from '~/Components/Global/Assets/Svgs/HabitCloudIcon';
import HabitCoffeeIcon from '~/Components/Global/Assets/Svgs/HabitCoffeeIcon';
import HabitCraftingIcon from '~/Components/Global/Assets/Svgs/HabitCraftingIcon';
import HabitDogIcon from '~/Components/Global/Assets/Svgs/HabitDogIcon';
import HabitDollarSignIcon from '~/Components/Global/Assets/Svgs/HabitDollarSignIcon';
import HabitDumbell from '~/Components/Global/Assets/Svgs/HabitDumbellIcon';
import HabitFastFoodIcon from '~/Components/Global/Assets/Svgs/HabitFastFoodIcon';
import HabitFootstepsIcon from '~/Components/Global/Assets/Svgs/HabitFootstepsIcon';
import HabitGratitudeIcon from '~/Components/Global/Assets/Svgs/HabitGratitudeIcon';
import HabitHangerIcon from '~/Components/Global/Assets/Svgs/HabitHangerIcon';
import HabitHappyIcon from '~/Components/Global/Assets/Svgs/HabitHappyIcon';
import HabitHealthyFoodIcon from '~/Components/Global/Assets/Svgs/HabitHealthyFoodIcon';
import HabitHeartIcon from '~/Components/Global/Assets/Svgs/HabitHeartIcon';
import HabitHomeIcon from '~/Components/Global/Assets/Svgs/HabitHomeIcon';
import HabitJournalIcon from '~/Components/Global/Assets/Svgs/HabitJournalIcon';
import HabitLaptopIcon from '~/Components/Global/Assets/Svgs/HabitLaptopIcon';
import HabitLaundryIcon from '~/Components/Global/Assets/Svgs/HabitLaundryIcon';
import HabitLeafIcon from '~/Components/Global/Assets/Svgs/HabitLeafIcon';
import HabitLotionIcon from '~/Components/Global/Assets/Svgs/HabitLotionIcon';
import HabitLotusIcon from '~/Components/Global/Assets/Svgs/HabitLotusIcon';
import HabitMagnifyingGlassIcon from '~/Components/Global/Assets/Svgs/HabitMagnifyingGlassIcon';
import HabitMealPrepIcon from '~/Components/Global/Assets/Svgs/HabitMealPrepIcon';
import HabitMedicalIcon from '~/Components/Global/Assets/Svgs/HabitMedicalIcon';
import HabitMedicineIcon from '~/Components/Global/Assets/Svgs/HabitMedicineIcon';
import HabitMeditateIcon from '~/Components/Global/Assets/Svgs/HabitMeditateIcon';
import HabitMoonIcon from '~/Components/Global/Assets/Svgs/HabitMoonIcon';
import HabitMusicIcon from '~/Components/Global/Assets/Svgs/HabitMusicIcon';
import HabitNailBitingIcon from '~/Components/Global/Assets/Svgs/HabitNailBitingIcon';
import HabitNoIcon from '~/Components/Global/Assets/Svgs/HabitNoIcon';
import HabitPauseIcon from '~/Components/Global/Assets/Svgs/HabitPauseIcon';
import HabitPencilIcon from '~/Components/Global/Assets/Svgs/HabitPencilIcon';
import HabitPeopleIcon from '~/Components/Global/Assets/Svgs/HabitPeopleIcon';
import HabitPhoneIcon from '~/Components/Global/Assets/Svgs/HabitPhoneIcon';
import HabitPillIcon from '~/Components/Global/Assets/Svgs/HabitPillIcon';
import HabitPlantIcon from '~/Components/Global/Assets/Svgs/HabitPlantIcon';
import HabitPlayIcon from '~/Components/Global/Assets/Svgs/HabitPlayIcon';
import HabitPlugIcon from '~/Components/Global/Assets/Svgs/HabitPlugIcon';
import HabitPotIcon from '~/Components/Global/Assets/Svgs/HabitPotIcon';
import HabitPrayingHandsIcon from '~/Components/Global/Assets/Svgs/HabitPrayingHandsIcon';
import HabitPriorityIcon from '~/Components/Global/Assets/Svgs/HabitPriorityIcon';
import HabitReadingIcon from '~/Components/Global/Assets/Svgs/HabitReadingIcon';
import HabitRepeatIcon from '~/Components/Global/Assets/Svgs/HabitRepeatIcon';
import HabitRunningIcon from '~/Components/Global/Assets/Svgs/HabitRunningIcon';
import HabitRunningTwoIcon from '~/Components/Global/Assets/Svgs/HabitRunningTwoIcon';
import HabitScalesIcon from '~/Components/Global/Assets/Svgs/HabitScalesIcon';
import HabitShotIcon from '~/Components/Global/Assets/Svgs/HabitShotIcon';
import HabitShowerheadIcon from '~/Components/Global/Assets/Svgs/HabitShowerheadIcon';
import HabitSoapIcon from '~/Components/Global/Assets/Svgs/HabitSoapIcon';
import HabitSodaIcon from '~/Components/Global/Assets/Svgs/HabitSodaIcon';
import HabitSparkleIcon from '~/Components/Global/Assets/Svgs/HabitSparkleIcon';
import HabitSportsIcon from '~/Components/Global/Assets/Svgs/HabitSportsIcon';
import HabitStarIcon from '~/Components/Global/Assets/Svgs/HabitStarIcon';
import HabitStopIcon from '~/Components/Global/Assets/Svgs/HabitStopIcon';
import HabitStretchIcon from '~/Components/Global/Assets/Svgs/HabitStretchIcon';
import HabitSunIcon from '~/Components/Global/Assets/Svgs/HabitSunIcon';
import HabitSweetsIcon from '~/Components/Global/Assets/Svgs/HabitSweetsIcon';
import HabitTVIcon from '~/Components/Global/Assets/Svgs/HabitTVIcon';
import HabitTeethIcon from '~/Components/Global/Assets/Svgs/HabitTeethIcon';
import HabitTobaccoIcon from '~/Components/Global/Assets/Svgs/HabitTobaccoIcon';
import HabitVacuumIcon from '~/Components/Global/Assets/Svgs/HabitVacuumIcon';
import HabitWaterGlass from '~/Components/Global/Assets/Svgs/HabitWaterGlassIcon';
import HabitWaterIcon from '~/Components/Global/Assets/Svgs/HabitWaterIcon';
import HabitWineGlassIcon from '~/Components/Global/Assets/Svgs/HabitWineGlassIcon';
import HabitZzzIcon from '~/Components/Global/Assets/Svgs/HabitZzzIcon';

const habitIcons = {
  gratitude: <HabitGratitudeIcon />,
  lotus: <HabitLotusIcon />,
  journal: <HabitJournalIcon />,
  running: <HabitRunningIcon />,
  people: <HabitPeopleIcon />,
  cloud: <HabitCloudIcon />,
  pencil: <HabitPencilIcon />,
  television: <HabitTVIcon />,
  bike: <HabitBikeIcon />,
  happy: <HabitHappyIcon />,
  star: <HabitStarIcon />,
  plant: <HabitPlantIcon />,
  cart: <HabitCartIcon />,
  phone: <HabitPhoneIcon />,
  bath: <HabitBathIcon />,
  home: <HabitHomeIcon />,
  sparkle: <HabitSparkleIcon />,
  sun: <HabitSunIcon />,
  laundry: <HabitLaundryIcon />,
  soap: <HabitSoapIcon />,
  stretch: <HabitStretchIcon />,
  bed: <HabitBedIcon />,
  moon: <HabitMoonIcon />,
  chat: <HabitChatIcon />,
  no: <HabitNoIcon />,
  calendar: <HabitCalendarIcon />,
  heart: <HabitHeartIcon />,
  sports: <HabitSportsIcon />,
  praying: <HabitPrayingHandsIcon />,
  repeat: <HabitRepeatIcon />,
  water: <HabitWaterIcon />,
  dumbell: <HabitDumbell />,
  dog: <HabitDogIcon />,
  bell: <HabitBellIcon />,
  reading: <HabitReadingIcon />,
  meditate: <HabitMeditateIcon />,
  healthyFood: <HabitHealthyFoodIcon />,
  mealPrep: <HabitMealPrepIcon />,
  teeth: <HabitTeethIcon />,
  runningTwo: <HabitRunningTwoIcon />,
  zzz: <HabitZzzIcon />,
  dollarSign: <HabitDollarSignIcon />,
  broom: <HabitBroomIcon />,
  medicine: <HabitMedicineIcon />,
  medical: <HabitMedicalIcon />,
  footsteps: <HabitFootstepsIcon />,
  pause: <HabitPauseIcon />,
  hanger: <HabitHangerIcon />,
  plug: <HabitPlugIcon />,
  music: <HabitMusicIcon />,
  laptop: <HabitLaptopIcon />,
  briefcase: <HabitBriefcaseIcon />,
  stop: <HabitStopIcon />,
  checkmarkCircle: <HabitCheckmarkCircleIcon />,
  leaf: <HabitLeafIcon />,
  coffee: <HabitCoffeeIcon />,
  wineGlass: <HabitWineGlassIcon />,
  alarmClock: <HabitAlarmClockIcon />,
  play: <HabitPlayIcon />,
  waterGlass: <HabitWaterGlass />,
  cat: <HabitCatIcon />,
  lotion: <HabitLotionIcon />,
  shot: <HabitShotIcon />,
  pot: <HabitPotIcon />,
  scales: <HabitScalesIcon />,
  alarmClock1: <HabitAlarmClockIcon2 />,
  priorities: <HabitPriorityIcon />,
  pill: <HabitPillIcon />,
  showerhead: <HabitShowerheadIcon />,
  cleaning: <HabitCleaningIcon />,
  bathroomScrub: <HabitBathroomScrubIcon />,
  vacuum: <HabitVacuumIcon />,
  crafting: <HabitCraftingIcon />,
  magnifyingGlass: <HabitMagnifyingGlassIcon />,
  fastFood: <HabitFastFoodIcon />,
  sweets: <HabitSweetsIcon />,
  tobacco: <HabitTobaccoIcon />,
  soda: <HabitSodaIcon />,
  nailBiting: <HabitNailBitingIcon />,
};

export default habitIcons;
