const env = process.env.NODE_ENV || 'local';

const subdomain = window.location.host.split('.').shift(); // eslint-disable-line no-unused-vars

let baseUrl = 'https://staging-api.artfulagenda.com/';
// let baseUrl = 'https://prod-api.artfulagenda.com/';
// let baseUrl = 'http://api.lvh.me:8000/';
let googleClient = '296336731516-m7e4d6ir4dr7cijd4cmqn434dgn7irat.apps.googleusercontent.com';
let stripeKey = 'pk_test_yGP8tqapb6LAuRkTsK8CE35x';
let gaProperty = false;
let outlookRedirectUri = 'http://localhost:3000';
let outlookAppId = '9dbf02a8-efdf-4065-9eca-460d74662010';
let logrocketKey = null; //"7sofju/artful-agenda-dev";
let logrocketUserIds = [1];
let outlookScopes =
  'openid%20profile%20offline_access%20User.Read%20Calendars.ReadWrite%20Calendars.ReadWrite.Shared';
let outlookGroupScope = '%20Group.Read.All';
let heartbeatInterval = 30;
let stripeOptions = { betas: [], apiVersion: '2018-02-28' };
let leanPlumAppId = 'app_AuyAgWVrl82wShcIOKcMNeoKAVeroQ6fPmZIQcBxiUM';
let leanPlumDevKey = 'dev_BIxaLnDvnoAL9PhXt0IORaG1vOd60uaFxgZseKLDDAU';
let leanPlumProdKey = 'prod_nelNvOP0SrFALqncHlIDyhJa2txR3WQu8ONW7AyQk8E';
let leanPlumDevMode = true;
let mParticleKey = 'us2-bd3a260cda6dd144bfd5e3820ba9a0c3';
let mParticleDevMode = true;
let mParticleDataPlanId = 'artful_data_plan';
let mParticleDataPlanVersion = 1;
let mParticlePrefix = 'staging_';
let appleRedirectUri = 'https://staging.artfulagenda.com/sign-in-with-apple';
let appleClientId = 'com.artfulagenda.api';
let yearlyWithTrialPlan = 'yearly_with_trial';
let yearlyWithTrialPlanA = 'yearly_w_trial_a';
let yearlyWithTrialPlanB = 'yearly_w_trial_b';
let yearlyPlus = 'yearly_plus';

switch (env) {
  case 'local':
    // baseUrl = 'http://api.lvh.me:8000/';
    // stripeKey = 'pk_test_YSyTIC8vu4Y9J5ywdcUTlUW9';
    yearlyWithTrialPlan = 'price_1NYFXXDgRMMurLDw6ew7In5e';
    yearlyWithTrialPlanA = 'price_1NUxGtDgRMMurLDwW4yeGdDX';
    yearlyWithTrialPlanB = 'price_1NUxGtDgRMMurLDwUcZ405z9';
    yearlyPlus = 'price_1O06dPDgRMMurLDw3R39VlsU';
    break;
  case 'staging':
    baseUrl = 'https://staging-api.artfulagenda.com/';
    googleClient = '296336731516-m7e4d6ir4dr7cijd4cmqn434dgn7irat.apps.googleusercontent.com';
    gaProperty = 'UA-122993275-2';
    outlookRedirectUri = 'https://staging.artfulagenda.com';
    outlookAppId = '2073f391-88f0-4acb-959f-4471939ec13b';
    logrocketKey = null; //"7sofju/artful-agenda-staging";
    logrocketUserIds = [1];
    heartbeatInterval = 300;
    appleRedirectUri = 'https://staging.artfulagenda.com/sign-in-with-apple';
    yearlyWithTrialPlan = 'price_1NYFXXDgRMMurLDw6ew7In5e';
    yearlyWithTrialPlanA = 'price_1NUxGtDgRMMurLDwW4yeGdDX';
    yearlyWithTrialPlanB = 'price_1NUxGtDgRMMurLDwUcZ405z9';
    yearlyPlus = 'price_1O06dPDgRMMurLDw3R39VlsU';
    break;
  case 'beta':
    baseUrl = 'https://prod-api.artfulagenda.com/';
    googleClient = '973234780985-tm7jdkh5ejvppl7tlfle4vmkhfbkibs8.apps.googleusercontent.com';
    stripeKey = 'pk_live_bxJHivvgbTS39XtJyKfgM1t4';
    gaProperty = 'UA-122993275-1';
    outlookRedirectUri = 'https://beta.artfulagenda.com';
    outlookAppId = 'd575fc77-a375-4fae-ab7f-5aea67ee5a61';
    logrocketKey = '7sofju/artful-agenda-production';
    logrocketUserIds = [3, 6];
    heartbeatInterval = 300;
    mParticlePrefix = 'production_';
    mParticleDevMode = false;
    leanPlumDevMode = false;
    appleRedirectUri = 'https://beta.artfulagenda.com/sign-in-with-apple';
    break;
  case 'production':
    baseUrl = 'https://prod-api.artfulagenda.com/';
    googleClient = '973234780985-tm7jdkh5ejvppl7tlfle4vmkhfbkibs8.apps.googleusercontent.com';
    stripeKey = 'pk_live_bxJHivvgbTS39XtJyKfgM1t4';
    gaProperty = 'UA-122993275-1';
    outlookRedirectUri = `https://${subdomain}.artfulagenda.com`;
    outlookAppId = 'd575fc77-a375-4fae-ab7f-5aea67ee5a61';
    logrocketKey = '7sofju/artful-agenda-production';
    logrocketUserIds = [3, 6, 145129];
    heartbeatInterval = 300;
    mParticlePrefix = 'production_';
    mParticleDevMode = false;
    leanPlumDevMode = false;
    appleRedirectUri = `https://${subdomain}.artfulagenda.com/sign-in-with-apple`;
    break;
}

const baseConfig = {
  env,
  googleClient,
  stripeKey,
  stripeOptions,
  api: {
    baseUrl,
  },
  outlook: {
    appId: outlookAppId,
    redirectUri: outlookRedirectUri,
    scopes: outlookScopes,
    groupScope: outlookGroupScope,
  },
  apple: {
    clientId: appleClientId,
    redirectUri: appleRedirectUri,
  },
  gaProperty,
  logrocket: {
    enabled: !!logrocketKey,
    key: logrocketKey,
    userIds: logrocketUserIds,
  },
  leanPlum: {
    appId: leanPlumAppId,
    devKey: leanPlumDevKey,
    prodKey: leanPlumProdKey,
    devMode: leanPlumDevMode,
  },
  mParticle: {
    apiKey: mParticleKey,
    devMode: mParticleDevMode,
    planId: mParticleDataPlanId,
    planVersion: mParticleDataPlanVersion,
    prefix: mParticlePrefix,
  },
  pricingPlans: {
    yearlyWithTrial: yearlyWithTrialPlan,
    yearlyWithTrialA: yearlyWithTrialPlanA,
    yearlyWithTrialB: yearlyWithTrialPlanB,
    yearlyPlus,
  },
  heartbeatInterval,
};

export default baseConfig;
