/* eslint-disable max-len */
import React from 'react';

export default function HabitShowerheadIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.066 20.783c-.211.211-.265.41-.265.41s-.012.211 1.26 1.692c1.252 1.457 1.777 1.899 1.777 1.899.15.15.766-.464.766-.464s.615-.615.465-.767c0 0-.442-.525-1.899-1.775-1.48-1.273-1.69-1.26-1.69-1.26-.003 0-.202.053-.414.265zM14.864 14.985c-.21.21-.265.41-.265.41s-.011.212 1.261 1.693c1.252 1.457 1.777 1.898 1.777 1.898.15.15.765-.464.765-.464s.615-.615.465-.766c0 0-.443-.525-1.898-1.777-1.482-1.272-1.691-1.26-1.691-1.26-.003 0-.202.053-.414.266zM20.663 9.186c-.211.211-.265.412-.265.412s-.012.21 1.26 1.692c1.252 1.456 1.777 1.898 1.777 1.898.15.15.766-.464.766-.464s.615-.615.463-.765c0 0-.441-.525-1.898-1.777-1.481-1.272-1.69-1.26-1.69-1.26 0-.002-.201.052-.413.264zM16.814 22.733a1.029 1.029 0 0 0-.265.412s-.011.21 1.261 1.692c1.252 1.456 1.777 1.898 1.777 1.898.15.15.765-.464.765-.464s.615-.615.465-.765c0 0-.443-.525-1.898-1.777-1.481-1.273-1.691-1.26-1.691-1.26-.001 0-.202.053-.414.264zM22.613 16.935c-.211.21-.265.41-.265.41s-.012.21 1.26 1.693c1.252 1.456 1.777 1.898 1.777 1.898.15.15.765-.464.765-.464s.616-.615.465-.765c0 0-.442-.525-1.899-1.776-1.481-1.273-1.691-1.261-1.691-1.261s-.2.052-.412.265zM19.908 5.344 18.23 3.665c-.281-.28-1.018-.965-1.018-.965s-.746.681-1.03.965L11.286 8.56l-.343-.343a1.84 1.84 0 0 0-2.048-.38L6.863 5.805l-.458.48-.48.458 2.032 2.032c-.3.675-.173 1.494.38 2.048l.343.343-4.895 4.895c-.258.258-.973 1.039-.973 1.039s.665.702.973 1.01l1.678 1.678L19.908 5.344zM6.756 21.08l-.848-.849L20.352 5.787l.848.848L6.756 21.08z"
        fill="#000"
      />
    </svg>
  );
}
