/* eslint-disable max-len */
import React from 'react';

export default function HabitTobaccoIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28.266 20.346a.15.15 0 0 0-.079-.084l-1.874-.855-12.355-5.633-9.91-4.518a.15.15 0 0 0-.198.073l-2.26 4.954a.152.152 0 0 0 .074.2l9.91 4.52 12.357 5.633 1.871.854a.145.145 0 0 0 .115.004.15.15 0 0 0 .084-.078l2.26-4.956a.142.142 0 0 0 .005-.114zm-15.7-3.156 1.404-3.081 12.083 5.51-2.133 4.683-12.084-5.51.73-1.602z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M23.232 12.272c0 1.58.736 2.358 1.33 2.983.52.551.771.843.771 1.456h.3c0-.724-.308-1.085-.855-1.663-.555-.586-1.246-1.316-1.246-2.777 0-1.466.693-2.194 1.248-2.781.545-.577.854-.939.854-1.662 0-.724-.309-1.085-.855-1.662-.555-.584-1.245-1.312-1.245-2.769h-.3c0 1.576.735 2.352 1.328 2.976.522.55.773.843.773 1.455 0 .612-.251.905-.77 1.456-.564.594-1.333 1.403-1.333 2.988z"
        fill={props.fill ? props.fill : '#000'}
        stroke="#000"
        strokeWidth=".225"
      />
      <path
        d="M26.565 7.83c0 1.463-.691 2.191-1.245 2.776-.545.579-.855.94-.855 1.667 0 .728.308 1.089.855 1.666.555.584 1.245 1.312 1.245 2.775h.3c0-1.582-.767-2.391-1.327-2.982-.522-.55-.773-.842-.773-1.459 0-.615.251-.908.773-1.461.591-.623 1.328-1.4 1.328-2.981 0-1.576-.734-2.353-1.33-2.984-.52-.548-.77-.839-.77-1.447h-.3c0 .72.309 1.08.857 1.66.553.584 1.242 1.314 1.242 2.77z"
        fill={props.fill ? props.fill : '#000'}
        stroke="#000"
        strokeWidth=".225"
      />
    </svg>
  );
}
