import React, { Component } from 'react';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import ReactSelect from 'react-select';
import Select from '~/Components/Global/Form/Select';
import { contactsApi } from '~/Providers/Api';

import './CreateEvent.scss';

class Attendees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newAttendee: '',
      contacts: [],
    };
  }

  componentDidMount = () => {
    this.getContacts();
  };

  shouldAddCurrentUserAsAttendee = () => {
    const { calendarAccount, calendar } = this.props;
    console.log('Attendees', calendar, calendarAccount);
    return (
      calendarAccount.driver === 'GOOGLE' &&
      calendarAccount?.email?.toLowerCase() === calendar?.calendar_id?.toLowerCase()
    );
  };

  handleAddAttendee = attendee => {
    if (attendee.email.trim() === '') return;
    const { attendees, calendarAccount } = this.props;
    let newAttendees = [...attendees];
    if (newAttendees.length === 0 && this.shouldAddCurrentUserAsAttendee()) {
      newAttendees = [
        ...newAttendees,
        {
          role: 'REQ-PARTICIPANT',
          status: 'ACCEPTED',
          contact: { email: calendarAccount.email, name: '' },
        },
      ];
    }
    newAttendees = [
      ...newAttendees,
      {
        role: 'REQ-PARTICIPANT',
        status: 'NEEDS-ACTION',
        contact: {
          email: attendee.email,
          name: attendee.email === attendee.name ? '' : attendee.name,
        },
      },
    ];
    this.props.updateField({ contact_events: newAttendees });
  };

  isAttendee = () => {
    const { attendees, calendarAccount } = this.props;
    return attendees.filter(attendee => attendee.contact.email === calendarAccount.email)[0];
  };

  handleRemoveAttendee = attendee => {
    const { attendees } = this.props;
    this.props.updateField({
      contact_events: attendees.filter(attd => attd !== attendee),
    });
  };

  handleChangeRsvp = (attendee, value) => {
    const { attendees } = this.props;
    let newAttendees = attendees.map(att => {
      if (att.contact.email !== attendee.contact.email) return att;
      return { ...att, status: value };
    });
    this.props.updateField({ contact_events: newAttendees });
  };

  renderRsvp = () => {
    let attendee = this.isAttendee();
    if (!attendee) return;
    let rsvpValues = [
      { value: 'ACCEPTED', label: 'Yes' },
      { value: 'TENTATIVE', label: 'Maybe' },
      { value: 'DECLINED', label: 'No' },
    ];
    return (
      <div className="rsvp">
        <Select
          name="rsvp"
          label="Going?"
          inlineLabel
          onChange={({ target: { value } }) => this.handleChangeRsvp(attendee, value)}
          className="minimal-input rsvp"
          data={rsvpValues}
          allowPlaceholder={false}
          value={attendee.status}
        />
      </div>
    );
  };

  getContacts = () => {
    return contactsApi.fetchAll().then(data => {
      this.setState({ ...this.state, contacts: data.data });
    });
  };

  renderOption = option => {
    return (
      <div>
        <div>{option.name || option.email}</div>
        {option.name && option.name !== option.email && <small>{option.email}</small>}
      </div>
    );
  };

  render() {
    const { attendees } = this.props;
    return (
      <div className="event-attendees">
        {this.renderRsvp()}
        <div className="react-select flex-row">
          <label className="inline-label" htmlFor="newAttendee">
            with
          </label>
          <ReactSelect.Creatable
            name="newAttendee"
            className="minimal-input"
            placeholder="+ ADD GUEST"
            options={this.state.contacts}
            valueKey="email"
            labelKey="name"
            clearable={false}
            arrowRenderer={() => false}
            value={this.state.newAttendee}
            promptTextCreator={label => `Add "${label}"`}
            optionRenderer={this.renderOption}
            onChange={this.handleAddAttendee}
          />
        </div>

        {attendees.map((attendee, index) => {
          return (
            <div className="attendee" key={index}>
              <span className={`attendee__status ${attendee['status']}`}>
                <img src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=20" />
              </span>
              <span className="attendee__name" title={attendee['contact']['email']}>
                {attendee['contact']['name'] || attendee['contact']['email']}
              </span>
              <span
                className="attendee__remove"
                onClick={() => this.handleRemoveAttendee(attendee, index)}
              >
                <FontAwesome name="times" />
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}

Attendees.propTypes = {
  updateField: propTypes.func,
  attendees: propTypes.array,
  calendarAccount: propTypes.object,
  calendar: propTypes.object,
};

export default Attendees;
