import React, { useEffect, useRef, useState } from 'react';
import history from '~/Providers/History';
import moment from '~/Providers/Moment';

import LoadingMessage from '~/Components/Global/Loading/LoadingMessage';
import Button from '~/Components/Global/Form/Button';
import { fetchAll } from '~/Services/Container';
import { AdminUserApi } from '~/Providers/Api';
import TextInput from '~/Components/Global/Form/TextInput';
import { HUMAN_DATE_FORMAT, HUMAN_TIME_FORMAT } from '~/Redux/Modules/UserOptions';

import './AdminUsers.scss';

const useSearch = (page, search, isSubscribed) => {
  const [users, setUsers] = useState([]);
  const interval = useRef();
  const request = useRef();
  const [isFetching, setFetching] = useState(true);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    if (interval.current) clearTimeout(interval.current);
    interval.current = setTimeout(() => {
      const stamp = moment().unix();
      request.current = stamp;
      if (search.length < 1) {
        search = null;
      }
      setFetching(true);
      AdminUserApi.fetch(page, search, isSubscribed === null ? null : Boolean(isSubscribed)).then(
        response => {
          if (request.current !== stamp) {
            return;
          }
          setFetching(false);
          setUsers(response.data.data);
          setPageData({
            per_page: response.data.per_page,
            currentPage: response.data.current_page,
            total: response.data.total,
            lastPage: response.data.last_page,
            from: response.data.from,
            to: response.data.to,
          });
        }
      );
    }, 300);
  }, [page, search, isSubscribed]);
  return { users, isFetching, pageData };
};

const AdminUsers = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [localPage, setLocalPage] = useState(1);
  const [isSubscribed, setIsSubscribed] = useState(null);

  const { users, isFetching, pageData } = useSearch(page, search, isSubscribed);

  const renderResults = () => {
    if (isFetching) {
      return <LoadingMessage messages="Loading users..." />;
    }
    return (
      <>
        <div className="results">
          <div className="results__header">
            <span>Name</span>
            <span>Email</span>
            <span>Subscribed?</span>
            <span>Joined</span>
          </div>
          {users.map(({ id, name, email, created_at, isSubscribed }) => {
            return (
              <div
                className="results__row user"
                key={id}
                role="button"
                tabIndex="0"
                onClick={() => history.push(`/admin/users/${id}`)}
              >
                <span className="user__name">{name}</span>
                <span className="user__email">{email}</span>
                <span className="user__subscribed">{isSubscribed ? 'YES' : 'NO'}</span>
                <span className="user__created">
                  {moment(created_at).format(HUMAN_DATE_FORMAT)}{' '}
                  {moment(created_at).format(HUMAN_TIME_FORMAT)}
                </span>
              </div>
            );
          })}
        </div>
        <div className="pagination">
          <div className="pagination__totals">
            Showing {pageData.from} to {pageData.to} of {pageData.total} users
          </div>
          <div className="pagination__pages">
            Page {pageData.currentPage} of {pageData.lastPage}
          </div>
          <div className="pagination__controls">
            <Button
              text="Prev"
              disabled={page <= 0}
              onClick={() => {
                setPage(page - 1);
                setLocalPage(page - 1);
              }}
            />
            <Button
              text="Next"
              disabled={page >= pageData.lastPage}
              onClick={() => {
                setPage(page + 1);
                setLocalPage(page + 1);
              }}
            />
            <TextInput
              className="minimal-input"
              value={localPage}
              name="page"
              onChange={({ page }) => setLocalPage(page)}
              onKeyDown={({ key }) => key === 'Enter' && setPage(localPage)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="admin-users-wrapper">
      <h1 className="text-center margin-bottom">User Management</h1>
      <div className="search-wrapper">
        <TextInput
          className="minimal-input"
          value={search}
          placeholder="Search"
          name="search"
          onChange={({ search }) => setSearch(search)}
        />
        {/* use a dropdown instead of checkbox */}
        <div className="subscription-filter">
          <label>
            Subscribed:
            <select
              value={isSubscribed}
              onChange={e =>
                setIsSubscribed(e.target.value === 'null' ? null : e.target.value === 'true')
              }
            >
              <option value="null">All</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </label>
        </div>
      </div>
      {renderResults()}
    </div>
  );
};

export default AdminUsers;
