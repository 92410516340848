import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FontAwesome from 'react-fontawesome';
import TextInput from '~/Components/Global/Form/TextInput';
import Select from '~/Components/Global/Form/Select';
import Button from '~/Components/Global/Form/Button';

import './ManageAlarms.scss';

const unitOptions = [
  { value: 'minutes', label: 'minutes' },
  { value: 'hours', label: 'hours' },
  { value: 'days', label: 'days' },
  { value: 'weeks', label: 'weeks' },
];

const allDayUnitOptions = [
  { value: 'days', label: 'days' },
  { value: 'weeks', label: 'weeks' },
];

const unitMultipliers = {
  minutes: 1,
  hours: 60,
  days: 60 * 24,
  weeks: 60 * 24 * 7,
};

const ManageAlarms = ({ alarms, updateField, allDay }) => {
  const [hasSorted, setSorted] = useState(false);
  const unitOptionsToUse = allDay ? allDayUnitOptions : unitOptions;

  useEffect(() => {
    if (alarms && !hasSorted) {
      alarms?.sort(
        (a, b) =>
          unitMultipliers[a.unit] * (a.amount || 0) - unitMultipliers[b.unit] * (b.amount || 0)
      );
      setSorted(true);
    }
  }, [alarms, hasSorted, setSorted]);

  return (
    <div className="manage-alarms">
      <div className="alarm-list">
        {alarms.map((alarm, i) => (
          <div className={`alarm-row ${allDay ? 'all-day' : ''}`} key={alarm.id || i}>
            <TextInput
              className="minimal-input"
              value={alarm.amount}
              name="amount"
              onChange={({ amount }) => {
                const newAlarms = alarms.map((alarm, k) => (i == k ? { ...alarm, amount } : alarm));
                updateField({ alarms: newAlarms });
              }}
            />
            <Select
              name="unit"
              onChange={({ target: { value: unit } }) => {
                console.log('onChange', unit);
                const newAlarms = alarms.map((alarm, k) => (i == k ? { ...alarm, unit } : alarm));
                updateField({ alarms: newAlarms });
              }}
              className="minimal-input"
              data={unitOptionsToUse}
              value={alarm.unit}
              allowPlaceholder={false}
            />
            {alarm.all_day ? <span className="at-text">at 9am</span> : <></>}
            <span
              className="remove-btn"
              onClick={() => {
                const newAlarms = alarms.filter((alarm, k) => i != k);
                updateField({ alarms: newAlarms });
              }}
            >
              <FontAwesome name="times" />
            </span>
          </div>
        ))}
      </div>
      <Button
        className="new-alarm-btn"
        text="ADD ALARM"
        onClick={() => {
          updateField({
            alarms: [
              ...alarms,
              allDay
                ? { id: null, amount: '1', unit: 'days', channel: 'push', all_day: true }
                : { id: null, amount: '10', unit: 'minutes', channel: 'push' },
            ],
          });
        }}
      />
    </div>
  );
};

ManageAlarms.propTypes = {
  alarms: PropTypes.array.isRequired,
  updateField: PropTypes.func.isRequired,
  allDay: PropTypes.bool,
};

export default ManageAlarms;
