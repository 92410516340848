import history from '~/Providers/History';

import { getAllMealPreps } from '~/Redux/Modules/MealPreps';
import { getWater, clearWater } from '~/Redux/Modules/Water';
import { getQuoteByDate, clearQuote } from '~/Redux/Modules/Quote';
import { getEvents, syncEvents } from '~/Redux/Modules/Events';
import { getDroppedStickers } from '~/Redux/Modules/DroppedStickers';
import { getDrawings, resetDrawings } from '~/Redux/Modules/Drawings';
import { fetchAllTasks } from '~/Redux/Modules/Tasks';

import { MONTH, WEEK, DAY, HABIT, periodForDateType } from '~/Config/DateRangeTypes';
import { DATE_TIME_FORMAT, DATE_FORMAT } from '~/Redux/Constants';
import { toStartOfWeekForChecklist } from '~/Context/Checklist';
import moment from '~/Providers/Moment';
import Store from '~/Redux/Store';
import { eventsApi } from '~/Providers/Api';
import { MONTH_TODO, WEEK_TODO, MUST_DO, OTHER, CUSTOM } from '~/Config/Checklists';

import { fetchAllHabits } from '~/Redux/Modules/Habits/actions';
import { fetchAllHabitsTracked } from '../HabitTrackeds';
import { SET_RANGE_TYPE, INCREMENT, DECREMENT, SET_SYNC_TOKEN, SET_LOCALE } from './constants';

export const setToday = currentType => dispatch => {
  const today = moment().startOf(currentType);
  const pin = moment().startOf('day');

  dispatch({
    type: SET_RANGE_TYPE,
    dateType: currentType,
    pin: pin,
    start: today,
  });
  dispatch(getChanges(currentType, today));
};

export const setDayType = (start, anchor = false, redirect = true) => dispatch => {
  dispatch({ type: SET_RANGE_TYPE, dateType: DAY, pin: start, start });
  dispatch(onDayChange(start));
  if (anchor) {
    return history.push(`/${DAY}#${anchor}`);
  }
  if (redirect) {
    return history.push(
      `/${DAY}/${start.format('YYYY')}/${start.format('MM')}/${start.format('DD')}`
    );
  }
};

export const setWeekType = (start, redirect = true) => dispatch => {
  dispatch({ type: SET_RANGE_TYPE, dateType: WEEK, start });
  dispatch(onWeekChange(start));
  if (redirect) {
    return history.push(
      `/${WEEK}/${start.format('YYYY')}/${start.format('MM')}/${start.format('DD')}`
    );
  }
};

export const setLocale = locale => dispatch => {
  dispatch({ type: SET_LOCALE, locale });
};

export const setMonthType = (start, redirect = true) => dispatch => {
  dispatch({ type: SET_RANGE_TYPE, dateType: MONTH, start });
  dispatch(onMonthChange(start));
  if (redirect) {
    return history.push(`/${MONTH}/${start.format('YYYY')}/${start.format('MM')}`);
  }
};

export const setHabitMonth = (start, redirect = true) => dispatch => {
  console.log(`Set Habit Month ${start}`);
  dispatch({ type: SET_RANGE_TYPE, dateType: HABIT, start });
  dispatch(onHabitChange(start));
  if (redirect) {
    console.log('Habits tab redirect');
    return history.push(`/${HABIT}/${start.format('YYYY')}/${start.format('MM')}`);
  }
};

/**
 * Change Handlers
 */
let changeTimeout = null;
let syncTimeout = null;
export const onDayChange = start => dispatch => {
  console.log('onDayChange', start.format(DATE_TIME_FORMAT));
  if (changeTimeout) clearTimeout(changeTimeout);
  changeTimeout = setTimeout(() => {
    dispatch(clearWater());
    dispatch(clearQuote());
    dispatch(
      getEvents(
        start
          .clone()
          .startOf('month')
          .subtract(1, MONTH)
          .startOf('week'),
        start
          .clone()
          .startOf('month')
          .add(2, MONTH)
          .startOf('week')
          .add(1, 'week')
      )
    );
    dispatch(
      fetchAllHabits(
        start
          .clone()
          .startOf('month')
          .subtract(1, MONTH)
          .startOf('week'),
        start
          .clone()
          .startOf('month')
          .add(2, MONTH)
          .startOf('week')
          .add(1, 'week'),
        true
      )
    );
    dispatch(
      fetchAllHabitsTracked(
        start
          .clone()
          .startOf('month')
          .subtract(1, MONTH)
          .startOf('week'),
        start
          .clone()
          .startOf('month')
          .add(2, MONTH)
          .startOf('week')
          .add(1, 'week'),
        true
      )
    );
    dispatch(fetchAllTasks(start, start, [MUST_DO, OTHER, CUSTOM]));
    dispatch(getDroppedStickers(start, start));
    dispatch(getAllMealPreps(start, start));
    dispatch(getWater(start));
    dispatch(getQuoteByDate(start));
    dispatch(resetDrawings());
    dispatch(getDrawings(start, start));
  }, 100);
};

export const onWeekChange = start => dispatch => {
  console.log('onWeekChange', start.format(DATE_TIME_FORMAT));
  if (changeTimeout) clearTimeout(changeTimeout);
  changeTimeout = setTimeout(() => {
    dispatch(
      getEvents(
        start
          .clone()
          .startOf('month')
          .subtract(1, MONTH)
          .startOf('week'),
        start
          .clone()
          .startOf('month')
          .add(2, MONTH)
          .startOf('week')
          .add(1, 'week')
      )
    );
    dispatch(
      fetchAllTasks(toStartOfWeekForChecklist(start), start.clone().add(1, WEEK), [
        WEEK_TODO,
        MUST_DO,
      ])
    );
    dispatch(getAllMealPreps(toStartOfWeekForChecklist(start), start.clone().add(1, WEEK)));
    dispatch(getDroppedStickers(toStartOfWeekForChecklist(start), start.clone().add(1, WEEK)));
    dispatch(clearQuote());
    dispatch(getQuoteByDate(toStartOfWeekForChecklist(start)));
    dispatch(getWater(toStartOfWeekForChecklist(start)));
    dispatch(resetDrawings());
    dispatch(getDrawings(toStartOfWeekForChecklist(start), start.clone().add(1, WEEK)));
  }, 100);
};

export const onMonthChange = start => dispatch => {
  console.log('onMonthChange', start.format(DATE_TIME_FORMAT));
  if (changeTimeout) clearTimeout(changeTimeout);
  if (syncTimeout) clearTimeout(syncTimeout);
  syncTimeout = null;
  changeTimeout = setTimeout(() => {
    syncTimeout = setTimeout(() =>
      dispatch(
        syncEvents(
          start
            .clone()
            .startOf('month')
            .subtract(1, MONTH)
            .startOf('week'),
          start
            .clone()
            .startOf('month')
            .add(2, MONTH)
            .startOf('week')
            .add(1, 'week'),
          moment().unix()
        )
      )
    );
    dispatch(
      getEvents(
        start
          .clone()
          .startOf('month')
          .subtract(1, MONTH)
          .startOf('week'),
        start
          .clone()
          .startOf('month')
          .add(2, MONTH)
          .startOf('week')
          .add(1, 'week'),
        true
      )
    );
    dispatch(
      getDroppedStickers(
        start.clone().subtract(7, DAY),
        start
          .clone()
          .add(1, MONTH)
          .add(7, DAY)
      )
    );
    dispatch(clearQuote());
    dispatch(getQuoteByDate(start));
    dispatch(resetDrawings());
    dispatch(
      getDrawings(
        start.clone().startOf(WEEK),
        start
          .clone()
          .startOf(MONTH)
          .add(1, MONTH)
          .startOf(WEEK)
          .endOf(WEEK)
      )
    );
    dispatch(fetchAllTasks(start, start, [MONTH_TODO]));
  }, 100);
};

export const onHabitChange = start => dispatch => {
  console.log('onHabitChange', start.format(DATE_TIME_FORMAT));
  if (changeTimeout) clearTimeout(changeTimeout);
  changeTimeout = setTimeout(() => {
    dispatch(
      fetchAllHabits(
        start
          .clone()
          .startOf('month')
          .subtract(1, MONTH)
          .startOf('week'),
        start
          .clone()
          .startOf('month')
          .add(2, MONTH)
          .startOf('week')
          .add(1, 'week'),
        true
      )
    );
    dispatch(
      fetchAllHabitsTracked(
        start
          .clone()
          .startOf('month')
          .subtract(1, MONTH)
          .startOf('week'),
        start
          .clone()
          .startOf('month')
          .add(2, MONTH)
          .startOf('week')
          .add(1, 'week'),
        true
      )
    );
  }, 100);
};

/**
 * Increments & Decrements
 */

export const increment = (currentType, start) => dispatch => {
  let periodCurrentType = periodForDateType[currentType];
  console.log(`increment currentType: ${periodCurrentType}`);
  start.add(1, periodCurrentType).startOf('day');
  dispatch({ type: INCREMENT, start });
  dispatch(getChanges(currentType, start));
};

export const decrement = (currentType, start) => dispatch => {
  let periodCurrentType = periodForDateType[currentType];
  console.log(`decrement currentType: ${currentType}`);
  start.add(-1, periodCurrentType).startOf('day');
  dispatch({ type: DECREMENT, start });
  dispatch(getChanges(currentType, start));
};

/**
 * Change Sorting
 */

export const getChanges = (currentType, start) => dispatch => {
  if (currentType === DAY) {
    updateDayPath(start);
    dispatch(onDayChange(start));
  }

  if (currentType === WEEK) {
    updateWeekPath(start);
    dispatch(onWeekChange(start));
  }

  if (currentType === MONTH) {
    console.log('updateMonthPath');
    updateMonthPath(start);
    dispatch(onMonthChange(start));
  }

  if (currentType === HABIT) {
    console.log('updateHabitPath');
    updateHabitPath(start);
    dispatch(onHabitChange(start));
  }
};

export const updateMonthPath = start => {
  return history.push(`/${MONTH}/${start.format('YYYY')}/${start.format('MM')}`);
};

export const updateWeekPath = start => {
  return history.push(
    `/${WEEK}/${start.format('YYYY')}/${start.format('MM')}/${start.format('DD')}`
  );
};

export const updateDayPath = start => {
  return history.push(
    `/${DAY}/${start.format('YYYY')}/${start.format('MM')}/${start.format('DD')}`
  );
};

export const updateHabitPath = start => {
  return history.push(`/${HABIT}/${start.format('YYYY')}/${start.format('MM')}`);
};

export const fetchSyncToken = () => async () => {
  try {
    const response = await eventsApi.syncToken();
    return response.data.syncToken;
  } catch (err) {
    return null;
  }
};

export const heartbeat = start => async dispatch => {
  const newSyncToken = await dispatch(fetchSyncToken());
  const {
    DateRange: { syncToken },
  } = Store.getState();
  if (syncToken && syncToken === newSyncToken) {
    console.log('heartbeat: sync tokens match', syncToken, newSyncToken);
    return;
  }
  console.log('heartbeat: sync tokens do not match', syncToken, newSyncToken);
  dispatch({ type: SET_SYNC_TOKEN, syncToken: newSyncToken });
  dispatch(
    syncEvents(
      start
        .clone()
        .startOf('month')
        .subtract(1, MONTH)
        .startOf('week'),
      start
        .clone()
        .startOf('month')
        .add(2, MONTH)
        .startOf('week')
        .add(1, 'week'),
      moment().unix(),
      true
    )
  );
};

export const reloadCurrentView = () => async dispatch => {
  const {
    DateRange: { currentType, pin },
  } = Store.getState();
  switch (currentType) {
    case MONTH:
      console.log('MondayStart reloadCurrentView', moment.locale(), currentType, pin);
      dispatch(setMonthType(moment(pin.format(DATE_FORMAT), DATE_FORMAT).startOf('month'), false));
      break;
    case WEEK:
      dispatch(setWeekType(moment(pin.format(DATE_FORMAT), DATE_FORMAT), false));
      break;
    case DAY:
      dispatch(setDayType(moment(pin.format(DATE_FORMAT), DATE_FORMAT), false, false));
      break;
    case HABIT:
      dispatch(setHabitMonth(moment(pin.format(DATE_FORMAT), DATE_FORMAT).startOf('month'), false));
      break;
  }
};
