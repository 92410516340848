import throttle from 'lodash.throttle';
import { listGroupsApi } from '~/Providers/Api';
import { error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import Store from '~/Redux/Store';
import mParticle, { FolderCreateEvent } from '~/Providers/mParticle';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  SET_CURRENT,
  UNSET_CURRENT,
  START_UPDATING,
  FINISH_UPDATING,
  SET_QUEUE,
  SET_LAST_UPDATE,
} from './constants';

export const getFolders = () => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await listGroupsApi.fetch();
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    if (response.data) {
      dispatch(setCurrent(response.data[0]));
    }
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
    dispatch(error('Unable to fetch folders', parseErrors(err)));
  }
};

export const getFolder = id => async dispatch => {
  dispatch({ type: FETCH_ONE, id });
  try {
    let response = await listGroupsApi.fetchOne(id);
    dispatch({ type: FETCH_ONE_SUCCESS, data: response.data, id });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_ONE_FAILURE, error: err, id });
    dispatch(error('Unable to fetch folder', parseErrors(err)));
  }
};

export const createFolder = data => async dispatch => {
  dispatch({ type: STORE });
  try {
    let response = await listGroupsApi.store(data);
    mParticle.logEvent(new FolderCreateEvent());
    dispatch({ type: STORE_SUCCESS, data: response.data });
    if (response.data) {
      dispatch(setCurrent(response.data));
    }
    return response;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
    dispatch(error('Folder was not created', parseErrors(err)));
  }
};

export const deleteFolder = data => async dispatch => {
  dispatch({ type: DELETE });
  try {
    let response = await listGroupsApi.delete(data);
    dispatch({ type: DELETE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, error: err });
    dispatch(error('Folder was not deleted', parseErrors(err)));
  }
};

export const setCurrent = data => dispatch => {
  dispatch({ type: SET_CURRENT, data });
};

const updateThrottlers = {};

export const updateFolder = data => dispatch => {
  console.log(data);
  const {
    Folders: {
      updates: { isUpdating, queue },
    },
  } = Store.getState();
  const toUpdate = { ...data };
  dispatch({
    type: UPDATE,
    data: toUpdate,
  });

  if (isUpdating) {
    dispatch({
      type: SET_QUEUE,
      queue: [...queue.filter(update => update.id !== toUpdate.id), toUpdate],
    });
  } else {
    dispatch(folderUpdate(toUpdate));
  }
};
const folderUpdate = data => dispatch => {
  const {
    Folders: {
      updates: { lastUpdates },
    },
  } = Store.getState();
  let toUpdate = data;
  const identifier = data.id;
  if (
    lastUpdates[identifier] &&
    (!data.updated_at || lastUpdates[identifier].updated_at > data.updated_at)
  ) {
    toUpdate = {
      ...data,
      updated_at: lastUpdates[identifier].updated_at,
    };
  }
  dispatch({ type: START_UPDATING });
  listGroupsApi
    .update(toUpdate)
    .then(result => {
      if (result && result.data) {
        dispatch({ type: SET_LAST_UPDATE, data: result.data, original: toUpdate });
        dispatch({ type: UPDATE_SUCCESS, data: { ...result.data, confirm: toUpdate.confirm } });
      }
      return result;
    })
    .then(result => {
      const {
        Folders: {
          updates: { queue },
        },
      } = Store.getState();

      if (!queue.length) {
        dispatch({ type: FINISH_UPDATING });
        return;
      }
      let [nextUpdate, ...rest] = queue;
      dispatch({ type: SET_QUEUE, queue: rest });
      if (result && result.data && nextUpdate.id === result.data.id) {
        nextUpdate = { ...nextUpdate, updated_at: result.data.updated_at };
      }
      dispatch(folderUpdate(nextUpdate));
    })
    .catch(err => {
      dispatch({ type: UPDATE_FAILURE, error: err });
      updateThrottlers[data.id] =
        updateThrottlers[data.id] ||
        throttle(
          err => {
            dispatch(
              error(
                'Folder was not updated',
                err.status === 422
                  ? 'The folder you are trying to update is out of date. Please wait a moment.'
                  : parseErrors(err)
              )
            );
          },
          15000,
          { trailing: false }
        );
      updateThrottlers[data.id](err);
      dispatch(getFolder(data.id))
        .then(({ data }) => {
          dispatch({ type: UNSET_CURRENT });
          dispatch({ type: SET_CURRENT, data });
        })
        .finally(() => {
          const {
            Folders: {
              updates: { queue },
            },
          } = Store.getState();

          let updatedQueue = queue.filter(item => item.id !== data.id);
          if (updatedQueue.length) {
            let [nextUpdate, ...rest] = updatedQueue;
            dispatch({ type: SET_QUEUE, queue: rest });
            dispatch(folderUpdate(nextUpdate));
          } else {
            dispatch({ type: SET_QUEUE, queue: [] });
            dispatch({ type: FINISH_UPDATING });
          }
        });
    });
};
