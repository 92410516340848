/**
 * Generic loading message component
 * @param messages [string] Array of strings to render as paragraphs under the loading spinner
 */

import React from 'react';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import './LoadingMessage.scss';

const LoadingMessage = ({ messages, small }) => (
  <div className="loading">
    <span>
      <FontAwesome className="fa-spin" name="spinner" size={small ? 'lg' : '3x'} /> {messages}
    </span>
  </div>
);

LoadingMessage.propTypes = {
  messages: propTypes.oneOfType([propTypes.array, propTypes.string]),
  small: propTypes.bool,
};

LoadingMessage.defaultProps = {
  messages: ['Loading, please be patient and do not navigate away from or refresh this page.'],
};

export default LoadingMessage;
