/* eslint-disable max-len */
import React from 'react';

export default function HabitMealPrepIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#5m9v0aumra)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M23.617 8.367a.39.39 0 0 0-.514.13l-2.281 3.705a.438.438 0 0 1-.577.146.383.383 0 0 1-.155-.541l2.281-3.705a.376.376 0 0 0-.152-.533.43.43 0 0 0-.568.142l-2.281 3.705a.437.437 0 0 1-.577.145.383.383 0 0 1-.155-.54l2.281-3.706a.377.377 0 0 0-.152-.532.43.43 0 0 0-.567.143l-2.282 3.705a.437.437 0 0 1-.577.144.383.383 0 0 1-.155-.54l2.282-3.706a.34.34 0 0 0-.138-.481.39.39 0 0 0-.514.13l-2.892 4.698c-.661 1.073-.435 2.395.46 3.229l-5.876 8.411c-.23.375.412 1.127.736 1.303.325.176 1.341.321 1.572-.054l4.832-8.977c1.218.31 2.553-.167 3.213-1.24l2.894-4.699a.34.34 0 0 0-.138-.482z" />
        <path d="m12.567 18.716 1.026-1.469-.277-.45-2.594-4.211-3.751-6.092C3.709 8.26 9.712 18.749 9.712 18.749l2.157-1.167.698 1.134zM15.347 20.096l-.9 1.673 1.132 1.839c.231.374.742.503 1.142.287.4-.217.538-.697.306-1.072l-1.68-2.727z" />
      </g>
      <defs>
        <clipPath id="5m9v0aumra">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
