import { connect } from 'react-redux';

import Notifications from '~/Components/Global/Notifications/Notifications';

const mapStateToProps = ({ Notifications }) => {
  return {
    queued: Notifications.queued,
    displayed: Notifications.displayed,
    clear: Notifications.clear,
  };
};

export default connect(mapStateToProps)(Notifications);
