// Manage setting and retrieving items in local Storage
class Storage {
  constructor() {
    // Fallbacks here and in the methods below are to support browsers that don't have local Storage, e.g.
    // Safari in private mode
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      this.StorageSupported = true;
    } catch (e) {
      this.StorageSupported = false;
      window.appStorage = {};
    }
  }

  get(key) {
    if (this.StorageSupported) return localStorage.getItem(key);

    return window.appStorage[key];
  }

  set(key, value) {
    if (this.StorageSupported) return localStorage.setItem(key, value);

    return (window.appStorage[key] = value);
  }

  remove(key) {
    if (this.StorageSupported) return localStorage.removeItem(key);

    return delete window.appStorage[key];
  }
}

export default Storage;
