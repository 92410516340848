import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import moment from '~/Providers/Moment';
import history from '~/Providers/History';
import config from '~/Config';
import MyAccountSection from '~/Components/Pages/Account/MyAccountSection';
import { DATE_TIME_FORMAT } from '~/Redux/Constants';
import Spinner from '~/Components/Global/Loading/Spinner';
import { getUser } from '~/Redux/Modules/User';
import { unsubscribe } from '~/Redux/Modules/Subscription';
import { closeModal } from '~/Redux/Modules/Modal';
import { MY_ACCOUNT_MODAL } from '~/Config/Modals';
import { humanDateFormats, HUMAN_DATE_FORMAT } from '~/Redux/Modules/UserOptions';
import StripePermittedView from '~/Components/Global/StripePermittedView';
import { handleGet } from '~/Redux/Modules/Account';
import ManageSubscriptionSection from './ManageSubscriptionSection';
import SwapToStripeSection from './SwapToStripeSection';

const stripe = loadStripe(config.stripeKey, config.stripeOptions);

const SUMMARY = 'summary';
const PAYMENTS = 'payments';
const UPDATE_CARD = 'update_card';

class PaymentsAndBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: SUMMARY,
      editingPaymentMethod: null,
    };
  }

  componentWillUnmount = () => {
    if (this.state.editPaymentWindowTimer) {
      clearInterval(this.state.editPaymentWindowTimer);
    }
  };

  handleSetupIntent = () => {
    const editPaymentWindow = window.open(
      `${window.location.origin}/change-payment-source`,
      '_blank',
      'popup,width=500,height=600'
    );

    const editPaymentWindowTimer = setInterval(() => {
      if (editPaymentWindow.closed) {
        this.setState({ ...this.state, editingPaymentMethod: null, editPaymentWindowTimer: null });
        clearInterval(editPaymentWindowTimer);
        this.props.fetchAccount();
        this.props.fetchUser();
      }
    }, 100);

    this.setState({
      ...this.state,
      editingPaymentMethod: editPaymentWindow,
      editPaymentWindowTimer,
    });
  };

  renderSpinner = () => {
    return <Spinner />;
  };

  cancelSubscription = () => {
    const { unsubscribe } = this.props;
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      unsubscribe();
    }
  };

  openSubscriptions = () => {
    const { closeModal } = this.props;
    closeModal();
    history.push('/subscription');
  };

  renderTrialInfo = () => {
    const { account, humanDateFormats } = this.props;
    if (!account.isOnTrial) return null;
    return (
      <div className="payments">
        <section className="payments__trial-period">
          TRIAL ENDS{' '}
          {moment(account.trial_ends_at, DATE_TIME_FORMAT).format(
            humanDateFormats[HUMAN_DATE_FORMAT]
          )}
        </section>
        <StripePermittedView>
          {!account.stripeSubscription && !account.iapSubscription && (
            <section className="payments__trial-select">
              <a onClick={this.openSubscriptions}>CHOOSE PLAN</a>
            </section>
          )}
        </StripePermittedView>
      </div>
    );
  };

  renderStripePlan = () => {
    const { account, humanDateFormats } = this.props;
    if (!account.stripeSubscription) return null;
    return (
      <div className="payments">
        <section className="payments__current-plan">
          <div className="payments__current-plan__title">Current Plan</div>
          <div className="payments__current-plan__value">{account.subscriptionType}</div>
        </section>
        <section className="payments__first-billing-date">
          <div className="payments__first-billing-date__title">First Billing Date</div>
          <div className="payments__first-billing-date__value">
            {account.stripeSubscription &&
              moment
                .unix(account.stripeSubscription.billing_cycle_anchor)
                .format(humanDateFormats[HUMAN_DATE_FORMAT])}
          </div>
        </section>
        <section className="payments__next-billing-date">
          <div className="payments__next-billing-date__title">Next Billing Date</div>
          <div className="payments__next-billing-date__value">
            {account.stripeUpcomingInvoice &&
              moment
                .unix(account.stripeUpcomingInvoice.date)
                .format(humanDateFormats[HUMAN_DATE_FORMAT])}
          </div>
        </section>
        <section className="payments__next-billing-amount">
          <div className="payments__next-billing-amount__title">Next Billing Amount</div>
          <div className="payments__next-billing-amount__value">
            $
            {account.stripeUpcomingInvoice &&
              (account.stripeUpcomingInvoice.amount_due / 100 || '0.00')}
          </div>
        </section>
      </div>
    );
  };

  renderIapPlan = () => {
    const { account, humanDateFormats } = this.props;
    if (!account.iapSubscription) return null;
    return (
      <div className="payments">
        <section className="payments__current-plan">
          <div className="payments__current-plan__title">Platform</div>
          <div className="payments__current-plan__value">
            {account.iapSubscription.platform.toUpperCase()}
          </div>
        </section>
        <section className="payments__first-billing-date">
          <div className="payments__first-billing-date__title">Current Plan</div>
          <div className="payments__first-billing-date__value">
            {account.iapSubscription.sku.toUpperCase()}
          </div>
        </section>
        <section className="payments__next-billing-date">
          <div className="payments__next-billing-date__title">Next Billing Date</div>
          <div className="payments__next-billing-date__value">
            {moment(account.iapSubscription.ends_at, DATE_TIME_FORMAT).format(
              humanDateFormats[HUMAN_DATE_FORMAT]
            )}
          </div>
        </section>
      </div>
    );
  };

  renderSummary = () => {
    const { account, openCancellation } = this.props;
    return (
      <div className="payments-and-billing">
        {this.renderTrialInfo()}
        {this.renderStripePlan()}
        {this.renderIapPlan()}
        <StripePermittedView>
          <div className="links">
            {account.stripeSubscription && !account.isOnGracePeriod && (
              <ManageSubscriptionSection />
            )}
            {account.iapSubscription && !account.stripeSubscription && <SwapToStripeSection />}
            <a onClick={() => this.setState({ ...this.state, currentView: UPDATE_CARD })}>
              Update Payment Information
            </a>
            <a onClick={() => this.setState({ ...this.state, currentView: PAYMENTS })}>
              View Payment History
            </a>
            {account.stripeSubscription ? (
              account.isOnGracePeriod ? (
                'SUBSCRIPTION CANCELLED'
              ) : (
                <a onClick={openCancellation}>Cancel Subscription</a>
              )
            ) : null}
          </div>
        </StripePermittedView>
      </div>
    );
  };

  renderCharge = ({ amount, created, status }) => {
    const { humanDateFormats } = this.props;
    return (
      <section className="payment-detail__charge">
        <div className="payment-detail__charge__created">
          {moment.unix(created).format(humanDateFormats[HUMAN_DATE_FORMAT])}
        </div>
        <div className="payment-detail__charge__amount">${amount / 100}</div>
        <div className="payment-detail__charge__status">{status}</div>
      </section>
    );
  };

  renderPayments = () => {
    const {
      account: { stripeCharges },
    } = this.props;
    return (
      <div className="payments-and-billing">
        <div className="payment-detail">
          <section className="payment-detail__heading">
            <div className="payment-detail__heading__label">Payment History</div>
            <a
              className="payment-detail__heading__back"
              onClick={() => this.setState({ ...this.state, currentView: SUMMARY })}
            >
              Back to Summary
            </a>
          </section>
          {stripeCharges.map(charge => this.renderCharge(charge))}
        </div>
      </div>
    );
  };

  renderUpdateCard = () => {
    const { user } = this.props;
    return (
      <div className="payments-and-billing">
        <div className="update-card">
          <section className="update-card__heading">
            <div className="update-card__heading__label">Update Payment Method</div>
            <a
              className="update-card__heading__back"
              onClick={() => this.setState({ ...this.state, currentView: SUMMARY })}
            >
              Back to Summary
            </a>
          </section>
          <section className="update-card__current">
            <div className="update-card__current__label">Current Card:</div>
            <div className="update-card__current__brand">{user.card_brand ?? user.pm_type}</div>
            <div className="update-card__current__last4">
              {user.card_last_four ?? user.pm_last_four}
            </div>
          </section>

          <section className="update-card__new">
            {this.state.editingPaymentMethod ? (
              <Spinner />
            ) : (
              <a
                className="update-card__new__open"
                onClick={() => this.handleSetupIntent()}
                role="button"
                tabIndex="0"
              >
                Update...
              </a>
            )}
          </section>
        </div>
      </div>
    );
  };

  renderBody = () => {
    const { currentView } = this.state;
    switch (currentView) {
      case SUMMARY:
        return this.renderSummary();
      case PAYMENTS:
        return this.renderPayments();
      case UPDATE_CARD:
        return this.renderUpdateCard();
    }
  };

  render() {
    const { account, isFetching } = this.props;

    return (
      <MyAccountSection header={'Payments & Billing'}>
        {isFetching || !account.id ? this.renderSpinner() : this.renderBody()}
      </MyAccountSection>
    );
  }
}

PaymentsAndBilling.propTypes = {
  unsubscribe: PropTypes.func,
  closeModal: PropTypes.func,
  account: PropTypes.object,
  openCancellation: PropTypes.func,
  user: PropTypes.object,
  isUpdating: PropTypes.bool,
  isFetching: PropTypes.bool,
  humanDateFormats: PropTypes.object.isRequired,
  fetchAccount: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Account, User, UserOptions }) => {
  return {
    account: Account.data,
    isFetching: Account.isFetching,
    isUpdating: User.isUpdating,
    user: User.data,
    humanDateFormats: humanDateFormats({ UserOptions }),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    unsubscribe: () => dispatch(unsubscribe()),
    closeModal: () => dispatch(closeModal(MY_ACCOUNT_MODAL)),
    fetchAccount: () => dispatch(handleGet()),
    fetchUser: () => dispatch(getUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsAndBilling);
