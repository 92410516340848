import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArtfulCheckbox from '~/Components/Global/Form/ArtfulCheckbox';
import { updateUserOptions } from '~/Redux/Modules/UserOptions';
import moment, { setMondayStart } from '~/Providers/Moment';
import { setMonthType, setWeekType, setDayType, setLocale } from '~/Redux/Modules/DateRange';
import { MONTH, WEEK, DAY } from '~/Config/DateRangeTypes';
import { DATE_FORMAT } from '~/Redux/Constants';
import './ToggleMondayStart.scss';

const ToggleMondayStart = () => {
  const userOptions = useSelector(({ UserOptions }) => UserOptions.data);
  const useMondayStart = userOptions?.use_monday_start;
  const dispatch = useDispatch();
  const { currentType, pin } = useSelector(({ DateRange }) => DateRange);

  const setUseMondayStart = use_monday_start => {
    dispatch(updateUserOptions(userOptions.id, { ...userOptions, use_monday_start }));
    setMondayStart(use_monday_start);
    dispatch(setLocale(moment.locale()));
    switch (currentType) {
      case MONTH:
        dispatch(
          setMonthType(moment(pin.format(DATE_FORMAT), DATE_FORMAT).startOf('month'), false)
        );
        break;
      case WEEK:
        dispatch(setWeekType(moment(pin.format(DATE_FORMAT), DATE_FORMAT), false));
        break;
      case DAY:
        dispatch(setDayType(moment(pin.format(DATE_FORMAT), DATE_FORMAT), false, false));
        break;
    }
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  return (
    <li className="toggle-monday-start" onClick={handleClick}>
      <span>
        <ArtfulCheckbox
          label="Start Week on Monday"
          value={useMondayStart}
          onChange={() => setUseMondayStart(!useMondayStart)}
          name="useMondayStart"
        />
      </span>
    </li>
  );
};

export default ToggleMondayStart;
