import { useState, useEffect } from 'react';
import { setupIntentApi } from '~/Providers/Api';

const useSetupIntent = () => {
  const [setupIntent, setSetupIntent] = useState(null);
  const [error, setError] = useState(null);
  const search = new URLSearchParams(window.location.search);
  const [setupIntentId, setSetupIntentId] = useState(search.get('setup_intent'));
  useEffect(() => {
    const promise = setupIntentId
      ? setupIntentApi.findById(setupIntentId)
      : setupIntentApi.findOrCreate();
    promise
      .then(response => {
        console.log('useSetupIntent', response);
        setSetupIntent({
          clientSecret: response.data.client_secret,
          ...response.data,
        });
      })
      .catch(error => {
        console.error('useSetupIntent', error);
        setError(error);
      });
  }, [setupIntentId]);

  return { setupIntent, error, setSetupIntentId };
};

export default useSetupIntent;
