/* eslint-disable max-len */
import React from 'react';

export default function HabitIcon(props) {
  return (
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#4djsq32j0a)">
        <path
          d="M12.03 1.935c.046-.457.046-.869.046-1.373H2.924c0 .504 0 .916.045 1.373H.178v.458c0 4.073 5.172 6.957 6.407 7.597v1.556c0 .779-.595 1.373-1.374 1.373h-.915v1.83h6.407v-1.83h-.915a1.348 1.348 0 0 1-1.373-1.373V9.991c1.236-.641 6.407-3.525 6.407-7.597v-.458h-2.791zM1.14 2.851H3.06c.183 2.06.687 3.524 1.236 4.576-1.465-1.143-2.975-2.746-3.158-4.576zm9.61 4.576c.55-1.052 1.054-2.516 1.236-4.576h1.923c-.23 1.83-1.74 3.433-3.158 4.576z"
          fill="#BDBDBD"
        />
      </g>
      <defs>
        <clipPath id="4djsq32j0a">
          <path fill="#fff" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
