/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';

export default function CheckClosed(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#999;}' }} />
      </defs>
      <g id="Check_Closed" data-name="Check Closed">
        <path
          className="cls-1"
          d="M150,0A150,150,0,1,0,300,150,150,150,0,0,0,150,0ZM135.42,204.17h0l-16.67,16.66-16.67-16.66L56.25,158.33l16.67-16.66,45.83,45.83L227.08,79.17l16.67,16.66Z"
        />
        <polygon
          className="cls-1"
          points="135.42 204.17 135.42 204.17 118.75 220.83 135.42 204.17"
        />
      </g>
    </svg>
  );
}
