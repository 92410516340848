export const FETCH_ALL = 'artful-client/HabitTracked/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'artful-client/HabitTracked/FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'artful-client/HabitTracked/FETCH_ALL_FAILURE';

export const FETCH_ONE = 'artful-client/HabitTracked/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'artful-client/HabitTracked/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'artful-client/HabitTracked/FETCH_ONE_FAILURE';

export const CREATE = 'artful-client/HabitTracked/CREATE';
export const CREATE_SUCCESS = 'artful-client/HabitTracked/CREATE_SUCCESS';
export const CREATE_FAILURE = 'artful-client/HabitTracked/CREATE_FAILURE';

export const DELETE = 'artful-client/HabitTracked/DELETE';
export const DELETE_SUCCESS = 'artful-client/HabitTracked/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/HabitTracked/DELETE_FAILURE';
