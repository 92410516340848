/* eslint-disable max-len */
import React from 'react';

export default function HabitSportsIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.402 18.387c.03-.186.019-.377-.03-.559a1.45 1.45 0 0 0-1.77-1.027 1.482 1.482 0 0 0-.638.376c-.017-.064-.041-.126-.058-.187a1.802 1.802 0 0 1-.044-.207.083.083 0 0 1-.007-.027 1.811 1.811 0 0 1-.02-.175.111.111 0 0 1-.008-.03l-.001-.071c-1.05-.725-1.898-1.206-2.235-1.116-1.047.28-1.558 1.164-1.277 2.212.28 1.047 7.69 3.557 9.46 4.575l.443.253.883.512.444.255.884.512c.093.348.187.697.537.603.35-.094.86-.977.766-1.326-.094-.349-.443-.257-.79-.164l-1.328-.766-1.327-.767c-.74-.426-2.195-1.569-3.708-2.74l-.019.004a.935.935 0 0 1-.127.034.419.419 0 0 1-.062.017 1.04 1.04 0 0 0 .035-.177s.002-.013-.003-.015zM11.775 15.187a2.15 2.15 0 0 0-1.59-.912 1.43 1.43 0 0 0 1.59.912z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M7.835 16.362a2.165 2.165 0 0 1 3.309 1.912 2.166 2.166 0 0 0 .95-2.403 2.163 2.163 0 0 1-2.657-1.53 2.165 2.165 0 0 0-1.602 2.02zM24.18 11.818a5.032 5.032 0 0 0-2.774-1.268 4.355 4.355 0 0 0 1.07 2.972l1.704-1.704zM25.858 14.99a5.073 5.073 0 0 0-1.268-2.774l-1.704 1.704a4.395 4.395 0 0 0 2.972 1.07zM22.078 13.932a4.95 4.95 0 0 1-1.244-3.42h-.087a5.107 5.107 0 0 0-3.433 1.306l3.445 3.433 1.319-1.319zM19.043 16.967l1.318-1.318-3.433-3.433c-.895.995-1.318 2.264-1.306 3.52 1.269-.05 2.476.41 3.42 1.231zM25.734 15.562a4.868 4.868 0 0 1-3.259-1.244l-1.318 1.331 3.433 3.433a5.046 5.046 0 0 0 1.306-3.52h-.162zM19.44 17.366a4.949 4.949 0 0 1 1.244 3.42c1.256.012 2.525-.41 3.52-1.306l-3.445-3.433-1.319 1.319zM15.66 16.296a5.027 5.027 0 0 0 1.268 2.785l1.704-1.716a4.335 4.335 0 0 0-2.972-1.07zM17.326 19.48a5.11 5.11 0 0 0 2.786 1.268 4.287 4.287 0 0 0-1.082-2.972l-1.704 1.704zM15.983 8.076c.314-.85.355-1.648.084-2.225-.272-.577-.913-1.054-1.767-1.355a5.22 5.22 0 0 0 1.683 3.58zM5.015 8.863c-.313.85-.354 1.647-.084 2.225.272.577.913 1.053 1.767 1.354a5.222 5.222 0 0 0-1.683-3.58zM13.612 4.307c-1.387-.3-3.14-.187-4.793.59-1.653.778-2.859 2.056-3.512 3.316a5.9 5.9 0 0 1 2.08 4.42c1.385.3 3.139.186 4.791-.59 1.653-.778 2.86-2.056 3.512-3.315a5.902 5.902 0 0 1-2.078-4.421zm-1.299 4.769a.294.294 0 0 1-.392-.142l-.312-.661-.718.338.311.661a.295.295 0 0 1-.534.251l-.31-.661-.72.339.311.66a.295.295 0 1 1-.534.252l-.31-.661-.476.223a.296.296 0 0 1-.392-.141.294.294 0 0 1 .14-.392l.476-.224-.31-.661a.295.295 0 1 1 .533-.251l.31.66.72-.338-.31-.66a.295.295 0 1 1 .534-.252l.31.661.72-.338-.311-.661a.295.295 0 0 1 .533-.251l.311.66.476-.222a.294.294 0 1 1 .25.533l-.475.224.311.66a.295.295 0 0 1-.142.394z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
