import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from '~/Providers/Moment';

import AppFeatureView, { CUSTOM_COLORS } from '~/Components/Global/AppFeatureView';
import Button from '~/Components/Global/Form/Button';
import ColorButtonGrid from '~/Components/Global/ColorPicker/ColorButtonGrid';
import { colors as DEFAULT_COLORS } from '~/Config/Colors';
import { HABIT_CREATE_EDIT_MODAL, HABIT_DELETE_STOP_TRACKING_MODAL } from '~/Config/Modals';
import { createHabit, updateHabit } from '~/Redux/Modules/Habits';
import { closeModal, openModal, setModalData } from '~/Redux/Modules/Modal';
import * as styles from '~/Styles/Colors';

import habitIcons from './HabitIconsLibrary';
import { nextPriority } from './helpers';

import './NewHabitForm.scss';

function NewHabitForm({ user, habit, habits, start }) {
  const dispatch = useDispatch();

  const customColors = useSelector(({ Colors }) => Colors.colors);

  const [selectedIcon, setSelectedIcon] = useState(habit ? habit.icon : '');
  const [selectedColor, setSelectedColor] = useState(habit ? habit.color : '');
  const [editHabitTitle, setEditHabitTitle] = useState(habit ? habit.title : '');
  const [sortedHabits, setSortedHabits] = useState([]);
  const [activeTab, setActiveTab] = useState('icon');
  const [saveClicked, setSaveClicked] = useState(false);

  const startOfMonth = moment(start).startOf('month');

  let isValid = selectedIcon && selectedColor && editHabitTitle.trim() !== '';

  useEffect(() => {
    const sortedHabits = [...habits].sort((a, b) => {
      if (a.priority < b.priority) return -1;
      if (a.priority > b.priority) return 1;

      return 0;
    });

    setSortedHabits(sortedHabits);
  }, [habits]);

  const selectIcon = newIcon => {
    setSelectedIcon(newIcon);
  };

  const selectColor = newColor => {
    setSelectedColor(newColor);
  };

  const handleEditHabitName = ({ target: { value } }) => {
    setEditHabitTitle(value);
  };

  const handleCloseModal = () => {
    dispatch(setModalData({ habit: null }));
    dispatch(closeModal(HABIT_CREATE_EDIT_MODAL));
  };

  const handleSaveHabit = () => {
    const newHabit = {
      id: 'temp-id',
      icon: selectedIcon,
      color: selectedColor,
      title: editHabitTitle,
      start: startOfMonth,
      priority: nextPriority(
        null,
        sortedHabits.length === 0 ? null : sortedHabits[sortedHabits.length - 1]
      ),
      userId: user.id,
      recent_streak: 0,
    };

    dispatch(createHabit(newHabit)).then(response => {
      setSortedHabits([...sortedHabits, response]);
      handleCloseModal();
    });
  };

  const handleEditHabit = () => {
    let habitToUpdate = { ...habit };
    habitToUpdate.title = editHabitTitle;
    habitToUpdate.icon = selectedIcon;
    habitToUpdate.color = selectedColor;

    if (habitToUpdate) {
      dispatch(updateHabit(habitToUpdate)).then(response => {
        setSortedHabits([...sortedHabits, response]);
        handleCloseModal();
      });
    }
  };

  const handleDeleteHabit = (habit, isDelete) => {
    dispatch(setModalData({ habit: habit, isDelete: isDelete }));
    dispatch(openModal(HABIT_DELETE_STOP_TRACKING_MODAL));
    dispatch(closeModal(HABIT_CREATE_EDIT_MODAL));
  };

  return (
    <aside className="habit-form">
      {/* Form Title */}
      <h2 className="habit-form-header">{habit ? 'Edit Habit' : 'Create a Habit'}</h2>

      {/* Habit Name Input */}
      <input
        type="text"
        placeholder="New Habit"
        className="habit-form-title-input"
        onChange={handleEditHabitName}
        value={editHabitTitle}
      />

      {/* Tabs */}
      <div className="habit-form-tabs">
        <div
          className={cn('habit-form-tab', { 'habit-form-tab-active': activeTab === 'icon' })}
          onClick={() => setActiveTab('icon')}
        >
          Icon:{' '}
          {selectedIcon && (
            <div
              className={cn('selected-icon', { selected: selectedIcon })}
              style={{
                fill: selectedColor ? selectedColor : '',
              }}
            >
              {habitIcons[selectedIcon]}
            </div>
          )}
        </div>
        <div
          className={cn('habit-form-tab', { 'habit-form-tab-active': activeTab === 'color' })}
          onClick={() => setActiveTab('color')}
        >
          Color:{' '}
          <div className={cn('selected-color', { selected: selectedColor })}>
            <FontAwesome
              name="circle"
              style={{ color: selectedColor ? selectedColor : 'transparent', fontSize: '2.5rem' }}
            />{' '}
          </div>
        </div>
      </div>

      {/* Tab Content */}
      {/* Icons Section */}
      {activeTab === 'icon' && (
        <section className="habit-form-icon-library">
          {Object.keys(habitIcons).map(iconKey => (
            <div
              key={iconKey}
              className={cn('habit-icon', { selected: selectedIcon === iconKey })}
              role="button"
              tabIndex={0}
              onClick={() => selectIcon(iconKey)}
              style={{
                backgroundColor:
                  selectedIcon === iconKey
                    ? selectedColor
                      ? selectedColor
                      : 'rgb(51, 51, 51)'
                    : 'transparent',
                fill: selectedIcon === iconKey ? 'white' : '',
              }}
            >
              {' '}
              {habitIcons[iconKey]}{' '}
            </div>
          ))}
        </section>
      )}
      {/* Colors Section */}
      {activeTab === 'color' && (
        <section className="habit-form-color-library">
          <ColorButtonGrid
            selectedColor={selectedColor}
            colors={DEFAULT_COLORS}
            onSelect={selectColor}
            circleButtons
            isAdjustable
          />

          <AppFeatureView featureName={CUSTOM_COLORS}>
            <section className="my-colors">
              <h4 className="sub-header">My Colors</h4>
              <ColorButtonGrid
                selectedColor={selectedColor}
                colors={customColors}
                onSelect={selectColor}
                circleButtons
                isGrowable
                isEditable
              />
            </section>
          </AppFeatureView>
        </section>
      )}

      {/* Habit Actions */}
      {habit && (
        <section className="habit-form-habit-action-buttons">
          {/* Tracking Toggle */}
          <div
            className="action-button"
            role="button"
            tabIndex={0}
            onClick={() => handleDeleteHabit(habit, false)}
          >
            <FontAwesome
              className="action-icon"
              name={habit.end !== null ? 'play-circle' : 'stop-circle'}
              style={{ color: styles.habitActionIcon }}
            />
            <span className="action-text">
              {habit.end !== null ? 'Continue Tracking' : 'Stop Tracking'}
            </span>
          </div>
        </section>
      )}

      {/* Warning Message */}
      {saveClicked && !isValid && (
        <div className="center warning-message">
          Please complete all required fields: Title, Icon, and Color.
        </div>
      )}

      <div className="row margin-top">
        {habit ? (
          [
            <div key="a" className="col-xs-6 align-right">
              <Button
                id="save"
                text="SAVE"
                actionText="SAVING"
                className="btn btn-default"
                onClick={() => {
                  setSaveClicked(!saveClicked);
                  if (isValid) {
                    habit ? handleEditHabit() : handleSaveHabit();
                  }
                }}
              />
            </div>,
            <div key="b" className="col-xs-6 align-left">
              <Button
                text="DELETE"
                actionText="DELETING"
                className="btn btn-default"
                onClick={() => handleDeleteHabit(habit, true)}
              />
            </div>,
          ]
        ) : (
          <div className="center">
            <Button
              text="SAVE"
              actionText="SAVING"
              className="btn btn-default"
              onClick={() => {
                setSaveClicked(!saveClicked);
                if (isValid) {
                  habit ? handleEditHabit() : handleSaveHabit();
                }
              }}
            />
          </div>
        )}
      </div>
    </aside>
  );
}

NewHabitForm.propTypes = {
  user: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  habit: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  habits: propTypes.array,
  start: propTypes.object,
};

const mapStateToProps = ({ User, Habits, DateRange }) => {
  return {
    user: User.data,
    habits: Habits.habitCollection.habits,
    start: DateRange.start,
  };
};

export default connect(mapStateToProps)(NewHabitForm);
