/* eslint-disable max-len */
import React from 'react';

export default function HabitStopIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#62tkg0jaha)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 15.003C6 10.035 10.036 6 14.997 6 19.965 6 24 10.035 24 15.003 24 19.965 19.965 24 14.997 24 10.035 24 6 19.964 6 15.003zM19.001 11h-8v8h8v-8z"
          fill={props.fill ? props.fill : '#000'}
          className="fill"
        />
      </g>
      <defs>
        <clipPath id="62tkg0jaha">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
