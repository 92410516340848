import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Button from '~/Components/Global/Form/Button';
import OpenDatePicker from '~/Components/Global/DatePicker/OpenDatePicker';
import moment from '~/Providers/Moment';

import { hideSnooze } from '~/Redux/Modules/SnoozePopup';

import { DATE_FORMAT } from '~/Redux/Constants';
import { MEAL_PREPS } from '~/Redux/Modules/UserOptions/constants';
import { MUST_DO, OTHER, CUSTOM, MONTH_TODO, WEEK_TODO } from '~/Config/Checklists';
import { titlesForSections } from '~/Redux/Modules/UserOptions';

import Select from '../Form/Select';

import './SnoozePopup.scss';

const defaultSectionTitles = {
  [MUST_DO]: 'PRIORITY TASKS',
  [OTHER]: 'OTHER TASKS',
  [CUSTOM]: 'CUSTOM LIST',
  [MONTH_TODO]: 'MONTHLY GOALS',
  [WEEK_TODO]: 'WEEKLY GOALS',
};

const SnoozePopup = () => {
  const { onCancel, show, currentDate, positionRect, changeType, onSnooze } = useSelector(
    ({ SnoozePopup }) => SnoozePopup
  );
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(moment('1993-09-28', DATE_FORMAT));
  const [type, setType] = useState(OTHER);
  const sectionTitles = useSelector(titlesForSections);
  const typeOptions = useRef([]);

  useEffect(() => {
    typeOptions.current = Object.keys(sectionTitles)
      .filter(type => type !== MEAL_PREPS)
      .map(type => ({ id: type, value: sectionTitles[type] || defaultSectionTitles[type] }));
  }, [sectionTitles, defaultSectionTitles]);

  useEffect(() => {
    if (changeType) {
      setSelected(moment(currentDate, DATE_FORMAT));
    } else {
      setSelected(moment('1993-09-28', DATE_FORMAT));
    }
  }, [changeType, currentDate]);

  const handleCancel = () => {
    dispatch(hideSnooze());
    if (onCancel) onCancel();
  };

  const handleOK = () => {
    dispatch(hideSnooze());
    if (onSnooze) onSnooze(selected, type);
    setSelected(moment('1993-09-28', DATE_FORMAT));
  };

  const handleSnooze = snoozeDate => {
    if (changeType) {
      setSelected(snoozeDate);
      return;
    }
    dispatch(hideSnooze());
    if (onSnooze) onSnooze(snoozeDate, type);
    setSelected(moment('1993-09-28', DATE_FORMAT));
  };

  if (!show) return null;
  let style = {};
  if (positionRect) {
    style = {
      ...style,
      position: 'absolute',
      top:
        positionRect.top > window.innerHeight - 250 ? window.innerHeight - 250 : positionRect.top,
      left:
        positionRect.left > window.innerWidth - 125 ? window.innerWidth - 125 : positionRect.left,
      transform: 'translateX(-50%)',
    };
  }
  return (
    <div className="snooze-popup-wrapper" onClick={handleCancel}>
      <div className="snooze-popup" style={style} onClick={e => e.stopPropagation()}>
        <div className="snooze-popup__body">
          <OpenDatePicker
            onChange={handleSnooze}
            selected={selected}
            openToDate={moment(currentDate, DATE_FORMAT)}
          />

          {changeType && (
            <Select
              name="snooze-type"
              className="minimal-input"
              inlineLabel
              onChange={({ target: { value } }) => {
                console.log('SnoozePopup', 'onChange', value);
                setType(value);
              }}
              value={type}
              data={typeOptions.current}
              dataValueKey="id"
              dataLabelKey="value"
              allowPlaceholder={false}
            />
          )}
        </div>

        <div className="snooze-popup__buttons">
          <Button text="CANCEL" className="btn btn-default" onClick={handleCancel} />
          {changeType && (
            <>
              <div className="spacer" />
              <Button text="OK" className="btn btn-default" onClick={handleOK} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

SnoozePopup.propTypes = {
  onCancel: PropTypes.func,
  hideSnooze: PropTypes.func,
  onSnooze: PropTypes.func,
  show: PropTypes.bool,
  currentDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  positionRect: PropTypes.object,
};

export default SnoozePopup;
