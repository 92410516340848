import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { setToday, setDayType } from '~/Redux/Modules/DateRange';
import ClosedApple from '~/Components/Global/Assets/Svgs/ClosedApple';
import FilledWater from '~/Components/Global/Assets/Svgs/FilledWater';
import CheckClosed from '~/Components/Global/Assets/Svgs/CheckClosed';
import Today from '~/Components/Global/Assets/Svgs/Today';
import AppFeatureView, { HABIT_TRACKER } from '~/Components/Global/AppFeatureView';
import HabitIcon from '../Assets/Svgs/HabitIcon';
import './MobileTray.scss';

export class MobileTray extends Component {
  constructor() {
    super();
  }

  handleAnchorClick = hash => {
    const { currentLocation, dayView, currentDate } = this.props;
    if (currentLocation.path !== '/day') {
      return dayView(currentDate, hash);
    }
    return window.location.replace(`${currentLocation.path}#${hash}`);
  };

  handleSetToday = () => {
    this.props.setToday(this.props.currentView);
  };

  render() {
    const { activeTracker } = this.props;
    return (
      <div className="mobile-tray">
        <div className="flex-row">
          <AppFeatureView
            featureName={HABIT_TRACKER}
            disabledView={
              <Link
                onClick={() => this.handleAnchorClick('mobile-water-tracking')}
                to="/day#mobile-water-tracking"
                className="flex-1 tab water"
              >
                <span className="svg-icon">
                  <FilledWater />
                </span>
                <span>Water</span>
              </Link>
            }
          >
            {activeTracker === 'habit' ? (
              <Link
                onClick={() => this.handleAnchorClick('mobile-habit-tracking')}
                to="/day#mobile-habit-tracking"
                className="flex-1 tab habits"
              >
                <span className="svg-icon">
                  <HabitIcon />
                </span>
                <span>Habits</span>
              </Link>
            ) : (
              <Link
                onClick={() => this.handleAnchorClick('mobile-water-tracking')}
                to="/day#mobile-water-tracking"
                className="flex-1 tab water"
              >
                <span className="svg-icon">
                  <FilledWater />
                </span>
                <span>Water</span>
              </Link>
            )}
          </AppFeatureView>
          <Link
            onClick={() => this.handleAnchorClick('mobile-meal-prep')}
            to="/day#mobile-meal-prep"
            className="flex-1 tab meals"
          >
            <span className="svg-icon">
              <ClosedApple />
            </span>
            <span>Meals</span>
          </Link>
          <Link
            onClick={() => this.handleAnchorClick('mobile-todo')}
            to="/day#mobile-todo"
            className="flex-1 tab todo"
          >
            <span className="svg-icon">
              <CheckClosed />
            </span>
            <span>To-do</span>
          </Link>
          <div onClick={this.handleSetToday} className="flex-1 tab today">
            <span className="svg-icon">
              <Today />
            </span>
            <span>Today</span>
          </div>
        </div>
      </div>
    );
  }
}

MobileTray.propTypes = {
  currentDate: propTypes.object,
  currentLocation: propTypes.object,
  dayView: propTypes.func,
  setToday: propTypes.func,
  currentView: propTypes.string,
  activeTracker: propTypes.string,
};

const mapStateToProps = ({ DateRange, Location, DayTrackers }, props) => {
  return {
    currentDate: props.currentDate ? props.currentDate : DateRange.today,
    currentView: DateRange.currentType,
    currentLocation: Location.data,
    activeTracker: DayTrackers.activeTracker,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dayView: (currentDate, anchor) => dispatch(setDayType(currentDate.clone(), anchor)),
    setToday: currentView => dispatch(setToday(currentView)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileTray);
