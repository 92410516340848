import React, { Component } from 'react';
import propTypes from 'prop-types';
import Spinner from '~/Components/Global/Loading/Spinner';

class Button extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    if (this.props.onClick) {
      event.preventDefault();
      this.props.onClick();
    }
  }

  render() {
    const { text, actionText, isFetching, className, disabled, small, type, ...props } = this.props;
    return (
      <button
        {...props}
        type={type}
        className={`btn btn-default ${className} ${small && 'small'}`}
        disabled={disabled || isFetching}
        onClick={e => this.handleClick(e)}
      >
        {isFetching ? (
          actionText ? (
            <span>
              {actionText} <Spinner />
            </span>
          ) : (
            <span>
              {text} <Spinner />
            </span>
          )
        ) : (
          text
        )}
      </button>
    );
  }
}

Button.propTypes = {
  className: propTypes.string,
  disabled: propTypes.bool,
  onClick: propTypes.func,
  small: propTypes.bool,
  text: propTypes.string,
  type: propTypes.string,
  actionText: propTypes.string,
  isFetching: propTypes.bool,
};

Button.defaultProps = {
  children: 'Submit',
  disabled: false,
  className: '',
  small: false,
  type: 'button',
};

export default Button;
