export const OPEN_MODAL = 'artful-client/Modal/OPEN_MODAL';
export const CLOSE_MODAL = 'artful-client/Modal/CLOSE_MODAL';
export const SET_MODAL_DATA = 'artful-client/Modal/SET_MODAL_DATA';

const initialState = {
  modalsOpen: [], // Open and closed modals
  modalData: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, modalsOpen: [...state.modalsOpen, action.modal] };
    case CLOSE_MODAL:
      return { ...state, modalsOpen: state.modalsOpen.filter(m => m != action.modal) };
    case SET_MODAL_DATA:
      return { ...state, modalData: action.data };
    default:
      return state;
  }
}

export const closeModal = modal => dispatch => {
  dispatch({ type: CLOSE_MODAL, modal });
};

export const openModal = modal => dispatch => {
  dispatch({ type: OPEN_MODAL, modal });
};

export const setModalData = data => dispatch => {
  dispatch({ type: SET_MODAL_DATA, data });
};
