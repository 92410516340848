import { taskApi } from '~/Providers/Api';
import { TYPES, MUST_DO, OTHER, CUSTOM, WEEK_TODO, MONTH_TODO } from '~/Config/Checklists';
import { parseErrors } from '~/Redux/Helpers';
import Store from '~/Redux/Store';
import moment from '~/Providers/Moment';
import mParticle, { CreateTaskEvent } from '~/Providers/mParticle';
import { guid } from '~/util';
import { MONTH, WEEK, DAY } from '~/Config/DateRangeTypes';
import { toStartOfWeekForChecklist } from '~/Context/Checklist';
import { error } from '../Notifications';
import {
  FETCH_ALL,
  FETCH_ALL_FAILURE,
  FETCH_ALL_SUCCESS,
  START_UPDATING,
  FINISH_UPDATING,
  SET_QUEUE,
  SET_LAST_UPDATE,
  UPDATE_ITEM,
  UPDATE_SUCCESS,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  RESTORE,
  RESTORE_SUCCESS,
  RESTORE_FAILURE,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  REMOVE_TEMP_UPDATE,
  ADD_TEMP_UPDATE,
  REMOVE_TEMP_DELETE,
  ADD_TEMP_DELETE,
  SHOW_REPEAT_POPUP,
  HIDE_REPEAT_POPUP,
  SET_TASK_ERROR,
} from './constants';
import { identifierForTask } from './helpers';

const fetchAll = (start, end, taskType) => async dispatch => {
  const stamp = moment().unix();
  dispatch({ type: FETCH_ALL, taskType, start, end, stamp });
  try {
    let response = await taskApi.fetchAll({ type: taskType, start, end });
    dispatch({ type: FETCH_ALL_SUCCESS, data: response.data, taskType, start, end, stamp });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_ALL_FAILURE, error: err, taskType, start, end, stamp });
    throw err;
  }
};

export const fetchAllTasks = (start, end, only = TYPES) => async dispatch => {
  try {
    await Promise.all(only.map(type => dispatch(fetchAll(start, end, type))));
  } catch (err) {
    dispatch(error('Error loading tasks', parseErrors(err)));
  }
};

export const deleteTask = data => async dispatch => {
  try {
    dispatch({ type: DELETE, data });
    if (data.id.startsWith('temp-')) {
      dispatch({ type: ADD_TEMP_DELETE, data });
      return;
    }
    let response = await taskApi.delete(data);
    dispatch({ type: DELETE_SUCCESS, data });
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, data });
    dispatch(error('Error deleting task', parseErrors(err)));
  }
};

export const restoreTask = data => async dispatch => {
  try {
    dispatch({ type: RESTORE, data });
    if (data.id.startsWith('temp-')) {
      dispatch({ type: REMOVE_TEMP_DELETE, data });
      return;
    }
    let response = await taskApi.restore(data);
    dispatch({ type: RESTORE_SUCCESS, data: response.data });
    dispatch({ type: SET_LAST_UPDATE, data: response.data, original: data });
    const {
      DateRange: { currentType },
    } = Store.getState();
    if (data.repeat_rule && currentType === WEEK) dispatch(fetchForCurrentView());
    return response;
  } catch (err) {
    dispatch({ type: RESTORE_FAILURE, data });
    dispatch(error('Error restoring task', parseErrors(err)));
  }
};

export const createTask = data => async dispatch => {
  const id = data.tempId || `temp-${guid()}`;
  try {
    dispatch({ type: CREATE, data: { ...data, id } });
    let response = await taskApi.store(data);

    mParticle.logEvent(new CreateTaskEvent());

    dispatch({ type: CREATE_SUCCESS, data: response.data, id });
    const {
      Tasks: { tempUpdates, tempDeletes },
    } = Store.getState();
    if (tempUpdates[id]) {
      dispatch(
        updateTask({
          ...tempUpdates[id],
          id: response.data.id,
          updated_at: response.data.updated_at,
        })
      );
      dispatch({ type: REMOVE_TEMP_UPDATE, data: { id } });
    }
    if (tempDeletes[id]) {
      dispatch(deleteTask(response.data));
      dispatch({ type: REMOVE_TEMP_DELETE, data: { id } });
    }

    return response;
  } catch (err) {
    dispatch({ type: CREATE_FAILURE, data, id });
    dispatch(error('Error deleting task', parseErrors(err)));
  }
};

const taskUpdate = data => dispatch => {
  const {
    Tasks: {
      updates: { lastUpdates },
    },
  } = Store.getState();
  let toUpdate = data;

  const identifier = identifierForTask(data);
  if (
    lastUpdates[identifier] &&
    (!data.updated_at || lastUpdates[identifier].updated_at > data.updated_at)
  ) {
    console.log('taskUpdate - safety valve');
    toUpdate = {
      ...data,
      updated_at: lastUpdates[identifier].updated_at,
    };
  }

  if (
    lastUpdates[data.id] &&
    (!data.updated_at || lastUpdates[data.id].updated_at > data.updated_at)
  ) {
    console.log('taskUpdate - safety valve 2');
    toUpdate = {
      ...data,
      updated_at: lastUpdates[data.id].updated_at,
    };
  }

  console.log(
    `start update – ${identifierForTask(toUpdate)}.${toUpdate.updated_at}`,
    toUpdate,
    lastUpdates
  );

  dispatch({ type: START_UPDATING });
  taskApi
    .update(toUpdate)
    .then(result => {
      if (result && result.data) {
        dispatch({ type: SET_LAST_UPDATE, data: result.data, original: toUpdate });
        dispatch({ type: UPDATE_SUCCESS, data: { ...result.data, confirm: toUpdate.confirm } });
      }
      const {
        DateRange: { currentType },
      } = Store.getState();
      if (currentType === WEEK && (toUpdate.repeats || toUpdate.repeat_rule)) {
        return dispatch(fetchForCurrentView()).then(() => result);
      }
      if (toUpdate.rolloverChanged && toUpdate.auto_rollover > 0) {
        return dispatch(fetchForCurrentView()).then(() => result);
      }
      return result;
    })
    .then(result => {
      const {
        Tasks: {
          updates: { queue },
        },
      } = Store.getState();

      if (!queue.length) {
        dispatch({ type: FINISH_UPDATING });
        return;
      }

      let [nextUpdate, ...rest] = queue;
      dispatch({ type: SET_QUEUE, queue: rest });
      if (result && result.data && nextUpdate.id === result.data.id) {
        nextUpdate = { ...nextUpdate, updated_at: result.data.updated_at };
      }
      dispatch(taskUpdate(mapForUnsnooze(nextUpdate)));
    })
    .catch(err => {
      console.log(`start update – ${identifierForTask(toUpdate)}.${toUpdate.updated_at}`);
      dispatch(error('Task was not updated', err.status === 422 ? err.message : parseErrors(err)));
      dispatch({ type: SET_TASK_ERROR, data: toUpdate });
      dispatch(fetchForCurrentView()).finally(() => {
        dispatch({ type: SET_TASK_ERROR, data: null });
        const {
          Tasks: {
            updates: { queue },
          },
        } = Store.getState();

        let updatedQueue = queue.filter(item => item.id !== data.id);
        if (updatedQueue.length) {
          let [nextUpdate, ...rest] = updatedQueue;
          dispatch({ type: SET_QUEUE, queue: rest });
          dispatch(taskUpdate(mapForUnsnooze(nextUpdate)));
        } else {
          dispatch({ type: SET_QUEUE, queue: [] });
          dispatch({ type: FINISH_UPDATING });
        }
      });
    });
};

export const fetchForCurrentView = () => dispatch => {
  const {
    DateRange: { start, currentType },
  } = Store.getState();
  const fetchTypes = [
    ...(currentType === MONTH ? [MONTH_TODO] : []),
    ...(currentType === WEEK ? [WEEK_TODO, MUST_DO] : []),
    ...(currentType === DAY ? [MUST_DO, OTHER, CUSTOM] : []),
  ];
  let startToUse = start.clone();
  if (currentType === WEEK) {
    startToUse = toStartOfWeekForChecklist(start);
  }
  return dispatch(
    fetchAllTasks(
      moment(startToUse).startOf(currentType),
      moment(start)
        .startOf(currentType)
        .add(1, currentType),
      fetchTypes
    )
  );
};

export const updateTask = data => dispatch => {
  const {
    Tasks: {
      updates: { isUpdating, queue },
    },
  } = Store.getState();

  const toUpdate = { ...data, confirm: data.confirm || 'all' };

  if (toUpdate.id.startsWith('temp-')) {
    dispatch({ type: ADD_TEMP_UPDATE, data: toUpdate });
    return;
  }

  dispatch({
    type: UPDATE_ITEM,
    data: mapForUnsnooze(toUpdate),
  });

  if (isUpdating) {
    // enqueue an update
    console.log(`enqueue – ${identifierForTask(toUpdate)}.${toUpdate.updated_at}`);
    dispatch({
      type: SET_QUEUE,
      queue: [
        ...queue.filter(update => identifierForTask(update) !== identifierForTask(toUpdate)),
        toUpdate,
      ],
    });
  } else {
    // call api
    console.log(`update immediately – ${identifierForTask(toUpdate)}.${toUpdate.updated_at}`);
    dispatch(taskUpdate(mapForUnsnooze(toUpdate)));
  }
};

const mapForUnsnooze = task => {
  if (!task.unsnooze) return task;

  const {
    Tasks: {
      taskCollection: { tasks },
    },
  } = Store.getState();
  const filtered = tasks.filter(t => identifierForTask(t) === identifierForTask(task));
  if (filtered.length) {
    return { ...task, id: filtered[0].id, repeat_rule: filtered[0].repeat_rule };
  }
  return task;
};

export const showRepeatRulePopup = (repeatable, positionRect, onSave, onCancel) => dispatch => {
  dispatch({ type: SHOW_REPEAT_POPUP, repeatable, positionRect, onSave, onCancel });
};

export const hideRepeatRulePopup = () => dispatch => {
  dispatch({ type: HIDE_REPEAT_POPUP });
};
