import React from 'react';
import { connect } from 'react-redux';
import { success, error } from '~/Redux/Modules/Notifications';
import { unsubscribe } from '~/Redux/Modules/Subscription';
import Spinner from '~/Components/Global/Loading/Spinner';

import MyAccountSection from '~/Components/Pages/Account/MyAccountSection';
import { CriticReport } from '~/Components/Pages/Account/CriticReport';

class CancellationReport extends CriticReport {
  constructor(props) {
    super(props);
    this.state.unsubscribing = false;
  }

  createReportAndCancelSubscription = event => {
    this.setState({ unsubscribing: true });
    Promise.all([this.createReport(event, 'Web Cancellation'), this.props.unsubscribe()]).finally(
      () => {
        this.setState({ unsubscribing: false });
        this.props.close();
      }
    );
  };

  render() {
    const { openReport } = this.props;
    return (
      <MyAccountSection header={'Cancel Subscription'}>
        <div className="report-problem">
          <div style={{ textAlign: 'right' }}>
            <a href="#" className="back-btn" onClick={this.props.close}>
              Back
            </a>
          </div>
          <p>
            If you are having a technical issue, we would love the opportunity to help you! Please
            fill out a support ticket{' '}
            <a href="#" className="report-link" onClick={openReport}>
              HERE
            </a>
            .
          </p>
          <form encType="multipart/form-data" id="report_create" onSubmit={this.createReport}>
            <p>
              <label htmlFor="report_description" style={{ textTransform: 'none' }}>
                Still need to cancel your account? Artful Agenda is a new web application, and we
                are working hard to make it great for all users! Please take just a moment to tell
                us why it isn&apos;t working out for you. We greatly appreciate your feedback!
              </label>
              <textarea id="report_description" name="report[description]" rows="5" />
            </p>
            <p>
              To add screenshots or additional information, please check your email and reply to the
              message acknowledging receipt of your request!
            </p>
            <div style={{ marginTop: '2rem' }}>
              {!this.state.submitting && !this.state.unsubscribing && (
                <a
                  href="#"
                  className="send-report"
                  onClick={this.createReportAndCancelSubscription}
                >
                  Cancel Subscription
                </a>
              )}
              {(this.state.submitting || this.state.unsubscribing) && (
                <span>
                  <Spinner /> Cancelling subscription...
                </span>
              )}
            </div>
          </form>
        </div>
      </MyAccountSection>
    );
  }
}

const mapStateToProps = ({ User }) => {
  return {
    user: User.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    success: (title, body) => dispatch(success(title, body)),
    error: (title, body) => dispatch(error(title, body)),
    unsubscribe: () => dispatch(unsubscribe()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancellationReport);
