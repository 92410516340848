import React from 'react';
import PropTypes from 'prop-types';

import './UndoItem.scss';

export const UndoItem = ({ undo }) => {
  const { title, callback } = undo;
  return (
    <div className="undo-item">
      <span className="title">{title}</span>
      <button className="btn btn-primary btn-link" onClick={() => callback()}>
        UNDO
      </button>
    </div>
  );
};

UndoItem.propTypes = {
  undo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
  }).isRequired,
};

export default UndoItem;
