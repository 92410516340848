/* eslint-disable max-len */
import React from 'react';

export default function HabitStretchIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.994 17.184c-.333-1.246-1.946-4.06-1.946-4.06-.824-1.394-1.974-2.196-3.082-2.816 0 0-4.32-2.098-5.691-4.55 0 0-.431-.763-1.236-.368-.684.335-.654.867-.467 1.261.187.395 1.375 2.584 3.676 4.259-2.717 1.649-3.78 6.046-3.78 6.046l-1.656 6.749c-.06.14-.045.327-.007.477.142.567.702.903 1.238.768.417-.105.685-.374.782-.748l2.475-6.711 2.497 6.713c.097.374.364.662.782.767.535.134 1.095-.212 1.238-.777.038-.15.053-.343-.007-.483l-1.655-6.752s-.567-2.538 1.859-3.41c2.372 1.37 3.173 3.865 3.298 4.198.125.332.467.877 1.215.586.746-.291.467-1.149.467-1.149zM20.65 7.709a2.07 2.07 0 1 1-4.139 0 2.07 2.07 0 0 1 4.139 0z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
