export const FETCH = 'artful-client/UserOption/FETCH';
export const FETCH_SUCCESS = 'artful-client/UserOption/FETCH_SUCCESS';
export const FETCH_FAILURE = 'artful-client/UserOption/FETCH_FAILURE';

export const STORE = 'artful-client/UserOption/STORE';
export const STORE_SUCCESS = 'artful-client/UserOption/STORE_SUCCESS';
export const STORE_FAILURE = 'artful-client/UserOption/STORE_FAILURE';

export const UPDATE = 'artful-client/UserOption/UPDATE';
export const UPDATE_SUCCESS = 'artful-client/UserOption/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'artful-client/UserOption/UPDATE_FAILURE';

export const UPDATE_TIMEZONE = 'artful-client/UserOption/UPDATE_TIMEZONE';
export const UPDATE_TIMEZONE_SUCCESS = 'artful-client/UserOption/UPDATE_TIMEZONE_SUCCESS';
export const UPDATE_TIMEZONE_FAILURE = 'artful-client/UserOption/UPDATE_TIMEZONE_FAILURE';

export const UPDATE_FONT = 'artful-client/UserOption/UPDATE_FONT';
export const UPDATE_FONT_SUCCESS = 'artful-client/UserOption/UPDATE_FONT_SUCCESS';
export const UPDATE_FONT_FAILURE = 'artful-client/UserOption/UPDATE_FONT_FAILURE';

export const UPDATE_SKIN = 'artful-client/UserOption/UPDATE_SKIN';
export const UPDATE_SKIN_SUCCESS = 'artful-client/UserOption/UPDATE_SKIN_SUCCESS';
export const UPDATE_SKIN_FAILURE = 'artful-client/UserOption/UPDATE_SKIN_FAILURE';

export const HUMAN_DATE_FORMAT = 'MM/DD/YYYY';
export const HUMAN_DATE_TIME_FORMAT = 'hh:mma on MM/DD/YYYY';
export const HUMAN_TIME_FORMAT = 'hh:mma';

export const MEAL_PREPS = 'MEAL_PREPS';
