import React, { Component } from 'react';
import propTypes from 'prop-types';

import Form from '~/Components/Global/Form/Form';
import HorizontalTextInput from '~/Components/Global/Form/HorizontalTextInput';

class UpdateAccount extends Component {
  componentDidMount = () => {
    this.props.fetchAll();
  };

  render() {
    const { handleUpdateAccount, updateField, fieldsUpdate, isEditing, toggleEditing } = this.props;
    return (
      <Form
        handleSubmit={() => {
          toggleEditing(null, isEditing);
          return handleUpdateAccount({ fieldsUpdate });
        }}
        className={'form-horizontal'}
      >
        <div className="form-title">
          <div
            className="my-account-header"
            style={{ display: 'inline-block', fontSize: '1.25em' }}
          >
            Profile
          </div>
          <div style={{ display: 'inline-block' }}>
            {isEditing ? (
              <button>Save</button>
            ) : (
              <button onClick={e => toggleEditing(e, isEditing)} type="button">
                Edit
              </button>
            )}
          </div>
        </div>
        <HorizontalTextInput
          handleChange={updateField}
          isDisabled={!isEditing}
          label="First name"
          name="name"
          value={fieldsUpdate.name}
        />
        <HorizontalTextInput
          handleChange={updateField}
          isDisabled={!isEditing}
          label="Email address"
          name="email"
          type="email"
          value={fieldsUpdate.email}
        />
      </Form>
    );
  }
}

UpdateAccount.propTypes = {
  fetchAll: propTypes.func,
  handleUpdateAccount: propTypes.func,
  fieldsUpdate: propTypes.object,
  updateField: propTypes.func,
  isEditing: propTypes.bool,
  toggleEditing: propTypes.func,
};

UpdateAccount.defaultProps = {};

export default UpdateAccount;
