import React from 'react';
import './StoreLink.scss';

export default function StoreIcon() {
  return (
    <svg
      className="store-icon"
      version="1.1"
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 18 14.4"
      style={{ enableBackground: 'new 0 0 18 14.4' }}
      xmlSpace="preserve"
    >
      <title>Store</title>
      <path
        d="M17.6,4.6H0.4l2.3-4.4C2.8,0.1,2.9,0,3.1,0h11.7c0.2,0,0.3,0.1,0.4,0.2L17.6,4.6z M1.2,4.1h15.5l-2-3.6H3.1L1.2,4.1z
	 M14.8,0.5C14.8,0.5,14.8,0.5,14.8,0.5L14.8,0.5z"
      />
      <rect
        x="3.3"
        y="2"
        transform="matrix(0.2167 -0.9762 0.9762 0.2167 1.9874 7.0678)"
        width="4.2"
        height="0.5"
      />
      <rect x="8.7" y="0.2" width="0.5" height="4.1" />
      <path
        d="M13,4.6c-0.1,0-0.2-0.1-0.2-0.2l-1-4.1c0-0.1,0.1-0.3,0.2-0.3c0.1,0,0.3,0.1,0.3,0.2l1,4.1C13.3,4.4,13.2,4.6,13,4.6
	C13,4.6,13,4.6,13,4.6z"
      />
      <path d="M17.7,14.4H0.2c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h17.5c0.1,0,0.2,0.1,0.2,0.2S17.8,14.4,17.7,14.4z" />
      <rect x="1.7" y="6.6" width="0.5" height="7.5" />
      <rect x="10.7" y="6.8" width="0.5" height="7.4" />
      <rect x="15.7" y="6.5" width="0.5" height="7.6" />
      <path
        d="M7.4,13.1c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.3,0-0.4l2.1-2.2c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.4L7.6,13
	C7.5,13,7.5,13.1,7.4,13.1z"
      />
      <path
        d="M4.5,12.9c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.3,0-0.4l3.9-4c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.4l-3.9,4
	C4.7,12.9,4.6,12.9,4.5,12.9z"
      />
      <path
        d="M3.4,11c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.3,0-0.4l2.1-2.1c0.1-0.1,0.3-0.1,0.4,0s0.1,0.3,0,0.4l-2.1,2.1
	C3.5,11,3.5,11,3.4,11z"
      />
      <path d="M6.9,6.9c-1.3,0-2.3-1-2.3-2.3h0.5c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8h0.5C9.2,5.8,8.2,6.9,6.9,6.9z" />
      <path d="M11,6.9c-1.3,0-2.3-1-2.3-2.3h0.5c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8h0.5C13.2,5.8,12.2,6.9,11,6.9z" />
      <path d="M2.8,6.9c-1.3,0-2.4-1.1-2.4-2.4h0.5c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9h0.5C5.1,5.9,4.1,6.9,2.8,6.9z" />
      <path d="M15.2,7c-1.3,0-2.4-1.1-2.4-2.4h0.5c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.8,1.9-1.9h0.5C17.6,5.9,16.5,7,15.2,7z" />
      <g>
        <circle cx="12.2" cy="10" r="0.2" />
        <path
          d="M12.2,10.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4C12.6,10.2,12.4,10.4,12.2,10.4z M12.2,9.9
		C12.1,9.9,12.1,9.9,12.2,9.9C12.1,10.1,12.3,10.1,12.2,9.9C12.3,9.9,12.2,9.9,12.2,9.9z"
        />
      </g>
    </svg>
  );
}
