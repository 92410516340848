/* eslint-disable max-len */
import React from 'react';

export default function HabitCatIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.047 20.788V4.725l-2.313 2.313a4.846 4.846 0 0 0-5.012 0L17.41 4.725v6.425a9.624 9.624 0 0 0-9.637 9.638 3.222 3.222 0 0 1-3.213-3.213 3.222 3.222 0 0 1 3.213-3.212c.9 0 1.606-.707 1.606-1.607s-.706-1.606-1.606-1.606c-3.534 0-6.425 2.891-6.425 6.425S4.238 24 7.772 24h19.275c.9 0 1.606-.706 1.606-1.606 0-.9-.706-1.606-1.606-1.606zm-7.228-9.638a.796.796 0 0 1-.803-.803c0-.45.354-.803.803-.803v1.606zm.803 0V9.544c.45 0 .803.354.803.803 0 .45-.354.803-.803.803zm3.212 0a.796.796 0 0 1-.803-.803c0-.45.354-.803.803-.803v1.606zm.804 0V9.544c.449 0 .803.354.803.803 0 .45-.354.803-.803.803z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
