import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArtfulCheckbox from '~/Components/Global/Form/ArtfulCheckbox';
import { updateUserOptions } from '~/Redux/Modules/UserOptions';

import './ToggleCompletedTasksToBottom.scss';

const ToggleCompletedTasksToBottom = () => {
  const userOptions = useSelector(({ UserOptions }) => UserOptions.data);
  const move_completed_tasks_to_bottom = userOptions?.move_completed_tasks_to_bottom ?? true;
  const dispatch = useDispatch();

  const setCompletedTasksToBottom = move_completed_tasks_to_bottom => {
    dispatch(updateUserOptions(userOptions.id, { ...userOptions, move_completed_tasks_to_bottom }));
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  return (
    <li className="toggle-completed-tasks-to-bottom" onClick={handleClick}>
      <span>
        <ArtfulCheckbox
          label="Checked Tasks To Bottom"
          value={move_completed_tasks_to_bottom}
          onChange={() => setCompletedTasksToBottom(!move_completed_tasks_to_bottom)}
          name="completedTasksToBottom"
        />
      </span>
    </li>
  );
};

export default ToggleCompletedTasksToBottom;
