import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { success, error } from '~/Redux/Modules/Notifications';
import Spinner from '~/Components/Global/Loading/Spinner';
import { findUserTimeZone } from '~/Context/Event';
import MyAccountSection from '~/Components/Pages/Account/MyAccountSection';
import Select from '~/Components/Global/Form/Select';
import mParticle, { ScreenViewEvent, SupportRequestEvent } from '~/Providers/mParticle';

export class CriticReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      categoryOptions: [
        {
          value: 'I am unable to sync to Google, Outlook, or iCloud',
          label: 'I am unable to sync to Google, Outlook, or iCloud',
        },
        { value: 'I am unable to access my account', label: 'I am unable to access my account' },
        { value: 'I am getting an error message', label: 'I am getting an error message' },
        { value: 'I need help using a feature', label: 'I need help using a feature' },
        {
          value: "My app isn't performing the way I think it should",
          label: "My app isn't performing the way I think it should",
        },
        {
          value: 'I would like to cancel my subscription',
          label: 'I would like to cancel my subscription',
        },
        {
          value: 'I have a suggestion for Artful Agenda',
          label: 'I have a suggestion for Artful Agenda',
        },
        { value: 'I need help with something else', label: 'I need help with something else' },
      ],
      selectedCategory: '',
    };
  }

  createReport = (event, subject = 'Web Support Request') => {
    let { user } = this.props;
    let { selectedCategory } = this.state;
    event.preventDefault();
    event.stopPropagation();
    let prepend = [
      `User Info: ${user && user.name} (${user && user.email}) ${user && user.id}`,
      `App Info: ${VERSION} / ${BUILD_NUMBER} / ${COMMIT}`,
      `User Agent: ${window.navigator.userAgent}`,
      `Timezone: ${findUserTimeZone()}`,
    ].join('\n');

    if (selectedCategory) {
      subject = `${subject} (Category: ${selectedCategory})`;
    }

    let data = {
      request: {
        requester: {
          name: user && user.name,
          email: user && user.email,
        },
        subject: subject,
        comment: {
          body: `${prepend}\n\n---\n\n${document.getElementById('report_description').value ||
            'BLANK REPORT'}`,
        },
      },
    };

    this.setState({ submitting: true });
    mParticle.logEvent(new SupportRequestEvent(selectedCategory));
    return this.sendReport(
      data,
      (one, two) => {
        this.props.closeReport();
        this.props.success(one, two);
        this.setState({ submitting: false });
      },
      (one, two) => {
        this.props.error(one, two);
        this.setState({ submitting: false });
      }
    );
  };

  sendReport = async (json, onSuccess, onError) => {
    try {
      let response = await fetch('https://artfulagendahelp.zendesk.com/api/v2/requests.json', {
        body: JSON.stringify(json),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      if (window.Rollbar) window.Rollbar.error('Zendesk Report');
      if (response.status >= 400) {
        console.log(response);
        onError(
          'Report not created',
          'Please email hello@artfulagenda.com if you are having trouble.'
        );
      } else {
        onSuccess('Report submitted', 'Thank you for your feedback');
      }
    } catch (err) {
      console.error(err);
      if (window.Rollbar) window.Rollbar.error('Zendesk Report Error');
      onError(
        'Report not created',
        'Please email hello@artfulagenda.com if you are having trouble.'
      );
    }
  };

  render() {
    return (
      <MyAccountSection header={'Report a Problem'}>
        <div className="report-problem">
          <div style={{ textAlign: 'right' }}>
            <a href="#" className="back-btn" onClick={this.props.closeReport}>
              Back
            </a>
          </div>
          <form
            encType="multipart/form-data"
            id="report_create"
            onSubmit={e => this.createReport(e)}
          >
            <p>
              We are here to help! Before submitting a support ticket, please be sure to read our{' '}
              <a className="gold" href="http://www.artfulagenda.com/faq/">
                FAQs
              </a>{' '}
              as many common technical concerns are addressed there.
            </p>
            <p>
              <fieldset>
                <legend>
                  WHAT&apos;S HAPPENING? (We will respond to the email we have on file for you, so
                  please be sure to check it for updates!)
                </legend>
                <label htmlFor="report_category" className="sr-only">
                  Category
                </label>
                <Select
                  id="report_category"
                  name="report[category]"
                  className="minimal-input"
                  allowPlaceholder
                  data={this.state.categoryOptions}
                  placeholder="Select a Category"
                  value={this.state.selectedCategory}
                  onChange={({ target: { value } }) => {
                    this.setState({ selectedCategory: value });
                  }}
                />
                <label
                  htmlFor="report_description"
                  style={{ textTransform: 'none' }}
                  className="sr-only"
                >
                  Description
                </label>
                <textarea id="report_description" name="report[description]" rows="5" />
              </fieldset>
            </p>
            <p>
              To add screenshots or additional information, please check your email and reply to the
              message acknowledging receipt of your request!
            </p>
            <div style={{ marginTop: '2rem' }}>
              {!this.state.submitting && this.state.selectedCategory && (
                <a href="#" className="send-report" onClick={e => this.createReport(e)}>
                  Submit Report
                </a>
              )}
              {this.state.submitting && (
                <span>
                  <Spinner /> Sending report...
                </span>
              )}
            </div>
          </form>
        </div>
      </MyAccountSection>
    );
  }
}

CriticReport.propTypes = {
  user: PropTypes.object,
  closeReport: PropTypes.func,
  success: PropTypes.func,
  error: PropTypes.func,
};

const mapStateToProps = ({ User }) => {
  return {
    user: User.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    success: (title, body) => dispatch(success(title, body)),
    error: (title, body) => dispatch(error(title, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CriticReport);
