import React from 'react';
import propTypes from 'prop-types';
import AppleSignIn from 'react-apple-signin-auth';
import config from '~/Config';
import Spinner from '../Loading/Spinner';

const { apple } = config;

const AppleSignInButton = ({
  actionText,
  buttonText,
  disabled,
  isFetching,
  onSuccess,
  onError,
}) => {
  const renderChildren = () => {
    if (isFetching) {
      return (
        <span>
          {actionText} <Spinner />
        </span>
      );
    }
    return <span>{buttonText}</span>;
  };
  return (
    <AppleSignIn
      authOptions={{
        clientId: apple.clientId,
        scope: 'email name',
        redirectURI: apple.redirectUri,
        usePopup: true,
      }}
      uiType="light"
      className="apple-signin-button"
      buttonExtraChildren={renderChildren()}
      onSuccess={response => {
        console.log('onSuccess', JSON.stringify(response));
        onSuccess(response);
      }}
      onError={error => {
        console.log('onError', error);
        onError(error);
      }}
      disabled={isFetching || disabled}
    />
  );
};

AppleSignInButton.propTypes = {
  actionText: propTypes.string,
  buttonText: propTypes.string.isRequired,
  disabled: propTypes.bool,
  isFetching: propTypes.bool,
  onSuccess: propTypes.func,
  onError: propTypes.func,
};

export default AppleSignInButton;
