/* eslint-disable max-len */
import React from 'react';

export default function HabitFastFoodIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.377 6.475h-.509l.449-3.283c.09-.648.577-1.17 1.217-1.301l2.64-.54.103.492-2.64.541c-.43.09-.761.44-.822.877l-.438 3.214zm2.336 7.818c-.168.18-.124.028.218 4.513l-.006.087c-.989-.383-1.44-.291-4.713-.291-3.272 0-3.631-.084-4.6.29l-.627-8.72h10.568c-.174 2.407-.147 2.234-.174 2.234a.393.393 0 0 0-.393.393c0 1.992.079 1.113-.273 1.491v.003zm-2.493-.845c-1.365-2.465-4.364-1.21-4.364 1.865 0 2.525 2.152 4.044 3.747 2.646 1.144-1.003 1.428-3.045.617-4.509v-.002zM4.192 7.27v2.116H16.35v-1.73c0-.104-.005-.386-.005-.386H4.192zm7.212 6.346c-.286-.413-.709-.725-1.21-.688-1.635.118-2.178 3.411-.62 4.532 1.607 1.152 3.147-1.945 1.83-3.845zm4.372-.423v.97c.945 0 .976-.007 1.15.017v-.987h-1.15zm3.086 2.829v-4.816h-1.15v3.304c.615.465.452.874 1.15 1.512zm1.937.695v-3.862h-1.15v3.663c.436.178.743.2 1.15.2zm1.936-.2v-5.883h-1.15v6.083c.392 0 .704-.018 1.15-.2zm1.937-2.007c0-3.062-.068-3.007.197-3.162V9.467h-1.15v6.124h.179c.312-.446.302-.724.774-1.08zm1.936-.346v-2.086H25.46v2.102c.176-.024.33-.016 1.15-.016zm-9.09 1.317a.907.907 0 0 0-.59-.498c-.163-.042-.108-.032-1.493-.032l.333 4.372a4.263 4.263 0 0 1 1.895 3.543c0 .475.023.703-.105 1.05a1.35 1.35 0 0 1 .06 2.385c.15.654-.068 1.606-.719 2.302.302.162.008.11 8.172.11.254 0 .847-.03.847-.03s.031-.588.048-.8l.99-12.929c-1.205 0-1.728-.2-2.14.64-.561 1.142-1.684 1.913-3.227 1.913h-.948c-1.343 0-2.574-.8-3.12-2.02l-.003-.006zM5.702 28.713h9.134c1.347 0 2.213-1.305 2.01-2.226h-2.532l-.451.67c-.486.721-1.58.721-2.066 0l-.45-.67H3.69c-.204.927.66 2.226 2.01 2.226zm-1.919-4.95h12.972c.141-.26.113-.422.113-.897a3.453 3.453 0 0 0-1.446-2.816c-1.145-.829-1.824-.661-5.317-.661-3.028 0-3.508-.102-4.435.333-1.236.583-2 1.81-2 3.144 0 .467-.028.638.113.898zM16.938 25.7a.575.575 0 0 0 .575-.575.588.588 0 0 0-.575-.574h-1.317l-.777 1.15h2.094zM3.432 24.58a.572.572 0 0 0-.155 1.02c.23.158.026.1 7.534.1l-.777-1.15c-6.6 0-6.463-.015-6.602.03zm9.394 2.33c.32 0 .229.031 1.78-2.265h-3.559c1.493 2.21 1.438 2.265 1.78 2.265z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
