/* eslint-disable max-len */
import React from 'react';

export default function HabitSweetsIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.245 15.91 1.563 26.59c-.42.42-.663.635-.663.635L2.363 28.8s.517-.486.717-.689L13.692 17.5a10.07 10.07 0 0 1-1.448-1.589zM26.232 4.107a8.644 8.644 0 0 0-12.226 0c-3.377 3.377-3.375 8.85 0 12.227A8.646 8.646 0 1 0 26.232 4.107zm-.855 9.64a5.542 5.542 0 0 1-3.947 1.633 4.535 4.535 0 0 1-3.232-1.337 4.54 4.54 0 0 1-1.34-3.233c0-1.005.391-1.95 1.103-2.662a3.74 3.74 0 0 1 2.662-1.102c1.666-.002 3.12 1.452 3.12 3.12a2.606 2.606 0 0 1-2.6 2.6c-1.172 0-2.19-1.02-2.19-2.19a.533.533 0 1 1 1.068.002c0 .6.521 1.121 1.122 1.121a1.54 1.54 0 0 0 1.535-1.536c-.002-1.094-.957-2.05-2.054-2.05-.72 0-1.397.279-1.908.79a2.685 2.685 0 0 0-.79 1.908c0 1.871 1.633 3.506 3.504 3.505a4.487 4.487 0 0 0 3.194-1.323 4.485 4.485 0 0 0 1.322-3.192 5.732 5.732 0 0 0-1.692-4.085 5.726 5.726 0 0 0-3.124-1.61c-1.816-.253-3.799.373-5.276 1.849-2.385 2.385-2.555 6.096-.379 8.272a.534.534 0 0 1-.753.753c-2.59-2.591-2.422-6.977.378-9.778 1.376-1.376 3.136-2.116 4.876-2.206a.508.508 0 0 1 .193-.036 6.96 6.96 0 0 1 1.163.099 6.291 6.291 0 0 1 3.547 1.765.53.53 0 0 1 .063.077c.022.022.046.04.065.062a6.79 6.79 0 0 1 2.004 4.838 5.534 5.534 0 0 1-1.634 3.945z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
