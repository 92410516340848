import React, { useRef, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import { getPricing } from '~/Config/Stripe';
import AccountSvg from '~/Components/Global/Assets/Svgs/Account';
import StoreLink from '~/Components/Global/Assets/Svgs/StoreIcon/StoreLink.js';
import { WEEK, DAY } from '~/Config/DateRangeTypes';
import CalendarsRoute from '~/Containers/Layout/ApplicationNavigation/RouteSections/Calendars/CalendarsRoute';
import Styles from '~/Containers/Layout/ApplicationNavigation/RouteSections/Styles/Styles';
import ViewHeader from '~/Containers/Pages/Calendar/ViewHeader/ViewHeader';
import history from '~/Providers/History';
import { useMountEffect } from '~/util';

import { useManageSubscription } from '~/Components/ManageSubscription';
import StarburstOval from '~/Components/Global/Assets/Svgs/StarburstOval';
import { featureEnabled } from '~/Redux/Modules/FeatureFlags';
import { FLAG_ZENDESK_BOT } from '~/Redux/Modules/FeatureFlags/constants';
import { getCalendarAccounts } from '~/Redux/Modules/CalendarAccounts';
import DropDown from './DropDown';
import NavItem from './NavItem';
import { Search } from './Search';

import './ApplicationNavigation.scss';

const MainLogo = require('./MainLogo.png');
const MobileLogo = require('./MobileLogo.png');

function ApplicationNavigation({
  className,
  user,
  currentMonth,
  start,
  end,
  location,
  currentType,
  monthView,
  onClickMyAccount,
  onClickReportProblem,
  isOverlayModalOpen,
  useIntlDates,
  isAuthenticated,
}) {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileCalendarOpen, setMobileCalendarOpen] = useState(false);
  const manageSubscription = useManageSubscription();
  const isFetching = useSelector(({ User }) => User.isFetching);
  const accountDropdown = useRef(null);
  const [hasChatted, setHasChatted] = useState(false);
  const isZendeskBotEnabled = useSelector(state => featureEnabled(state, FLAG_ZENDESK_BOT));
  const dispatch = useDispatch();

  useMountEffect(() => {
    window?.zE?.('messenger', 'logoutUser');
  });

  const startNewChat = () => {
    window.zE?.('messenger', 'logoutUser');
    window.zE?.('messenger', 'open');
    setHasChatted(true);
  };

  const openChat = () => {
    window.zE?.('messenger', 'open');
    setHasChatted(true);
  };

  const pricingPlans = useCallback(getPricing(), []);

  const activeSubscription =
    user?.subscriptions?.find(
      subscription => !subscription.ends_at || new Date(subscription.ends_at) > new Date()
    ) || {};

  const activePlan = Object.values(pricingPlans).find(
    ({ plan }) => plan == (activeSubscription.stripe_plan ?? activeSubscription.stripe_price)
  );

  const isUpgrade = activePlan != pricingPlans?.yearlyPlus;

  useMountEffect(() => {
    window.addEventListener('flutter.openMyAccount', handleOpenMyAccount);
    window.addEventListener('flutter.openReportAProblem', handleOpenReportAProblem);
    window.addEventListener('native.navigation', handleNativeNavigation);
    window.addEventListener('native.openAccountDropdown', handleOpenAccountDropdown);

    return () => {
      window.removeEventListener('flutter.openMyAccount', handleOpenMyAccount);
      window.removeEventListener('flutter.openReportAProblem', handleOpenReportAProblem);
      window.removeEventListener('native.navigation', handleNativeNavigation);
      window.removeEventListener('native.openAccountDropdown', handleOpenAccountDropdown);
    };
  });

  function handleOpenMyAccount() {
    console.log('handleOpenMyAccount');
    onClickMyAccount();
  }

  function handleOpenReportAProblem() {
    console.log('handleOpenReportAProblem');
    onClickReportProblem();
  }

  function handleNativeNavigation({ path }) {
    console.log('handleNativeNavigation', path);
    history.push(path);
  }

  function handleOpenAccountDropdown() {
    console.log('handleOpenAccountDropdown');
    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(accountDropdown.current)
      ?.querySelector('.dropdown-toggle')
      ?.click?.();
  }

  function handleArtfulPlus() {
    manageSubscription();
  }

  function handleGoHome() {
    dispatch(getCalendarAccounts());
    monthView(currentMonth);
    history.push('/month');
  }

  function getNumWeeks() {
    const startDate = start
      .clone()
      .startOf('month')
      .startOf('week');
    const endDate = start
      .clone()
      .endOf('month')
      .startOf('week')
      .add(1, 'week');

    return endDate.diff(startDate, 'weeks');
  }

  function renderMonthDay() {
    const separator = useIntlDates ? '' : ',';

    if (currentType === WEEK && useIntlDates && start.month() !== end.month()) {
      return (
        <span className="navbar-current__day">
          {start.format('D MMM')}-{end.format('D MMM')}
        </span>
      );
    }

    const month = (
      <span className="navbar-current__month">
        {currentType === WEEK ? start.format('MMM') : start.format('MMMM')}
      </span>
    );

    const day = (
      <span className="navbar-current__day">
        {currentType === WEEK
          ? `${start.format('D')}-${
              start.month() === end.month() ? end.format('D') : end.format('MMM D')
            }${separator}`
          : ''}
        {currentType === DAY ? `${start.format('D')}${separator}` : ''}
      </span>
    );

    return useIntlDates ? (
      <>
        {day}
        {month}
      </>
    ) : (
      <>
        {month}
        {day}
      </>
    );
  }

  return (
    <nav
      className={`navbar navbar-default navbar-static-top${isOverlayModalOpen ??
        ' navbar-inverse'}${mobileCalendarOpen &&
        ' calendar-open'} weeks-${getNumWeeks()} ${className}`}
    >
      <header className="navbar-header">
        <button
          className="navbar-toggle collapsed"
          type="button"
          data-target="#app-navbar-collapse"
          data-toggle="collapse"
          onClick={() => setMobileNavOpen(state => !state.mobileNavOpen)}
        >
          <span className="sr-only">Toggle Navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>

        {/* Brand Logo */}
        <div className="navbar-brand" onClick={handleGoHome}>
          <img src={MainLogo} className="tablet" />
          <img src={MobileLogo} className="mobile" />
        </div>

        <div
          className="navbar-current"
          role="button"
          tabIndex={0}
          onClick={() => setMobileCalendarOpen(state => !state.mobileCalendarOpen)}
        >
          {renderMonthDay()}
          <span className="navbar-current__year">{start.format('YYYY')}</span>
        </div>
      </header>

      <div id="app-navbar-collapse" className={`navbar-collapse${!mobileNavOpen && ' collapse'}`}>
        <ul className="nav navbar-nav navbar-right">
          <NavItem label="Login" path="/login" unAuth />
          <NavItem label="Register" path="/register" unAuth />

          {isAuthenticated && (
            <li>
              <Search />
            </li>
          )}

          <CalendarsRoute />
          <Styles />

          {isAuthenticated && (
            <li>
              <StoreLink />
            </li>
          )}

          <DropDown auth svg={AccountSvg} ref={accountDropdown}>
            {user && user.isAdmin && <NavItem auth label="Admin" path="/admin/dashboard" />}

            <NavItem auth label="My Account">
              <NavLink to={{ state: { from: location } }} onClick={onClickMyAccount}>
                My Account
              </NavLink>
            </NavItem>

            {window.zE && isZendeskBotEnabled && (
              <NavItem auth label="Ask A Question">
                <a role="button" tabIndex="0" onClick={() => startNewChat()}>
                  Ask a Question
                </a>
              </NavItem>
            )}

            {window.zE && isZendeskBotEnabled && hasChatted && (
              <NavItem auth label="Continue Chat">
                <a role="button" tabIndex="0" onClick={() => openChat()}>
                  Continue Chat
                </a>
              </NavItem>
            )}

            <NavItem auth label="Tech Support">
              <NavLink to={{ state: { from: location } }} onClick={onClickReportProblem}>
                Tech Support
              </NavLink>
            </NavItem>

            {isUpgrade && !isFetching && (
              <NavItem auth label="Artful Plus" className="artful-plus-nav-item">
                <NavLink
                  to={{ state: { from: location } }}
                  onClick={handleArtfulPlus}
                  className="artful-plus-nav-link"
                >
                  Artful Plus
                  <div className="artful-plus-new-icon-div" style={{ position: 'relative' }}>
                    <StarburstOval className="artful-plus-new-icon" id="artful-plus-new-icon" />
                    <div className="artful-plus-new-text">NEW</div>
                  </div>
                </NavLink>
              </NavItem>
            )}

            <NavItem auth label="Logout" path="/logout" />
          </DropDown>
        </ul>
      </div>

      <div
        id="app-mobile-calendar-collapse"
        className={`navbar-mobile-calendar${!mobileCalendarOpen && ' collapse'}`}
      >
        <ViewHeader showOnDayView />
      </div>
    </nav>
  );
}

ApplicationNavigation.propTypes = {
  className: propTypes.string,
  user: propTypes.object,
  currentMonth: propTypes.object,
  start: propTypes.string,
  end: propTypes.string,
  location: propTypes.string,
  currentType: propTypes.string,
  monthView: propTypes.func,
  onClickMyAccount: propTypes.func,
  onClickReportProblem: propTypes.func,
  isOverlayModalOpen: propTypes.bool,
  useIntlDates: propTypes.bool,
  isAuthenticated: propTypes.bool,
};

export default ApplicationNavigation;
