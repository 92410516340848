import { droppedStickersApi } from '~/Providers/Api';
import {
  MONTH_VIEW,
  MONTH_VIEW_OUTSIDE,
  WEEK_VIEW,
  WEEK_VIEW_OUTSIDE,
  DAY_VIEW,
  DAY_VIEW_OUTSIDE,
} from '~/Config/CalendarViews';
import { error } from '~/Redux/Modules/Notifications';
import { parseErrors, randomString } from '~/Redux/Helpers';

import {
  updateDateState,
  filterDateState,
  insertDateState,
  uniqueMergeState,
  changeStateDate,
} from '~/Context/Global';

const FETCH = 'artful-client/DroppedStickers/FETCH';
const FETCH_SUCCESS = 'artful-client/DroppedStickers/FETCH_SUCCESS';
const FETCH_FAILURE = 'artful-client/DroppedStickers/FETCH_FAILURE';

const DROP = 'artful-client/DroppedStickers/DROP';
const DROP_SUCCESS = 'artful-client/DroppedStickers/DROP_SUCCESS';
const DROP_FAILURE = 'artful-client/DroppedStickers/DROP_FAILURE';

const MOVE = 'artful-client/DroppedStickers/MOVE';
const MOVE_SUCCESS = 'artful-client/DroppedStickers/MOVE_SUCCESS';
const MOVE_FAILURE = 'artful-client/DroppedStickers/MOVE_FAILURE';

const DELETE = 'artful-client/DroppedStickers/DELETE';
const DELETE_SUCCESS = 'artful-client/DroppedStickers/DELETE_SUCCESS';
const DELETE_FAILURE = 'artful-client/DroppedStickers/DELETE_FAILURE';

const CHANGE_START = 'artful-client/DroppedStickers/CHANGE_START';
const CHANGE_END = 'artful-client/DroppedStickers/CHANGE_END';

const initialState = {
  isFetching: false,
  data: {},
  error: '',
  isChanging: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: uniqueMergeState(state, action.data),
      };
    case FETCH_FAILURE:
      return { ...state, isFetching: false };
    case DROP:
      return {
        ...state,
        isFetching: true,
        data: {
          ...state.data,
          ...insertDateState(state, action.date, action.tempSticker),
        },
      };
    case DROP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...updateDateState(state, action.data.date, s =>
            s.id === action.tempId ? action.data : s
          ),
        },
      };
    case DROP_FAILURE:
      return { ...state, isFetching: false };

    case MOVE:
      return {
        ...state,
        isFetching: true,
        data: {
          ...state.data,
          ...changeStateDate(
            state,
            action.data,
            action.data.date,
            action.date,
            s => s.id !== action.data.id
          ),
        },
      };
    case MOVE_SUCCESS:
      return { ...state, isFetching: false };
    case MOVE_FAILURE:
      return { ...state, isFetching: false };

    case DELETE:
      return {
        ...state,
        isFetching: true,
        data: {
          ...state.data,
          ...filterDateState(state, action.data.date, s => s.id !== action.data.id),
        },
      };
    case DELETE_SUCCESS:
      return { ...state, isFetching: false };
    case DELETE_FAILURE:
      return { ...state, isFetching: false };

    case CHANGE_START:
      return { ...state, isChanging: true };
    case CHANGE_END:
      return { ...state, isChanging: false };

    default:
      return state;
  }
}

export const getDroppedStickers = (start, end) => async dispatch => {
  dispatch({ type: FETCH });
  try {
    let response = await droppedStickersApi.fetch({ start, end });
    dispatch({ type: FETCH_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE, error: err });
  }
};

export const dropSticker = (data, date, view) => async dispatch => {
  const tempId = randomString();
  const tempSticker = {
    id: tempId,
    temp: true,
    position_top: data.position_top,
    position_left: data.position_left,
    position_bottom: data.position_bottom,
    position_right: data.position_right,
    position_version: data.position_version,
    center_x: data.center_x,
    center_y: data.center_y,
    calendarViewName: view,
    url: data.url,
    scale: data.scale,
  };

  const request = {
    position_top: data.position_top,
    position_left: data.position_left,
    position_bottom: data.position_bottom,
    position_right: data.position_right,
    position_version: data.position_version,
    center_x: data.center_x,
    center_y: data.center_y,
    sticker_id: data.pivot.sticker_id,
    sticker_pack_id: data.pivot.sticker_pack_id,
    date: date,
    calendar_view: view,
    scale: data.scale,
  };
  dispatch({ type: DROP, tempSticker, date });
  try {
    let response = await droppedStickersApi.drop(request);
    dispatch({ type: DROP_SUCCESS, data: response.data, tempId });
    return response;
  } catch (err) {
    dispatch({ type: DROP_FAILURE, error: err });
    dispatch(error('Sticker not added', parseErrors(err)));
  }
};

//this is essentially the same as the moveSticker function minus the date, I wanted this because I could alter this pone without
//messing with the moveSticker function. As long as it is a PUT request thats really all that matters

export const resizeSticker = (data, date) => async dispatch => {
  dispatch({ type: MOVE, data, date });
  try {
    let response = await droppedStickersApi.move({ ...data, date: date });
    dispatch({ type: MOVE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: MOVE_FAILURE, error: err });
  }
};

//to get all the correct data within the moveSticker function we need this constant map to take in a constant and return the correct id
const viewIdsForNames = {
  [MONTH_VIEW]: 1,
  [WEEK_VIEW]: 2,
  [DAY_VIEW]: 3,
  [MONTH_VIEW_OUTSIDE]: 4,
  [WEEK_VIEW_OUTSIDE]: 5,
  [DAY_VIEW_OUTSIDE]: 6,
};
export const moveSticker = (data, date, view) => async dispatch => {
  //when we move stickers we need to see if the view has been changed to outside drop target or inner drop target
  //and account for that. I have tried only altering the calendar_view_id however it still will not function properly
  //all information has to be altered
  data = {
    ...data,
    calendar_view_id: viewIdsForNames[view],
    calendar_view: { id: viewIdsForNames[view], name: view },
    calendarViewName: view,
  };

  dispatch({ type: MOVE, data, date });
  try {
    let response = await droppedStickersApi.move({ ...data, date: date });
    dispatch({ type: MOVE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: MOVE_FAILURE, error: err });
  }
};

export const deleteSticker = data => async dispatch => {
  dispatch({ type: DELETE, data });
  try {
    let response = await droppedStickersApi.delete(data.id);
    dispatch({ type: DELETE_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: DELETE_FAILURE, error: err });
  }
};

export const changeStarted = () => dispatch => {
  dispatch({ type: CHANGE_START });
};

export const changeEnded = () => dispatch => {
  dispatch({ type: CHANGE_END });
};
