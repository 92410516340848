/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';
import propTypes from 'prop-types';

export default function Edit({ className, title }) {
  const defaultTitle = 'Styling';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" className={className}>
      <title>{title || defaultTitle}</title>
      <g id="Edit">
        <path
          className="cls-1"
          d="M107.05,299.8H0v-107L181.84,10.94c14.34-14.32,39.43-14.32,53.77,0l53.27,53.25a38.08,38.08,0,0,1,0,53.79ZM17,282.83H83l-66-66Zm3.51-86.54,83,83L245.69,137.14l-16.54-16.53L112.37,237.38l-12-12L217.14,108.59,191.22,82.68,74.43,199.45l-12-12L179.21,70.67,162.67,54.13ZM174.68,42.12l83,83L276.87,106a21.06,21.06,0,0,0,0-29.75L223.59,23C215.68,15,201.77,15,193.86,23Z"
        />
      </g>
    </svg>
  );
}

Edit.propTypes = {
  className: propTypes.string,
  title: propTypes.string,
};

Edit.defaultProps = {
  className: '',
};
