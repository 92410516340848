import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '~/Components/Global/Form/Button';
import Checkbox from '~/Components/Global/Form/Checkbox';

import { hideConfirm } from '~/Redux/Modules/EventConfirmation';

import './EventConfirmation.scss';

export const THIS_EVENT = 'this_event';
export const FOLLOWING_EVENTS = 'following_events';
export const ALL_EVENTS = 'all_events';

class EventConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: THIS_EVENT,
    };
  }

  handleChange = change => {
    this.setState({ selected: change });
  };

  handleCancel = () => {
    const { onCancel, hideConfirm } = this.props;
    hideConfirm();
    if (onCancel) onCancel();
    this.setState({ selected: THIS_EVENT });
  };

  handleConfirm = () => {
    const { onConfirm, hideConfirm } = this.props;
    const { selected } = this.state;
    hideConfirm();
    if (onConfirm) onConfirm(selected);
    this.setState({ selected: THIS_EVENT });
  };

  render() {
    const { heading, model } = this.props;
    const { selected } = this.state;
    if (!heading) {
      return null;
    }
    return (
      <div className="event-confirmation-wrapper">
        <div className="event-confirmation">
          <h1 className="event-confirmation__heading">{heading}</h1>

          <div className="event-confirmation__body">
            <Checkbox
              type="radio"
              label={`This ${model}`}
              name="this_event"
              value={selected === THIS_EVENT}
              onChange={() => this.handleChange(THIS_EVENT)}
            />
            <Checkbox
              type="radio"
              label={`This and Following ${model}s`}
              name="following_events"
              value={selected === FOLLOWING_EVENTS}
              onChange={() => this.handleChange(FOLLOWING_EVENTS)}
            />
            <Checkbox
              type="radio"
              label={`All ${model}s`}
              name="all_events"
              value={selected === ALL_EVENTS}
              onChange={() => this.handleChange(ALL_EVENTS)}
            />
          </div>

          <div className="event-confirmation__buttons">
            <Button text="CANCEL" className="btn btn-default" onClick={this.handleCancel} />
            <Button
              text="OK"
              actionText="SAVING"
              className="btn btn-default"
              onClick={this.handleConfirm}
            />
          </div>
        </div>
      </div>
    );
  }
}

EventConfirmation.propTypes = {
  onCancel: PropTypes.func,
  hideConfirm: PropTypes.func,
  onConfirm: PropTypes.func,
  heading: PropTypes.string,
  model: PropTypes.string,
};

const mapStateToProps = ({ EventConfirmation }) => {
  return { ...EventConfirmation };
};

const mapDispatchToProps = dispatch => {
  return {
    hideConfirm: () => dispatch(hideConfirm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventConfirmation);
