import React, { Component } from 'react';
import propTypes from 'prop-types';
import ReactModal from 'react-modal';

import './Modal.scss';

class Modal extends Component {
  componentDidMount = () => {
    if (this.props.startOpen) {
      this.props.openModal(this.props.id);
    }
  };

  handleCloseModal = () => {
    this.props.onClose?.();
    this.props.closeModal(this.props.id);
  };

  modalOpen = () => {
    const { modalsOpen, id } = this.props;
    return modalsOpen.find(m => m == id) != undefined;
  };

  componentWillReceiveProps = nextProps => {
    if (this.modalOpen() && !nextProps.modalsOpen.find(m => m == this.props.id)) {
      console.log('modalDidClose');
      if (this.props.callback) this.props.callback();
    }
  };

  render() {
    const {
      children,
      fullScreen,
      afterOpenModal,
      label,
      showCloseButton,
      displayOverlay,
      overlayClassName,
      buttons,
    } = this.props;
    return (
      <ReactModal
        className={`react-modal ${fullScreen && 'full-screen'} ${this.props.id}`}
        contentLabel={label}
        isOpen={this.modalOpen()}
        onAfterOpen={afterOpenModal}
        onRequestClose={this.handleCloseModal}
        overlayClassName={`react-modal-overlay ${overlayClassName} ${
          displayOverlay ? 'fade-overlay' : ''
        }`}
        appElement={document.getElementById('artful-client')}
      >
        <div className="modal-top-buttons">
          {buttons}
          {showCloseButton && (
            <span className="react-modal-close" onClick={this.handleCloseModal}>
              X
            </span>
          )}
        </div>
        <div className="clearfix" />
        <div className="react-modal-content container-fluid">{children}</div>
      </ReactModal>
    );
  }
}

Modal.defaultProps = {
  label: '',
  fullScreen: false,
  startOpen: false,
  showCloseButton: true,
  displayOverlay: false,
  closeByEsc: true,
};

Modal.propTypes = {
  id: propTypes.oneOfType([propTypes.number, propTypes.string]),
  label: propTypes.oneOfType([propTypes.number, propTypes.string]),
  children: propTypes.oneOfType([propTypes.element, propTypes.node]),
  fullScreen: propTypes.bool,
  afterOpenModal: propTypes.func,
  modalsOpen: propTypes.array,
  startOpen: propTypes.bool,
  showCloseButton: propTypes.bool,
  openModal: propTypes.func,
  closeModal: propTypes.func,
  displayOverlay: propTypes.bool,
  callback: propTypes.func,
  overlayClassName: propTypes.string,
  buttons: propTypes.oneOfType([propTypes.element, propTypes.node]),
  onClose: propTypes.func,
};

export default Modal;
