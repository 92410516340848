import set from 'lodash.set';

// Helper function for showing validation errors
export const parseErrors = (response, anyModalsOpen) => {
  if (response.status == 412) {
    return anyModalsOpen
      ? 'Sync issue. Please close the modal and try again.'
      : 'Sync issue. Please try again.';
  }

  if (response.status !== 422)
    return response.errors
      ? response.errors.message
      : response.message
      ? response.message
      : 'Bad Request';

  if (response.detail instanceof Array) return '';
  return Object.values(response.detail)
    .map(errors => {
      return errors.join(', and ');
    })
    .join(' ');
};

export const reduxUpdate = (state, update) => {
  const clonedState = { ...state };
  Object.keys(update).map(key => {
    set(clonedState, key, update[key]);
  });
  return clonedState;
};

export const randomString = (length = 32) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};
