import React from 'react';
import propTypes from 'prop-types';
import './Card.scss';

const MainLogo = require('~/Components/Layout/ApplicationNavigation/MainLogo.png');

const Card = ({ children, cardCaption, noLogo, className, customLogo }) => (
  <div className={`card layered ${className}`}>
    {cardCaption && <div className="card-caption">{cardCaption}</div>}
    <div className="card-body login">
      {!noLogo && !customLogo && (
        <div className="logo-wrapper">
          <img className="logo" src={MainLogo} alt="" />
        </div>
      )}
      {customLogo && <div className="logo-wrapper">{customLogo}</div>}

      {children}
    </div>
  </div>
);

Card.propTypes = {
  children: propTypes.node,
  cardCaption: propTypes.string,
  noLogo: propTypes.bool,
  className: propTypes.string,
  customLogo: propTypes.node,
};

export default Card;
