import { connect } from 'react-redux';

import CalendarsRoute from '~/Components/Layout/ApplicationNavigation/RouteSections/Calendars/CalendarsRoute';
import {
  toggleCalendarStatus,
  syncCalendarAccounts,
  changeColor,
} from '~/Redux/Modules/CalendarAccounts';
import { getUserLists, setCurrent } from '~/Redux/Modules/UserLists';

const mapStateToProps = ({ CalendarAccounts, DateRange, Location }) => {
  return {
    accounts: CalendarAccounts.data,
    togglingCalendars: CalendarAccounts.togglingCalendars,
    start: DateRange.start,
    currentType: DateRange.currentType,
    path: Location.data.path,
    isSyncing: CalendarAccounts.isSyncing,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCalendarStatus: (calendar, start, getEvents) =>
      dispatch(toggleCalendarStatus(!calendar.active, calendar.id, start, getEvents)),
    changeColor: (calendar, color) => dispatch(changeColor(calendar, color, calendar.color)),
    syncCalendarAccounts: (start, currentType) =>
      dispatch(syncCalendarAccounts(start, currentType)),
    fetchUserLists: () => dispatch(getUserLists()),
    setCurrentList: list => dispatch(setCurrent(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarsRoute);
