const UPDATE_PATH = 'artful-client/Location/UPDATE_PATH';

const initialState = {
  data: {
    path: '',
    hash: '',
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PATH:
      return { ...state, data: { path: action.path, hash: action.hash ? action.hash : '' } };
    default:
      return state;
  }
}

export const updatePath = location => dispatch => {
  dispatch({ type: UPDATE_PATH, path: location.pathname, hash: location.hash });
};
