import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from './constants';

const initialState = {
  isFetching: false,
  colors: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      return { ...state, isFetching: false, colors: action.colors };
    case FETCH_FAILURE:
      return { ...state, isFetching: false };

    case STORE:
      return { ...state, isFetching: true };
    case STORE_SUCCESS:
      return { ...state, isFetching: false, colors: [...state.colors, action.color] };
    case STORE_FAILURE:
      return { ...state, isFetching: false };

    case DELETE:
      return { ...state, isFetching: true };
    case DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        colors: state.colors.filter(color => color.id != action.color.id),
      };
    case DELETE_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
