import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Modal from '~/Containers/Global/Modal/Modal';
import { REPORT_PROBLEM_MODAL } from '~/Config/Modals';
import { closeModal } from '~/Redux/Modules/Modal';
import CriticReport from '~/Components/Pages/Account/CriticReport';

import './ReportProblemModal.scss';

const ReportProblemModal = ({ modalId }) => {
  const dispatch = useDispatch();

  return (
    <Modal overlayClassName="report-problem-modal" id={modalId} displayOverlay ariaHideApp={false}>
      <CriticReport key="c" closeReport={() => dispatch(closeModal(modalId))} />
    </Modal>
  );
};

ReportProblemModal.propTypes = {
  modalId: propTypes.string,
};

ReportProblemModal.defaultProps = {
  modalId: REPORT_PROBLEM_MODAL,
};

export default ReportProblemModal;
