/* eslint-disable max-len */
import React from 'react';

export default function HabitLotusIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.505 12.389c0 3.32-1.93 6.158-3.406 7.86 1.93-.141 4.854-.709 6.782-2.639 2.214-2.214 2.61-5.732 2.667-7.577 0-.255-.198-.454-.425-.425-1.39.029-3.746.284-5.762 1.362.087.454.144.937.144 1.419zM14.66 5.153c-1.277 1.333-3.462 4.115-3.462 7.236 0 3.122 2.214 5.903 3.462 7.265.17.171.454.171.624 0 1.277-1.333 3.462-4.143 3.462-7.265 0-3.121-2.214-5.903-3.462-7.265a.423.423 0 0 0-.624.029zM12.843 20.25c-1.448-1.674-3.406-4.54-3.406-7.86 0-.512.057-.994.142-1.449C7.564 9.864 5.21 9.636 3.82 9.58c-.256 0-.455.199-.426.426.056 1.844.454 5.363 2.667 7.576 1.93 1.96 4.825 2.527 6.783 2.669z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="m25.187 18.774-.085.085c-2.837 2.866-7.264 3.206-9.222 3.206h-1.816c-1.93 0-6.356-.34-9.222-3.207-.03-.029-.03-.056-.057-.056-2.1.738-3.264 1.618-3.264 2.355 0 1.561 5.25 3.831 13.479 3.831 8.23 0 13.48-2.27 13.48-3.83 0-.738-1.192-1.647-3.293-2.384z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
