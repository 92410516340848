/* eslint-disable max-len */
import React from 'react';

export default function HabitCheckmarkCircleIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#gaanqxha6a)">
        <path
          className="fill"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.067 15.467c0-5.24-4.51-9.467-10.036-9.467-5.589 0-10.098 4.227-10.098 9.467 0 5.18 4.51 9.408 10.098 9.408 5.527 0 10.036-4.228 10.036-9.408zm-15.66 1.606 3.3 3.3 8.799-8.799-1.1-1.1-7.699 7.7-2.2-2.2-1.1 1.1z"
          fill={props.fill ? props.fill : '#000'}
        />
      </g>
      <defs>
        <clipPath id="gaanqxha6a">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
