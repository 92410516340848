import { connect } from 'react-redux';

import AdminEditSkins from '~/Components/Pages/Admin/AdminSkins/AdminEditSkins';
import {
  getSkin,
  updateForm,
  updateSkin,
  clearSkin,
  updateFormImage,
} from '~/Redux/Modules/Admin/Skins';

const mapStateToProps = ({ Skins }) => {
  return {
    isUpdating: Skins.isUpdating,
    actionSkin: Skins.actionSkin,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSkin: data => dispatch(updateSkin(data)),
    updateForm: update => dispatch(updateForm(update)),
    updateFormImage: (image, name) => dispatch(updateFormImage(image, name)),
    getSkin: skinId => dispatch(getSkin(skinId)),
    clearSkin: () => dispatch(clearSkin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditSkins);
