import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArtfulCheckbox from '~/Components/Global/Form/ArtfulCheckbox';
import { updateUserOptions } from '~/Redux/Modules/UserOptions';

import './ToggleIntlDates.scss';

const ToggleIntlDates = () => {
  const userOptions = useSelector(({ UserOptions }) => UserOptions.data);
  const use_intl_dates = userOptions?.use_intl_dates;
  const dispatch = useDispatch();

  const setAutoRollover = use_intl_dates => {
    dispatch(updateUserOptions(userOptions.id, { ...userOptions, use_intl_dates }));
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  return (
    <li className="toggle-intl-dates" onClick={handleClick}>
      <span>
        <ArtfulCheckbox
          label="Use International Dates"
          value={use_intl_dates}
          onChange={() => setAutoRollover(!use_intl_dates)}
          name="intlDates"
        />
      </span>
    </li>
  );
};

export default ToggleIntlDates;
