import reducer from './reducers';

export { getColors, addColor, deleteColor } from './actions';

export {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from './constants';

export default reducer;
