import { connect } from 'react-redux';

import Styles from '~/Components/Layout/ApplicationNavigation/RouteSections/Styles/Styles';
import { updateFont, updateUserOptions, fontNameForEvents } from '~/Redux/Modules/UserOptions';

const mapStateToProps = ({ Fonts, UserOptions }) => {
  return {
    userOptions: UserOptions.data,
    fonts: Fonts.data,
    isFetching: UserOptions.isFetching,
    fontNameForEvents: fontNameForEvents({ Fonts, UserOptions }),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFont: (id, font) => dispatch(updateFont(id, font)),
    updateUserOptions: userOptions => dispatch(updateUserOptions(userOptions.id, userOptions)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Styles);
