import { drawingsApi } from '~/Providers/Api';
import { success, error } from '~/Redux/Modules/Notifications';
import { parseErrors } from '~/Redux/Helpers';
import Store from '~/Redux/Store';
import { getStartAndEnd } from './helpers';

import {
  SET_DRAWING_DATA,
  CLEAR_DRAWING,
  RESET_DRAWINGS,
  FETCH_DRAWINGS,
  FETCH_DRAWINGS_SUCCESS,
  FETCH_DRAWINGS_FAILURE,
  STORE,
  STORE_SUCCESS,
  STORE_FAILURE,
} from './constants';

export const modifyDrawingState = data => {
  return { type: SET_DRAWING_DATA, data };
};

export const clearDrawing = () => {
  return { type: CLEAR_DRAWING };
};

export const resetDrawings = () => {
  return { type: RESET_DRAWINGS };
};

export const getDrawings = (start, end) => async dispatch => {
  dispatch({ type: FETCH_DRAWINGS });
  try {
    const response = await drawingsApi.get({ start: start, end: end });
    dispatch({ type: FETCH_DRAWINGS_SUCCESS, data: response.data });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_DRAWINGS_FAILURE, error: err });
    throw err;
  }
};

export const getDrawingsForView = () => async dispatch => {
  const {
    DateRange: { start, currentType },
  } = Store.getState();
  let startToUse = start.clone();
  const [rangeStart, rangeEnd] = getStartAndEnd(startToUse, currentType);
  return dispatch(getDrawings(rangeStart, rangeEnd));
};

export const saveDrawing = drawingData => async dispatch => {
  dispatch({ type: STORE });
  try {
    const response = await drawingsApi.store(drawingData);
    dispatch({ type: STORE_SUCCESS, data: response.data });
    dispatch(getDrawingsForView());
    dispatch(success('Drawing Saved!'));
    return response;
  } catch (err) {
    dispatch({ type: STORE_FAILURE, error: err });
    dispatch(error('Drawing was not saved', parseErrors(err)));
  }
};
