import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { appFeatureEnabled } from '~/Redux/Modules/AppFeatures';

const AppFeatureView = ({ featureName, children, disabledView }) => {
  const featureEnabled = useSelector(state => appFeatureEnabled(state, featureName));

  if (!featureEnabled) {
    return disabledView || null;
  }

  return children;
};

AppFeatureView.propTypes = {
  featureName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabledView: PropTypes.node,
};

export default AppFeatureView;
