/* eslint-disable max-len */
import React from 'react';

export default function StickerUploadIcon(props) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className="stroke"
        d="M6 .625h28A5.375 5.375 0 0 1 39.375 6v16.695l-16.359 16.68H6A5.375 5.375 0 0 1 .625 34V6A5.375 5.375 0 0 1 6 .625z"
        stroke="#919191"
        strokeWidth="1.25"
      />
      <path
        className="fill"
        d="M16.123 25.352h7.464V17.89h4.976L19.855 9.18l-8.707 8.708h4.975v7.463zm-4.976 2.488h17.416v2.488H11.148V27.84z"
        fill="#919191"
      />
      <path
        className="fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.182 22.183a6 6 0 0 0-5.887 5.999v11.817l9.18-8.525 8.197-9.508-11.49.217z"
        fill="#919191"
      />
    </svg>
  );
}
