import Moment from 'moment-timezone';
import { monkey } from 'moment-round';
import { extendMoment } from 'moment-range';

const moment = extendMoment(monkey(Moment));
window.moment = moment;

export const setMondayStart = value => {
  console.log(`setMondayStart: ${value}`);
  moment.locale(value ? 'en-gb' : 'en');
};

export default moment;
