import React, { useCallback, useRef } from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import debounce from 'lodash.debounce';

import './AddColorButton.scss';
import { useMountEffect } from '~/util';

function AddColorButton({ value, onInput, onChange, isCircle }) {
  const inputRef = useRef(null);

  // This is necessary to achieve Javascript's default onChange behavior,
  // because React makes no distinction between onChange and onInput in JSX nodes.
  useMountEffect(() => {
    const input = inputRef.current;
    input.addEventListener('change', handleChange);

    return () => {
      input.removeEventListener(handleChange);
    };
  });

  const debouncedOnChange = useCallback(
    debounce(value => {
      onChange(value);
    }, 300),
    [onChange]
  );

  const handleChange = useCallback(
    ({ target: { value } }) => {
      debouncedOnChange(value);
    },
    [debouncedOnChange]
  );

  return (
    <div className={cn('grid-button-wrapper', { circle: isCircle })}>
      <input
        ref={inputRef}
        className="add-color-input"
        type="color"
        value={value}
        onClick={e => e.stopPropagation()}
        onChange={onInput} // This is effectively onInput
      />
      <div className="button add-color-button" role="button" tabIndex={0}>
        +
      </div>
    </div>
  );
}

AddColorButton.propTypes = {
  value: propTypes.string.isRequired,
  onInput: propTypes.func,
  onChange: propTypes.func,
  isCircle: propTypes.bool,
};

AddColorButton.defaultProps = {
  isCircle: false,
};

export default AddColorButton;
