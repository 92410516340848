import React from 'react';
import propTypes from 'prop-types';
import Geosuggest from 'react-geosuggest';

import 'react-geosuggest/module/geosuggest.css';
import './GeoSuggest.scss';

const GeoSuggest = ({ name, label, placeholder, value, className, onChange }) => {
  const updateField = address => {
    console.log('change', address);
    address = { label: '', ...address };
    onChange({ [name]: address.description || address.label });
  };

  return (
    <div className="flex-row">
      <label htmlFor={name} className="inline-label">
        {label}
      </label>
      <Geosuggest
        name={name}
        className={`minimal-input inline-input ${className}`}
        initialValue={value}
        placeholder={placeholder}
        onSuggestSelect={updateField}
        onChange={value => updateField({ description: value })}
      />
    </div>
  );
};

GeoSuggest.propTypes = {
  name: propTypes.string,
  className: propTypes.string,
  label: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  placeholder: propTypes.string,
};

export default GeoSuggest;
