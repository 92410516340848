/* eslint-disable max-len */
import React from 'react';

export default function HabitWineGlassIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.555 4.327A.34.34 0 0 0 19.22 4h-8.438a.34.34 0 0 0-.334.327l-.447 8.42c0 .428.15 1.46 1.557 1.81l3.108.685v10.065h-2.658a.34.34 0 0 0-.335.347c0 .191.15.346.335.346h5.986a.34.34 0 0 0 .335-.346.34.34 0 0 0-.335-.346h-2.658V15.242l3.117-.687c1.398-.348 1.548-1.38 1.548-1.827l-.446-8.4z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
