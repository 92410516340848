import PropTypes from 'prop-types';
import { useConsentSettings } from './useConsentSettings';

export const CONSENT_TYPE_COOKIES = 'cookie_opt_in';
export const CONSENT_TYPE_EMAIL = 'email_opt_out';
const CONSENT_TYPES = [CONSENT_TYPE_COOKIES, CONSENT_TYPE_EMAIL];

export const ConsentRequiredView = ({ children, consentType, disabledView }) => {
  const consentSettings = useConsentSettings();

  if (!consentSettings?.[consentType]) return disabledView || null;

  return children;
};

ConsentRequiredView.propTypes = {
  consentType: PropTypes.oneOf(CONSENT_TYPES).isRequired,
  children: PropTypes.node.isRequired,
  disabledView: PropTypes.node,
};

export default ConsentRequiredView;
