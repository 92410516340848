import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import './ChangeDisplay.scss';
import Button from '~/Components/Global/Form/Button';
import { openModal, setModalData } from '~/Redux/Modules/Modal';
import { EVENT_DISPLAY_WARNING_MODAL } from '~/Config/Modals';
import ArtfulCheckbox from '../Global/Form/ArtfulCheckbox';

const ChangeDisplay = ({ event, currentCalendar, onSave }) => {
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const dispatch = useDispatch();

  const getDisplayMonthViewValue = event => {
    return event.display_month_view ?? currentCalendar.display_month_view;
  };

  const getDisplayWeekViewValue = event => {
    return event.display_week_view ?? currentCalendar.display_week_view;
  };

  const getDisplayDayViewValue = event => {
    return event.display_day_view ?? currentCalendar.display_day_view;
  };

  const changeEventDisplayView = (event, displayOptions) => {
    const { display_month_view, display_week_view, display_day_view } = displayOptions;
    const currentDisplayMonthView = getDisplayMonthViewValue(event);
    const currentDisplayWeekView = getDisplayWeekViewValue(event);
    const currentDisplayDayView = getDisplayDayViewValue(event);

    let hiddenViewsCount = 0;
    if (currentDisplayMonthView === 0 || !currentDisplayMonthView) hiddenViewsCount++;
    if (currentDisplayWeekView === 0 || !currentDisplayWeekView) hiddenViewsCount++;
    if (currentDisplayDayView === 0 || !currentDisplayDayView) hiddenViewsCount++;

    let isUnhidingFromView = display_month_view || display_week_view || display_day_view;

    if (hiddenViewsCount >= 2 && !isUnhidingFromView) {
      dispatch(setModalData({ onSave, displayOptions }));
      dispatch(openModal(EVENT_DISPLAY_WARNING_MODAL));
    } else {
      onSave(displayOptions);
    }
  };

  return (
    <div className="change-display">
      {showCheckboxes ? (
        <div className="change-display-checkboxes">
          <div className={event.display_month_view != null ? '' : 'half-opacity'}>
            <ArtfulCheckbox
              label={
                getDisplayMonthViewValue(event) ? 'Display on Month View' : 'Hidden from Month View'
              }
              value={getDisplayMonthViewValue(event)}
              onChange={newValue => {
                const booleanValue = Object.values(newValue)[0];
                changeEventDisplayView(event, { display_month_view: booleanValue });
              }}
              name="displayMonthView"
            />
          </div>
          <div className={event.display_week_view != null ? '' : 'half-opacity'}>
            <ArtfulCheckbox
              label={
                getDisplayWeekViewValue(event) ? 'Display on Week View' : 'Hidden from Week View'
              }
              value={getDisplayWeekViewValue(event)}
              onChange={newValue => {
                const booleanValue = Object.values(newValue)[0];
                changeEventDisplayView(event, { display_week_view: booleanValue });
              }}
              name="displayWeekView"
            />
          </div>
          <div className={event.display_day_view != null ? '' : 'half-opacity'}>
            <ArtfulCheckbox
              label={getDisplayDayViewValue(event) ? 'Display on Day View' : 'Hidden from Day View'}
              value={getDisplayDayViewValue(event)}
              onChange={newValue => {
                const booleanValue = Object.values(newValue)[0];
                changeEventDisplayView(event, { display_day_view: booleanValue });
              }}
              name="displayDayView"
            />
          </div>
        </div>
      ) : (
        <div className="change-display-button">
          <Button
            className="change-display-btn"
            text="CHANGE DISPLAY"
            onClick={() => setShowCheckboxes(true)}
          />
        </div>
      )}
    </div>
  );
};

ChangeDisplay.propTypes = {
  onSave: PropTypes.func.isRequired,
  currentCalendar: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};

export default ChangeDisplay;
