/* eslint-disable max-len */
import React from 'react';

export default function HabitSoapIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 7.666c.734 0 1.333-.599 1.333-1.334C10.333 5.597 9.734 5 8.999 5c-.735 0-1.333.598-1.333 1.333s.598 1.334 1.333 1.334zm0-2A.668.668 0 1 1 8.997 7a.668.668 0 0 1 .001-1.335zM8.666 9.5a1.501 1.501 0 0 0 3 0 1.501 1.501 0 0 0-3 0zm2.333 0c0 .458-.374.832-.833.832a.835.835 0 0 1-.833-.833c0-.46.374-.833.833-.833.46 0 .833.374.833.833z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.334 15c0 .329-.068.649-.198.943.528.328.864.915.864 1.556a1.836 1.836 0 0 1-2.667 1.632v4.175c0 .847 0 1.27-.211 1.482-.212.212-.636.212-1.482.212H9.36c-.847 0-1.27 0-1.482-.212-.212-.212-.212-.635-.212-1.482v-4.025c-.97-.193-1.667-.916-1.667-1.782 0-.532.268-1.03.732-1.375a1.663 1.663 0 0 1 .42-1.633 1.494 1.494 0 0 1-.144-.5c-.058.005-.116.008-.175.008C5.822 14 5 13.102 5 12c0-1.102.823-2 1.833-2 1.011 0 1.834.898 1.834 2 0 .115-.01.23-.026.34.76.071 1.36.713 1.36 1.494 0 .323-.105.634-.293.887.19.276.292.603.292.946h5.746a1.69 1.69 0 0 1-.079-.5c0-1.01.898-1.834 2-1.834h.007a2.246 2.246 0 0 1-.007-.166c0-1.011.748-1.834 1.667-1.834.837 0 1.531.682 1.65 1.566A2.336 2.336 0 0 1 24.333 15zM7.3 16.546c-.398.222-.635.578-.635.953 0 .593.596 1.103 1.357 1.16.046.004.095.006.143.006.828 0 1.5-.523 1.5-1.166 0-.309-.156-.6-.438-.821a.333.333 0 0 1-.07-.451.938.938 0 0 0 .141-.308.995.995 0 0 0-.27-.973.333.333 0 0 1 .018-.492.815.815 0 0 0 .286-.622.835.835 0 0 0-1.048-.805.333.333 0 0 1-.402-.45A1.46 1.46 0 0 0 8 11.999c0-.735-.523-1.333-1.166-1.333-.644 0-1.167.598-1.167 1.333s.523 1.334 1.167 1.334c.135 0 .271-.029.403-.085a.334.334 0 0 1 .452.391.827.827 0 0 0 .16.71.334.334 0 0 1-.073.487 1 1 0 0 0-.338 1.27c.08.16.02.354-.136.441zm14.957 1.682c.223.279.553.437.909.437a1.168 1.168 0 0 0 .398-2.262.333.333 0 0 1-.167-.498A1.668 1.668 0 0 0 22 13.332c-.433 0-.841.165-1.149.463a.333.333 0 0 1-.555-.318c.024-.1.037-.208.037-.311 0-.643-.448-1.167-1-1.167s-1 .524-1 1.167c0 .152.025.3.076.434a.334.334 0 0 1-.392.44 1.45 1.45 0 0 0-.35-.04c-.736 0-1.334.523-1.334 1.166 0 .173.044.343.129.5h3.808c.119 0 .229.064.289.168.26.453.724.759 1.24.818a.333.333 0 0 1 .273.448 1.136 1.136 0 0 0-.072.4c0 .27.089.522.258.729zm-10.05.504a.675.675 0 0 0-.675.675v2.01c0 .373.302.675.675.675h5.53a.675.675 0 0 0 .675-.675v-2.01a.675.675 0 0 0-.675-.675h-5.53z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
