import { connect } from 'react-redux';

import PaymentModal from '~/Components/Global/Stripe/PaymentModal';

import { getUser } from '~/Redux/Modules/User';
import { makePurchase } from '~/Redux/Modules/Products';

const mapStateToProps = ({ User, Products }) => {
  return {
    user: User.data,
    isUpdating: User.isUpdating,
    product: Products.current,
    currentType: Products.currentType,
    isBuying: Products.isBuying,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    makePurchase: (product, type) => dispatch(makePurchase(product, type)),
    fetchUser: () => dispatch(getUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
