import { ADMIN } from '~/Config/Roles';

export function hasRoles(user, roles = false) {
  if (!roles) return true;
  if (typeof roles === 'string') roles = [roles];

  let hasRoles = false;
  roles.map(requiredRole => {
    if (!hasRoles) {
      hasRoles = user.role === ADMIN || user.role === requiredRole;
    }
  });
  return hasRoles;
}
