import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArtfulCheckbox from '~/Components/Global/Form/ArtfulCheckbox';
import { updateUserOptions } from '~/Redux/Modules/UserOptions';

import './ToggleAutoRollover.scss';
import { fetchForCurrentView } from '~/Redux/Modules/Tasks/actions';

const ToggleAutoRollover = () => {
  const userOptions = useSelector(({ UserOptions }) => UserOptions.data);
  const auto_rollover = userOptions?.auto_rollover;
  const dispatch = useDispatch();

  const setAutoRollover = auto_rollover => {
    dispatch(updateUserOptions(userOptions.id, { ...userOptions, auto_rollover })).then(() => {
      if (auto_rollover) dispatch(fetchForCurrentView());
    });
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  return (
    <li className="toggle-auto-rollover" onClick={handleClick}>
      <span>
        <ArtfulCheckbox
          label="Rollover Unchecked Tasks"
          value={auto_rollover}
          onChange={() => setAutoRollover(!auto_rollover)}
          name="autoRollover"
        />
      </span>
    </li>
  );
};

export default ToggleAutoRollover;
