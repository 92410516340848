const ADD_DAY = 'artful-client/MonthSelect/ADD_DAY';
const CLEAR_DAYS = 'artful-client/MonthSelect/CLEAR_DAYS';

const initialState = {
  start: null,
  end: null,
  selecting: false,
  forward: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_DAY: {
      let newState = { ...state, selecting: true };
      if (!newState.start) newState.start = action.day;
      if (!newState.end) newState.end = action.day;
      if (newState.forward) {
        if (action.day.isBefore(newState.start)) {
          newState.start = action.day;
          newState.forward = false;
        } else {
          newState.end = action.day;
        }
      } else {
        if (action.day.isAfter(newState.end)) {
          newState.end = action.day;
          newState.forward = true;
        } else {
          newState.start = action.day;
        }
      }
      return newState;
    }
    case CLEAR_DAYS:
      return initialState;
    default:
      return state;
  }
}

export const addDay = day => async dispatch => {
  dispatch({ type: ADD_DAY, day: day });
};

export const clearDays = () => async dispatch => {
  dispatch({ type: CLEAR_DAYS });
};
