import { ADD_UNDOABLE, CLEAR_UNDOABLE } from './constants';

const initialState = {
  current: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_UNDOABLE:
      return { ...state, current: action.undoable };
    case CLEAR_UNDOABLE: {
      const { stamp } = action;
      if (state.current?.stamp == stamp) return { ...state, current: null };
      return state;
    }
  }
  return state;
}
