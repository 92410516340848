/* eslint-disable max-len */
import React from 'react';

export default function HabitBellIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.313 5v2.575c-2.91.784-5.062 3.547-5.062 6.837v4.117l-2.25 2.353v1.765h6.75c0 1.298 1.009 2.353 2.25 2.353s2.25-1.055 2.25-2.353h6.75v-1.765l-2.25-2.353v-4.117c0-3.29-2.152-6.053-5.063-6.837V5h-3.374zm1.125 1.176h1.125V7.38a6.323 6.323 0 0 0-.562-.027c-.19 0-.377.01-.563.027V6.176zm-3.375 8.236H9.938c0-2.92 2.272-5.294 5.063-5.294v1.176c-2.17 0-3.938 1.848-3.938 4.118zm3.938 9.411c-.62 0-1.125-.527-1.125-1.176h2.25c0 .649-.505 1.177-1.125 1.177z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
