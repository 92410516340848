/* eslint-disable max-len */
import React from 'react';

export default function HabitPriorityIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.219 23.438h22.5V28.593H3.28v-5.156h.938zM3.281 14.063H26.72v7.968H3.28V14.062zM26.719 1.406H3.28V11.72c.002.517 0 .937 0 .937H26.72V1.406zM19.125 4.95l-4.636 4.734-.652.666s-.495-.489-.67-.666l-2.255-2.371.623-.583.615-.543 1.575 1.575 4.05-3.937.69.561.66.564z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
