/* eslint-disable max-len */
import React from 'react';

export default function HabitMoonIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.191 5c-3.93 1.457-6.19 5.244-6.19 9.681C5.001 20.381 9.621 25 15.32 25c4.437 0 8.224-2.26 9.681-6.19a10.149 10.149 0 0 1-3.802.714c-5.633 0-10.722-5.09-10.722-10.721A10.154 10.154 0 0 1 11.191 5z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
