/* eslint-disable max-len */
import React from 'react';

export default function HabitReadingIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.656 22.845c-.85 0-1.77.145-2.735.43a1.512 1.512 0 0 1-1.33-.245A1.493 1.493 0 0 1 4 21.836V8.751h-.5c-.275 0-.5.225-.5.5v16c0 .165.08.315.21.41a.512.512 0 0 0 .456.059c3.545-1.26 6.615-1.575 9.505-.955-.685-.77-2.106-1.92-4.515-1.92z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M14.5 9.91v14.97c-.435-.705-2.976-4.305-8.86-2.565a.499.499 0 0 1-.64-.48V8.24c0-.22.146-.415.36-.48 4.72-1.395 7.58.394 8.996 1.795.095.09.144.22.144.356zM15.525 9.91v14.97c.435-.705 2.975-4.305 8.86-2.565a.499.499 0 0 0 .64-.48V8.24a.504.504 0 0 0-.36-.48c-4.72-1.395-7.58.394-8.996 1.795a.488.488 0 0 0-.144.356z"
        fill={props.fill ? props.fill : '#000'}
      />
      <path
        d="M26.5 8.751H26v13.085c0 .475-.215.91-.596 1.196a1.521 1.521 0 0 1-1.33.244c-.96-.284-1.88-.43-2.73-.43-2.405 0-3.826 1.145-4.516 1.92 2.895-.615 5.96-.305 9.505.955a.51.51 0 0 0 .455-.06.505.505 0 0 0 .212-.41v-16c0-.275-.225-.5-.5-.5z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
