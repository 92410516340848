/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';

export default function StarburstOval(props) {
  return (
    <svg viewBox="0 0 54 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m27 0 3.657 4.057 5.577-3.002L37.53 5.68l6.825-1.585-1.222 4.632 7.25.024-3.593 4.081 6.8 1.63-5.53 3.039 5.53 3.039-6.8 1.63 3.593 4.081-7.25.024 1.222 4.632-6.825-1.585-1.296 4.624-5.577-3.002L27 35l-3.657-4.057-5.578 3.002-1.295-4.624-6.825 1.585 1.222-4.632-7.25-.024 3.593-4.081-6.8-1.63L5.94 17.5.41 14.461l6.8-1.63L3.617 8.75l7.25-.024-1.222-4.632L16.47 5.68l1.296-4.624 5.577 3.002L27 0z"
        fill="#AB3A72"
      />
    </svg>
  );
}
