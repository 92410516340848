import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '~/Containers/Global/Modal/Modal';
import { REVALIDATE_PASSWORD_MODAL } from '~/Config/Modals';
import { closeModal } from '~/Redux/Modules/Modal';
import TextInput from '../Global/Form/TextInput';

import './RevalidatePassword.scss';
import Button from '../Global/Form/Button';

const MainLogo = require('~/Components/Layout/ApplicationNavigation/MainLogo.png');

const RevalidatePassword = ({ modalId }) => {
  const dispatch = useDispatch();
  const { modalData } = useSelector(({ Modal }) => Modal);
  const [password, setPassword] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(closeModal(modalId));
    modalData.revalidatePromise?.resolve(password);
  };

  return (
    <Modal
      overlayClassName="revalidate-password-modal"
      id={modalId}
      ariaHideApp={false}
      afterOpenModal={() => setPassword('')}
      onClose={() => modalData.revalidatePromise?.reject()}
    >
      <div className="logo-wrapper">
        <img className="logo" src={MainLogo} alt="" />
      </div>
      {modalData?.body}
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Please verify your password"
          type="password"
          name="password"
          value={password}
          onChange={({ password }) => setPassword(password)}
        />
        <div className="submit-button">
          <Button type="submit" text="Verify" />
        </div>
      </form>
    </Modal>
  );
};

RevalidatePassword.propTypes = {
  modalId: PropTypes.string,
};

RevalidatePassword.defaultProps = {
  modalId: REVALIDATE_PASSWORD_MODAL,
};

export default RevalidatePassword;
