export const FETCH_ALL = 'artful-client/Task/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'artful-client/Task/FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'artful-client/Task/FETCH_ALL_FAILURE';

export const FETCH_ONE = 'artful-client/Task/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'artful-client/Task/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'artful-client/Task/FETCH_ONE_FAILURE';

export const CREATE = 'artful-client/Task/CREATE';
export const CREATE_SUCCESS = 'artful-client/Task/CREATE_SUCCESS';
export const CREATE_FAILURE = 'artful-client/Task/CREATE_FAILURE';

export const DELETE = 'artful-client/Task/DELETE';
export const DELETE_SUCCESS = 'artful-client/Task/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/Task/DELETE_FAILURE';

export const RESTORE = 'artful-client/Task/RESTORE';
export const RESTORE_SUCCESS = 'artful-client/Task/RESTORE_SUCCESS';
export const RESTORE_FAILURE = 'artful-client/Task/RESTORE_FAILURE';

export const SET_QUEUE = 'artful-client/Task/SET_QUEUE';
export const UPDATE_ITEM = 'artful-client/Task/UPDATE_ITEM';
export const UPDATE_SUCCESS = 'artful-client/Task/UPDATE_SUCCESS';
export const START_UPDATING = 'artful-client/Task/START_UPDATING';
export const FINISH_UPDATING = 'artful-client/Task/FINISH_UPDATING';
export const SET_LAST_UPDATE = 'artful-client/Task/SET_LAST_UPDATE';
export const SET_TASK_ERROR = 'artful-client/Task/SET_TASK_ERROR';

export const ADD_TEMP_UPDATE = 'artful-client/Task/ADD_TEMP_UPDATE';
export const REMOVE_TEMP_UPDATE = 'artful-client/Task/REMOVE_TEMP_UPDATE';

export const ADD_TEMP_DELETE = 'artful-client/Task/ADD_TEMP_DELETE';
export const REMOVE_TEMP_DELETE = 'artful-client/Task/REMOVE_TEMP_DELETE';

export const SHOW_REPEAT_POPUP = 'artful-client/Task/SHOW_REPEAT_POPUP';
export const HIDE_REPEAT_POPUP = 'artful-client/Task/HIDE_REPEAT_POPUP';
