import React from 'react';
import propTypes from 'prop-types';

const HorizontalTextInput = ({
  name,
  label,
  value,
  type,
  isDisabled,
  showLabel,
  handleChange,
  labelColClass,
  inputColClass,
}) => {
  const updateField = e => {
    let name = e.target.name;
    let value = e.target.value;
    let field = {};
    field[name] = value;
    handleChange(field);
  };

  return (
    <div className="form-group">
      <label className={`${labelColClass} control-label ${!showLabel && 'sr-only'}`} htmlFor={name}>
        {label}
      </label>
      <div className={inputColClass}>
        <input
          className="form-control"
          disabled={isDisabled}
          name={name}
          onChange={updateField}
          placeholder={label}
          type={type}
          value={value}
        />
      </div>
    </div>
  );
};

HorizontalTextInput.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  showLabel: propTypes.bool,
  value: propTypes.string,
  isDisabled: propTypes.bool,
  handleChange: propTypes.func,
  labelColClass: propTypes.string,
  inputColClass: propTypes.string,
};

HorizontalTextInput.defaultProps = {
  type: 'text',
  showLabel: true,
  isDisabled: false,
  labelColClass: 'col-xs-6',
  inputColClass: 'col-xs-6',
};

export default HorizontalTextInput;
