import { connect } from 'react-redux';

import Register from '~/Components/Pages/Register/Register';

import { fetchAll } from '~/Services/Container';

import {
  handleCreate,
  updateRegisterField,
  handleAppleCreate,
  handleGoogleCreate,
} from '~/Redux/Modules/Account';

const mapStateToProps = ({ Account }) => {
  return {
    fieldsRegister: Account.fieldsRegister,
    isFetching: Account.isRegistering,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAll: () => fetchAll(), //pass dispatches
    handleRegistration: data => dispatch(handleCreate(data)),
    handleAppleRegistration: data => dispatch(handleAppleCreate(data)),
    handleGoogleRegistration: data => dispatch(handleGoogleCreate(data)),
    updateField: data => dispatch(updateRegisterField(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
