import React, { Component } from 'react';
import propTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';

import config from '~/Config';
import Modal from '~/Containers/Global/Modal/Modal';
import Button from '~/Components/Global/Form/Button';

import './PaymentModal.scss';

import { PAYMENT_MODAL } from '~/Config/Modals';
import Spinner from '../Loading/Spinner';

const stripe = loadStripe(config.stripeKey, config.stripeOptions);

const MainLogo = require('~/Components/Layout/ApplicationNavigation/MainLogo.png');

class PaymentModal extends Component {
  constructor() {
    super();
    this.state = {
      changeCard: false,
    };
  }

  componentWillUnmount = () => {
    if (this.state.editPaymentWindowTimer) {
      clearInterval(this.state.editPaymentWindowTimer);
    }
  };

  handleSetupIntent = () => {
    const editPaymentWindow = window.open(
      `${window.location.origin}/change-payment-source`,
      '_blank',
      'popup,width=400,height=600'
    );

    const editPaymentWindowTimer = setInterval(() => {
      if (editPaymentWindow.closed) {
        this.setState({ ...this.state, editingPaymentMethod: null, editPaymentWindowTimer: null });
        clearInterval(editPaymentWindowTimer);
        this.props.fetchUser();
      }
    }, 100);

    this.setState({
      ...this.state,
      editingPaymentMethod: editPaymentWindow,
      editPaymentWindowTimer,
    });
  };

  formatPrice = price => {
    return `$${price.toFixed(2)}`;
  };

  render() {
    const { user, product, currentType, isBuying, isUpdating } = this.props;
    if (!user || !product) return false;
    const className = this.state.changeCard ? 'payment-modal expanded' : 'payment-modal';
    return (
      <Modal showCloseButton={false} overlayClassName={className} id={this.props.modalId}>
        <div className="logo-wrapper">
          <img className="logo" src={MainLogo} alt="" />
        </div>
        <h3 className="center margin-top margin-bottom">Purchasing: {product.description}</h3>
        <h3 className="center margin-top margin-bottom">
          Price: {this.formatPrice(product.price)}
        </h3>
        <div className="clear-fix margin-top">
          <span className="float-left">
            Current Card: {user.card_brand ?? user.pm_type}{' '}
            {user.card_last_four ?? user.pm_last_four}
          </span>
          {this.state.editingPaymentMethod ? (
            <span className="float-right">
              <Spinner />
            </span>
          ) : (
            <span
              className="float-right edit"
              onClick={() => this.handleSetupIntent()}
              role="button"
              tabIndex="0"
            >
              Update
            </span>
          )}
        </div>
        <div className="center clear-fix purchase-button">
          <Button
            isFetching={isBuying}
            disabled={this.state.changeCard}
            className="btn default"
            text="Confirm Purchase"
            actionText="...Purchasing"
            onClick={() => this.props.makePurchase(product, currentType)}
          />
        </div>
      </Modal>
    );
  }
}

PaymentModal.propTypes = {
  modalId: propTypes.string,
  currentType: propTypes.string,
  isBuying: propTypes.bool,
  isUpdating: propTypes.bool,
  user: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  product: propTypes.oneOfType([propTypes.bool, propTypes.object]),
  makePurchase: propTypes.func,
  fetchUser: propTypes.func.isRequired,
};

PaymentModal.defaultProps = {
  modalId: PAYMENT_MODAL,
};

export default PaymentModal;
