export const FETCH = 'artful-client/Colors/FETCH';
export const FETCH_SUCCESS = 'artful-client/Colors/FETCH_SUCCESS';
export const FETCH_FAILURE = 'artful-client/Colors/FETCH_FAILURE';

export const STORE = 'artful-client/Colors/STORE';
export const STORE_SUCCESS = 'artful-client/Colors/STORE_SUCCESS';
export const STORE_FAILURE = 'artful-client/Colors/STORE_FAILURE';

export const DELETE = 'artful-client/Colors/DELETE';
export const DELETE_SUCCESS = 'artful-client/Colors/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/Colors/DELETE_FAILURE';
