/* eslint-disable max-len */
import React from 'react';

export default function HabitSodaIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.928 3.19h2.523V1.5H13.554v1.69h.374zM11.641 12.048H18.374s-.01-.095-.039-.332c-.145-1.665-1.732-3.412-1.894-7.56.41-.238.048-.552-.085-.533H13.644c-.131-.02-.493.295-.083.534-.162 4.147-1.749 5.894-1.892 7.56l-.028.331zM18.152 22.526c.068-.87.523-5.201.523-5.201h-.113l-7.183-.001c-.016 0-.13-.005-.129 0 0 .563.504 4.331.572 5.202.068.89-.595 3.495-.518 4.276.065.643.397 1.794 1.436 1.605.369-.068.594-.23.752-.271.254-.07.404-.139.764.07.505.294.86.29.86.29s.332.067.914-.29c.242-.147.464-.148.78-.07.146.034.325.17.652.215.909.12 1.177-.95 1.236-1.55.08-.78-.612-3.386-.546-4.275zM18.693 16.756c.074-1.429-.166-3.168-.326-4.14h-6.73c-.162.972-.4 2.711-.327 4.14h7.383z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
