export const applyEventCommands = (events, commands) => {
  return commands.reduce((currentEvents, command) => {
    if (command.insert) {
      return [...currentEvents, command.insert];
    }

    if (command.remove) {
      return currentEvents.filter(event => {
        if (event.id !== command.remove.id) return true;
        if (command.at && event.start !== command.at) return true;
        if (command.after && event.start < command.after) return true;
        return false;
      });
    }

    if (command.update) {
      return currentEvents.map(event => {
        if (
          event.id === command.update.id &&
          event.startForRecurrence === command.update.startForRecurrence
        )
          return command.update;
        return event;
      });
    }
    return currentEvents;
  }, events);
};
