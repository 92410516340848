const SET_ACTIVE_PACK = 'artful-client/StickerPacksUi/SET_ACTIVE_PACK';

const TOGGLE = 'artful-client/StickerPacksUi/TOGGLE';

const CLEAR = 'artful-client/StickerPacksUi/CLEAR';

export const STICKERS_SEARCH = 'STICKERS_SEARCH';

const initialState = {
  activePack: false,
  tabOpen: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PACK:
      return { ...state, activePack: action.id };

    case TOGGLE:
      return {
        ...state,
        tabOpen: action.toggle,
        activePack: state.activePack === STICKERS_SEARCH ? false : state.activePack,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

export const setActivePack = id => dispatch => {
  dispatch({ type: SET_ACTIVE_PACK, id });
};

export const toggle = toggle => dispatch => {
  dispatch({ type: TOGGLE, toggle });
};

export const clear = () => dispatch => {
  dispatch({ type: CLEAR });
};
