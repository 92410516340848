/* eslint-disable max-len */
import React from 'react';

export default function HabitBriefcaseIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#3msrpwgv3a)" fill={props.fill ? props.fill : '#000'} className="fill">
        <path d="M14.99 15.042c1.876 0 6.071-.581 8.988-1.05v-1.76c0-.768.022-.768-.819-.768H6.82c-.819 0-.82.036-.82.768v1.76c2.918.45 7.113 1.05 8.99 1.05z" />
        <path d="M14.99 16.09c-1.877 0-6.072-.58-8.99-1.049v6.986c0 .768 0 .768.82.768h16.36c.82 0 .82 0 .82-.768v-6.985c-2.938.449-7.132 1.048-9.01 1.048zM12.053 10.772v-.262c0-.6.52-1.086 1.158-1.086h3.536c.64 0 1.159.487 1.159 1.086v.262h1.518v-.262c0-1.386-1.198-2.51-2.676-2.51H13.23c-1.478 0-2.676 1.124-2.676 2.51v.261h1.498z" />
      </g>
      <defs>
        <clipPath id="3msrpwgv3a">
          <rect width="30" height="30" rx="15" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
