import { appFeatureApi } from '~/Providers/Api';
import { FETCH, FETCH_FAILURE, FETCH_SUCCESS } from './constants';

export const fetchAppFeatures = () => async dispatch => {
  dispatch({ type: FETCH });

  try {
    const response = await appFeatureApi.fetch();
    dispatch({ type: FETCH_SUCCESS, data: response.data });

    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE });
  }
};
