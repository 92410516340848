import { connect } from 'react-redux';
import UserSettings from '~/Components/Pages/Account/UserSettings/UserSettings';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
