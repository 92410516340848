import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import history from '~/Providers/History';

import {
  getStickerPack,
  updateForm,
  updateStickerPack,
  clearStickerPack,
} from '~/Redux/Modules/Admin/StickerPacks';
import { getStickers } from '~/Redux/Modules/Admin/Stickers';

import StickerPackForm from '~/Components/Pages/Admin/AdminStickerPacks/StickerPackForm';

import './AdminStickerPacks.scss';

class AdminEditStickerPacks extends Component {
  componentDidMount = () => {
    const { match, getStickerPack } = this.props;
    getStickerPack(match.params.stickerPackId);
    this.props.getStickers();
  };

  componentWillUnmount = () => {
    this.props.clearStickerPack();
  };

  updateStickerPack = () => {
    return this.props
      .updateStickerPack(this.props.actionStickerPack)
      .then(() => history.push('/admin/sticker-packs'));
  };

  render() {
    const { isUpdating, actionStickerPack, updateForm, stickers } = this.props;
    return (
      <div className="admin-sticker-packs-edit">
        <StickerPackForm
          isUpdating={isUpdating}
          onSubmit={() => this.updateStickerPack()}
          data={actionStickerPack}
          onChange={updateForm}
          stickers={stickers}
          buttonText="Update"
          actionText="Updating"
        />
      </div>
    );
  }
}

AdminEditStickerPacks.propTypes = {
  actionStickerPack: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  isUpdating: propTypes.bool,
  match: propTypes.object,
  updateForm: propTypes.func,
  getStickerPack: propTypes.func,
  clearStickerPack: propTypes.func,
  updateStickerPack: propTypes.func,
  stickers: propTypes.object,
  getStickers: propTypes.func.isRequired,
};

const mapStateToProps = ({ StickerPacks, Stickers }) => {
  return {
    isUpdating: StickerPacks.isUpdating,
    actionStickerPack: StickerPacks.actionStickerPack,
    stickers: Stickers.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateStickerPack: data => dispatch(updateStickerPack(data)),
    updateForm: update => dispatch(updateForm(update)),
    getStickerPack: skinId => dispatch(getStickerPack(skinId)),
    clearStickerPack: () => dispatch(clearStickerPack()),
    getStickers: () => dispatch(getStickers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditStickerPacks);
