import { combineReducers } from 'redux';

import Account from '~/Redux/Modules/Account';
import AppFeatures from '~/Redux/Modules/AppFeatures/reducers';
import Apple from '~/Redux/Modules/Apple';
import Auth from '~/Redux/Modules/Auth';
import CalendarAccounts from '~/Redux/Modules/CalendarAccounts';
import Checklists from '~/Redux/Modules/Checklists/reducers';
import Colors from '~/Redux/Modules/Colors/reducers';
import DateRange from '~/Redux/Modules/DateRange/reducers';
import Drawings from '~/Redux/Modules/Drawings/reducers';
import DroppedStickers from '~/Redux/Modules/DroppedStickers';
import EventConfirmation from '~/Redux/Modules/EventConfirmation';
import Events from '~/Redux/Modules/Events/reducers';
import FeatureFlags from '~/Redux/Modules/FeatureFlags/reducers';
import Fonts from '~/Redux/Modules/Fonts';
import Google from '~/Redux/Modules/Google';
import Location from '~/Redux/Modules/Location';
import MealPreps from '~/Redux/Modules/MealPreps';
import Menus from '~/Redux/Modules/Menus';
import Modal from '~/Redux/Modules/Modal';
import MonthSelect from '~/Redux/Modules/MonthSelect';
import NativeWrapper from '~/Redux/Modules/NativeWrapper';
import Notifications from '~/Redux/Modules/Notifications';
import Outlook from '~/Redux/Modules/Outlook';
import Products from '~/Redux/Modules/Products';
import Quote from '~/Redux/Modules/Quote';
import SnoozePopup from '~/Redux/Modules/SnoozePopup';
import Subscription from '~/Redux/Modules/Subscription';
import Undo from '~/Redux/Modules/Undo';
import UserOptions from '~/Redux/Modules/UserOptions';
import User from '~/Redux/Modules/User';
import UserLists from '~/Redux/Modules/UserLists';
import SkinWrapper from '~/Redux/Modules/SkinWrapper';
import StickerPacksUi from '~/Redux/Modules/StickerPacksUi';
import Tasks from '~/Redux/Modules/Tasks/reducers';
import Water from '~/Redux/Modules/Water';
import Folders from '~/Redux/Modules/Folders';
import Habits from '~/Redux/Modules/Habits/reducers';
import HabitTrackeds from '~/Redux/Modules/HabitTrackeds/reducers';
import DayTrackers from '~/Redux/Modules/DayTrackers/reducers';

import Skins from '~/Redux/Modules/Admin/Skins';
import Stickers from '~/Redux/Modules/Admin/Stickers';
import StickerPacks from '~/Redux/Modules/Admin/StickerPacks';
import Users from '~/Redux/Modules/Admin/Users';

export default combineReducers({
  Account,
  AppFeatures,
  Apple,
  Auth,
  CalendarAccounts,
  Checklists,
  Colors,
  DateRange,
  Drawings,
  DroppedStickers,
  EventConfirmation,
  Events,
  FeatureFlags,
  Fonts,
  Google,
  Location,
  MealPreps,
  Menus,
  Modal,
  MonthSelect,
  NativeWrapper,
  Notifications,
  Outlook,
  Products,
  Quote,
  Subscription,
  Water,
  UserOptions,
  SkinWrapper,
  SnoozePopup,
  StickerPacksUi,
  Tasks,
  Undo,
  User,
  UserLists,
  Folders,
  Habits,
  HabitTrackeds,
  DayTrackers,

  //Admin States
  Skins,
  Stickers,
  StickerPacks,
  Users,
});
