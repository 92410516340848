import React from 'react';
import propTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import StickerUploadIcon from '../Assets/Svgs/StickerUploadIcon';

import './StickerDropZone.scss';

export default function StickerDropZone({ onDrop }) {
  return (
    <Dropzone id="sticker-dropzone" name="dropzone" onDrop={onDrop}>
      <StickerUploadIcon id="sticker-upload-icon" />
      Drag files to upload
      <br />
      <small>or</small>
      <br />
      <u className="text-magenta">Browse</u>
      <br />
      <br />
      <small>
        Max file size: <b className="text-dark-gray">10 MB</b>
      </small>
      <br />
      <small className="disclaimer">
        For best results, stickers should be a minimum of{' '}
        <b className="text-dark-gray">200x200 px</b> with transparency.
      </small>
    </Dropzone>
  );
}

StickerDropZone.propTypes = {
  onDrop: propTypes.func,
};
