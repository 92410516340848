export const FETCH = 'artful-client/UserLists/FETCH';
export const FETCH_SUCCESS = 'artful-client/UserLists/FETCH_SUCCESS';
export const FETCH_FAILURE = 'artful-client/UserLists/FETCH_FAILURE';

export const FETCH_ONE = 'artful-client/UserLists/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'artful-client/UserLists/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'artful-client/UserLists/FETCH_ONE_FAILURE';

export const UPDATE = 'artful-client/UserLists/UPDATE';
export const UPDATE_SUCCESS = 'artful-client/UserLists/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'artful-client/UserLists/UPDATE_FAILURE';

export const DELETE = 'artful-client/UserLists/DELETE';
export const DELETE_SUCCESS = 'artful-client/UserLists/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/UserLists/DELETE_FAILURE';

export const STORE = 'artful-client/UserLists/STORE';
export const STORE_SUCCESS = 'artful-client/UserLists/STORE_SUCCESS';
export const STORE_FAILURE = 'artful-client/UserLists/STORE_FAILURE';

export const CLEAR_LISTS = 'artful-client/UserLists/CLEAR_LISTS';

export const SET_CURRENT = 'artful-client/UserLists/SET_CURRENT';
export const UNSET_CURRENT = 'artful-client/UserLists/SET_CURRENT';

export const SET_QUEUE = 'artful-client/UserLists/SET_QUEUE';
export const START_UPDATING = 'artful-client/UserLists/START_UPDATING';
export const FINISH_UPDATING = 'artful-client/UserLists/FINISH_UPDATING';
export const SET_LAST_UPDATE = 'artful-client/UserLists/SET_LAST_UPDATE';

export const TOGGLE_RECOVER = 'artful-client/UserLists/TOGGLE_RECOVER';
