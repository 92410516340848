import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import moment from '~/Providers/Moment';

import { DATE_TIME_FORMAT } from '~/Redux/Constants';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
import {
  humanDateFormats,
  HUMAN_DATE_FORMAT,
  HUMAN_TIME_FORMAT,
} from '~/Redux/Modules/UserOptions';

class DatePicker extends PureComponent {
  handleFocusTime = () => {
    if (document.querySelector('.react-datepicker__time-list-item')) return;
    setTimeout(() => {
      let element = document.querySelector('.react-datepicker__time-list-item--selected');
      if (element) {
        element.scrollIntoView();
        return;
      }

      const parsedValue =
        typeof this.props.value === 'string'
          ? moment(this.props.value, DATE_TIME_FORMAT)
          : this.props.value.clone();
      const roundedValue = parsedValue.round(30, 'minutes').format('hh:mm A');
      document.querySelectorAll('.react-datepicker__time-list-item').forEach(item => {
        if (item.textContent === roundedValue) {
          item.scrollIntoView();
        }
      });
    });
  };

  handleBlurTime = event => {
    if (!event) return;
    let {
      currentTarget: { value },
    } = event;
    let { onChange, humanDateFormats } = this.props;
    let parsedValue =
      typeof this.props.value === 'string'
        ? moment(this.props.value, DATE_TIME_FORMAT)
        : this.props.value.clone();
    console.log('handleBlurTime', value, moment(value, humanDateFormats[HUMAN_TIME_FORMAT]));
    if (value.indexOf('a') >= 0 || value.indexOf('p') >= 0) {
      let newTime = moment(value, humanDateFormats[HUMAN_TIME_FORMAT]);
      let newDateTime = moment([
        parsedValue.year(),
        parsedValue.month(),
        parsedValue.date(),
        newTime.hour(),
        newTime.minutes(),
        0,
      ]);
      onChange(newDateTime);
      return;
    }

    if (parseInt(value.split(':')[0]) >= 6) {
      let newTime = moment(`${value}am`, humanDateFormats[HUMAN_TIME_FORMAT]);
      let newDateTime = moment([
        parsedValue.year(),
        parsedValue.month(),
        parsedValue.date(),
        newTime.hour(),
        newTime.minutes(),
        0,
      ]);
      console.log('handleBlurTime ->', newDateTime, this.timePicker);
      this.timePicker.setState({
        ...this.timePicker.state,
        inputValue: newDateTime.format(humanDateFormats[HUMAN_TIME_FORMAT]),
      });
      onChange(newDateTime);
    } else {
      let newTime = moment(`${value}pm`, humanDateFormats[HUMAN_TIME_FORMAT]);
      let newDateTime = moment([
        parsedValue.year(),
        parsedValue.month(),
        parsedValue.date(),
        newTime.hour(),
        newTime.minutes(),
        0,
      ]);
      console.log('handleBlurTime ->', newDateTime, this.timePicker);
      this.timePicker.setState({
        ...this.timePicker.state,
        inputValue: newDateTime.format(humanDateFormats[HUMAN_TIME_FORMAT]),
      });
      onChange(newDateTime);
    }
  };

  render() {
    let {
      name,
      value,
      label,
      onChange,
      onMonthChange,
      dateFormat,
      showTimeSelect,
      className,
      humanDateFormats,
      ...props
    } = this.props;
    const parsedValue = typeof value === 'string' ? moment(value, DATE_TIME_FORMAT) : value.clone();
    className += ' date-time';
    let style = {};
    let renderTimePicker = () => {
      if (!showTimeSelect) {
        return '';
      }
      return (
        <ReactDatePicker
          {...props}
          ref={r => (this.timePicker = r)}
          disabledKeyboardNavigation
          name={name}
          className={className}
          style={style}
          selected={parsedValue}
          onChange={onChange}
          dateFormat={humanDateFormats[HUMAN_TIME_FORMAT]}
          showTimeSelectOnly
          showTimeSelect
          timeIntervals={30}
          onFocus={this.handleFocusTime}
          onBlur={this.handleBlurTime}
        />
      );
    };

    let renderLabel = () => {
      if (!showTimeSelect) {
        return '';
      }
      return (
        <label className="inline-label" htmlFor={name}>
          on
        </label>
      );
    };

    return (
      <div className="date-picker-wrapper flex-row">
        <label className="inline-label" htmlFor={name}>
          {label}
        </label>
        {renderTimePicker()}
        {renderLabel()}
        <ReactDatePicker
          {...props}
          disabledKeyboardNavigation
          name={name}
          className={className}
          selected={parsedValue}
          onChange={onChange}
          dateFormat={humanDateFormats[HUMAN_DATE_FORMAT]}
          onMonthChange={onMonthChange}
          key={moment.locale()}
        />
      </div>
    );
  }
}

DatePicker.defaultProps = {
  value: moment(),
  showTimeSelect: true,
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onMonthChange: PropTypes.func,
};

const mapStateToProps = state => ({
  humanDateFormats: humanDateFormats(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
