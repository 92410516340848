/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';

export default function Search(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 6" {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#999;}' }} />
      </defs>
      <title>Search</title>
      <g id="layer1">
        <path
          className="cls-1"
          d="M 2.2480469 0 A 2.25 2.25 0 0 0 0 2.25 A 2.25 2.25 0 0 0 2.25 4.5 A 2.25 2.25 0 0 0 4.5 2.25 A 2.25 2.25 0 0 0 2.25 0 A 2.25 2.25 0 0 1 2.2480469 0 z M 2.25 0.19921875 A 2.05 2.05 0 0 1 4.3007812 2.25 A 2.05 2.05 0 0 1 2.25 4.3007812 A 2.05 2.05 0 0 1 0.19921875 2.25 A 2.05 2.05 0 0 1 2.25 0.19921875 z "
          id="path839"
        />
        <path
          className="cls-1"
          d="m -0.12047016,5.3530602 h 0.2409401 v 3.0117512 h -0.2409401 z"
          transform="rotate(-45)"
          id="rect871"
        />
      </g>
    </svg>
  );
}
