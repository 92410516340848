export const SET_DRAWING_DATA = 'artful-client/Drawings/SET_DRAWING_DATA';
export const RESET_DRAWINGS = 'artful-client/Drawings/RESET_DRAWINGS';
export const CLEAR_DRAWING = 'artful-client/Drawings/CLEAR_DRAWING';

export const FETCH_DRAWINGS = 'artful-client/Drawings/FETCH_DRAWINGS';
export const FETCH_DRAWINGS_SUCCESS = 'artful-client/Drawings/FETCH_DRAWINGS_SUCCESS';
export const FETCH_DRAWINGS_FAILURE = 'artful-client/Drawings/FETCH_DRAWINGS_FAILURE';

export const STORE = 'artful-client/Drawings/STORE';
export const STORE_SUCCESS = 'artful_client/Drawings/STORE_SUCCESS';
export const STORE_FAILURE = 'artful_client/Drawings/STORE_FAILURE';

export const DELETE = 'artful-client/Drawings/DELETE';
export const DELETE_SUCCESS = 'artful-client/Drawings/DELETE_SUCCESS';
export const DELETE_FAILURE = 'artful-client/Drawings/DELETE_FAILURE';
