import React from 'react';

const SwapToStripeSection = props => {
  return (
    <section className="payments__manage">
      <a href="/swap-to-stripe">SWAP TO WEB SUBSCRIPTION</a>
    </section>
  );
};

export default SwapToStripeSection;
