import React from 'react';
import propTypes from 'prop-types';

import './MyAccountSection.scss';

const MyAccountSection = ({ header, children }) => (
  <div className="my-account-section">
    <span className="my-account-header" style={{ fontSize: '1.5em' }}>
      {header}
    </span>
    <div className="my-account-content">{children}</div>
  </div>
);

MyAccountSection.propTypes = {
  header: propTypes.string,
  children: propTypes.node,
};

export default MyAccountSection;
