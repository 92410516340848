import { connect } from 'react-redux';

import UnAuthRoute from '~/Components/Global/Routes/UnAuthRoute';

const mapStateToProps = ({ Auth }) => {
  return {
    user: Auth.user,
    isAuthenticated: Auth.isAuthenticated,
    isFetching: Auth.isFetching,
    hasFetched: Auth.hasFetched,
  };
};

export default connect(mapStateToProps)(UnAuthRoute);
