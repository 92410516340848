/* eslint-disable max-len */
import React from 'react';

export default function HabitPotIcon(props) {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.581 7.65h-.675c1.21-1.388 5.119-2.222 9.441-2.456.872 0 1.763-.075 2.653-.075.89 0 1.782 0 2.653.075.694 0 1.37.084 2.025.16 3.479.355 6.404 1.133 7.416 2.296H3.581zm14.072-2.963v-1.33a.47.47 0 0 0-.469-.47h-4.368a.469.469 0 0 0-.469.47v1.33a45.543 45.543 0 0 1 5.306 0zM28.125 8.12H1.406v.469c0 1.612.938 2.878 2.11 2.878h1.171v12.768c-.016.762 0 2.87 0 2.87h20.625s.017-2.108 0-2.87V11.466h1.172c1.181 0 2.11-1.266 2.11-2.878v-.47h-.469z"
        fill={props.fill ? props.fill : '#000'}
      />
    </svg>
  );
}
