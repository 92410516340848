import { featureFlagApi } from '~/Providers/Api';
import { FETCH, FETCH_FAILURE, FETCH_SUCCESS } from './constants';

export const fetchFeatureFlags = () => async dispatch => {
  dispatch({ type: FETCH });
  try {
    const response = await featureFlagApi.fetch();
    dispatch({ type: FETCH_SUCCESS, data: response });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_FAILURE });
    console.log('Error fetching feature flags', err);
  }
  return null;
};
