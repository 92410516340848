import { connect } from 'react-redux';

import AdminStickers from '~/Components/Pages/Admin/AdminStickers/AdminStickers';
import { getStickers, updateForm, addSticker, deleteSticker } from '~/Redux/Modules/Admin/Stickers';

const mapStateToProps = ({ Stickers }) => {
  return {
    stickers: Stickers.data,
    isFetching: Stickers.isFetching,
    isAdding: Stickers.isAdding,
    isDeleting: Stickers.isDeleting,
    actionSticker: Stickers.actionSticker,
    actionId: Stickers.actionId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStickers: (page, search) => dispatch(getStickers(page, search)),
    addSticker: data => dispatch(addSticker(data)),
    updateForm: update => dispatch(updateForm(update)),
    deleteSticker: id => dispatch(deleteSticker(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStickers);
